import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import _ from 'lodash'
import { IMemberParams } from 'modules/Members/Member/Member.interfaces'
import axios from 'services/axios'
import { commonMessages } from 'utils/messages'
import { onCreateEndpointsWithClientid } from 'utils/common'

/**
 * service to get count on dashboard
 */
export function getDashboardCounts(program: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get(
		onCreateEndpointsWithClientid(ENDPOINTS.EPIDEMIOLOGIST_DASHBOARD_STATS) + '/' + program,
	)
	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

/**
 * service to get complete and incomplete MDEs
 */
export function getCompleteIncompleMDEs(program: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get(
		onCreateEndpointsWithClientid(ENDPOINTS.EPIDEMIOLOGIST_DASHBOARD_COMPLETED_INCOMPLETED) +
			'/' +
			program,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

/**
 * service to get least patient MDEs
 */
export function getLeastPatientMDE(program: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get(
		onCreateEndpointsWithClientid(ENDPOINTS.EPIDEMIOLOGIST_DASHBOARD_PATIENT_INCOMPLETE_MDE) +
			'/' +
			program,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

export function getLeastPerformingPartners(
	program: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get(
		onCreateEndpointsWithClientid(ENDPOINTS.EPIDEMiOLOGIST_DASHBOARD_LEAST_PERFORMING_PARTNERS) +
			'/' +
			program,
	)
	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

/**
 * service to get program partner list
 */
export function getProgramPartnerList(params: Partial<IMemberParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get(onCreateEndpointsWithClientid(ENDPOINTS.GET_HEALTHCARE_PARTNERS), {
		params: filteredParams,
	})

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

/**
 * service to get dashboard program partner list
 */

export function getDashboardPartnerList(
	params: Partial<IMemberParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get(onCreateEndpointsWithClientid(ENDPOINTS.PARTNER_LIST), {
		params: filteredParams,
	})

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

/**
 * service to get program  list
 */
export function getProgramClientFilter(params: Partial<IMemberParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get(onCreateEndpointsWithClientid(ENDPOINTS.PROGRAM_FILTER_LIST), {
		params: filteredParams,
	})

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

/**
 * service to get epidemiologist report program  list
 */
export function getEpidemiologistReportProgramListFilter(
	params: Partial<IMemberParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get(
		onCreateEndpointsWithClientid(ENDPOINTS.EPIDEMIOLOGIST_PROGRAM_FILTER),
		{
			params: filteredParams,
		},
	)

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}
