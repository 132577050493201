import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	UseMutationOptions,
	UseQueryOptions,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { FormikHelpers } from 'formik'
import axios, { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { errorNotify, successNotify } from 'utils/toster'
import { scrollToError } from 'utils/common'
import { commonMessages } from 'utils/messages'
import { assignTemplateToMember, getMemberTemplateDropdownList } from './MemberScreening.service'
import { useUrlQuery } from 'hooks/url'
import { MemberScreeningInitialState, MemberScreeningPayload } from './MemberScreening.types'
import { IAxiosResponseWithPagination } from 'types/entities'
import { ITemplatesList } from 'modules/Templates/Templates.interface'
import { IMemberTemplatesParams } from './MemberScreening.interface'

export function useInitial() {
	/**
	 * *useEffexts Section
	 * @param useEffectName
	 */

	return useMemo(() => {
		return new MemberScreeningInitialState()
	}, [])
}

export function useSubmitHandler() {
	const navigate = useNavigate()
	const params = useParams<{ id: string }>()
	const [, setUrlQuery] = useUrlQuery<IMemberTemplatesParams>()
	const client = useQueryClient()
	const { mutateAsync: create } = useAssignTemplateToMember()
	return useCallback(
		async (
			form: MemberScreeningInitialState,
			formikHelpers: FormikHelpers<MemberScreeningInitialState>,
		) => {
			formikHelpers.setSubmitting(true)

			try {
				await create(new MemberScreeningPayload({ ...form, patient: params?.id }))
				successNotify(commonMessages.assignedMessage)
				formikHelpers.resetForm()
				setUrlQuery((old) => ({
					...old,
					assginScreening: '',
				}))
				client.invalidateQueries([ENDPOINTS.TEMPLATE])
			} catch (err: any) {
				if (err?.response?.data?.message) {
					errorNotify(err.response.data.message)
				} else {
					formikHelpers.setErrors(err.response.data)
					errorNotify(commonMessages.errorHandlingMessage)
				}
				scrollToError()
			}
			client.invalidateQueries([ENDPOINTS.MEMBER_SCREENING_LIST])
			formikHelpers.setSubmitting(false)
		},
		[client, create, navigate],
	)
}

type ICreateTemplateProps = Partial<
	UseMutationOptions<AxiosResponse<MemberScreeningPayload>, any, Partial<MemberScreeningPayload>>
>

export function useAssignTemplateToMember({ ...rest }: ICreateTemplateProps = {}) {
	return useMutation<AxiosResponse<MemberScreeningPayload>, any, Partial<MemberScreeningPayload>>(
		async (data) =>
			assignTemplateToMember({
				...data,
			}),
		{
			...rest,
		},
	)
}
interface IMemberTemapltesDropdownQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<ITemplatesList>>>,
		Partial<IMemberTemplatesParams> {}

/**
 * * The useDropdownTemplates function is a React hook that takes in a set of query parameters.
 */
export function useMemberDropdownTemplates({
	page,
	limit,
	sort,
	search,
	conditions,
	client,
	patient,
	...rest
}: IMemberTemapltesDropdownQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<ITemplatesList>>,
		any,
		IAxiosResponseWithPagination<Partial<ITemplatesList>>
	>(
		[ENDPOINTS.MEMBER_TEMPLATE, page, limit, sort, search, conditions, client, patient],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getMemberTemplateDropdownList(
				{ page, limit, sort, search, conditions, client, patient },
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!(client && patient),
			...rest,
		},
	)
}
