import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
	IQuestionOptionsList,
	IQuestionnaireList,
	IQuestionnaireServiceParams,
	ISuggestionBoxList,
	ISuggestionBoxParams,
} from '../Questionnaires.interface'
import { useUrlQuery } from 'hooks/url'
import { QuestionnaireInitialState, QuestionnairePayload } from './Questionnaire.types'
import {
	UseMutationOptions,
	UseQueryOptions,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import axiosLibrary, { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import {
	createScreeningQuestion,
	getExistingQuestions,
	getScreeningQuestion,
	getSuggestionBoxList,
	updateScreeningQuestion,
} from './Questionnaire.service'
import { useNavigate } from 'react-router-dom'
import { IScreeningsList, IScreeningsParams } from '../Screenings/Screenings.interface'
import { FormikHelpers } from 'formik'
import { errorNotify, successNotify } from 'utils/toster'
import { commonMessages } from 'utils/messages'
import { scrollToError } from 'utils/common'
import { IAxiosResponseWithPagination } from 'types/entities'
import axios from 'axios'

export function useInitial({
	currentScreenData = null,
}: {
	currentScreenData: Partial<IScreeningsList> | null
}) {
	/**
	 * * Variables and hooks Section
	 */
	const alreadyMounted = useRef(false)
	const [urlQuery] = useUrlQuery<Partial<IQuestionnaireServiceParams>>()
	const isEdit = urlQuery?.isQuestionnaireEdit

	const { data, isFetching } = useScreeningQuestion({
		id: isEdit ?? '',
	})
	/**
	 * *useEffects Section
	 * * @param useEffectName
	 */
	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetching])

	/**
	 * *useMemo Section
	 */

	return useMemo(() => {
		return new QuestionnaireInitialState(
			isEdit ? { ...data?.data } : { isScore: currentScreenData?.scoring },
		)
	}, [isEdit, currentScreenData, data?.data])
}
//* Formik useSubmitHandler hook
export function useSubmitHandler({
	currentScreenData = null,
}: {
	currentScreenData: Partial<IScreeningsList> | null
}) {
	const { mutateAsync: create } = useCreateScreeningQuestion()
	const navigate = useNavigate()
	const [urlQuery, setUrlQuery] = useUrlQuery<IScreeningsParams & IQuestionnaireServiceParams>()
	const id = urlQuery.isQuestionnaireEdit ?? ''
	const parent = urlQuery.parent
	const isRoot = urlQuery?.isRoot
	const option = urlQuery?.child
	const { mutateAsync: update } = useUpdateScreeningQuestion({ id })
	const client = useQueryClient()
	return useCallback(
		async (
			form: QuestionnaireInitialState,
			formikHelpers: FormikHelpers<QuestionnaireInitialState>,
		) => {
			formikHelpers.setSubmitting(true)
			try {
				if (!id) {
					await create(
						new QuestionnairePayload({
							...form,
							templateScreening: currentScreenData?.id,
							parentQuestion: parent,
							option,
							isRoot: isRoot ? true : false,
						}),
					)
					successNotify(commonMessages.createMessage)
				}
				if (id) {
					await update(
						new QuestionnairePayload({
							...form,
							templateScreening: currentScreenData?.id,
							isRoot: isRoot ? true : false,
						}),
					)
					successNotify(commonMessages.updateMessage)
				}
				formikHelpers.setSubmitting(false)
				client.removeQueries([ENDPOINTS.QUESTION])
				client.invalidateQueries([ENDPOINTS.QUESTION])
				setUrlQuery((old) => ({
					...old,
					parent: '',
					child: '',
					isQuestionnaireEdit: '',
					isQuestionnaireAdd: '',
					bridgeId: '',
					isRoot: '',
				}))
			} catch (err: any) {
				if (err?.response?.data?.message) {
					errorNotify(err.response.data.message)
				} else {
					formikHelpers.setErrors(err.response.data) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
					errorNotify(commonMessages.errorHandlingMessage)
				}
				scrollToError()
			}
		},
		[client, create, id, navigate, update],
	)
}

interface IScreeningQuestionQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<any>>>,
		Partial<IQuestionnaireServiceParams> {}

export function useScreeningQuestion({ id, ...rest }: IScreeningQuestionQueryProps) {
	return useQuery<AxiosResponse<IQuestionnaireList>, any, AxiosResponse<IQuestionnaireList>>(
		[ENDPOINTS.QUESTION, id],
		() => {
			const CancelToken = axiosLibrary.CancelToken
			const source = CancelToken.source()
			return getScreeningQuestion(id, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}

//* Create question hook
type ICreateScreeningQuestionProps = Partial<
	UseMutationOptions<AxiosResponse<QuestionnairePayload>, any, Partial<QuestionnairePayload>>
>

export function useCreateScreeningQuestion({ ...rest }: ICreateScreeningQuestionProps = {}) {
	return useMutation<AxiosResponse<QuestionnairePayload>, any, Partial<QuestionnairePayload>>(
		createScreeningQuestion,
		{
			...rest,
		},
	)
}

//* Update partner hook
interface IUpdateScreeningQuestionTProps
	extends Partial<
		UseMutationOptions<AxiosResponse<QuestionnairePayload>, any, Partial<QuestionnairePayload>>
	> {
	id?: string
}

export function useUpdateScreeningQuestion({ id, ...rest }: IUpdateScreeningQuestionTProps) {
	return useMutation<AxiosResponse<QuestionnairePayload>, any, Partial<QuestionnairePayload>>(
		async (data) => await updateScreeningQuestion(id, data),
		{
			...rest,
		},
	)
}

interface IExistingQuestionsQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IQuestionnaireList>>>,
		Partial<IQuestionnaireServiceParams> {}
/**
 * * The useExistingQuestions function is a React hook that takes in a set of query parameters.
 */
export function useExistingQuestions({
	page,
	limit,
	sort,
	search,
	parent = '',
	isEnabled = true,
	screeningId,
	...rest
}: IExistingQuestionsQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IQuestionnaireList>>,
		any,
		IAxiosResponseWithPagination<Partial<IQuestionnaireList>>
	>(
		[ENDPOINTS.EXISTING_QUESTIONS, page, limit, sort, search, parent, screeningId],
		() => {
			const CancelToken = axiosLibrary.CancelToken
			const source = CancelToken.source()
			return getExistingQuestions(
				{
					page,
					limit,
					sort,
					search,
					questionId: parent,
					screeningId,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: isEnabled,
			...rest,
		},
	)
}

// Sugesstion box hook

interface ISiggestionBoxQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<ISuggestionBoxList>>>,
		Partial<ISuggestionBoxParams> {}

export function useSuggestionBoxList({ search, ...rest }: ISiggestionBoxQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<ISuggestionBoxList>>,
		any,
		IAxiosResponseWithPagination<Partial<ISuggestionBoxList>>
	>(
		[ENDPOINTS.QUESTION_LIBRARY_SUGGESTION_BOX, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getSuggestionBoxList(
				{
					search,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}
