import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'
import { CancelTokenSource } from 'axios'
import { commonMessages } from 'utils/messages'
import { ScreeningPayload } from './Screening.types'
import {
	IExistingScreeningList,
	IExistingScreeningParams,
	IScreeningsList,
} from '../Screenings.interface'
import { IPagination } from 'types/entities'
import _ from 'lodash'
import { onCreateEndpointsWithClientid } from 'utils/common'

/**
 * * GET Screening
 */
export function getScreeening(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<IScreeningsList>(
		onCreateEndpointsWithClientid(ENDPOINTS.SCREENINGS) + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * Create Sscreening
 */
export function createScreening(data: Partial<ScreeningPayload>, source?: CancelTokenSource) {
	const promise = axios.post<ScreeningPayload>(
		onCreateEndpointsWithClientid(ENDPOINTS.SCREENINGS),
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * * Update Screening
 */
export function updateScreening(
	id: string | undefined,
	data: Partial<ScreeningPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch<ScreeningPayload>(
		onCreateEndpointsWithClientid(ENDPOINTS.SCREENINGS) + '/' + id,
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * GET Existing Screenings (view existing screening list)
 */
export function getExistingScreenings(
	template: string,
	params: Partial<IExistingScreeningParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IExistingScreeningList>>>(
		onCreateEndpointsWithClientid(ENDPOINTS.EXISTING_SCREENINGS) + '/' + template,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
