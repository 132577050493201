import ServicesAssigned from './AssignedServices'
export function AssignedModulesContainer() {
	/**
	  JSX Section
	 * @returns AssignedModulesConatiner
	 */
	return (
		<div className="px-4 md:px-auto flex flex-col">
			<ServicesAssigned />
		</div>
	)
}
