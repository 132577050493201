import { useState } from 'react'
import './style.css'
import { useQueryClient } from '@tanstack/react-query'
import { errorNotify, successNotify } from 'utils/toster'

interface ToggleSwitchProps {
	checked: boolean | undefined
	onChange?: (checked?: boolean | undefined) => void
	id: number
	statusToggle: any
	data: any
	url: string
	disabled: boolean
	setPayload?: any
	setCareTeamChecked?: any
	setIsEmailChecked?: any
	setIsSMSChecked?: any
}

export default function ToggleSwitch(props: ToggleSwitchProps | any) {
	// const profile = useMyProfile()
	// const isCHCT = profile.role === ROLES.CHCT
	const client = useQueryClient()
	const id = props.id
	const [isChecked, setIsChecked] = useState<any>(props.checked)
	const disabled = props?.disabled ?? false
	async function handleChange() {
		try {
			setIsChecked(!isChecked)
			props?.setCareTeamChecked && props?.setCareTeamChecked(!isChecked)
			props?.setIsEmailChecked && props?.setIsEmailChecked(!isChecked)
			props?.setIsSMSChecked && props?.setIsSMSChecked(!isChecked)
			await props?.statusToggle(id).then(async (response: any) => {
				successNotify(response?.data?.message)
				await client.invalidateQueries(props.url)
			})
		} catch (error: any) {
			errorNotify(error?.response?.data?.message)
			setIsChecked(props?.checked)
		}
	}

	return (
		<div className={`${disabled && 'cursor-not-allowed'}`}>
			<label className={`switch`}>
				<input
					type="checkbox"
					checked={isChecked}
					disabled={disabled}
					onChange={handleChange}
					className={`opacity-0 w-0 h-0 `}
				/>
				<span className="slider round"></span>
			</label>
		</div>
	)
}
