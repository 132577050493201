import { useEffect, useMemo, useRef, useState } from 'react'
import { AdvancedSelectField } from 'components/AdvancedSelectField'
import {
	setLocalStorageDetails,
	useMyProfile,
	useServicesClientsAllDropdownOptions,
} from 'hooks/common'
import { useUrlQuery } from 'hooks/url'
import { SelectOption } from 'types/entities'
import { DROPDOWN_LIMIT, LOCAL_STORAGE } from 'const/common'
import { onStringify } from 'utils/common'
import AdvancedScrollSelectDropdown from 'components/AdvancedScrollSelectField/AdvancedScrollSelectDropdown'

const ServicTableFilter = (props?: any) => {
	/**
	 * *   Custom hooks Section
	 */
	const alreadyMount: any = useRef(false)
	const profile: any = useMyProfile()
	const [params, setParams] = useUrlQuery<any>()
	/**
	 * *   Variables Section
	 */
	const isSuperAdminOptionsFind = profile?.providerRole?.find((role: any) => {
		return role?.roles?.isSuperAdmin
	})

	/**
	 * *   State Section
	 */
	const [clientDropdownProp, setClientDropdownProp] = useState(1)

	/**
	 * *   API Hooks Section
	 */
	const { options, isFetching, meta, makeSearch, search } = useServicesClientsAllDropdownOptions({
		limit: DROPDOWN_LIMIT.LIMIT,
		page: clientDropdownProp,
		setPage: setClientDropdownProp,
		isEnabled: !!isSuperAdminOptionsFind,
	})

	/**
	 * *   This function create for Options shown in client dropdown in case when not logged in with Super Admin..
	 */
	const clientOptions = () => {
		const clientOptionsLabel = profile?.providerRole?.map((item: any) => {
			return {
				label: item?.client?.title,
				value: item?.client?.id,
				id: item?.client?.id,
				rolesId: item?.roles?.id,
				rolesTitle: item?.roles?.roleName,
				rolesSlug: item?.roles?.slug,
				providerRole: item?.id,
			}
		})
		return clientOptionsLabel
	}
	const clientDropdownOptions: any = !!isSuperAdminOptionsFind ? options : clientOptions()

	/**
	 * *   Function Section
	 */

	const clientValue = useMemo(() => {
		const findOptions = clientDropdownOptions?.find((item: any) => {
			return item?.value === params?.client
		})
		if (findOptions) {
			return new SelectOption(findOptions?.value, findOptions?.label)
		} else return null
	}, [params?.client, clientDropdownOptions])

	const handleClientChange = (value: any) => {
		onSelectClient(value)
	}

	//  This function is used for on selection of client
	const onSelectClient = (selectedObj: any) => {
		const storeClientRoleAndProviderRole = onStringify({
			clientDetails: {
				clientLabel: selectedObj?.label ?? undefined,
				clientId: selectedObj?.id ?? undefined,
				carehalo: selectedObj?.careHalo ?? false,
			},
			roleDetails: {
				rolesSlug: isSuperAdminOptionsFind
					? isSuperAdminOptionsFind?.roles?.slug
					: selectedObj?.rolesSlug,
				rolesId: selectedObj?.rolesId,
			},
			providerRoleDetails: {
				providerRole: selectedObj?.providerRole,
			},
		})
		setLocalStorageDetails(LOCAL_STORAGE?.INHERITED_LOGIN, storeClientRoleAndProviderRole)
		setParams((prev) => {
			return {
				...prev,
				client: selectedObj?.value ?? undefined,
				role: selectedObj?.roles ?? undefined,
				providerRole: selectedObj?.providerRole ?? undefined,
			}
		})
	}
	useEffect(() => {
		if (!params?.client) {
			props?.setServiceDetail([])
		}
	}, [params?.client])

	useEffect(() => {
		if (clientDropdownOptions?.length === 1 && !alreadyMount.current) {
			onSelectClient(clientDropdownOptions[0])
			alreadyMount.current = true
		}
	}, [clientDropdownOptions])

	/**
	 * *JSX Section
	 * @returns Service Table Filters
	 */
	return (
		<>
			<AdvancedScrollSelectDropdown
				isClearable
				className="sm:w-[420px] select-with-shadow absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
				label="Select Client"
				placeholder="Select Client"
				isFetching={isFetching}
				options={clientDropdownOptions}
				makeSearch={makeSearch}
				totalPages={meta?.meta?.totalPages || 0}
				onChange={handleClientChange}
				setPage={setClientDropdownProp}
				search={search}
				page={clientDropdownProp}
				value={clientValue}
			/>
		</>
	)
}

export default ServicTableFilter
