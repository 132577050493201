export enum ENDPOINTS {
	/**
	 * * activity-log ENDPOINTS
	 */
	ACTIVITY_LOG = '/activity-log',

	ME = '/me',
	/**
	 * * Client's module ENDPOINTS
	 */
	CLIENTS = '/clients',
	CLIENTS_ALL = '/clients/all',
	/**
	 * * Fee Schedule's module ENDPOINTS
	 */
	FEESCHEDULE = '/cpt',
	FEESCHEDULES = '/cpt/all',
	FEESCHEDULE_STATUS = 'cpt/status',
	CREATE_FEESCHEDULE = 'cpt/create',

	CHAT = '/patients/sms',

	/**
	 * * Program's module ENDPOINTS
	 */
	PROGRAMS = '/program',
	GLOBAL_PROGRAMS = '/global-programs',
	GLOBAL_PROGRAMS_ALL = '/global-programs/all',
	ASSIGN_GLOBAL_PROGRAMS = 'global-programs/assign',
	PROGRAM_CONDITIONS = '/program/conditions',
	ASSIGNEE_LISTING = 'partner/list-assignee',
	CLIENT_PROGRAM = 'program/client-program',
	EXPORT_PROGRAM_EXCEL = 'sessions/report',
	EXPORT_PROGRAM_PATIENT_EXCEL = 'excel-import/program-patient',
	PARTNER_SCHEDULER_SLOTS = 'partner-scheduler/default-slot',
	AUDITS = '/audits',
	ZIP_CODES = '/addresses/zip-codes',
	CLIENT_ENTITIES = '/client-entities',
	STATES = '/addresses/states',
	PROVIDERS = '/providers',
	PROVIDERS_PROFILE = '/providers/get-provider-profile',
	PROVIDERS_ROLE = '/providers/role',
	PROVIDER_LIST = 'providers/doctor',
	PATIENTS = '/patients',
	NON_HEALTHCARE_PATIENT = '/nonHealthCare-patient',
	PROGRAM_MANAGER_MEMBERS = 'nonHealthCare-patient/list',
	MEMBERS = '/members',
	PROVIDER_ENTITY = '/provider-entities',
	// New Provider Post
	CREATE_ASSIGN_ROLES_PERMISSION = '/provider-role/create-assign-provider-role-permission',
	PATIENT_ENTITY = '/patient-entities',
	REQUEST_RESET_PASSWORD = '/request-reset-password',
	RESET_PASSWORD = '/reset-password',
	VITALS = '/vitals',
	VITALS_REPORT = '/vitals/report',
	HEALTH_GOALS = '/health-goals/bulk',
	JOURNAL_NOTES = '/journal-notes',
	MEMBER_SESSIONS = '/sessions-patient',
	VITAL_NOTE = '/vitals/vital-note',
	NOTIFICATIONS = '/notifications',
	SUMMARY_REPORT = '/summary',
	ADMIN_PORTAL = '/admin',
	MULTI_FACTOR_AUTHENTICATION = '/mfa',

	/**
	 * * Partner's module ENDPOINTS
	 */
	PARTNER = '/partner',
	PARTNERS = '/partners',

	PARTNER_ENTITY = '/partner-entities',
	PARTNER_ENTITIES_STATUS = '/partner-entities/status',
	PARTNER_GET_ALL_LOCATION = 'partner-location/all',
	PARTNER_GET_ALL_POC = 'partner-pointOfContact/all',
	PARTNER_CREATE_LOCATION = '/partner-location',
	PARTNER_LOCATION_STATUS = '/partner-location/status',
	PARTNER_POC_STATUS = '/partner-pointOfContact/status',
	PARTNER_CREATE_POC = '/partner-pointOfContact',
	PARTNER_PROFILE = '/partner-profile',

	PARTNER_SCHEDULER = 'partner-scheduler',
	PARTNER_SCHEDULER_WEEK = 'partner-scheduler/week',
	NONHEATHCARE_PARTNERS = '/partner/non-healthcare',
	PARTNER_SLOTS = '/patients/book-slot',
	SCHEDULE_LIST = 'partner-scheduler/all',
	SCHEDULE_LIST_BY_PROVIDERS = 'partner-scheduler/provider',
	PROVIDER_CONTACTS = '/provider-entities/pointofcontact',
	PROVIDERS_DOCTORS = '/providers/doctor',
	PROVIDER_STATUS = '/providers/status',
	SESSIONS = '/sessions',
	SESSIONS_ALL = 'sessions/session-all',
	SESSIONS_SUGGESTION = 'sessions/session-suggestion',
	PARTNERS_LIST = 'partner/list',
	UNDER_PROGRAM_VITAL = 'program/programs-vitals',
	PROGRAMS_VITALS_ITEM = 'program/vital-item',
	STATES_LIST = 'program/usa-state',
	CITY_LIST = 'addresses/all-state',
	DASHBOARD_STATS = 'providers/provider-dashboard',
	DOWNLOAD_PROVIDER_EXCEL = 'excel-import/export-patient-detail',
	PROGRAMS_CREATE_VITAL = '/program/program-vitals',
	PROGRAMS_VITALS_LIST = '/program/programs-vitals',
	SESSION_PATIENT_ENTITY = '/sessions/session-patient',
	SESSION_ENTITY = '/sessions-entities',
	PROGRAM_PROFILE = '/program_profile',
	SESSION_QUESTIONNAIRE = 'sessions-questionnaire',
	SESSION_QUESTIONNAIRE_CREATE = 'sessions-questionnaire/create',
	SESSION_QUESTIONNAIRE_SUBMIT = 'sessions-questionnaire/submit-questionnaire',
	SESSION_QUESTIONNAIRE_ALL = 'sessions-questionnaire/all',
	PROGRAM_DEVICES = '/devices',
	PROGRAM_VITAL_DEVICES = '/program/program-device',
	PROGRAM_CREATE_GOAL = '/goals',
	GOALS_CATEGORY = '/goal-categories/all',
	GOALS_SUBCATEGORY = '/goal-categories',
	SUB_CATEGORY = 'goal-categories',
	PROGRAM_GOALS_LISTING = 'goals/all',
	PARTNER_CALENDER = '/partner-calender',
	FEESCHEDULE_LIST = 'cpt/list',
	SESSION_FEESCHEDULE = 'session-cpt',
	ALL_PATIENTS_FOR_REFFERALS = 'patients/all',
	PARTNER_SPECITIALIZATION = 'partner/specialization',
	PATIENT_SPECILIZATION = '/providers/speciality',
	TASKS = 'patient-timetracker',
	PATIENT_PROVIDERS = '/providers/provider-patient',
	PATIENT_CONDITIONS = '/patient-conditions/all',
	PATIENT_CONDITION = '/patient-conditions',
	PATIENT_PROGRAMS = 'program/patient',
	PROVIDER_PROGRAM_LIST = 'program/all',
	CREATE_ASSIGN_PROGRAM = 'patients/program',
	PATIENT_PROGRAM_GOALS = 'goals/patient',
	PROVIDER_SPECIALITY = 'providers/speciality',
	CLIENT_PROVIDER = 'partner/client-partner',
	MEMBER_PROFILE = 'patients/profile',
	NONHEALTHCAREMEMBER_PROFILE = 'nonHealthCare-patient/profile',
	REFERREL_CONDITIONS = 'patient-conditions/all',
	REFERRAL = '/referral',
	PROGRAM_CONDITION = '/program/conditions',
	PROVIDER_UNDER_SPECIALIZATION = '/providers/doctor-speciality',
	REFERRAL_LIST = '/referral',
	ASSIGNEE = 'partner/list',
	CUSTOM = 'referral/status',
	MEMBER_CALENDAR_SLOT = 'patients/get-patient-slots',
	MEMBER_PDF_VITALS = 'patient-pdf/vitals',
	MEMBER_ADDRESS = 'patients/general-info',
	NONHEALTHCAREMEMBER_ADDRESS = 'nonHealthCare-patient/general-info',
	MEMBER_INSURANCE = 'patient-insurance',
	MEMBER_PROFILE_SESSIONS = '/patients/patient-session',
	MEMBER_SESSIONS_ADD_FEESCHEDULE = '/session-cpt',
	MEMBER_PROGRAM_LIST = 'program/list',
	MDE_QUESTIONS = '/patientMdeFields/all',
	PATIENT_MDE = '/patientMde',
	PATIENT_MDE_TIMEPER = '/patientMdeFields/timePer',
	MEMBER_FEESCHEDULES_LISTING = '/session-cpt',
	MDE_AUTOFILLED_DATA = 'patients/getAutoFillData',
	PROGRAM_MEMBER = '/patients/all/programs',
	PATIENT_PROGRAM_CONDITIONS = 'program/conditions-patient',
	PATIENT_DEVICE = 'patient-device',
	PATIENT_DEVICES = 'devices/patient',
	NONHEALTHCAREPATIENT_DEVICES = 'devices/nonHealthCare-patient',
	PATIENT_DEVICE_ALL = 'patient-device/all',
	PATIENT_DEVICE_STATUS = 'patient-device/status',
	PROGRAM_MANAGER_DASHBOARD = 'program-manager-dashboard',
	SMBP_DASHBOARD_COUNTS = 'smbp/count',
	PROGRAM_MANAGER_DASHBOARD_SESSION = 'program-manager-dashboard/goals-session',
	PROGRAM_MANAGER_DASHBOARD_GOALS = 'program-manager-dashboard/goals',
	SESSION_FEESCHEDULE_TIMER = '/sessions/session-logs',
	PROGRAM_PARTNER = 'partner/client-partner',
	MEMBER_FEESCHEDULE_LISTING = '/session-cpt',
	MEMBER_FEESCHEDULE_SUBMIT = '/patients/session-cpt',
	DASHBOARD_PROGRAMS = 'program/dashboard',
	MEMBER_START_SESSION = '/sessions/start-session',
	STAR_PARTNERS = 'program-manager-dashboard/star-partners',
	EPIDEMIOLOGIST_DASHBOARD_STATS = 'patient-mde-dashboard/calculated-percentage-for-all',
	EPIDEMIOLOGIST_DASHBOARD_COMPLETED_INCOMPLETED = 'patient-mde-dashboard/top-completed-incompleted',
	EPIDEMIOLOGIST_DASHBOARD_PATIENT_INCOMPLETE_MDE = 'patient-mde-dashboard/top-incompleted-mde-with-patients',
	EPIDEMiOLOGIST_DASHBOARD_LEAST_PERFORMING_PARTNERS = 'patient-mde-dashboard/least-performing-partners',
	MEMBER_QUESTIONNAIRE = '/sessions-questionnaire/answered',
	ADMIN_DASHBOARD = 'admin-dashboard',
	ADMIN_DASHBOARD_APPOINTMENT = 'admin-dashboard/appointments',
	EPIDEMIOLOGIST_MEMBERS_LIST = '/patient-mde-dashboard/get-patients',
	EPIDEMIOLOGIST_MEMBER_LIST = '/patient-mde-dashboard/get-patient',
	EPIDEMIOLOGIST_PARTNERS_LIST = '/patient-mde-dashboard/least-performing-partners',
	FEESCHEDULE_CLAIMS = 'cpt-claims',
	ADMIN_STAR_PARTNERS = 'admin-dashboard/star-partner',
	GET_MDE = 'patient-mde-dashboard/calculated-percentage-for-all',
	SESSION_FEESCHEDULE_CLAIMS = 'sessions/session-patient/claims',
	CLAIM_NOW_FEESCHEDULE_LIST = 'patients/session-cpt/session-patient',
	CLAIM_NOW_CPT_LIST_SUBMIT = 'patients/session-cpt/submit',
	GET_SCREENING_PERIOD = 'patientMdeFields/get-screening-periods',
	GET_VITAL_DEVICES = 'devices/patient-vitals-devices',
	GET_NONHEALTHCARE_VITAL_DEVICES = 'devices/nonHealthCare-patient-vitals-devices',
	GET_ALL_MDE = 'patient-mde-dashboard/get-mde-list-with-completed-incompleted',
	DOWNLOAD_ASCII = 'patientMde/download',
	DOWNLOAD_PDF = 'patient-mde-dashboard/dashboard-generate-pdf',
	DOWNLOAD_EXCEL = 'excel-import/downloadExcel',
	PARTNER_LIST = 'partner/list-program',
	CREATE_VITAL = 'vitals/create',
	CLAIMS_INVOICE = 'cpt-claims/invoice',
	GET_MEMBER_VITALS = 'vitals/patient',
	UPDATE_VITAL = 'vitals/update',
	GET_VITAL_BY_ID = 'vitals/get',
	NOTIFICATIONS_LIST = 'notifications/all',
	DELETE_NOTIFICATION = 'notifications',
	UPLOAD_MDE = 'patientMde/upload-mde',
	NOTIFICATION_COUNT = 'notifications/count',
	GRAPHS_DATA = 'vitals/patient-all-measurement',
	DELETE_VITAL = 'vitals/delete',
	GOAL_SUB_CATEGORY = 'goal-categories',
	GET_HEALTHCARE_PARTNERS = '/partner/healthcare',
	CREATE_THRESHOLD = '/patientThreshold',
	PATIENT_TIME_TRACKER = '/patient-timetracker',
	GET_COUNTY = '/addresses/county-names',
	VITAL_ALERTS = '/vitals/alerts',
	GET_TIME_TRACKER_BY_ID = 'patient-timetracker',
	RESEND_VERIFICATION = '/resend-verification',
	ASSIGN_PROGRAM_PATIENT = '/program/patient-program',
	TWO_FACTOR_ENABLE_DISBALE = '/mfa',
	MEMBER_GOALS_HISTORY = '/patient-goals',
	ADD_VITAL_NOTE = '/vitals-notes',
	UPDATE_VITAL_NOTE = 'vitals-notes/update-vital-note',
	GET_VITAL_NOTE_BY_ID = 'vitals-notes/get-vital-note-by-vitalId',
	PROVIDER_CLIENTS = 'provider-entities/client',
	MEMBER_PROVIDER_CLIENTS = 'patients/provider-patient',
	GET_DEMOGRAPHIC_DETAILS = 'patients/demographic-details-percentage',
	NOTIFICATION_UPDATE = 'notifications/updateToRead',
	DOWNLOAD_MEMBERVITAL_EXCEL = 'excel-import/export-vitals-excel',
	PARTNER_LIST_BY_CLIENT = '/partner/by-client',
	VITAL_PDF = 'vitals/patient-pdf',
	FILE_UPLOAD = 'sessions-entities/files',
	CANCEL_FILE_UPLOAD = 'sessions-entities/deleteImage',
	//Speicality Settings

	GET_ALL_SPECIALITY = 'speciality/all',
	CREATE_UPDATE_SPECIALITY = 'speciality',
	GET_SPECIFIC_SPECIALITY = '',
	SPECIALITY_STATUS = '',

	// Modules Settings

	MODULES = 'modules/all',
	DELETE_MODULES = 'modules',
	CREATE_UPDATE_MODULES = 'modules',
	GET_MODULE_BY_ID = 'modules',

	//Services Settings

	SERVICES = '/services/all',

	ALL_MEMBER_FOR_REFERRAL = 'patients/all-referrals',
	PARTNER_CLIENTS = 'partner/all-clients',

	PATIENTS_PARTNER = 'patients/all-partners',
	CLIENT_PARTNER = 'partner/by-client',
	PARTNER_PROVIDER = 'providers/by-partner',
	CONDITION_PARTNER_ASSIGNEE = 'providers/doctor-assignee',
	PROVIDERS_CARE_TEAM = 'providers/care-team',
	PATIENTS_CARE_TEAm = 'patient-care-team',
	GLOBAL_SESSIONS = 'global-sessions',
	GLOBAL_SESSIONS_ALL = 'global-sessions/all',
	ASSIGN_GLOBAL_SESSIONS = 'global-sessions/assign',
	GLOBAL_CONDITION_ALL = 'condition/all',
	GLOBAL_CONDITION = 'condition',
	GLOBAL_CONDITION_STATUS = 'condition/update-toggle',
	GET_GLOBAL_CODE = 'global-codes/all',
	GET_GLOBAL_CATEGORY_ALL = 'global-code-category/all',
	GLOBAL_CODE_CATEGORY = 'global-code-category',
	GLOBAL_CODE_ALL = 'global-codes/all',
	GLOBAL_CODE = 'global-codes',
	GLOBAL_CODE_CATEGORY_STATUS = 'global-code-category/update-toggle',
	GLOBAL_CODE_STATUS = 'global-codes/update-toggle',
	PATIENTS_CARE_TEAM = 'patient-care-team',
	PROVIDERS_DOCTOR_REFERRAL = 'providers/doctor',
	SLOT_CONTAINER_MEMBERS = 'patients/patient-dropdown-calender',
	/**
	 * Templates
	 */
	TEMPLATE = 'template',
	TEMPLATE_ALL = 'template/all',
	MEMBER_TEMPLATE = 'template/assign/patient',
	TEMPLATE_CLONE = 'template/clone',
	CLIENT_SCREENING_LIST = 'member-dashboard/screening',
	MEMBER_SCREENING_LIST = 'patient-template/screenings',
	TEMPLATE_MEMBERS_LIST = `member-dashboard/patients`,
	SUBMIT_ANSWER = 'question-answer',
	GET_SCREENING_QUESTION = 'patient-template/question',
	TEMPLATE_CLIENT_MEMBER_STATS = 'member-dashboard/screenings/patients',
	/**
	 * Assign Templates
	 */
	ASSIGN_TEMPLATE = 'template/assign-template	',
	ASSIGN_MEMBERS_TEMPLATE = 'patient-template/assign',

	/**
	 * TEMPLATE_CLIENT_INFORMATIONS
	 */
	TEMPLATE_CLIENT_INFORMATION = 'template/clients',

	/**
	 * Screenings
	 */
	SCREENINGS_STATS_TYPE = 'global-code-category/get-by-category-code-screening',
	SCREENINGS_STATS = 'screening/rating',
	SCREENINGS = 'screening',
	SCREENINGS_LISTING = 'screening/template-screening',
	ANSWERED_SCREENING = 'question/submitted-answers',
	TEMPLATE_DASHBOARD_STATS = 'member-dashboard',
	REASSIGNED_SCREENING = 'patient-template/re-assign-screening',
	SCREENING_TOTAL_STATS = 'member-dashboard/scoring',
	/**
	 * Existing Screeenings
	 */
	EXISTING_SCREENINGS = 'screening/existing-screeenings',
	/**
	 * Questions
	 */
	QUESTION = 'question',
	/**
	 * Question LIBRARY
	 */
	QUESTION_LIBRARY = 'question-library',
	QUESTION_LIBRARY_QUESTION = 'question-library/question',
	QUESTION_LIBRARY_OPTION = 'question-library/option',
	QUESTION_LIBRARY_SUGGESTION_BOX = 'question-library/suggestion-box',

	/**
	 * Existing Questions
	 */
	EXISTING_QUESTIONS = 'question/existing-question',

	QUESTION_OPTION = 'question-option',
	ROLES = 'roles',
	ROLES_ALL = 'roles/all',
	//provider-services/inherited-data?role=c458bc6c-f04a-4bfa-80f8-946a89d836c1&client=dee6be69-d0fd-4b6a-ad7d-fdcaecbf021c
	// PERMISSION = 'provider-services/inherited-data?role=abf1a872-3e79-43f7-8373-cad05f754317&client=dee6be69-d0fd-4b6a-ad7d-fdcaecbf021c',
	PERMISSION = 'provider-services/inherited-data',
	CHANGE_DEFAULT_ROUTE = 'providers/changeDefaultRoute',
	// PERMISSION = 'client-service/client-specific-details',
	PROGRAM_FILTER_LIST = 'program/client-program',
	MEMBER_ALERTS = 'patient-alerts/patient',
	// provider-services/get-provider-details-from-role?roleId=da92f874-75c0-4cad-8da5-f8f46256ece8&clientId=dee6be69-d0fd-4b6a-ad7d-fdcaecbf021c
	GET_PROVIDER_DETAILS_FROM_ROLE = 'provider-services/get-provider-details-from-role',
	CLIENT_SPECIFIC_DETAILS_WITH_PROVIDER = 'client-service/client-details-with-provider',
	PROVIDER_ACCESS = 'nodes/provider-access',
	// nodes/assignNodesToProvider
	ASSIGN_NODES_TO_PROVIDER = 'nodes/assign-nodes-to-provider',

	ALL_SELECT_UNSELECT_PERMISSION_TO_PROVIDER = 'nodes/assign-nodes-to-provider',

	//{{URL}}provider-module/get-module-for-role?providerService=4c9ac277-5a1c-4708-a3be-679e7c43cefd&role=17cd2db0-b74f-406b-84c9-779104f6e27c&clientId=dee6be69-d0fd-4b6a-ad7d-fdcaecbf021c

	ASSIGN_SERVICE_TO_ROLE = 'provider-services/assign-service-to-role',
	GET_MODULE_FOR_ROLE = 'provider-module/get-module-for-role',
	// Global Codes
	GET_GLOBAL_CATEGORY_BY_CATEGORYCODE = 'global-code-category/get-by-categoryCode',

	//Groups Apis
	GROUPS = 'groups',
	GROUPS_ALL = 'groups/all',
	GROUPS_PROVIDERS = 'groups/providers',
	GROUPS_STATUS = 'groups/status',
	GROUPS_PROVIDER = 'groups/provider',
	PROVIDERS_GROUPS = 'providers/groups',
	// file upload
	AZURE_FILE_UPLOAD = 'azure/upload',
	AZURE_FILE_DELETE = 'azure',
	CLIENT_MODULE_ASSIGN = 'client-modules/assign-inherited-data-to-client',
	CLIENT_SEVICE_ALL_WITH_CLIENT = 'services/getAllWithClient',
	CLIENT_SERVICES_ASSIGN_TO_CLIENT = 'client-service/assign-service-to-client',
	MODULES_ALL = 'modules/all',
	GET_PROVIDER_ROLE_PERMISSION_BY_SCREEN_ID = 'provider-screen/get-permissions-by-screen-id',
	MODULES_ATTRIBUTES = 'nodes/assignPermissionsToRole',
	CLIENT_MODULES_UPDATE_INHERITED_TO_CLIENT = 'client-modules/update-inherited-data-to-client',
	CLIENT_MODULES_ACCORDING_TO_SERVICE = 'client-modules/getAllWithClient',
	CLIENT_MODULES_ASSIGN_TO_CLIENT = 'client-modules/assign-unassign-module-of-client',
	PROVIDERS_CREATE_ASSIGN_PROVIDER_TO_CLIENT = 'providers/create-assign-provider-to-client',
	PROVIDER_ROLE_ALL = 'provider-role/all',
	ASSIGN_PROVIDER_ROLE = 'provider-role',
	ASSIGN_MODULES_TO_CLIENT = 'nodes/assign-inherited-data-to-client',
	CLIENTS_TO_ALL_CLIENTS = 'clients/get-all-clients',

	// For Modular Approach API Change for Providers
	DEFAULT_ROUTE = 'default-route',
	DEFAULT_ROUTE_ALL = 'default-route/all',
	ROLES_MA_DOCTOR = 'roles/ma-and-doctor-roles',
	ROLES_MA_DOCTOR_POC = 'roles/get-partner-roles',
	GET_ROLES_FOR_ALL_CLIENT = 'roles/get-roles-for-client',
	// Global Codes
	GET_GLOBAL_CATEGORY_BY_CATEGORY_CODE = 'global-code-category/get-by-categoryCode',

	//Messages Apis
	CHAT_GET_TOKEN = '/chat/getToken',
	GROUPS_ALL_CONVERSATION = 'groups/all-conversation',
	PATIENT_GROUP = 'patients/group',
	CONVERSATION = 'conversations',
	ADD_MORE_MEMBERS = 'conversations/add-more-members',
	CONVERSATION_MEMBERS_LISTING = 'patients/group-no-conversation',
	MESSAGES = 'conversations/messages',
	JOIN_PARTICIPANTS = 'conversations/join-participants',
	ACTIVITY_LOG_SAVE = 'm1/activity-log/save',

	// task management
	TASK_MANAGEMENT = 'task-management',
	ASSIGN_TASK_MANAGEMENT = 'task-management/assign-task',
	TASK_LIBRARY = 'task-management/taskLibrary',
	TASK_DETAIL = 'task-management/details',
	TASK_NOTES = 'task-notes',
	ALL_TASK_NOTES = 'task-notes/all',
	TASK_MANAGEMENT_CATEGORY = 'task-management-category/all-category',
	TASK_MANAGEMENT_CATEGORY_TYPE = 'task-management-category/all-category-type',
	TASK_PROGRAMS = 'program/client-program',
	TASK_PROVIDERS = 'providers/get-providers',
	TASK_PATIENTS = 'patients/get-patient-by-provider',
	TASK_COUNTS = 'task-management/tasks-count/details',
	TASK_COUNTS_ADMIN = 'task-management/tasks-count-admin',
	TASK_ALL_CATEGORY_TYPE = 'task-management-category/all-category-type-by-client',
	TASK_ALL_FOLLOW_UPS = 'task-notes/all-follow-ups',
	TASK_ALL_FOLLOW_UPS_All_LIST = 'task-management/all-list',
	TASK_TRACK_GRAPH = 'task-management/task-track-provider',
	TASK_MANAGEMENT_INFO = 'task-management/info/',
	TASK_DETAIL_CALENDER = 'task-notes/taskStatus-calender',
	TASK_TRACK_RECORDING = 'task-call-tracker/task-logs',
	TASK_CALL_TRACKER_ALL = 'task-call-tracker/all-by-client',
	TASK_CALL_TRACKER_GRAPH = 'task-management/task-call-track-admin',
	TASK_MANAGEMENT_CPT = 'cpt/all',
	TASK_MANAGEMENT_REPORT = 'task-report',
	TASK_REPORT_EXCEL = 'task-report/export-excel',
	TASK_CALL_EXCEL = 'task-call-tracker/export-excel',
	TASK_NOTES_SLOT = 'task-notes/slot',
	TASK_CPTS_LISTING = 'task-cpt/get-task-cpt-list',
	TASK_CALL_TRACKER = 'task-call-tracker',
	TASK_CALL_TRACKER_EXPORT = 'task-call-tracker/export',
	TASK_MANAGEMENT_PROVIDER = 'task-management/provider-task',
	// task notes
	// New Member info
	NEW_MEMBER_GENERAL_INFO = 'patients/profile',
	NEW_MEMBER_ADDRESS_INFO = 'patients/general-info',
	NEW_MEMBER_INSURANCE_INFO = 'patient-insurance',
	CLIENT_FOR_PROVIDER_ROLE = 'clients/client-for-provider',
	CONSENT_FILE = 'consentFile',
	MEMBER_UPLOAD_FILE = 'consentFile/create',
	MEMBERS_UPLOAD_FILE_LISTING = 'consentFile/allConsentFiles',
	DELETE_MEMBER_FILES = 'consentFile/delete-with-assest',
	//Epidemiologist -report filter
	EPIDEMIOLOGIST_PROGRAM_FILTER = 'program/client-program-mde',
	// New mde endpoints
	DELETE_ASSESSMENT = '/delete-assessment',
	MDE_PROGRAMS_LIST = 'program/patient-program-dropdown',
	MDE_ALL = 'patientMdeFields/all',
	DRAFT_MDE = 'patientMdeFields/draft',
	PARENT_ROLE = 'roles/get-parent-role',
	CLIENTS_FOR_GLOBAL_SESSION = 'clients/get-client-for-global-sessions',
	CLIENTS_FOR_GLOBAL_PROGRAM = 'clients/get-client-for-global-program',
	PROVIDER_ROLE = 'provider-role',
	GET_PATIENT_FOR_CHAT = 'patients/get-patient-for-chat',
	PROVIDER_ROLE_CLIENT_ADMIN = 'provider-role/get-client-admin',
	AUTO_POPULATE_MDE_FIELDS = '/patientMdeFields/auto-save-fields',
	MDE_VALIDATE_FIELDS = 'patientMde/validations-check',
	// Services Clients
	SERVICES_CLIENTS = 'clients/all-for-dropdown',
	GET_ALL_HEALTHCARE_PARTNERS = 'partner/all-healthCare',
	SMBP_DASHBOARD_SCREENINGS = 'smbp/screening',
	SMBP_DASHBOARD_BP_COUNTS = 'smbp/bp-count',
	SMBP_DEMOGRAPHIC_DETAILS = 'smbp/demographic-details',
	SMBP_PARTNER_TABLE = 'smbp/partner',
	SMBP_REPORTS = 'smbp/report',
	DOWNLOAD_REPORT = 'smbp/download-report',
	TASK_TRACKING_GET_DATA = 'task-management/task-track-admin',
	CREATE_CALL_TRACKING = 'task-call-tracker',
	SESSION_QUESTIONNAIRE_PATIENT = 'sessions-questionnaire/patient',
	// Patient Vital Alert
	MEMBER_DEVICE = 'patient-device',
}
