import {
	default as ReactSelect,
	GroupBase,
	MenuListProps,
	Props,
	components,
	OptionProps,
	ControlProps,
} from 'react-select'
import { ISelectOption } from 'types/forms'
import classnames from 'classnames'
import CreatableSelect from 'react-select/creatable'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import { getStylesForReactSelectInputs } from 'utils/react-select'
import { memo, useEffect, useMemo, useState } from 'react'
import { ColorBorderWrapper } from 'components/ColorBorderWrapper'
import { Label } from 'components/Label'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import MenuListWrapper from './MenuList'

function Option(props: OptionProps<any>) {
	const desc = props?.data?.desc
	const isCustom = props?.data?.isCustom
	if (isCustom) {
		return (
			<div className="">
				<components.Option {...props}>
					<label className="pl-1">
						<span className="pl-1 font-semibold mr-2">
							{props?.data?.code} <span className="text-gray-500"> {desc}</span>
						</span>
					</label>
				</components.Option>
			</div>
		)
	} else {
		return (
			<div className="">
				<components.Option {...props}>
					<label className="pl-1">{props?.label}</label>
				</components.Option>
			</div>
		)
	}
}

interface IControlProps extends ControlProps<any> {
	square: boolean
	isError: boolean
	shadow?: boolean
}

function Control({ children, isError, square, shadow, ...props }: IControlProps) {
	return (
		<ColorBorderWrapper className={square ? '!rounded-none' : ''} isError={isError} shadow={shadow}>
			<components.Control {...props}>{children}</components.Control>
		</ColorBorderWrapper>
	)
}

//@ts-ignore
export interface IAdvanceFieldProps extends Props<ISelectOption> {
	withErrorMessage?: boolean
	label?: string
	message?: string
	shadow?: boolean
	className?: string
	classNames?: {
		input?: string // TODO
		message?: string
		label?: string
		desc?: string
		drafted?: boolean
	}
	require?: string | boolean
	required?: boolean
	square?: boolean
	isCreatable?: boolean
	disableOption?: boolean
	value?: any
	bottomMenuListComponent?: React.ReactNode
	isError?: boolean
	isMulti?: boolean
	// options?: string;
	components?:
		| Partial<SelectComponents<ISelectOption, boolean, GroupBase<ISelectOption>>>
		| undefined
	onClick?: any
	onCustomMenuScrollToBottom?: any
	isLoading?: boolean
}

function AdvanceSelect({
	withErrorMessage = true,
	name,
	message,
	className,
	classNames,
	label,
	require,
	required,
	square = false,
	bottomMenuListComponent,
	isCreatable,
	disableOption = false,
	components,
	styles,
	isError = false,
	shadow = false,
	value,
	isLoading = false,
	onCustomMenuScrollToBottom,
	...rest
}: IAdvanceFieldProps) {
	const Select = isCreatable ? CreatableSelect : ReactSelect
	const [closeMenuOnSelect, setCloseMenuOnSelect] = useState(true)
	const buildedComponents = useMemo(() => {
		const MenuList = bottomMenuListComponent
			? (props: MenuListProps) =>
					MenuListWrapper({
						...props,
						onMenuScrollDown: onCustomMenuScrollToBottom,
						children: (
							<>
								{props.children}
								{bottomMenuListComponent}
							</>
						),
					})
			: (props: MenuListProps) =>
					MenuListWrapper({
						...props,
						onMenuScrollDown: onCustomMenuScrollToBottom,
						children: <>{props.children}</>,
					})
		return {
			MenuList,
			...components,
		}
	}, [bottomMenuListComponent, components])

	if (!disableOption && !buildedComponents.Option) {
		buildedComponents.Option = Option
	}
	const memoizedControl = useMemo(() => {
		return (props: ControlProps<any>) => Control({ ...props, square, shadow, isError })
	}, [isError, square, shadow])
	if (!components?.Control) {
		buildedComponents.Control = memoizedControl
	}

	useEffect(() => {
		if (rest?.isMulti) {
			setCloseMenuOnSelect(false)
		}
	}, [rest?.isMulti])
	return (
		<div
			id="scrollContainer"
			className={classnames('flex text-[12px] flex-col w-full mb-4', className, {
				'': !message,
			})}
		>
			{label && <Label name={label} className={classNames?.label} required={require || required} />}
			<Select
				menuPlacement="auto"
				name={name}
				closeMenuOnSelect={closeMenuOnSelect}
				isLoading={isLoading}
				menuPortalTarget={document.body}
				styles={getStylesForReactSelectInputs({
					...styles,
					menu: (base: any) => ({
						...base,
						top: '0',
						height: 'auto',
						position: 'absolute',
					}),
					menuPortal: (base: any) => ({
						// to popOut dropdown options outside overflow
						...base,
						zIndex: 999900,
					}),
					control: (provided, state) => {
						const styles = {
							...provided,
						}
						if (state.isFocused) {
							styles.borderColor = '#78ade4'
							styles.zIndex = '999999'
							styles.boxShadow =
								'0 0 5px 0 rgb(120 173 228 / 76%), inset 0 2px 2px 0 rgb(0 0 0 / 7%);'
						}

						if (isError || classNames?.drafted) {
							styles.border = 'solid 1px #eb5028'
						}

						if (shadow) {
							styles.border = 'none'
						}
						return styles
					},
				})}
				closeMenuOnScroll={(event: any) => {
					return event.target.id == 'scrollContainer'
				}}
				isMulti={rest?.isMulti}
				hideSelectedOptions={false}
				//@ts-ignoreonCustomMenuScrollToBottom
				components={buildedComponents}
				value={value}
				{...rest}
			/>

			{message && <ErrorMessage>{message}</ErrorMessage>}
		</div>
	)
}

export default memo(AdvanceSelect)
