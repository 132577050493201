import { divServiceClassName } from './Services.service.constants'

export const ServiceComponent = ({ serviceObj, onServiceClick, indexKey }: any) => {
	const { isActive, image, serviceName } = serviceObj
	const serviceClassName = `${divServiceClassName} ${
		isActive ? 'cursor-pointer' : 'opacity-60 cursor-not-allowed'
	}`
	return (
		<div
			className={`md:w-[190px] w-[100%] md:h-[190px] h-[60px] rounded-lg shadow-[0_0px_6px_2px_rgba(0,0,0,0.05)] bg-white flex flex-row md:flex-col items-center inactive-item ${serviceClassName}`}
			key={indexKey}
			onClick={() => onServiceClick(serviceObj)}
		>
			<img
				src={image}
				alt={serviceName}
				className="md:h-[85px] md:w-[85px] md:mt-[26px] md:mb-[16px] h-[40px] w-[40px] mt-0 mb-0 mr-[20px] ml-[20px]"
			/>
			<p className="text-base text-nightRider opacity-60  md:mb-[24px] mt-0">{serviceName}</p>
		</div>
	)
}

// Client_Component
export const ClientComponentValues = ({
	clientData,
	onClientChangeChange,
	key,
	checked,
	label,
	index,
}: any) => {
	const {
		client: { title },
	} = clientData
	const serviceClassName = `${divServiceClassName}}`
	return (
		<div
			className={`${serviceClassName} cursor-pointer${
				checked ? ' border-2 border-fountainBlue' : ''
			}`}
			key={key}
			onClick={() => onClientChangeChange(clientData, index)}
		>
			<p className="text-base text-nightRider opacity-60  md:mb-[24px] mt-0">{title}</p>
		</div>
	)
}
