import _ from 'lodash'
import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'
import { IPagination } from 'types/entities'
import { commonMessages } from 'utils/messages'
import { AddVitalPayload } from '../Vitals/Vital/Vital.types'
import {
	IFullVitalsProgram,
	IProgramVitalsListingParams,
	ITrackingDevices,
	ITrackingDevicesParams,
} from './Vital.interfaces'
import { onCreateEndpointsWithClientid } from 'utils/common'
/**
 * *createVitalProgram
 */
export function createVitalProgram(
	clientId: string | undefined,
	data: Partial<AddVitalPayload>,
	source?: CancelTokenSource,
) {
	if (!data?.otherParam) {
		delete data?.otherParam
	}
	const promise = axios.post<AddVitalPayload>(
		ENDPOINTS.PROGRAMS_CREATE_VITAL + '/' + clientId,
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *updateVitalProgram
 */
export function updateVitalProgram(
	id: string | undefined,
	clientId: string | undefined,
	data: Partial<AddVitalPayload>,
	source?: CancelTokenSource,
) {
	if (!data?.otherParam) {
		delete data?.otherParam
	}
	const promise = axios.patch<AddVitalPayload>(
		onCreateEndpointsWithClientid(ENDPOINTS.PROGRAMS_CREATE_VITAL) + '/' + id,
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *getProgramVitalsListing
 */
export function getProgramVitalsListing(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
	params?: Partial<IProgramVitalsListingParams>,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullVitalsProgram>>>(
		ENDPOINTS.PROGRAMS_VITALS_LIST + '/' + clientId + '/' + id,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *getProgramVitals
 */
export function getProgramVitals(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<IFullVitalsProgram>(
		ENDPOINTS.PROGRAMS_CREATE_VITAL + '/' + clientId + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *getTrackingDevices
 */
export function getTrackingDevices(
	params: Partial<ITrackingDevicesParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<ITrackingDevices<string[]>>(
		onCreateEndpointsWithClientid(ENDPOINTS.PROGRAM_DEVICES),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *getProgramDevices
 */
export function getProgramDevices(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<IFullVitalsProgram>(
		ENDPOINTS.PROGRAM_VITAL_DEVICES + '/' + clientId + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
