import _ from 'lodash'
import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'
import { IPagination } from 'types/entities'
import { commonMessages } from 'utils/messages'
import { PatientPayload } from 'modules/Patient_old/Patient.types' // TODO need to import from Members
import { ProgramPayload } from './Program/Program.types'
import {
	IDeleteProgramParams,
	IFullHealthConditions,
	IFullProgram,
	IHealthConditionsParams,
	IProgramParams,
} from './Program.interface'
import { ISessionCptListParams } from 'modules/Members/Member/MemberSessions/MemberSessionsCpts/Cpts.interface'
import { onCreateEndpointsWithClientid } from 'utils/common'
/**
 * *create Program Section
 */
export function createProgram(
	clientId: string | undefined,
	data: Partial<PatientPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.post<ProgramPayload>(ENDPOINTS.PROGRAMS + '/' + clientId, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *Update Program Section
 */
export function updateProgram(
	id: string | undefined,
	clientId: string | undefined,
	data: Partial<ProgramPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch<ProgramPayload>(ENDPOINTS.PROGRAMS + '/' + clientId + '/' + id, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *Parter Assignee List Section
 */
export function getAllPartnersForAssigneeList(
	client: string | undefined,
	params: Partial<IProgramParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullProgram>>>(ENDPOINTS.ASSIGNEE + '/' + client, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *Listing API of Filter
 */
export function getAllAssigneeList(
	id: string | undefined,
	params: Partial<IProgramParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullProgram>>>(
		ENDPOINTS.ASSIGNEE_LISTING + '/' + id,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *Get Program in Listing
 */
export function getPrograms(
	id: string | undefined,
	params: Partial<IProgramParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullProgram>>>(ENDPOINTS.PROGRAMS + '/' + id, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *Delete a Program
 */
export function deleteProgram(
	id: string | undefined,
	params: IDeleteProgramParams,
	source?: CancelTokenSource,
) {
	const promise = axios.delete<IFullProgram>(ENDPOINTS.PROGRAMS + '/' + id, {
		params,
	})

	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

/**
 * *Get All Conditions
 */

export function getAllConditions(
	clientId: string | undefined,
	params: Partial<IHealthConditionsParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const getProgramconditionEndpointWithClientId = onCreateEndpointsWithClientid(
		ENDPOINTS?.PROGRAM_CONDITION,
	)
	const promise = axios.get<IPagination<Partial<IFullHealthConditions>>>(
		// ENDPOINTS.PROGRAM_CONDITION + '/' + clientId,
		getProgramconditionEndpointWithClientId,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *Get Program For Edit
 */
export function getProgramForEdit(
	id: string | undefined = '',
	clientId?: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<Partial<IFullProgram>, any>(
		ENDPOINTS.PROGRAMS + '/' + clientId + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *Get All Partners
 */
export function getAllPartners(params: Partial<IProgramParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullProgram>>>(ENDPOINTS.ASSIGNEE, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel('Query was cancelled by React Query')
	}
	return promise
}
/**
 * *Get All States
 */
export function getAllStates(
	clientId: string | undefined,
	params: Partial<IProgramParams>,
	source?: CancelTokenSource,
) {
	const promise = axios.get(ENDPOINTS.STATES_LIST + '/' + clientId, {
		params: {
			search: params,
		},
	})
	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}
/**
 * *Get Single Program
 */
export function getSingleProgram(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get(`${ENDPOINTS.PROGRAMS}/${clientId}/${id}`)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *Get All CPT
 */
export function getAllCpt(
	params?: Partial<IProgramParams | ISessionCptListParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get(onCreateEndpointsWithClientid(ENDPOINTS.FEESCHEDULE_LIST), {
		// const promise = axios.get(ENDPOINTS.FEESCHEDULE_LIST + '/' + clientId, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * *Get Goal SubCategories
 */
export function getGoalSubCategories(source?: CancelTokenSource) {
	const promise = axios.get(ENDPOINTS.SUB_CATEGORY)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
