import { io, Socket } from 'socket.io-client'

let socket: Socket | null = null

const connectSocket = (userId: string, messageContextValue: any) => {
	if (socket && socket.connected) {
		console.log('Socket already connected')
		return
	}

	socket = io(process.env.REACT_APP_SOCKET_API_URL as string, {
		transports: ['websocket'],
		autoConnect: true,
		reconnectionDelayMax: 1000,
		reconnection: true,
		timeout: 10000,
		query: {
			id: userId,
		},
	})

	socket.on('connect', () => {
		console.log('Socket connected')
		messageContextValue?.setIsSocketConnected(true)
	})

	socket.on('connect_error', (error: any) => {
		console.error('Socket connection error:', error)
		messageContextValue?.setIsSocketConnected(false)
	})

	socket.on('disconnect', (reason: string) => {
		console.log('Socket disconnected:', reason)
		messageContextValue?.setIsSocketConnected(false)
	})

	socket.on('reconnect_attempt', () => {
		console.log('Socket attempting to reconnect')
	})

	socket.on('reconnect_failed', () => {
		console.error('Socket failed to reconnect')
	})
}

const disconnectSocket = () => {
	if (socket) {
		socket.disconnect()
		socket = null
	}
}

export { socket, connectSocket, disconnectSocket }
