import React from 'react'
import './style.css'

interface SideDrawerProps {
	isOpen: boolean
	children: React.ReactNode
	header?: string
}

const SideDrawer: React.FC<SideDrawerProps> = ({ isOpen, children, header }) => {
	return (
		<div
			className={` bg-opacity-0 ${
				isOpen
					? ' before:fixed before:z-[99] before:h-screen before:w-screen before:top-0 before:left-0 before:bg-black before:bg-opacity-50'
					: ''
			} `}
		>
			<div
				className={`xl:w-7/12 sm:w-8/12 w-full ml-auto py-5 flex overflow-auto flex-col justify-top bg-white shadow-lg fixed z-[99] right-0 h-full top-0 duration-500  ${
					isOpen ? 'translate-x-0 ' : 'translate-x-full'
				} `}
			>
				{header && (
					<h3 className="sm:text-[25px] text-base font-bold tracking-wide sm:pb-[30px] pb-[20px] ml-2 sm:ml-6">
						{header}
					</h3>
				)}
				{/* <div className="content-height scrollbar-thin scrollbar-thumb-fountainBlue scrollbar-track-gray-200 overflow-y-auto  pr-8 pl-5"> */}
				<div>{children}</div>
			</div>
		</div>
	)
}

export default SideDrawer
