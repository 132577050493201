import logger from './Logger'
import { useGetActivityLogDetails } from 'hooks/customHooks'

export const useLogger = () => {
	const { screenName, clientName, searched, location, tokenUserId } = useGetActivityLogDetails()

	const onCallLoggerFunction = ({
		actionName,
		buttonName,
	}: {
		actionName: string
		buttonName?: string
	}) => {
		const objectDetails = {
			action: actionName,
			buttonName,
			searched,
			screenName,
			clientName,
			tokenUserId,
		}
		logger?.log(objectDetails)
	}

	return {
		onCallLoggerFunction,
		location,
	}
}
