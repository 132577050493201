import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import axios from 'services/axios'
import { AddVitalPayload } from './Member.types'
import { IPagination } from 'types/entities'
import _ from 'lodash'
import {
	IFullVitalsProgram,
	IProgramVitalsListingParams,
	ITrackingDevices,
	ITrackingDevicesParams,
} from '../Members.interfaces'
import { commonMessages } from 'utils/messages'
import { onCreateEndpointsWithClientid } from 'utils/common'
import { errorNotify } from 'utils/toster'

export function createVitalProgram(data: Partial<AddVitalPayload>, source?: CancelTokenSource) {
	const promise = axios.post<AddVitalPayload>(ENDPOINTS.PROGRAMS_CREATE_VITAL, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function updateVitalProgram(
	id: string | undefined,
	data: Partial<AddVitalPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch<AddVitalPayload>(ENDPOINTS.PROGRAMS_CREATE_VITAL + '/' + id, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getProgramVitalsListing(
	id: string | undefined,
	source?: CancelTokenSource,
	params?: Partial<IProgramVitalsListingParams>,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullVitalsProgram>>>(
		ENDPOINTS.PROGRAMS_VITALS_LIST + '/' + id,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getProgramVitals(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<IFullVitalsProgram>(ENDPOINTS.PROGRAMS_CREATE_VITAL + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getTrackingDevices(
	clientId: string | undefined,
	params: Partial<ITrackingDevicesParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<ITrackingDevices<string[]>>(
		ENDPOINTS.PROGRAM_DEVICES + '/' + clientId,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getProgramDevices(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<IFullVitalsProgram>(ENDPOINTS.PROGRAM_VITAL_DEVICES + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// export function getProviderList(
// 	id: string | undefined,
// 	source?: CancelTokenSource,
// 	params?: Partial<{ client: string; partner: string; page?: number; search: any }>,
// ) {
// 	const filteredParams = _.pickBy(params, _.identity)

// 	const promise = axios.get(ENDPOINTS.PROVIDER_ROLE_ALL + '/' + id, {
// 		params: filteredParams,
// 	})
// 	//@ts-ignore
// 	promise.cancel = () => {
// 		source?.cancel(commonMessages.queryCancelledMessage)
// 	}
// 	return promise
// }

export function getProviderList(
	source?: CancelTokenSource,
	params?: Partial<{ partner: string; page?: number; search: any; limit: number }>,
	id?: string,
) {
	const filteredParams = _.pickBy(params, _.identity)
	if (params?.partner) {
		delete filteredParams?.client
	}
	const promise = axios.get(
		id
			? ENDPOINTS?.PROVIDER_LIST + '/' + id
			: onCreateEndpointsWithClientid(ENDPOINTS?.PROVIDER_LIST),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getPartnerListingByClient(
	id: string | undefined,
	source?: CancelTokenSource,
	params?: Partial<{ limit: string | number; page: number; search: string }>,
) {
	const filteredParams = _.pickBy(params, _.identity)
	// const promise = axios.get<IPagination<Partial<any>>>(
	// 	onCreateEndpointsWithClientid(ENDPOINTS.PARTNER_LIST_BY_CLIENT),
	// 	{
	// 		params: filteredParams,
	// 	},
	// )
	const promise = axios.get<any>(ENDPOINTS.PARTNER_LIST_BY_CLIENT + '/' + id, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Export Export Program Member Excel Report
 */
export function exportProgramMemberReport(
	prpgramId: string | undefined,
	data: any,
	source?: CancelTokenSource,
) {
	const { fileName, fileType } = data
	const endPointUrl =
		onCreateEndpointsWithClientid(ENDPOINTS.EXPORT_PROGRAM_PATIENT_EXCEL) + '/' + prpgramId
	const promise = axios
		.get(endPointUrl, {
			responseType: 'blob',
		})
		.then((response) => {
			// Create a temporary URL object to generate a download link
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `${fileName}.${fileType}`)

			// Simulate a click event to trigger the download
			document.body.appendChild(link)
			link.click()
			// Clean up the temporary URL object
			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		})
		.catch(() => {
			errorNotify(`Error downloading File ${fileType}`)
			// console.error('Error downloading PDF:', error)
		})

	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
