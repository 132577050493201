import * as yup from 'yup'
import { FIELD_NAMES } from '../Roles.constants'

export default function validationSchema() {
	return yup.object().shape({
		[FIELD_NAMES.ROLE_NAME]: yup
			.string()
			.required('Role  Name is required')
			.typeError('Role  Name is required'),
	})
}
