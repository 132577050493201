import { Formik } from 'formik'
import { RoleComponent } from './Role.component'
import { RoleBaseInitialState } from './Role.types'
import validationSchema from './Role.schema'
import { useInitial, useSubmitHandler } from './Role.hooks'

export default function RoleContainer() {
	/**
	 * *JSX Section
	 * @returns  Role Container Form
	 */
	return (
		<Formik<RoleBaseInitialState>
			initialValues={useInitial()}
			validationSchema={validationSchema()}
			onSubmit={useSubmitHandler()}
			enableReinitialize
		>
			{({ ...rest }) => <RoleComponent />}
		</Formik>
	)
}
