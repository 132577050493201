import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import axios from 'axios'
import ToggleSwitch from 'components/Switch'
import { ReactComponent as EditIcon } from 'assets/images/ic_edit.svg'
import { ReactComponent as ViewIcon } from 'assets/images/Eye.svg'
import { APP_ROUTES } from 'const/router'
import { ENDPOINTS } from 'const/endpoints'
import {
	capitalizeTwoCharacters,
	getClientDetails,
	useCheckScreenPermission,
	usePermission,
	useSortHandler,
} from 'hooks/common'
import { useUrlQuery } from 'hooks/url'
import { IAxiosResponseWithPagination } from 'types/entities'
import { useStatusUpdateToggle } from './Partner/Partner.hooks'
import { IFullPartner, IHealthCarePartners, IPartnerParams } from './Partner.interfaces'
import { getHealthCarePartners, getPartners } from './Partner.service'
import ReactTooltip from 'react-tooltip'
import { COLUMN_NAME, SCREEN_NAME } from 'const/screen'
import { MODULE_NAME } from 'const/module'
import { onGetTablebodyColumn, onGetTableColumns } from 'utils/common'

export function usePartnersTableData({
	onEdit,
	tableSectionDetail,
}: {
	onEdit: any
	tableSectionDetail: any
}): [
	Column<{ [key: string]: React.ReactNode }>[],
	{ [key: string]: React.ReactNode }[],
	(param: string) => void,
	any,
	boolean,
] {
	/**
	 * * Hooks Section
	 */
	const [params, setParams] = useUrlQuery<IPartnerParams>()

	const {
		data: partners,
		refetch,
		isFetching,
	} = usePartners({ ...params, id: getClientDetails()?.clientId })
	const onSort = useSortHandler(params.sort)
	//  Custom hook to check is screen Available
	const isProfileLocationScreenVisible = useCheckScreenPermission(
		SCREEN_NAME.PARTNERS_PROFILE_LOCATION,
	)

	// Module wise permission custom hook to get permission details module wise
	const partnerPermission = usePermission(MODULE_NAME?.PARTNERS)
	/**
	 * * Toggle Active/Inactive Section
	 * * The mutateAsync function can be used to toggle the value of the status variable.
	 */
	const { mutateAsync: status } = useStatusUpdateToggle({ clientId: getClientDetails()?.clientId })
	/**
	 * * Using the useMemo hook to create an array of partner data for the partners table.
	 */
	// To filter action particular column
	const dynamicColumn = tableSectionDetail?.providerColumns
	const partnersTableColumn = onGetTableColumns(dynamicColumn)

	// To filter action particular column if nothing to display in it
	const filterPartnersColumns = Array.isArray(partnersTableColumn)
		? partnersTableColumn?.filter((columnObj: any) => {
				return !(columnObj?.name?.toLowerCase() === COLUMN_NAME?.ACTION && !partnerPermission?.edit)
		  })
		: []

	const partnersData = useMemo(() => {
		const mapBodyObj = (item: any) => {
			const data: any = item
			const location = data?.location?.find((location: any) => location)
			const contactPoint = data?.partnerPointOfContact?.find(
				(partnerPointOfContact: any) => partnerPointOfContact,
			)
			const speciality = item?.speciality?.map((item: any, index: number) => {
				return item?.speciality?.speciality
			})
			const formattedSpeciality = speciality.join(', ')
			const companyName = item?.companyName?.charAt(0).toUpperCase() + item?.companyName?.slice(1)
			return {
				companyName: (
					<div className="flex gap-2 min-w-[200px] max-w-[250px] whitespace-normal">
						{isProfileLocationScreenVisible ? (
							<Link
								to={APP_ROUTES.PARTNER_PROFILE + '/' + item?.id + '/locations'}
								className=" text-fountainBlue truncate max-w-[200px]"
							>
								<span data-tip={companyName}>{companyName || '--'}</span>
							</Link>
						) : (
							<span data-tip={companyName}>{companyName || '--'}</span>
						)}
					</div>
				),
				client: data?.client?.title ?? '--',
				contact: (
					<div className=" max-w-[400px] w-full">
						{contactPoint?.humanName?.text?.charAt(0).toUpperCase() +
							contactPoint?.humanName?.text?.slice(1) || '--'}
					</div>
				),
				phone: (
					<div className=" max-w-[400px] w-full">
						{contactPoint?.countryCode}
						{contactPoint?.phone?.replace(
							/(\d{3})(\d{3})(\d{4})/,
							function formatPhoneNumber(plus: string, p1: string, p2: string, p3: string) {
								return `${plus ? '' : ''}(${p1}) ${p2}-${p3}`
							},
						)}
					</div>
				),
				city: location?.address?.city || '--',
				state: capitalizeTwoCharacters(location?.address?.state) || '--',
				partnerType: item?.partnerTypeId?.globalCodesName || '--',
				speciality: (
					<div className="w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
						<span data-tip={formattedSpeciality}>{formattedSpeciality || '--'}</span>
						<ReactTooltip />
					</div>
				),
				active: (
					<ToggleSwitch
						checked={item?.active}
						id={item?.id}
						disabled={!partnerPermission?.edit || item?.assigned}
						statusToggle={status}
						url={[ENDPOINTS.PARTNER]}
						refetch={refetch}
					/>
				),
				actions: (
					<div className="flex items-center gap-2">
						{isProfileLocationScreenVisible && (
							<Link
								to={APP_ROUTES.PARTNER_PROFILE + '/' + item?.id + '/locations'}
								className="text-blue-500"
							>
								<span data-tip="Open to preview">
									<ViewIcon className="hover:stroke-fountainBlue  stroke-solidGray " />
								</span>
							</Link>
						)}
						{partnerPermission?.edit && (
							<span
								data-tip="Open to edit"
								onClick={() => {
									onEdit('edit')
									setParams((old) => ({
										...old,
										id: item?.id,
									}))
								}}
							>
								<EditIcon className="hover:stroke-fountainBlue  stroke-solidGray fill-solidGray hover:fill-fountainBlue" />
							</span>
						)}
					</div>
				),
			}
		}

		return (
			partners?.data?.items?.map((item: any) => {
				const bodyobj = mapBodyObj(item)
				return onGetTablebodyColumn(item, bodyobj, filterPartnersColumns)
			}, []) || []
		)
	}, [partners?.data?.items, filterPartnersColumns, onEdit])
	return [filterPartnersColumns, partnersData, onSort, partners, isFetching]
}
/**
 * * Defining an interface called IPartnersQueryProps which extends the UseQueryOptions type from the axios library.
 * * Used to define the options for a query used to get data from an API, such as a partner list.
 * * Contains a partial IPartnerParams type which will be used to specify any additional parameters that the query might require, such as a filter or sorting criteria.
 */
interface IPartnersQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullPartner>>>,
		Partial<IPartnerParams> {}

/**
 * * The usePartners function is a React hook that takes in a set of query parameters.
 */
export function usePartners({
	id,
	page,
	limit,
	sort,
	name,
	companyName,
	speciality,
	partnerType,
	city,
	status,
	search,
	client,
	...rest
}: IPartnersQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullPartner>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullPartner>>
	>(
		[
			ENDPOINTS.PARTNER,
			page,
			limit,
			sort,
			name,
			status,
			city,
			companyName,
			partnerType,
			speciality,
			search,
			client,
			id,
		],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getPartners(
				id,
				{
					page,
					limit,
					sort,
					name,
					status,
					city,
					companyName,
					partnerType,
					speciality,
					search,
					client,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}

interface IHealthCarePartnersQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IHealthCarePartners>>>,
		Partial<IPartnerParams> {
	isEnabled?: boolean
}

/**
 * * The usePartners function is a React hook that takes in a set of query parameters.
 */
export function useHealthCarePartners({
	page,
	limit,
	sort,
	isEnabled = true,
	search,
	...rest
}: IHealthCarePartnersQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IHealthCarePartners>>,
		any,
		IAxiosResponseWithPagination<Partial<IHealthCarePartners>>
	>(
		[ENDPOINTS.GET_ALL_HEALTHCARE_PARTNERS, page, limit, sort, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getHealthCarePartners(
				{
					page,
					limit,
					sort,
					search,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			enabled: isEnabled,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}
