import { useUrlQuery } from 'hooks/url'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
	UseMutationOptions,
	UseQueryOptions,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import axiosLibrary, { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { FormikHelpers } from 'formik'
import { useNavigate } from 'react-router-dom'
import { errorNotify, successNotify } from 'utils/toster'
import { commonMessages } from 'utils/messages'
import { scrollToError } from 'utils/common'
import {
	IExistingScreeningList,
	IExistingScreeningParams,
	IScreeningsList,
	IScreeningsParams,
} from '../Screenings.interface'
import {
	createScreening,
	getExistingScreenings,
	getScreeening,
	updateScreening,
} from './Screening.service'
import { ScreeningInitialState, ScreeningPayload } from './Screening.types'
import { IQuestionnaireServiceParams } from 'modules/Questionnaires/Questionnaires.interface'
import { IAxiosResponseWithPagination } from 'types/entities'

export function useInitial() {
	/**
	 * * Variables and hooks Section
	 */
	const [urlQuery] = useUrlQuery<Partial<IScreeningsParams>>()
	const isEdit = urlQuery?.isScreeningEdit

	const { data, isFetching } = useScreening({
		id: isEdit ?? '',
	})

	/**
	 * *useMemo Section
	 */
	return useMemo(() => {
		return new ScreeningInitialState(
			isEdit
				? {
						...data?.data,
						isDataFetching: isEdit ? isFetching : false,
				  }
				: {},
		)
	}, [isEdit, isFetching])
}
//* Formik useSubmitHandler hook
export function useSubmitHandler() {
	const { mutateAsync: create } = useCreateScreening()
	const navigate = useNavigate()
	const [urlQuery, setUrlQuery] = useUrlQuery<IScreeningsParams & IQuestionnaireServiceParams>()
	const id = urlQuery.isScreeningEdit ?? ''
	const { mutateAsync: update } = useUpdateScreening({ id })
	const client = useQueryClient()
	return useCallback(
		async (form: ScreeningInitialState, formikHelpers: FormikHelpers<ScreeningInitialState>) => {
			formikHelpers.setSubmitting(true)
			try {
				if (!id) {
					await create(new ScreeningPayload({ ...form, questionTemplate: urlQuery.template }))
					successNotify(commonMessages.createMessage)
				}
				if (id) {
					await update(new ScreeningPayload({ ...form, questionTemplate: urlQuery.template }))
					successNotify(commonMessages.updateMessage)
				}
				formikHelpers.setSubmitting(false)
				client.invalidateQueries([ENDPOINTS.SCREENINGS_LISTING])
				setUrlQuery((old) => ({
					...old,
					isScreeningAdd: '',
					isScreeningEdit: '',
					existingScreening: '',
				}))
			} catch (err: any) {
				if (err?.response?.data?.message) {
					errorNotify(err.response.data.message)
				} else {
					formikHelpers.setErrors(err.response.data) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
					errorNotify(commonMessages.errorHandlingMessage)
				}
				scrollToError()
			}
		},
		[client, create, id, navigate, update],
	)
}

//* Create screening hook
type ICreateTemplateProps = Partial<
	UseMutationOptions<AxiosResponse<ScreeningPayload>, any, Partial<ScreeningPayload>>
>

export function useCreateScreening({ ...rest }: ICreateTemplateProps = {}) {
	return useMutation<AxiosResponse<ScreeningPayload>, any, Partial<ScreeningPayload>>(
		createScreening,
		{
			...rest,
		},
	)
}

//* Update screening hook
interface IUpdateTemplateProps
	extends Partial<
		UseMutationOptions<AxiosResponse<ScreeningPayload>, any, Partial<ScreeningPayload>>
	> {
	id?: string
}

export function useUpdateScreening({ id, ...rest }: IUpdateTemplateProps) {
	return useMutation<AxiosResponse<ScreeningPayload>, any, Partial<ScreeningPayload>>(
		async (data) => await updateScreening(id, data),
		{
			...rest,
		},
	)
}

interface IScreeningQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IScreeningsList>>>,
		Partial<IScreeningsParams> {
	id?: string
}

export function useScreening({ id, ...rest }: IScreeningQueryProps) {
	return useQuery<AxiosResponse<IScreeningsList>, any, AxiosResponse<IScreeningsList>>(
		[ENDPOINTS.TEMPLATE, id],
		() => {
			const CancelToken = axiosLibrary.CancelToken
			const source = CancelToken.source()
			return getScreeening(id, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!id,
			...rest,
		},
	)
}

interface IExistingScreeningsQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IExistingScreeningList>>>,
		Partial<IExistingScreeningParams> {}

/**
 * * The useExistingScreens function is a React hook that takes in a set of query parameters.
 */
export function useExistingScreenings({
	page,
	limit,
	sort,
	search,
	template = '',
	isEnabled = true,
	...rest
}: IExistingScreeningsQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IExistingScreeningList>>,
		any,
		IAxiosResponseWithPagination<Partial<IExistingScreeningList>>
	>(
		[ENDPOINTS.EXISTING_SCREENINGS, page, limit, sort, search, template],
		() => {
			const CancelToken = axiosLibrary.CancelToken
			const source = CancelToken.source()
			return getExistingScreenings(
				template,
				{
					page,
					limit,
					sort,
					search,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: isEnabled,
			...rest,
		},
	)
}
