import { ReactComponent as SortIcon } from 'assets/images/ic-sort.svg'
import { ReactComponent as SortIconAsc } from 'assets/images/ic-sort-asc.svg'
import { ReactComponent as SortIconDesc } from 'assets/images/ic-sort-desc.svg'

export default function SortHeader({ sortState, onSort, column, children }: any) {
	let showIcon: 'asc' | 'desc' | null = null

	if (sortState === column?.name) {
		showIcon = 'asc'
	}
	if (sortState === '-' + column?.name) {
		showIcon = 'desc'
	}
	return (
		<div className="flex  items-center">
			{children}
			<div className="relative ml-1">
				<div
					className="flex flex-col absolute top-1/2 translate-y-[-50%] cursor-pointer shortIcon"
					onClick={onSort}
				>
					{!showIcon && <SortIcon className="fill-[#d1caca]" />}
					{showIcon === 'asc' && <SortIconAsc className="fill-[#d1caca]" />}
					{showIcon === 'desc' && <SortIconDesc className="fill-[#d1caca]" />}
				</div>
			</div>
		</div>
	)
}
