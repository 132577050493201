/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AdvancedSelectField, IAdvanceFieldProps } from 'components/AdvancedSelectField'
import { useField } from 'formik'
import { useCallback, useEffect, useMemo } from 'react'
import { MultiValue, SingleValue, Options } from 'react-select'
import { ISelectOption } from 'types/forms'

export type CommonSelectType = Omit<IAdvanceFieldProps, 'message'> & {
	name: string
	options: Options<ISelectOption>
	message?: string | boolean
}

export type SearchableSelectType = CommonSelectType & {
	isSearchable: true
	makeSearch: (value: string) => void
	isLoading?: boolean
}

type PropsType<T> = (T extends { isSearchable: true } ? SearchableSelectType : CommonSelectType) & {
	debounceTime?: number
	onChange?: any
	defaultOptions?: any
}
//@ts-ignore
export function FormikAdvanceSelect<T>({
	debounceTime = 100,
	onChange = () => {
		//
	},
	...rest
}: PropsType<T>) {
	const [field, meta, { setValue, setTouched }] = useField<
		MultiValue<ISelectOption> | SingleValue<ISelectOption>
	>(rest.name)
	const error = (!!meta.touched && meta.error) || ''
	const classNames = useMemo(() => {
		let classes: PropsType<T>['classNames'] = {}
		if (rest.classNames) {
			classes = { ...rest.classNames }
		}
		if (error) {
			//@ts-ignore
			classes = {
				...classes,
				message: classes?.message ? classes?.message + ' !text-red-500' : ' !text-red-500',
			}
		}
		return classes
	}, [error, rest.classNames])

	useEffect(() => {
		if (rest.isCreatable && rest.defaultValue) {
			setValue(rest.defaultValue)
		}
	}, [rest.isCreatable])

	useEffect(() => {
		if (!rest.isCreatable && rest.defaultValue) {
			setValue(rest.defaultValue)
		}
	}, [rest.defaultValue && !rest.isCreatable])

	const callBack = useCallback(
		(value: MultiValue<ISelectOption> | SingleValue<ISelectOption>) => {
			setValue(value)
		},
		[setValue],
	)

	return (
		<AdvancedSelectField
			onChange={(value: any, val: any) => {
				callBack(value)
				onChange(value, val)
			}}
			message={error}
			value={field.value}
			onInputChange={rest.onInputChange}
			onBlur={useCallback(() => {
				setTouched(true)
			}, [setTouched])}
			{...rest}
			classNames={classNames}
			isError={!!error}
		/>
	)
}
