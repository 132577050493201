import { APP_ROUTES } from 'const/router'
import _ from 'lodash'
import { useMemo } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

export function useUrlQuery<T extends object>() {
	const [searchParams, setSearchParams] = useSearchParams()
	const navigate = useNavigate()

	const params = useMemo(() => {
		return Object.fromEntries(new URLSearchParams(searchParams))
	}, [searchParams]) as T

	const setter = useMemo(() => {
		return (cb: (val: T) => T, path?: APP_ROUTES | string) => {
			const newValues = _.pickBy(cb(params), _.identity)
			if (!path) {
				//@ts-ignore
				setSearchParams(newValues)
			} else {
				const search = Object.keys(newValues)
					//@ts-ignore
					.map((key) => key + '=' + (newValues[key] ? newValues[key] : ''))
					.join('&')
				navigate({ pathname: path, search }) //
			}
		}
	}, [navigate, params, setSearchParams])

	return [params, setter, searchParams.toString()] as [T, typeof setter, string]
}
