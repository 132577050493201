import { PERMISSION_NAME } from 'const/common'
import {
	getClientDetails,
	onGetModuleDetailWithSlug,
	useGetScreenDetails,
	useMyProfile,
	usePermission,
} from './common'
import { getTokenUserId, onCheckPermissionInModule } from 'utils/common'
import { useLocation } from 'react-router-dom'
import axios from 'services/axios'
import { errorNotify } from 'utils/toster'
import { CommonMessages } from 'utils/messages'
import axiosApi from 'axios'

/**
 * Uses get screen slug detail
 * @param screenName
 * @returns
 */
export function useGetScreenSlugDetail(screenName: string) {
	const screenData = useGetScreenDetails(screenName)
	const screenSlug = screenData?.clientScreen?.screen?.slug
	const screenDetails = screenSlug?.split('/')
	const screenPath = screenDetails?.[0]
	return `/${screenPath}`
}

/**
 * Determines whether check screen permission loader on
 * @param screenDetails
 * @returns
 */
export function onCheckScreenPermissionLoader(screenDetails: any) {
	const isScreenDataFetching = screenDetails?.allowedSectionDetail?.isScreenFetching
	return isScreenDataFetching
}

/**
 * This function is used to get Module Permission of Add, Edit and Delete
 * @param moduleName
 * @returns
 */
export function onGetModulePermissions(moduleName: string) {
	const modulePermissions = usePermission(moduleName)
	const isAddAllowed = onCheckPermissionInModule(modulePermissions, PERMISSION_NAME?.ADD)
	const isEditAllowed = onCheckPermissionInModule(modulePermissions, PERMISSION_NAME?.EDIT)
	const isDeleteAllowed = onCheckPermissionInModule(modulePermissions, PERMISSION_NAME?.DELETE)
	const isAssignAllowed = onCheckPermissionInModule(modulePermissions, PERMISSION_NAME?.ASSIGN)
	const isexportExcelAllowed = onCheckPermissionInModule(
		modulePermissions,
		PERMISSION_NAME?.EXPORT_EXCEL,
	)
	const isexportPdfAllowed = onCheckPermissionInModule(
		modulePermissions,
		PERMISSION_NAME?.EXPORT_PDF,
	)
	const modulePermissionObj = {
		isAddAllowed,
		isEditAllowed,
		isDeleteAllowed,
		isAssignAllowed,
		isexportPdfAllowed,
		isexportExcelAllowed,
	}
	return {
		isAddAllowed,
		isEditAllowed,
		isDeleteAllowed,
		isAssignAllowed,
		isexportPdfAllowed,
		isexportExcelAllowed,
		modulePermissionObj,
	}
}

/**
 * This function is used to get Screen slug details
 * @param screenName
 * @returns
 */
export function onGetScreenSlug(screenName: string) {
	const screenDetails = screenName?.split('/')
	const screenPath = screenDetails?.[0]
	return `/${screenPath}`
}

/**
 * Gets module icon from module obj
 * @param moduleObj
 * @returns
 */

export function getModuleDetailsFromModuleObj(moduleObj: any) {
	const moduleIcon = moduleObj?.providerModule?.clientModules?.clientModuleImage ?? ''
	const moduleSlug = moduleObj?.providerModule?.clientModules?.modules?.slug ?? ''
	const moduleName = moduleObj?.providerModule?.clientModules?.clientModuleName ?? ''
	return {
		moduleIcon,
		moduleSlug,
		moduleName,
	}
}

/**
 * Gets module icon
 * @param moduleName
 * @returns
 */
export function getModuleIcon(moduleSlug: string) {
	const moduleDetailObj = onGetModuleDetailWithSlug(moduleSlug)
	const { moduleIcon } = getModuleDetailsFromModuleObj(moduleDetailObj)
	return moduleIcon
}
/**
 * Converts to lower case without spaces
 * @param str
 * @returns
 */
export function convertToLowerCaseWithoutSpaces(str: string) {
	return str?.toLowerCase?.().replace(/\s/g, '')
}
/**
 * Checks role is super admin
 * @param profile
 * @returns
 */
export function useCheckRoleIsSuperAdmin() {
	const profile = useMyProfile()
	const isSuperAdmin = profile?.providerRole?.some((roleObj: any) => {
		return roleObj?.roles?.isSuperAdmin
	})
	return isSuperAdmin
}
export enum SECTION_TYPE_CODE {
	TAB = 'Tab',
}
export function useGetTabDetails(screenDetails: any) {
	if (screenDetails?.allScreenSection || screenDetails?.allSectionDetails) {
		const filteredObjects = Object.entries(
			screenDetails?.allScreenSection || screenDetails?.allSectionDetails,
		)
			.filter(
				([key, value]: any) =>
					value?.clientSection?.sections?.sectionType?.globalCodesName === SECTION_TYPE_CODE?.TAB,
			)
			.map(([key, value]: any) => {
				return { ...value }
			})

		return filteredObjects
	}

	return []
}

export function useGetActivityLogDetails() {
	const profile = useMyProfile()
	const location = useLocation()
	const clientDetails = getClientDetails()
	const tokenUserId = getTokenUserId()
	const activityObj = {
		userId: profile?.id,
		screenName: location?.pathname,
		clientName: clientDetails?.clientName,
		searched: location?.search,
		location,
		tokenUserId,
	}
	return activityObj
}

export function commonDownloadPdfExcel({
	apiPath,
	payload,
	downloadedFileName,
	isPdf,
}: {
	apiPath: string
	payload: any
	downloadedFileName: string
	isPdf: boolean
}) {
	const CancelToken = axiosApi?.CancelToken
	const source = CancelToken?.source()
	const promise = axios
		.get(apiPath, {
			params: payload,
			responseType: 'blob',
		})
		.then((response) => {
			// Create a temporary URL object to generate a download link
			const url = window?.URL?.createObjectURL(new Blob([response.data]))
			const link = document?.createElement('a')
			link.href = url
			link.setAttribute('download', downloadedFileName)

			// Simulate a click event to trigger the download
			document?.body?.appendChild(link)
			link?.click()

			// Clean up the temporary URL object
			document?.body?.removeChild(link)
			window?.URL?.revokeObjectURL(url)
		})
		.catch(() => {
			errorNotify(isPdf ? CommonMessages?.ERROR_DOWNLOADING_PDF : CommonMessages?.DOWNLOADING_ERROR)
		})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(CommonMessages?.QUERY_CANCELLED_MESSAGE)
	}
	return promise
}

// export function ({
