import { PARTNER_STATUS } from 'const/auth'
import { CONTACT_POINT } from 'types/entities'

export enum FIELD_NAMES {
	PARTNER_TYPE = 'partnerType',
	PARTNER_TYPE_ID = 'partnerTypeId',

	COMPANY_NAME = 'companyName',
	EMAIL = 'email',
	WEBSITE_URL = 'websiteUrl',
	SPECIALITY = 'speciality',
	EIN = 'ein',
	NPI = 'npi',
	IMAGE = 'image',
	ACTIVE = 'active',
	POC_ACCESS = 'POC_ACCESS',
	CLIENT = 'client',
	GOALS = 'goals',
	PARTERN_ID = 'partner_id',
	TIMEZONE = 'timezone',
	PROGRAM = 'program',
	HEALTHCARE_PARTNER = 'healthCarePartner',
}
export const CONTACT_POINT_TYPES_OPTIONS: {
	label: string
	value: CONTACT_POINT
}[] = [
	{ label: 'Phone', value: CONTACT_POINT.PHONE },
	{ label: 'Email', value: CONTACT_POINT.EMAIL },
	{ label: 'Fax', value: CONTACT_POINT.FAX },
]

export enum PARTNER_ROLES {
	CLINIC_SITE = 'clinic site',
	HEALTH_COACH = 'healthcoach',
	TRAINER = 'trainer',
}

export const PARTNER_ROLES_OPTIONS: {
	label: string
	value: PARTNER_ROLES
}[] = [
	{ label: 'clinic site', value: PARTNER_ROLES.CLINIC_SITE },
	{ label: 'healthcoach', value: PARTNER_ROLES.HEALTH_COACH },
	{ label: 'trainer', value: PARTNER_ROLES.TRAINER },
]

export enum SPECIALITY_TYPE {
	DIABETES_CARE = 'diabetes_care',
	DIETITIANS = 'dietitians',
	FITNESS_EXPERT = 'fitness_expert',
}

export const SPECIALITY_OPTIONS: {
	label: string
	value: SPECIALITY_TYPE
}[] = [
	{ label: 'diabetes care', value: SPECIALITY_TYPE.DIABETES_CARE },
	{ label: 'dietitians ray', value: SPECIALITY_TYPE.DIETITIANS },
	{ label: 'x-fitness expert', value: SPECIALITY_TYPE.FITNESS_EXPERT },
]

export const SUPPORTED_FORMAT: string[] = ['image/gif', 'image/jpeg', 'image/png']

export const PARTNER_STATUS_OPTION: {
	label: string
	value: PARTNER_STATUS
}[] = [
	{ label: 'Active', value: PARTNER_STATUS.ACTIVE },
	{ label: 'Inactive', value: PARTNER_STATUS.IN_ACTIVE },
]

export enum PARTNER_TYPE {
	HEALTH_CARE = 'healthCare',
	NON_HEALTH_CARE = 'nonHealthCare',
}

export enum IS_PRIMARY_STATUS {
	IS_PRIMARY = 'isPrimary',
	IS_NOT_PRIMARY = 'isNotPrimary',
}

export const PARTNER_TYPE_OPTION: {
	label: string
	value: PARTNER_TYPE
}[] = [
	{ label: 'Healthcare', value: PARTNER_TYPE.HEALTH_CARE },
	{ label: 'Non Healthcare', value: PARTNER_TYPE.NON_HEALTH_CARE },
]

export const IS_ACTIVE_OPTIONS = [
	{ value: 'isActive', label: 'Is Active' },
	{ value: 'isInactive', label: 'Is Inactive' },
]

export const IS_PRIMARY_OPTIONS = [
	{ value: 'isPrimary', label: 'Is Primary' },
	{ value: 'isNotPrimary', label: 'Is Not Primary' },
]

export const getLabel = (OPTIONS: any, VALUE: any) => {
	return OPTIONS.find((i: any) => i.value == VALUE)?.label
}

// Get Phone Number Dropdown
export const IS_PHONE_OPTIONS = [{ value: '+1', label: '+1' }]
export enum COUNTRY_CODE_TYPE {
	countryCode = '+1',
}
