export enum FIELD_NAMES {
	TEXT = 'title',
	ACTION = 'action',
	SCORE = 'score',
	EXISTING_QUESTIONS = 'reference',
	DEFAULT = 'isDefault',
	OPTIONS = 'option',
}

export enum ACTION_LABELS {
	GO_TO_EXISTING_QUESTIONS = 'Go to existing question',
	GO_TO_NEXT_QUESTION = 'Go to next question',
	GO_TO_NEXT_STEP = 'Go to next step',
}
export const questionOptions = {
	title: '',
	score: null,
	action: null,
	reference: null,
	isDefault: false,
}
