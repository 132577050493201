import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import {
	createAssignServices,
	getClientServcesAll,
	getSectionScreenPermission,
} from './AssignedServices.service'
import { IAssignModulesParams } from '../AssignedModules/AssignModules.interface'

/**
 * * The ClientServiceAll function is a React hook that takes in a set of query parameters.
 */
interface IServiceQueryProps extends Partial<UseQueryOptions<AxiosResponse<any>>>, Partial<any> {
	id?: string
}

export function useClientServiceAll({ id, ...rest }: IServiceQueryProps = {}) {
	return useQuery<any>(
		[ENDPOINTS.CLIENT_SEVICE_ALL_WITH_CLIENT, id],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getClientServcesAll(id, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			enabled: !!id,
		},
	)
}

/**
 * * The useCreateAssignServices function is a React hook that takes in a set of body parameters.
 */
export function useCreateAssignServices({ ...rest }: any = {}) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(createAssignServices, {
		...rest,
	})
}

/**
 * * The Service Permission function is a React hook that takes in a set of query parameters.
 */
interface IAssignedModulesQueryProps extends UseQueryOptions<any>, Partial<IAssignModulesParams> {}
export function useSectionScreenWisePermission({
	screenId,
	clientId,
	...rest
}: IAssignedModulesQueryProps = {}) {
	return useQuery<any>(
		[ENDPOINTS.GET_PROVIDER_ROLE_PERMISSION_BY_SCREEN_ID, screenId, clientId],
		({ signal }) => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getSectionScreenPermission(clientId, { screenId }, source, signal)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
		},
	)
}
