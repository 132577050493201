import { ISelectOption } from 'types/forms'
import { IClient } from 'modules/Clients/Clients.interface'
import { PARTNER_TYPE } from './Partner.constants'
import { IFullPartner } from '../Partner.interfaces'
import { getCurrentTimeZone } from 'utils/time'

export class PartnerPayload {
	npi?: number | null | undefined = null
	ein?: number | null | undefined = null
	goals?: number | null | undefined = null
	id?: string
	active?: boolean
	email: string | null = null
	websiteUrl: string | null | undefined = null
	companyName: string | null = null
	partnerType: PARTNER_TYPE | null = PARTNER_TYPE.HEALTH_CARE
	partnerTypeId: ISelectOption | null | any = null
	speciality: Array<{ partnerSpeciality: string }> | undefined
	image?: (File | string | any) | null = null
	previewImage?: string | null
	client: ISelectOption | string | null = null
	partner_id: string | undefined
	timezone: string | null | undefined = getCurrentTimeZone
	healthCarePartner: any

	constructor({
		active,
		companyName,
		websiteUrl,
		image,
		npi,
		email,
		partnerTypeId,
		speciality,
		client,
		goals,
		partner_id,
		...data
	}: Partial<PartnerInitialState> = {}) {
		this.addIsActive(active)
		this.addEmail(email)
		this.addClient(client)
		this.addCompanyName(companyName)
		this.addWebsite(websiteUrl)
		this.addPartnerType(partnerTypeId)
		this.addEin(data.ein)
		this.addNpi(npi, partnerTypeId)
		this.addAssets(image)
		this.addSpeciality(speciality)
		this.addGoals(goals)
		this.addPartnerId(partner_id)
		this.addTimezone(data?.timezone)
		this.addHealthCare(data?.healthCarePartner)
	}

	addHealthCare(healthCarePartner: any) {
		if (healthCarePartner) {
			this.healthCarePartner = healthCarePartner?.value
		}
	}

	addTimezone(data: ISelectOption<string, string> | null | undefined) {
		this.timezone = data?.value
	}

	addPartnerId(id: string | undefined) {
		this.partner_id = id
	}

	addGoals(goals?: number | null | undefined) {
		if (goals) {
			this.goals = goals
		} else {
			this.goals = null
		}
	}

	addSpeciality(speciality: any) {
		if (speciality) {
			const newSpeciality = speciality?.map((obj: any) => {
				return obj?.value
			})
			this.speciality = newSpeciality
		} else {
			this.speciality = []
		}
	}

	addNpi(
		npi: PartnerInitialState['npi'] | number | null | undefined,
		type: ISelectOption<PARTNER_TYPE> | null | undefined,
	) {
		if (npi && type?.value === PARTNER_TYPE.HEALTH_CARE) {
			this.npi = npi
		} else {
			delete this.npi
		}
	}

	addClient(client: ISelectOption | null | undefined | any) {
		if (client) {
			this.client = client?.value
		}
	}
	addEin(ein?: number | null | undefined) {
		if (ein) {
			this.ein = ein
		} else {
			this.ein = null
		}
	}

	addPartnerType(type: ISelectOption | null | undefined | any) {
		if (type) {
			this.partnerTypeId = type?.id
			this.partnerType = type?.value
		}
	}

	addCompanyName(companyName: string | undefined | null) {
		if (companyName) {
			this.companyName = companyName
		}
	}

	addEmail(email: string | undefined | null) {
		if (email) {
			this.email = email?.toLowerCase()
		}
	}

	addIsActive(status?: 'isActive' | 'isInactive') {
		if (status === 'isActive') {
			this.active = true
		}
		if (status === 'isInactive') {
			this.active = false
		}
	}

	addWebsite(websiteUrl: string | undefined | null) {
		if (websiteUrl) {
			this.websiteUrl = websiteUrl
		} else {
			delete this.websiteUrl
		}
	}

	addAssets(assets: any) {
		if (assets) {
			this.image = assets
		}
	}
}

export class PartnerInitialState {
	npi?: number | null | undefined = null
	ein?: number | null | undefined = null
	goals?: number | null | undefined = null
	partner_id: string | undefined
	partnerType: ISelectOption<PARTNER_TYPE> | null = null
	partnerTypeId: ISelectOption | null | any = null
	serverImage?: string | null = null
	client: ISelectOption | null = null
	email: string | null = null
	companyName: string | null = null
	disabled?: boolean = false
	active: 'isActive' | 'isInactive' = 'isActive'
	websiteUrl: string | null = null
	image: (File | string | any) | null = null
	timezone?: ISelectOption | null = null
	speciality:
		| {
				speciality: string | null
		  }[]
		| undefined
	healthCarePartner: ISelectOption | null = null
	constructor({ ...data }: Partial<IFullPartner> = {}) {
		this.addEmail(data?.email)
		this.addCompanyName(data.companyName)
		this.addIsActive(data?.active)
		this.addWebsite(data.websiteUrl)
		this.addPartnerTypedRole(data?.partnerTypeId)
		this.addNpi(data?.npi)
		this.addEin(data?.ein)
		this.addSpeciality(data?.speciality)
		this.addAssets(data.image)
		this.addClient(data.client)
		this.addGoals(data?.goals)
		this.addPartnerId(data?.partner_id)
		this.addDisabled(data?.assigned)
		this.addTimezone(data?.options, data?.timezone)
		this.addHealthCarePartner(data?.healthCarePartner)
	}

	addTimezone(options: ISelectOption[] | undefined, timezone = getCurrentTimeZone) {
		if (options) {
			this.timezone = options.find((item) => {
				return item.value === timezone
			})
		}
	}

	addDisabled(assigned: any) {
		if (assigned) {
			this.disabled = true
		} else {
			this.disabled = false
		}
	}
	addPartnerId(id: string | undefined) {
		this.partner_id = id
	}

	addGoals(goals: number | null | undefined) {
		if (goals) {
			this.goals = goals
		} else {
			this.goals = null
		}
	}

	addEin(ein: number | null | undefined) {
		if (ein) {
			this.ein = ein
		} else {
			this.ein = null
		}
	}

	addClient(client: IClient | null = null) {
		if (client) {
			this.client = {
				label: client.title,
				value: client.id,
			}
		}
	}

	addHealthCarePartner(healthcarePartner: any) {
		if (healthcarePartner) {
			this.healthCarePartner = {
				label: healthcarePartner.companyName,
				value: healthcarePartner.id,
			}
		}
	}

	addSpeciality(speciality?: any) {
		if (speciality) {
			const newArray = speciality.map((obj: any) => ({
				value: obj?.speciality?.id,
				label: obj?.speciality?.speciality,
			}))
			this.speciality = newArray
		} else {
			this.speciality = []
		}
	}

	addAssets(assets: any) {
		if (assets) {
			this.image = assets
			this.serverImage = assets
		}
	}

	addNpi(npi?: PartnerInitialState['npi'] | number | null | undefined) {
		if (npi) {
			this.npi = npi
		}
	}

	addPartnerTypedRole(type?: ISelectOption | any) {
		if (type) {
			this.partnerTypeId = {
				label: type?.globalCodesName,
				value: type?.slug,
				id: type?.id,
			}
		}
	}

	addEmail(email: string | undefined) {
		if (email) {
			this.email = email
		}
	}

	addWebsite(websiteUrl: string | undefined) {
		if (websiteUrl) {
			this.websiteUrl = websiteUrl
		} else {
			this.websiteUrl = 'http://'
		}
	}

	addIsActive(isActive?: boolean) {
		if (typeof isActive === 'boolean') {
			if (isActive) {
				this.active = 'isActive'
			}
			if (!isActive) {
				this.active = 'isInactive'
			}
		}
	}

	addCompanyName(companyName: string | undefined) {
		if (companyName) {
			this.companyName = companyName
		}
	}
}

export class PartnerInitialStateWithAdProp extends PartnerInitialState {
	isFetched?: boolean | undefined = false
}
