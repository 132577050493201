import { useActivityLogger } from 'components/ActivityLogger'
import Button from 'components/Button'
import { API_ERROR_STATUS, API_ERROR_STATUS_MESSAGES, commonMessages } from 'utils/messages'
import { ReactComponent as ErrorFound } from 'assets/images/PageNotWorking.svg'
import { useEffect } from 'react'
import { errorNotify } from 'utils/toster'

//@ts-ignore

export function ErrorFallback({
	error,
	resetErrorBoundary,
	isNetworkError,
}: {
	error: Error
	resetErrorBoundary: (...args: Array<unknown>) => void
	isNetworkError: boolean
}) {
	const handleRetry = () => {
		window.location.assign(window.location.href)
	}

	const handleTryAgain = () => {
		window.location.assign(window.location.href)
		resetErrorBoundary()
	}

	// Error handling messages for network errors

	const networkErrors = (axiosStatus: number | null = 0) => {
		switch (axiosStatus) {
			case API_ERROR_STATUS.BAD_REQUEST:
				return API_ERROR_STATUS_MESSAGES.BAD_REQUEST
			case API_ERROR_STATUS.UNAUTHORIZED:
				return API_ERROR_STATUS_MESSAGES.UNAUTHORIZED
			case API_ERROR_STATUS.PAYMENT_REQUIRED:
				return API_ERROR_STATUS_MESSAGES.PAYMENT_REQUIRED
			case API_ERROR_STATUS.NOT_FOUND:
				return API_ERROR_STATUS_MESSAGES.NOT_FOUND
			case API_ERROR_STATUS.METHOD_NOT_ALLOWED:
				return API_ERROR_STATUS_MESSAGES.METHOD_NOT_ALLOWED
			case API_ERROR_STATUS.NOT_ACCEPTABLE:
				return API_ERROR_STATUS_MESSAGES.NOT_ACCEPTABLE
			case API_ERROR_STATUS.PROXY_AUTHENTICATION_REQUIRED:
				return API_ERROR_STATUS_MESSAGES.PROXY_AUTHENTICATION_REQUIRED
			case API_ERROR_STATUS.REQUEST_TIMEOUT:
				return API_ERROR_STATUS_MESSAGES.REQUEST_TIMEOUT
			case API_ERROR_STATUS.CONFLICT:
				return API_ERROR_STATUS_MESSAGES.CONFLICT
			case API_ERROR_STATUS.GONE:
				return API_ERROR_STATUS_MESSAGES.GONE

			default:
				return commonMessages.errorHandlingMessage
		}
	}

	useEffect(() => {
		const isChunkLoad = error?.name === 'ChunkLoadError'
		error?.message?.includes('Loading chunk') || error?.message?.includes('ChunkLoadError')
		const isChunkLoadError =
			error?.message?.includes('Loading chunk') || error?.message?.includes('ChunkLoadError')
		if ((isChunkLoadError || isChunkLoad) && !isNetworkError) {
			handleRetry()
		}
	}, [error])

	useActivityLogger(error as Error)
	return (
		<div role="alert" className="flex flex-col items-center justify-center h-full flex-1 gap-4">
			<p>
				{
					//@ts-ignore
					networkErrors(error?.response?.status)
				}
			</p>
			<pre>
				{' '}
				<ErrorFound />{' '}
			</pre>
			<Button onClick={handleTryAgain}>Try again</Button>
		</div>
	)
}
