import { IFullMemberTasks } from '../MemberTasks.interface'
import { FORMAT, SESSION_TIME_FORMAT, convertUnix, formatDate, toDate } from 'utils/time'
import { SelectOption } from 'types/entities'
import { ISelectOption } from 'types/forms'
import moment from 'moment'

export class MemberTaskPayload {
	start_time: string | null = null
	end_time: string | null = null
	note: string | null = null
	taskType: any
	task_type: any
	taskTypeId?: string | null = null
	patient?: string | null = null
	duration: number | null | string = null
	createdDate: string | null = null
	profile: string | null = null
	details: string | null = null
	title: string | null = null
	taskManagement: any
	status?: string | null = null
	startTime: number | null = null
	endTime: number | null = null

	constructor(data: MemberTaskInitialState) {
		this.addStartTime(data?.start_time)
		this.addEndTime(data?.end_time)
		this.addNote(data?.note)
		this.addTitle(data?.title)
		this.addTaskType(data?.taskType)
		this.addPatient(data?.patient)
		this.addDuration(data?.duration)
		this.addCreatedDate(data?.createdDate)
		this.addDetail(data?.note)
		this.addTaskStatus(data?.status)
		this.addTaskManagement(data?.taskManagement)
		this.addStartUnix(data?.startTime, data?.start_time)
		this.addEndUnix(data?.endTime, data?.end_time)
		this.addSelectedTaskType(data?.task_type)
	}

	addSelectedTaskType(task_type: any | undefined | null) {
		if (task_type) {
			this.task_type = task_type?.label
			this.taskTypeId = task_type?.value
		} else {
			delete this.task_type
			delete this.taskTypeId
		}
	}
	addTitle(title: string | null) {
		if (title) {
			this.title = title
		}
	}

	addTaskManagement(taskManagement: ISelectOption | undefined | any) {
		if (taskManagement) {
			this.taskManagement = taskManagement?.value
		}
	}

	addTaskStatus(status?: any | null) {
		if (status) {
			this.status = status?.id
		}
	}
	addStartTime(startTime?: string | null) {
		if (startTime) {
			this.start_time = startTime
		}
	}
	addEndTime(endTime?: string | null) {
		if (endTime) {
			this.end_time = endTime
		}
	}

	addStartUnix(startUnix: number | null, startTime: string | null = '') {
		if (startUnix) {
			this.startTime = startUnix
		} else if (startTime) {
			const startDate = formatDate(toDate(), FORMAT)
			this.startTime = convertUnix(startDate + ' ' + startTime)
		}
	}
	addEndUnix(endUnix: number | null, endTime: string | null) {
		if (endUnix) {
			this.endTime = endUnix
		} else if (endTime) {
			const endDate = formatDate(toDate(), FORMAT)
			this.endTime = convertUnix(endDate + ' ' + endTime)
		}
	}

	addNote(note?: string | null) {
		if (note) {
			this.note = note
		}
	}

	addDetail(note?: string | null) {
		if (note) {
			this.details = note
		}
	}

	addTaskType(taskType: ISelectOption | undefined | null) {
		if (taskType) {
			this.taskType = taskType?.value
		}
	}
	addPatient(patient?: string | null) {
		if (patient) {
			this.patient = patient
		}
	}
	addDuration(duration?: number | null | string) {
		if (duration) {
			this.duration = duration
		}
	}
	addCreatedDate(createdDate?: string | null) {
		if (createdDate) {
			this.start_time = createdDate
		}
	}
	addProfile(profile?: string | null) {
		if (profile) {
			this.profile = profile
		}
	}
}

export class MemberTaskInitialState {
	start_time: string | null = null
	end_time: string | null = null
	note: string | null = null
	title: string | null = null
	taskType: ISelectOption | null = null
	task_type: any | null = null
	taskTypeId: string | null = null
	patient?: string | null = null
	duration: string | null | number = null
	createdDate: string | null = null
	profile: string | null = null
	authName?: string | undefined
	taskManagement?: ISelectOption | null = null
	status?: any
	startTime: number | null = null
	endTime: number | null = null

	constructor(data: IFullMemberTasks | undefined | any) {
		this.addStartTime(data?.start_time, data?.time, data?.currentTime)
		this.addEndTime(data?.end_time, data?.currentTime)
		this.addNote(data?.note)
		this.addTitle(data?.title)
		this.addTaskType(data?.taskType)
		this.addPatient(data?.patient)
		this.addDuration(data?.duration, data?.time)
		this.addCreatedDate(data?.createdDate)
		this.addAuthName(data?.provider)
		this.addTaskManagement(data?.taskManagement)
		this.addStatus(data?.status)
		this.addStartUnix(data?.endTime)
		this.addEndUnix(data?.endTime)
		this.addSelectedTaskType(data?.task_type, data?.taskTypeId)
	}

	addSelectedTaskType(task_type: any, taskTypeId: any) {
		if (task_type && taskTypeId) {
			this.task_type = new SelectOption(taskTypeId?.id, task_type)
			this.taskTypeId = taskTypeId?.id
		}
	}

	addTitle(title: string) {
		if (title) {
			this.title = title
		}
	}

	addStartUnix(startUnix?: number | null) {
		if (startUnix) {
			this.startTime = startUnix
		}
	}
	addEndUnix(endUnix?: number | null) {
		if (endUnix) {
			this.endTime = endUnix
		}
	}

	addAuthName(provider: any) {
		if (provider?.humanName?.text) {
			this.authName = provider?.humanName?.text
		}
	}

	addTaskManagement(taskManagement: string | undefined | any) {
		if (taskManagement) {
			this.taskManagement = new SelectOption(taskManagement?.id, taskManagement?.name)
		}
	}

	addStartTime(startTime?: string | null, time: string | null = '', currentTime: any = null) {
		if (startTime) {
			this.start_time = startTime
		} else {
			if (time && currentTime) {
				const current = moment(currentTime, SESSION_TIME_FORMAT)
				const actualTime = current.subtract(Number(time) / 1000, 'seconds')
				this.start_time = formatDate(actualTime?.toDate(), SESSION_TIME_FORMAT)
			} else {
				this.startTime = +formatDate(toDate(), SESSION_TIME_FORMAT)
			}
		}
	}
	addEndTime(endTime?: string | null, currentTime: string | null = '') {
		if (endTime) {
			this.end_time = endTime
		} else {
			this.end_time = currentTime
		}
	}
	addNote(note?: string | null | any) {
		if (note) {
			this.note = note?.details
		}
	}
	addTaskType(taskType?: any) {
		if (taskType) {
			this.taskType = taskType?.id ? new SelectOption(taskType?.id, taskType?.name) : taskType
		}
	}
	addStatus(status?: any) {
		if (status) {
			this.status = new SelectOption(status?.id, status?.globalCodesName)
		}
	}
	addPatient(patient?: string | null) {
		if (patient) {
			this.patient = patient
		}
	}
	addDuration(duration?: any | null | string, time?: any) {
		if (duration) {
			this.duration = `${duration?.hours ? duration?.hours.toString().padStart(2, '0') : '00'}:${
				duration?.minutes ? duration?.minutes.toString().padStart(2, '0') : '00'
			}:${duration?.seconds ? duration?.seconds?.toString().padStart(2, '0') : '00'}`
		} else {
			const timer = `${('0' + Math.floor((time / 3600000) % 24)).slice(-2)}:${(
				'0' + Math.floor((time / 60000) % 60)
			).slice(-2)}:${('0' + Math.floor((time / 1000) % 60)).slice(-2)}`
			this.duration = timer
		}
	}
	addCreatedDate(createdDate?: string | null) {
		if (createdDate) {
			this.start_time = createdDate
		}
	}
	addProfile(profile?: string | null) {
		if (profile) {
			this.profile = profile
		}
	}
}
