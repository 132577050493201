import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
	useMutation,
	UseMutationOptions,
	useQueryClient,
	useQuery,
	UseQueryOptions,
} from '@tanstack/react-query'
import { matchPath, useNavigate, useParams } from 'react-router-dom'
import axiosLibrary, { AxiosResponse } from 'axios'
import axios from 'axios'
import { FormikHelpers } from 'formik'
import { useMyProfile } from 'hooks/common'
import { APP_ROUTES } from 'const/router'
import { errorNotify, successNotify } from 'utils/toster'
import { commonMessages } from 'utils/messages'
import { ENDPOINTS } from 'const/endpoints'
import { scrollToError } from 'utils/common'
import { useUrlQuery } from 'hooks/url'
import { IFullGlobalCodeCategory, IGlobalCodesCategory } from './GlobalCodeCategory.interface'
import {
	createGlobalCodeCategory,
	getGlobalCode,
	getGlobalCodeByCategoryCode,
	statusToggle,
	updateGlobalCodeCategory,
} from './GlobalCodeCategory.service'
import {
	GlobalCodeCategoryInitialState,
	GlobalCodeCategoryPayload,
} from './GlobalCodeCategory.types'

interface IGlobalCodeQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IFullGlobalCodeCategory>>>,
		Partial<IFullGlobalCodeCategory> {
	patient?: string
}

export function useGlobalCodeCategory({ id, ...rest }: IGlobalCodeQueryProps = {}) {
	return useQuery<
		AxiosResponse<IFullGlobalCodeCategory>,
		any,
		AxiosResponse<IFullGlobalCodeCategory>
	>(
		[ENDPOINTS.GLOBAL_CODE_CATEGORY, id],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getGlobalCode(id, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}

export function useGlobalCodeCategoryByCategoryCode({
	id,
	clientId,
	suspense = false,
	...rest
}: IGlobalCodeQueryProps | any = {}) {
	return useQuery<
		AxiosResponse<IFullGlobalCodeCategory>,
		any,
		AxiosResponse<IFullGlobalCodeCategory>
	>(
		[ENDPOINTS?.GET_GLOBAL_CATEGORY_BY_CATEGORY_CODE, id, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getGlobalCodeByCategoryCode(id, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: suspense,
			enabled: !!id,
			...rest,
		},
	)
}

export function useInitial() {
	const alreadyMounted = useRef(false)
	const [globalCategory] = useUrlQuery<any>()

	const id = globalCategory?.id
	const { data, isFetched } = useGlobalCodeCategory({
		id,
		suspense: !alreadyMounted.current, //TO USE SUSPENSE ONCE ON MOUNT
	})

	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetched])

	return useMemo(() => {
		return new GlobalCodeCategoryInitialState(id ? data?.data : {})
	}, [data?.data, id])
}

export function useSubmitHandler() {
	const params = useParams<{ id: string }>()
	let id = params.id
	const client = useQueryClient()

	const [globalCode] = useUrlQuery<any>()
	const globalCodeId = globalCode?.id
	const { mutateAsync: create } = useAddGlobalCodeCategory({ id })
	const navigate = useNavigate()
	const profile = useMyProfile()
	if (matchPath(APP_ROUTES.ME, location.pathname)) {
		id = profile.id
	}
	const { mutateAsync: update } = useUpdateGlobalCodeCategory({ id, globalCodeId })

	return useCallback(
		async (
			form: GlobalCodeCategoryInitialState,
			formikHelpers: FormikHelpers<GlobalCodeCategoryInitialState>,
		) => {
			formikHelpers.setSubmitting(true)
			// form.patient = id
			try {
				if (!globalCodeId) {
					await create(new GlobalCodeCategoryPayload(form))
					navigate(-1)
					successNotify(commonMessages.createMessage)
					client.invalidateQueries([ENDPOINTS.GET_GLOBAL_CATEGORY_ALL])
				}
				if (globalCodeId) {
					await update(new GlobalCodeCategoryPayload(form))
					navigate(-1)
					successNotify(commonMessages.updateMessage)
					client.invalidateQueries([ENDPOINTS.GET_GLOBAL_CATEGORY_ALL])
				}
			} catch (err: any) {
				formikHelpers.setErrors(err.response.data) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
				scrollToError()
				if (err?.response?.data?.message) {
					errorNotify(err.response.data.message)
				} else {
					errorNotify(commonMessages.errorHandlingMessage)
				}
			}
		},
		[create, id, navigate, update, globalCodeId],
	)
}

//* Global code status toggle hook
interface IUpdateStatusToggle {
	isActive?: true | false
}
interface IUpdateStatusToggleProps
	extends Partial<
			UseMutationOptions<AxiosResponse<IGlobalCodesCategory>, any, IUpdateStatusToggle>
		>,
		IUpdateStatusToggle {}

export function useStatusUpdateToggle({ isActive, ...rest }: IUpdateStatusToggleProps = {}) {
	//@ts-ignore
	const CancelToken = axiosLibrary.CancelToken
	const source = CancelToken.source()
	return useMutation<AxiosResponse<IGlobalCodesCategory>, any, any>(
		async (id: string) => await statusToggle(id, { isActive }, source),
		{
			...rest,
		},
	)
}

// Add Condition
interface IAddGlobalCodeProps
	extends Partial<
		UseMutationOptions<
			AxiosResponse<GlobalCodeCategoryPayload>,
			any,
			Partial<GlobalCodeCategoryPayload>
		>
	> {
	id?: string
}

export function useAddGlobalCodeCategory({ id, ...rest }: IAddGlobalCodeProps = {}) {
	return useMutation<
		AxiosResponse<GlobalCodeCategoryPayload>,
		any,
		Partial<GlobalCodeCategoryPayload>
	>(async (data) => await createGlobalCodeCategory(id, data), {
		...rest,
	})
}

interface IUpdateGlobalCodeProps
	extends Partial<
		UseMutationOptions<
			AxiosResponse<GlobalCodeCategoryPayload>,
			any,
			Partial<GlobalCodeCategoryPayload>
		>
	> {
	id?: string
	globalCodeId: string
}

export function useUpdateGlobalCodeCategory({ id, globalCodeId, ...rest }: IUpdateGlobalCodeProps) {
	return useMutation<
		AxiosResponse<GlobalCodeCategoryPayload>,
		any,
		Partial<GlobalCodeCategoryPayload>
	>(async (data) => await updateGlobalCodeCategory(id, globalCodeId, data), {
		...rest,
	})
}
