import { useCallback, useEffect, useMemo, useRef } from 'react'
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
	useMutation,
	UseMutationOptions,
	useQueryClient,
	useQuery,
	UseQueryOptions,
} from '@tanstack/react-query'
import { FormikHelpers } from 'formik'
import axiosLibrary, { AxiosResponse } from 'axios'
import axios from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { APP_ROUTES } from 'const/router'
import { getClientDetails, useMyProfile } from 'hooks/common'
import { useUrlQuery } from 'hooks/url'
import { scrollToError, timezoneDropdownOptions } from 'utils/common'
import { errorNotify, successNotify } from 'utils/toster'
import { commonMessages } from 'utils/messages'
import {
	createPartner,
	getPartner,
	getPartnerClients,
	statusToggle,
	updatePartner,
} from '../Partner.service'
import { PartnerInitialState, PartnerInitialStateWithAdProp, PartnerPayload } from './Partner.types'
import { IFullPartner, IPartner, IPartnerParams } from '../Partner.interfaces'

export const ERRORS = {
	phone: ['This number already exist in database', 'This number already exist in firebase'],
}

//* Formik useInitial hook
export function useInitial() {
	const alreadyMounted = useRef(false)

	const params = useParams<{ id: string }>()
	const [urlQuery] = useUrlQuery<IPartnerParams>()
	const id = urlQuery?.id ? urlQuery?.id : params?.id
	const profile = useMyProfile()
	const clientId = profile?.client?.id
	const { data, isFetched, isFetching, ...rest } = usePartner({
		clientId: getClientDetails()?.clientId,
		id,
		suspense: !alreadyMounted.current, //TO USE SUSPENSE ONCE ON MOUNT
	})
	/**
	 * *useEffexts Section
	 * @param useEffectName
	 */
	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetched])

	return useMemo(() => {
		const initialData = {
			...data?.data,
			options: timezoneDropdownOptions(),
		}
		const partner: PartnerInitialStateWithAdProp = new PartnerInitialState(
			id ? initialData : { options: timezoneDropdownOptions() },
		)
		partner.isFetched = isFetched
		return partner
	}, [data?.data, id, clientId, isFetching])
}

//* Formik useSubmitHandler hook
export function useSubmitHandler(props: any) {
	const { mutateAsync: create } = useCreatePartner({ clientId: getClientDetails()?.clientId })
	const navigate = useNavigate()
	const [urlQuery] = useUrlQuery<IPartnerParams>()
	const location = useLocation()
	const profile = useMyProfile()
	// let id = params.id
	let id = urlQuery.id
	if (matchPath(APP_ROUTES.ME, location.pathname)) {
		id = profile.id
	}
	const { mutateAsync: update } = useUpdatePartner({ id, clientId: getClientDetails()?.clientId })

	const client = useQueryClient()
	return useCallback(
		async (form: PartnerInitialState, formikHelpers: FormikHelpers<PartnerInitialState>) => {
			formikHelpers.setSubmitting(true)
			try {
				if (!id) {
					const result = await create(new PartnerPayload(form))
					successNotify(commonMessages.createMessage)
					props.setAddPartner(false)
					navigate(APP_ROUTES.PARTNER_PROFILE + '/' + result?.data?.id + '/locations')
				}
				if (id) {
					await update(new PartnerPayload(form))
					props.setAddPartner(false)
					location.pathname == APP_ROUTES.PARTNERS && navigate(APP_ROUTES.PARTNERS)
					navigate(APP_ROUTES.PARTNER_PROFILE + `/${id}` + `/locations`)
					successNotify(commonMessages.updateMessage)
				}
				props?.reloadPartnerProfile()
				formikHelpers.setSubmitting(false)
				client.invalidateQueries([ENDPOINTS.PARTNER], {
					type: 'all',
				})
			} catch (err: any) {
				if (err?.response?.data?.message) {
					Object.entries(ERRORS).forEach(([key, value]: [string, string[]]) => {
						if (value?.includes(err.response.data.message)) {
							formikHelpers.setFieldError(key, err.response.data.message)
						}
					})
					errorNotify(err.response.data.message)
				} else {
					formikHelpers.setErrors(err.response.data) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
					errorNotify(commonMessages.errorHandlingMessage)
				}
				scrollToError()
			}
		},
		[client, create, id, navigate, update],
	)
}
//* Create partner hook
type ICreatePartnerProps = Partial<
	UseMutationOptions<AxiosResponse<PartnerPayload>, any, Partial<PartnerPayload>>
>

export function useCreatePartner({ clientId, ...rest }: ICreatePartnerProps | any = {}) {
	return useMutation<AxiosResponse<PartnerPayload>, any, Partial<PartnerPayload>>(
		async (data) => await createPartner(clientId, data),

		{
			...rest,
		},
	)
}

//* Update partner hook
interface IUpdateProfileProps
	extends Partial<UseMutationOptions<AxiosResponse<PartnerPayload>, any, Partial<PartnerPayload>>> {
	id?: string
	clientId?: string
}

export function useUpdatePartner({ id, clientId, ...rest }: IUpdateProfileProps) {
	return useMutation<AxiosResponse<PartnerPayload>, any, Partial<PartnerPayload>>(
		async (data) => await updatePartner(id, clientId, data),
		{
			...rest,
		},
	)
}
//* Get partner by id for initial state
interface IPartnerQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IFullPartner>>>,
		Partial<IFullPartner> {
	id?: string
	clientId?: string
}

export function usePartner({ clientId, id, ...rest }: IPartnerQueryProps) {
	return useQuery<AxiosResponse<IFullPartner>, any, AxiosResponse<IFullPartner>>(
		[ENDPOINTS.PARTNER_ENTITY, id, clientId],
		() => {
			const CancelToken = axiosLibrary.CancelToken
			const source = CancelToken.source()
			return getPartner(id, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!id,
			...rest,
		},
	)
}
//* Partner status toggle hook
interface IUpdateStatusToggle {
	active?: true | false
	clientId?: string
}
interface IUpdateStatusToggleProps
	extends Partial<UseMutationOptions<AxiosResponse<IPartner>, any, IUpdateStatusToggle>>, //<AxiosResponse<received data from api>, any, payload data send to>
		IUpdateStatusToggle {}

export function useStatusUpdateToggle({
	active,
	clientId,
	...rest
}: IUpdateStatusToggleProps = {}) {
	//@ts-ignore
	const CancelToken = axiosLibrary.CancelToken
	const source = CancelToken.source()
	return useMutation<AxiosResponse<IPartner>, any, any>(
		async (id: string) => await statusToggle(id, clientId, { active }, source),
		{
			...rest,
		},
	)
}

export function usePartnerClients({
	limit,
	page,
	search,
	isEnabled,
	sort,
	templateCode,
	clientId,
	isAssign,
}: {
	limit?: number
	page?: number
	search?: string
	isEnabled?: boolean
	sort?: string
	templateCode?: string
	clientId?: string | number | any
	isAssign?: string
}) {
	const [urlParams] = useUrlQuery<{ clientSearch: string }>()
	const clientSearch = urlParams.clientSearch
	return useQuery<any>(
		[ENDPOINTS.CLIENTS_ALL, clientSearch, limit, page, search, sort, templateCode, isAssign],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getPartnerClients(
				{
					templateCode,
					limit,
					page,
					search: search || clientSearch,
					client: isAssign,
				},
				clientId,
				source,
			)
		},
		{
			suspense: false,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: isEnabled,
		},
	)
}
