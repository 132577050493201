import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import axios from 'axios'
import NoVitalsTracked from '../../assets/images/ic-mdi-alert-octagon.2f7e6f0f.svg'
import AbnormalVitals from '../../assets/images/ic-attention.c8a8f118.svg'
import { ReactComponent as EditIcon } from 'assets/images/ic_edit.svg'
import { ReactComponent as ProfileIcon } from 'assets/images/ic-profile.svg'
import { useUrlQuery } from 'hooks/url'
import { getClientDetails, useCheckScreenPermission, useSortHandler } from 'hooks/common'
import { APP_ROUTES } from 'const/router'
import { ENDPOINTS } from 'const/endpoints'
import { CONTACT_POINT, IAxiosResponseWithPagination } from 'types/entities'
import { IProvidersParams } from 'modules/Providers/Provider.interfaces'
import { membersAlertColumns, membersColumns } from './Members.constants'
import { COLUMN_NAME, SCREEN_NAME } from 'const/screen'
import { getMembers } from './Member/Member.service'
import ReactTooltip from 'react-tooltip'
import { IFullMember, IMemberParams } from './Member/Member.interfaces'
import { formatDate } from 'utils/time'

export function useMembersTableData(
	partnerFilter?: any,
): [
	Column<{ [key: string]: React.ReactNode }>[],
	Column<{ [key: string]: React.ReactNode }>[],
	{ [key: string]: React.ReactNode }[],
	(param: string) => void,
	any,
] {
	const [params] = useUrlQuery<IProvidersParams>()
	const stringfyPartner = JSON.stringify(partnerFilter)
	const patients = useMembers(
		{ ...params, client: getClientDetails()?.clientId },
		stringfyPartner && stringfyPartner,
	)
	const onSort = useSortHandler(params.sort)
	//  Custom hook to check is screen Available
	const isVitalScreenVisible = useCheckScreenPermission(SCREEN_NAME?.MEMBER_PROFILE_VITALS)
	const isProfileScreenVisible = useCheckScreenPermission(SCREEN_NAME?.MEMBER_PROFILE_PROFILE)
	// To filter action particular column if nothing to display in it
	const filteredMembersAlertColumns = membersAlertColumns?.filter((columnObj: any) => {
		return !(
			columnObj?.name === COLUMN_NAME?.ACTION &&
			!isVitalScreenVisible &&
			!isProfileScreenVisible
		)
	})
	const clientsData = useMemo(() => {
		return (
			patients?.data?.data.items.map((item: any, key: any) => {
				const phone = item?.patientContactPoints?.find(
					(item: any) => item?.contactPoint?.type === CONTACT_POINT.PHONE,
				)?.contactPoint?.text
				const email = item?.patientContactPoints?.find(
					(item: any) => item?.contactPoint?.type === CONTACT_POINT.EMAIL,
				)?.contactPoint?.text
				let contact = ''
				if (phone) {
					contact = phone
				} else {
					if (email) {
						contact = email
					}
				}
				return {
					col1: (
						<div className="flex gap-2" key={key}>
							{item?.vitalAlert && (
								<div className="">
									<div className="h-full border-r-2 border-red-500" />
								</div>
							)}
							<span>
								{isVitalScreenVisible ? (
									<Link
										to={`${APP_ROUTES.MEMBER_PROFILE}/${item?.id}/vitals`}
										className="text-fountainBlue"
									>
										{item?.humanName?.text?.charAt(0).toUpperCase() +
											item?.humanName?.text?.slice(1) || '--'}
									</Link>
								) : (
									<span>
										{item?.humanName?.text?.charAt(0).toUpperCase() +
											item?.humanName?.text?.slice(1) || '--'}
									</span>
								)}
							</span>
						</div>
					), //
					col2: (
						<div className="flex ">
							{item?.patientDevices?.some((device: any) => device.status === 'Abnormal') ? (
								<span data-tip="With abnormal vitals result" className="flex gap-1">
									<img src={AbnormalVitals} alt="icon" />
								</span>
							) : (
								''
							)}
							{item?.patientDevices?.some((device: any) => device.vitalTwoDays == false) ? (
								<span data-tip="With no readings for 48h" className="flex gap-1">
									<img src={NoVitalsTracked} alt="icon" />
								</span>
							) : (
								''
							)}
						</div>
					),
					col3: item?.dateOfBirth ? formatDate(item?.dateOfBirth) : '--', //
					//
					col4:
						(
							<a href={`mailto:${contact || '--'}`} className="text-fountainBlue">
								{contact || '--'}
							</a>
						) || '--',
					col5: item?.provider?.humanName?.text ?? '--',
					col6: item?.partner?.companyName ?? '--',

					col7: item?.active ? 'Active' : 'Inactive', //

					col8:
						item?.address?.city?.charAt(0).toUpperCase() + item?.address?.city?.slice(1) || '--', //
					col9: item?.address?.state || '--', //
					col10: item?.client?.title || '--', //
					//
					col11: (
						<div className="flex items-center gap-2">
							{isProfileScreenVisible && (
								<Link
									to={APP_ROUTES.MEMBER_PROFILE + `/${item?.id}/profile`}
									className="text-blue-500"
								>
									<span data-tip="Open profile">
										<EditIcon className="hover:stroke-fountainBlue  stroke-solidGray fill-solidGray hover:fill-fountainBlue" />
										<ReactTooltip />
									</span>
								</Link>
							)}
							{isVitalScreenVisible && (
								<Link
									to={`${APP_ROUTES.MEMBER_PROFILE}/${item?.id}/vitals`}
									className="text-blue-500"
								>
									<span data-tip="Open vitals">
										<ProfileIcon className="hover:stroke-fountainBlue  stroke-solidGray fill-solidGray hover:fill-fountainBlue" />
									</span>
								</Link>
							)}
						</div>
					),
					status: patients?.status,
				}
			}, []) || []
		)
	}, [patients?.data?.data?.items, onSort])

	return [membersColumns, filteredMembersAlertColumns, clientsData, onSort, patients]
}

interface IPatientsQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullMember>>>,
		Partial<IMemberParams> {}

export function useMembers(
	{
		id,
		page,
		limit,
		sort,
		name,
		client,
		status,
		programName,
		city,
		state,
		isAlert,
		untrained,
		welcomeCall,
		patientReadings,
		currentDate,
		abnormalVitals,
		lastMeasurementLt,
		healthCondition,
		search,
		...rest
	}: IPatientsQueryProps = {},
	partnerFilter?: any,
) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullMember>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullMember>>
	>(
		[
			ENDPOINTS.PATIENTS,
			page,
			limit,
			sort,
			name,
			client,
			status,
			programName,
			welcomeCall,
			patientReadings,
			currentDate,
			abnormalVitals,
			city,
			state,
			isAlert,
			untrained,
			lastMeasurementLt,
			healthCondition,
			search,
			partnerFilter,
			id,
		],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getMembers(
				id,
				client,
				{
					page,
					limit,
					sort,
					name,
					client,
					status,
					welcomeCall,
					patientReadings,
					abnormalVitals,
					currentDate,
					programName,
					city,
					state,
					isAlert,
					untrained,
					lastMeasurementLt,
					healthCondition,
					search,
					partner: partnerFilter,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,

			...rest,
		},
	)
}
