import { ENDPOINTS } from 'const/endpoints'
import { commonMessages } from 'utils/messages'
import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import { IPagination } from 'types/entities'
import _ from 'lodash'
import { GlobalCodeCategoryPayload } from './GlobalCodeCategory.types'
import { IFullGlobalCodeCategory, IGlobalCodeCategoryParams } from './GlobalCodeCategory.interface'

/**
 * * GET request for global codes (Global codes Listing)
 */
export function getGlobalCodesCategory(
	params: Partial<IGlobalCodeCategoryParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullGlobalCodeCategory>>>(
		ENDPOINTS.GET_GLOBAL_CATEGORY_ALL,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getGlobalCode(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<IFullGlobalCodeCategory>(ENDPOINTS.GLOBAL_CODE_CATEGORY + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getGlobalCodeByCategoryCode(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<IFullGlobalCodeCategory>(
		ENDPOINTS?.GET_GLOBAL_CATEGORY_BY_CATEGORY_CODE + '/' + clientId + '?categoryCode=' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function createGlobalCodeCategory(
	id: string | any,
	data: Partial<GlobalCodeCategoryPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.post<GlobalCodeCategoryPayload>(ENDPOINTS.GLOBAL_CODE_CATEGORY, {
		...data,
		patient: id,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function updateGlobalCodeCategory(
	id: string | undefined,
	globalCodeId: string | undefined,
	data: Partial<GlobalCodeCategoryPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch<GlobalCodeCategoryPayload>(
		ENDPOINTS.GLOBAL_CODE_CATEGORY + '/' + globalCodeId,
		{
			...data,
			patient: id,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * PATCH request for global code's status change
 */
export function statusToggle(
	id: string | undefined,
	data: Partial<any>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch(
		ENDPOINTS.GLOBAL_CODE_CATEGORY_STATUS + '/' + id + `?isActive=${data['isActive']}`,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
