import TableSortHeader from 'components/TableSortHeader'
import { ROLES } from 'const/auth'
import { CONTACT_POINT, IBaseWithId } from 'types/entities'

export const ProviderContactColumns = [
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Name</TableSortHeader>,
		accessor: 'col1', // accessor is the "key" in the data
		name: 'name',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Client</TableSortHeader>,
		accessor: 'col2',
		name: 'client',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Partner</TableSortHeader>,
		accessor: 'col3',
		name: 'partner',
		sortable: true,
	},
	{
		Header: 'Phone #',
		accessor: 'col4',
		name: 'contacts',
	},
	{
		Header: 'Email',
		accessor: 'col5',
		name: 'email',
	},
	{
		Header: 'Speciality',
		accessor: 'col6',
		name: 'speciality',
	},

	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Status</TableSortHeader>,
		accessor: 'col7',
		name: 'status',
		sortable: true,
	},

	{
		Header: 'Actions',
		accessor: 'col8',
		name: 'actions',
	},
]

export enum FIELD_NAMES {
	STATUS = 'status',
	IS_EMAIL_ALERT = 'isEmailAlert',
	IS_SMS_ALERT = 'isSmsAlert',
	GIVEN_NAME = 'givenName',
	FAMILY_NAME = 'familyName',
	EMAIL = 'email',
	CLIENT = 'client',
	CLIENT_NAME = 'clientName',
	SPECIALITY = 'providerSpeciality',
	PARTNER = 'partner',
	PROVIDER_CONTACT_POINTS = 'providerContactPoints',
	ROLE = 'role',
	NPI = 'npi',
	IMAGE = 'image',
	TYPE = 'type',
	TEXT = 'text',
	COUNTRY_CODE = 'countryCode',
	TIMEZONE = 'timezone',
	PROVIDER_ROLES = 'providerRole',
	CONTACT_POINT = 'contactPoint',
}
export interface IProviderRoles extends IBaseWithId {
	client: string
	roles: string
}
export const CONTACT_POINT_TYPES_OPTIONS: {
	label: string
	value: CONTACT_POINT
}[] = [
	{ label: 'Phone', value: CONTACT_POINT.PHONE },
	{ label: 'Email', value: CONTACT_POINT.EMAIL },
	{ label: 'Fax', value: CONTACT_POINT.FAX },
]

export const ROLES_OPTIONS: {
	label: string
	value: ROLES
}[] = [
	{ label: 'Super Admin', value: ROLES.SUPER_ADMIN },
	{ label: 'Client Admin', value: ROLES.ADMIN },
	{ label: 'Doctor / MD', value: ROLES.DOCTOR },
	{ label: 'Program Manager', value: ROLES.PROGRAM_MANAGER },
	{ label: 'Medical Assistant', value: ROLES.MA },
	{ label: 'Program Coordinator', value: ROLES.PROGRAM_COORDINATOR },
	{ label: 'Epidemiologist', value: ROLES.EPIDEMIOLOGIST },
]
export const SUPER_ADMIN_ROLES_OPTIONS: {
	label: string
	value: ROLES
}[] = [
	{ label: 'Super Admin', value: ROLES.SUPER_ADMIN },
	{ label: 'Client Admin', value: ROLES.ADMIN },
	{ label: 'Doctor / MD', value: ROLES.DOCTOR },
	{ label: 'Program Manager', value: ROLES.PROGRAM_MANAGER },
	{ label: 'Medical Assistant', value: ROLES.MA },
	{ label: 'Program Coordinator', value: ROLES.PROGRAM_COORDINATOR },
	{ label: 'Epidemiologist', value: ROLES.EPIDEMIOLOGIST },
	{ label: 'CHCT', value: ROLES.CHCT },
]

export const PROGRAM_MANAGER_ROLES_OPTIONS: {
	label: string
	value: ROLES
}[] = [
	{ label: 'Doctor / MD', value: ROLES.DOCTOR },
	{ label: 'Medical Assistance', value: ROLES.MA },
]

export const ADMIN_ROLES_OPTIONS: {
	label: string
	value: ROLES
}[] = [
	{ label: 'Client Admin', value: ROLES.ADMIN },
	{ label: 'Doctor / MD', value: ROLES.DOCTOR },
	{ label: 'Program Manager', value: ROLES.PROGRAM_MANAGER },
	{ label: 'Program Coordinator', value: ROLES.PROGRAM_COORDINATOR },
	{ label: 'Medical Assistance', value: ROLES.MA },
	{ label: 'Epidemiologist', value: ROLES.EPIDEMIOLOGIST },
]

export const PROVIDER_STATUS = [
	{ label: 'Active', value: 'active' },
	{ label: 'Inactive', value: 'inactive' },
]

export const IS_ACTIVE_OPTIONS = [
	{ value: 'isActive', label: 'Is Active' },
	{ value: 'isInactive', label: 'Is Inactive' },
]
export enum LABEL {
	COUNTRY_CODE = 'Please enter your country code (01) followed by the number we have on file.',
	TWO_FACTOR = 'Two Factor has been',
	ENABLED = 'enabled',
	DISABLED = 'disabled',
	SUCCESSFULL_MESSAGE = ' successfully. Now you are redirected to the login page',
	DISABLE_TWO_FACTOR = 'Disable Two-Factor Authentication',
	ENABLE_TWO_FACTOR = 'Enable Two-Factor Authentication',
	PHONE = 'phone',
}

export enum PROVIDER_ROLES {
	DOCTOR = 'doctor',
	ADMIN = 'admin',
	CAREHALO = 'CareHalo West',
}
export enum PROVIDER_FILTER_FIELDS {
	SEARCH = 'Search',
	CLIENT = 'Client',
	PARTNER = 'Partner',
	STATUS = 'Status',
}

export const testProviderData = [
	{
		createdAt: '2024-01-29T06:07:03.894Z',
		updatedAt: '2024-01-29T06:07:03.894Z',
		createdAtUTC: 1706508423896,
		updatedAtUTC: 1706508423896,
		deletedAt: null,
		id: 'GhdIbgWiCCP67GzanHN9Y92Vn6t2',
		emailVerified: false,
		image: 'https://carehalo.blob.core.windows.net/uploads/1706508417752download.png',
		active: true,
		role: 'doctor',
		email: 'amit234@yopmail.com',
		npi: '5675634563',
		isPartner: false,
		isAssociate: false,
		timezone: 'America/Adak',
		defaultRoute: '/members',
		humanName: {
			createdAt: '2024-01-29T06:07:03.050Z',
			updatedAt: '2024-01-29T06:07:03.050Z',
			createdAtUTC: 1706508423058,
			updatedAtUTC: 1706508423058,
			deletedAt: null,
			id: '7ad891c4-7d24-4900-933a-865c1cb4a3b5',
			text: 'Amit Singh',
			given: 'Amit',
			family: 'Singh',
			middle: null,
		},
		client: {
			createdAt: '2024-01-29T06:03:27.655Z',
			updatedAt: '2024-01-29T06:03:27.655Z',
			createdAtUTC: 1706508207664,
			updatedAtUTC: 1706508207664,
			deletedAt: null,
			id: '2cb80a69-73aa-4d66-be1d-adbf12cd0dc1',
			title: 'Riverdale Client ',
			clientRepresentative: null,
			active: true,
			isWisewoman: false,
			timezone: 'America/New_York',
			isModulesAssigned: false,
		},
		providerContactPoints: [
			{
				createdAt: '2024-01-29T06:07:04.112Z',
				updatedAt: '2024-01-29T06:07:04.112Z',
				createdAtUTC: 1706508424120,
				updatedAtUTC: 1706508424120,
				deletedAt: null,
				id: '1cb98065-d72b-4e14-9c19-afa92e89d874',
				contactPoint: {
					createdAt: '2024-01-29T06:07:04.097Z',
					updatedAt: '2024-01-29T06:07:04.097Z',
					createdAtUTC: 1706508424102,
					updatedAtUTC: 1706508424102,
					deletedAt: null,
					id: '72fa1333-bcca-42eb-a41b-fdb27a820dda',
					text: '+17865412345',
					type: 'phone',
				},
			},
		],
		providerSpeciality: [
			{
				createdAt: '2024-01-29T06:07:03.894Z',
				updatedAt: '2024-01-29T06:07:03.894Z',
				createdAtUTC: 1706508423896,
				updatedAtUTC: 1706508423896,
				deletedAt: null,
				id: '2ca7eee9-11e7-42e2-9e20-c346ec5380a4',
				speciality: {
					createdAt: '2024-01-25T10:18:28.059Z',
					updatedAt: '2024-01-25T10:18:28.059Z',
					createdAtUTC: null,
					updatedAtUTC: null,
					deletedAt: null,
					id: '0b186ee8-7541-4475-9dee-eab1f5060c41',
					speciality: 'Family Medicine',
					isActive: null,
					isGlobal: null,
				},
			},
			{
				createdAt: '2024-01-29T06:07:03.894Z',
				updatedAt: '2024-01-29T06:07:03.894Z',
				createdAtUTC: 1706508423896,
				updatedAtUTC: 1706508423896,
				deletedAt: null,
				id: '28fcbe2d-4f60-47be-89ac-446376198f3b',
				speciality: {
					createdAt: '2024-01-25T10:18:28.059Z',
					updatedAt: '2024-01-25T10:18:28.059Z',
					createdAtUTC: null,
					updatedAtUTC: null,
					deletedAt: null,
					id: '9aa7fda1-e055-42af-87d3-2ad60d589e1f',
					speciality: 'Internal Medicine',
					isActive: null,
					isGlobal: null,
				},
			},
		],
		partnerPointOfContact: [],
	},
	{
		createdAt: '2024-01-29T05:44:52.899Z',
		updatedAt: '2024-01-29T05:44:52.899Z',
		createdAtUTC: 1706507092912,
		updatedAtUTC: 1706507092912,
		deletedAt: null,
		id: 'IihzO4bvMKhKPxHqoQhHBDoKxGe2',
		emailVerified: false,
		image: null,
		active: true,
		role: 'superAdmin',
		email: 'first@yopmail.com',
		npi: null,
		isPartner: false,
		isAssociate: false,
		timezone: 'America/Boise',
		defaultRoute: '/members',
		humanName: {
			createdAt: '2024-01-29T05:44:51.617Z',
			updatedAt: '2024-01-29T05:44:51.617Z',
			createdAtUTC: 1706507091627,
			updatedAtUTC: 1706507091628,
			deletedAt: null,
			id: '3590f261-0f38-4ea4-baa3-8d6b78fb454e',
			text: 'first  provider',
			given: 'first ',
			family: 'provider',
			middle: null,
		},
		client: {
			createdAt: '2024-01-25T10:17:49.277Z',
			updatedAt: '2024-01-25T10:17:49.277Z',
			createdAtUTC: 1706177869445,
			updatedAtUTC: 1706177869445,
			deletedAt: null,
			id: 'dee6be69-d0fd-4b6a-ad7d-fdcaecbf021c',
			title: 'CareHalo West',
			clientRepresentative: null,
			active: true,
			isWisewoman: false,
			timezone: null,
			isModulesAssigned: false,
		},
		providerContactPoints: [
			{
				createdAt: '2024-01-29T05:44:53.024Z',
				updatedAt: '2024-01-29T05:44:53.024Z',
				createdAtUTC: 1706507093034,
				updatedAtUTC: 1706507093034,
				deletedAt: null,
				id: 'd0de2655-e93e-447c-813a-460720cedbdb',
				contactPoint: {
					createdAt: '2024-01-29T05:44:52.992Z',
					updatedAt: '2024-01-29T05:44:52.992Z',
					createdAtUTC: 1706507092997,
					updatedAtUTC: 1706507092997,
					deletedAt: null,
					id: '240ea58d-9549-4f8c-9df9-4ff30e78e44b',
					text: '+11234232112',
					type: 'phone',
				},
			},
		],
		providerSpeciality: [],
		partnerPointOfContact: [],
	},
	{
		createdAt: '2024-01-29T06:07:46.863Z',
		updatedAt: '2024-01-29T06:07:46.863Z',
		createdAtUTC: 1706508466866,
		updatedAtUTC: 1706508466866,
		deletedAt: null,
		id: 'paMlG3tLC0OnbNZuqcISqeNtvrk2',
		emailVerified: false,
		image: 'https://carehalo.blob.core.windows.net/uploads/1706508460832download.jpg',
		active: true,
		role: 'doctor',
		email: 'vipul@yopmail.com',
		npi: '8234692740',
		isPartner: false,
		isAssociate: false,
		timezone: 'America/Adak',
		defaultRoute: '/members',
		humanName: {
			createdAt: '2024-01-29T06:07:45.816Z',
			updatedAt: '2024-01-29T06:07:45.816Z',
			createdAtUTC: 1706508465817,
			updatedAtUTC: 1706508465817,
			deletedAt: null,
			id: '8a2616bb-6233-4788-86b7-c7a4cff47f3b',
			text: 'Vipul Singh',
			given: 'Vipul',
			family: 'Singh',
			middle: null,
		},
		client: {
			createdAt: '2024-01-29T06:03:27.655Z',
			updatedAt: '2024-01-29T06:03:27.655Z',
			createdAtUTC: 1706508207664,
			updatedAtUTC: 1706508207664,
			deletedAt: null,
			id: '2cb80a69-73aa-4d66-be1d-adbf12cd0dc1',
			title: 'Riverdale Client ',
			clientRepresentative: null,
			active: true,
			isWisewoman: false,
			timezone: 'America/New_York',
			isModulesAssigned: false,
		},
		providerContactPoints: [
			{
				createdAt: '2024-01-29T06:07:47.004Z',
				updatedAt: '2024-01-29T06:07:47.004Z',
				createdAtUTC: 1706508467012,
				updatedAtUTC: 1706508467012,
				deletedAt: null,
				id: 'bb36124f-b797-4873-aa8f-74eab540cc61',
				contactPoint: {
					createdAt: '2024-01-29T06:07:46.988Z',
					updatedAt: '2024-01-29T06:07:46.988Z',
					createdAtUTC: 1706508466995,
					updatedAtUTC: 1706508466995,
					deletedAt: null,
					id: '34a7ff53-3cb3-486f-a97f-074199b81977',
					text: '+14567876234',
					type: 'phone',
				},
			},
		],
		providerSpeciality: [
			{
				createdAt: '2024-01-29T06:07:46.863Z',
				updatedAt: '2024-01-29T06:07:46.863Z',
				createdAtUTC: 1706508466866,
				updatedAtUTC: 1706508466866,
				deletedAt: null,
				id: '5b745e01-6864-419b-8433-fb3e4489881c',
				speciality: {
					createdAt: '2024-01-25T10:18:28.059Z',
					updatedAt: '2024-01-25T10:18:28.059Z',
					createdAtUTC: null,
					updatedAtUTC: null,
					deletedAt: null,
					id: 'aea7f64f-2949-411c-af5f-c4666741ba4f',
					speciality: 'Dietitian',
					isActive: null,
					isGlobal: null,
				},
			},
			{
				createdAt: '2024-01-29T06:07:46.863Z',
				updatedAt: '2024-01-29T06:07:46.863Z',
				createdAtUTC: 1706508466866,
				updatedAtUTC: 1706508466866,
				deletedAt: null,
				id: 'e731578f-876f-4101-aa96-44e0f46e792a',
				speciality: {
					createdAt: '2024-01-25T10:18:28.059Z',
					updatedAt: '2024-01-25T10:18:28.059Z',
					createdAtUTC: null,
					updatedAtUTC: null,
					deletedAt: null,
					id: '086f4cef-bdc4-49ae-a91f-8f1429b3762b',
					speciality: 'Nutritionalist',
					isActive: null,
					isGlobal: null,
				},
			},
		],
		partnerPointOfContact: [],
	},
]

export enum PROVIDER_FIELDS_SLUG {
	CLIENT_NAME = 'clientName',
	TIMEZONE = 'timezone',
	ROLE = 'role',
	STATUS = 'Status',
	FIRST_NAME = 'firstname',
	LAST_NAME = 'lastname',
	EMAIL = 'email',
	PROVIDER_NPI = 'providerNpi',
	SPECIALITY = 'speciality',
	UPLOAD_PROFILE_IMAGE = 'uploadProfileImage',
	POINT_OF_CONTACTS = 'pointOfContacts',
	PHONE = 'phone',
	CONTACT_TEXT = 'contactText',
	CLIENT = 'client',
}
