import { ISelectOption } from 'types/forms'

export class MemberScreeningPayload {
	template: string | null = null
	patient: string | undefined
	constructor({ template, patient }: Partial<MemberScreeningInitialState>) {
		this.addTemplates(template)
		this.patient = patient
	}

	addTemplates(template: ISelectOption | null = null) {
		if (template) {
			this.template = template.value
		}
	}
}
export class MemberScreeningInitialState {
	template: ISelectOption | null = null
	patient: string | undefined
}
