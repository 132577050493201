import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	useMutation,
	UseMutationOptions,
	useQueryClient,
	UseQueryOptions,
	useQuery,
} from '@tanstack/react-query'
import { FormikHelpers } from 'formik'
import axios from 'axios'
import { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { scrollToError } from 'utils/common'
import { errorNotify, successNotify } from 'utils/toster'
import { useUrlQuery } from 'hooks/url'
import { commonMessages } from 'utils/messages'
import { IAxiosResponseWithPagination } from 'types/entities'
import {
	createVitalProgram,
	getProgramDevices,
	getProgramVitals,
	getProgramVitalsListing,
	getTrackingDevices,
	updateVitalProgram,
} from '../Vital.service'
import { AddVitalInitialState, AddVitalPayload } from './Vital.types'
import {
	IFullVitalsProgram,
	IProgramVitalsListingParams,
	ITrackingDevices,
	ITrackingDevicesParams,
} from '../Vital.interfaces'
import { createVitalDevices } from '../../Devices/Devices.service'
import { getClientDetails } from 'hooks/common'

const ERRORS = {
	phone: ['This number already exist in database', 'This number already exist in firebase'],
}
/**
 * *Formik useInitial hook section
 */
export function useInitial() {
	/**
	 * *Variables and hook section
	 */
	const alreadyMounted = useRef(false)
	const [vital] = useUrlQuery<any>()
	const id = vital?.vital
	const { data, isFetched } = useProgramVitalEdit({
		id,
		clientId: getClientDetails()?.clientId,
		suspense: !alreadyMounted.current, //TO USE SUSPENSE ONCE ON MOUNT
	})
	/**
	 * *useEffects Section
	 * @param useEffectName
	 */
	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetched])
	/**
	 * *useMemo Section
	 */
	return useMemo(() => {
		return new AddVitalInitialState(id ? data?.data : {})
	}, [data?.data, id])
}
/**
 * *Formik useSubmit hook section
 */
export function useSubmitHandler(props: any) {
	/**
	 * *Variable and context section
	 */
	const [vitalParams] = useUrlQuery<any>()
	const navigate = useNavigate()
	const param = useParams<{ id: string }>()
	const id = ''
	const programId = param.id
	const client = useQueryClient()
	/**
	 * * 1. Using useCreateProgramVital() hook to create a mutateAsync function for creating a new program vital.
	 * * 2. Using useUpdateVitalProgram() hook to create a mutateAsync function for updating an existing vital program, with the id specified in the params.
	 */
	const { mutateAsync: create } = useCreateProgramVital({ clientId: getClientDetails()?.clientId })
	const { mutateAsync: update } = useUpdateVitalProgram({
		id: props?.vitalId,
		clientId: getClientDetails()?.clientId,
	})
	/**
	 * *useCallback section
	 */
	return useCallback(
		async (form: AddVitalInitialState, formikHelpers: FormikHelpers<AddVitalInitialState>) => {
			formikHelpers.setSubmitting(true)
			form.program = programId
			try {
				if (!props?.vitalId) {
					await create(new AddVitalPayload(form))

					successNotify(commonMessages.createMessage)
				} else {
					await update(new AddVitalPayload(form))
					successNotify(commonMessages.updateMessage)
				}
				formikHelpers.resetForm()
				formikHelpers.setSubmitting(false)
				if (!vitalParams?.createVital) {
					props?.setOpenVitalDrawer(false)
				}
				client.invalidateQueries([ENDPOINTS.PROGRAMS_VITALS_LIST], {
					type: 'all',
				})
				client.invalidateQueries([ENDPOINTS.PROGRAMS_VITALS_ITEM])
			} catch (err: any) {
				if (err.response.data && err.response.data.message) {
					Object.entries(ERRORS).forEach(([key, value]: [string, string[]]) => {
						if (value?.includes(err.response.data.message)) {
							formikHelpers.setFieldError(key, err.response.data.message)
						}
					})
					errorNotify(err.response.data.message)
				} else {
					formikHelpers.setErrors(err.response.data) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
					errorNotify(commonMessages.errorHandlingMessage)
				}
				scrollToError()
			}
		},
		[client, create, id, navigate, update, props],
	)
}

/**
 * * Using the React Hooks useMutation to create a mutation function for creating a program vital.
 */
type ICreateProgramVitalProps = Partial<
	UseMutationOptions<AxiosResponse<AddVitalPayload>, any, Partial<AddVitalPayload>>
>

export function useCreateProgramVital({ clientId, ...rest }: ICreateProgramVitalProps | any = {}) {
	return useMutation<AxiosResponse<AddVitalPayload>, any, Partial<AddVitalPayload>>(
		async (data) => await createVitalProgram(clientId, data),

		{
			...rest,
		},
	)
}

/**
 * * Using the React Hooks useMutation to create a mutation function for creating a devices vital.
 */
type ICreateDevicesVitalProps = Partial<UseMutationOptions<AxiosResponse<any>, any, Partial<any>>>

export function useCreateDevicesVital({ clientId, ...rest }: ICreateDevicesVitalProps | any = {}) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(
		async (data) => await createVitalDevices(clientId, data),

		{
			...rest,
		},
	)
}

/**
 * * Using the React Hooks useMutation to create a mutation function for updating a program vital.
 */
interface IUpdateVitalProps
	extends Partial<
		UseMutationOptions<AxiosResponse<AddVitalPayload>, any, Partial<AddVitalPayload>>
	> {
	id?: string
	clientId?: string
}

export function useUpdateVitalProgram({ id, clientId, ...rest }: IUpdateVitalProps) {
	return useMutation<AxiosResponse<AddVitalPayload>, any, Partial<AddVitalPayload>>(
		async (data) => await updateVitalProgram(id, clientId, data),
		{
			...rest,
		},
	)
}
/**
 * * Using the React Hooks useQuery to create a Query function and is used to get a listing of program vitals such as check-in intervals, weeks, tracking devices, and search queries.
 */
interface IGetProgramListingQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullVitalsProgram>>>,
		Partial<IProgramVitalsListingParams> {}
export function useGetProgramListing({
	clientId,
	limit,
	page,
	sort,
	checkInInterval,
	week,
	trackingDevices,
	search,
	id,
	...rest
}: IGetProgramListingQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullVitalsProgram>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullVitalsProgram>>
	>(
		[
			ENDPOINTS.PROGRAMS_VITALS_LIST,
			page,
			limit,
			sort,
			checkInInterval,
			week,
			trackingDevices,
			search,
			id,
		],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getProgramVitalsListing(
				id,
				clientId,
				source,

				{
					page,
					limit,
					sort,
					checkInInterval,
					week,
					trackingDevices,
					search,
				},
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			...rest,
		},
	)
}
/**
 * * Using the React Hooks useQuery to create a Query function for retrieving program vitals for a given ID and source..
 */
interface IProgramEditVitalsProps
	extends Partial<UseQueryOptions<AxiosResponse<IFullVitalsProgram>>>,
		Partial<IFullVitalsProgram> {
	id?: string
	clientId?: string
}
export function useProgramVitalEdit({ id, clientId, ...rest }: IProgramEditVitalsProps) {
	return useQuery<AxiosResponse<IFullVitalsProgram>, any, AxiosResponse<IFullVitalsProgram>>(
		[ENDPOINTS.PROGRAMS_CREATE_VITAL, id],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getProgramVitals(id, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}
/**
 * * Using the React Hooks useQuery to create a Query function for object is used to configure the query for the tracking devices. The source parameter is also used to specify the source from which the tracking devices should be retrieved.
 */
interface IITrackingDevicesQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<ITrackingDevices<string[]>>>>,
		Partial<ITrackingDevicesParams> {}

export function useTrackingDevices({
	sort,
	limit,
	title,
	search,
	...rest
}: IITrackingDevicesQueryProps = {}) {
	return useQuery<
		AxiosResponse<ITrackingDevices<string[]>>,
		any,
		AxiosResponse<ITrackingDevices<string[]>>
	>(
		[ENDPOINTS.PROGRAM_DEVICES, sort, limit, title, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getTrackingDevices(
				{
					sort,
					limit,
					title,
					search,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			...rest,
		},
	)
}
/**
 * * Using the React Hooks useQuery to create a Query function for used to retrieve a list of devices associated with a given program (identified by the id parameter) from the source (which is the second parameter)..
 */
interface IDevicesQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IFullVitalsProgram>>>,
		Partial<IFullVitalsProgram> {
	id?: string
	clientId?: string
}
export function useProgramDevices({ id, clientId, ...rest }: IDevicesQueryProps) {
	return useQuery<AxiosResponse<IFullVitalsProgram>, any, AxiosResponse<IFullVitalsProgram>>(
		[ENDPOINTS.PROGRAM_VITAL_DEVICES, id, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getProgramDevices(id, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}
