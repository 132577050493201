export const commonMessages = {
	errorHandlingMessage: 'Something went wrong',
	noInternetConnection: 'No Internet Connection',
	queryCancelledMessage: 'Query was cancelled by React Query',
	createMessage: 'Created Successfully',
	deleteMessage: 'Deleted Successfully',
	updateMessage: 'Updated Successfully',
	assignedMessage: 'Assigned Successfully',
	sentMessage: 'The message has been sent successfully',
	fileUploadSuccess: 'File uploaded successfully',
	fileUploadError: 'Error uploading file',
	checkInternetConnection: 'Check your internet connection!!',
	backToOnline: 'Back to online!',
	cloneMessage: 'Cloned Successfully',
	sendMessage: 'Sended message successfully',
	addMessage: 'Members added sucsessfully',
	patientsErrorMsg: 'Plesae select atleast one member',
	addVital: 'Vitals are added',
	submitted: 'Submitted Successfully',
	socketError: 'This messaging service is not available.',
	assignMember: 'Please assign a provider to a member',
}
//  New considered need to use this on all above places
export enum CommonMessages {
	ERROR_HANDLING_MESSAGE = 'Something went wrong',
	QUERY_CANCELLED_MESSAGE = 'Query was cancelled by React Query',
	CREATE_MESSAGE = 'Created Successfully',
	DELETE_MESSAGE = 'Deleted Successfully',
	UPDATE_MESSAGE = 'Updated Successfully',
	ASSIGNED_MESSAGE = 'Assigned Successfully',
	SENT_MESSAGE = 'The message has been sent successfully',
	FILE_UPLOAD_SUCCESS = 'File uploaded successfully',
	FILE_UPLOAD_ERROR = 'Error uploading file',
	CHECK_INTERNET_CONNECTION = 'Check your internet connection!!',
	BACK_TO_ONLINE = 'Back to online!',
	CLONE_MESSAGE = 'Cloned Successfully',
	SEND_MESSAGE = 'Sended message successfully',
	ADD_MESSAGE = 'Members added sucsessfully',
	PATIENTS_ERROR_MSG = 'Plesae select atleast one member',
	ADD_VITAL = 'Vitals are added',
	SUBMITTED = 'Submitted Successfully',
	SOCKET_ERROR = 'This messaging service is not available.',
	DOWNLOADING_ERROR = 'Downloading error',
	ERROR_DOWNLOADING_PDF = 'Error downloading PDF',
	CONVERSATION_MESSAGE = 'Pick a group from left menu and start your conversation',
	NO_DATA_FOUND = 'No data found',
	ASSIGN_MEMBER = 'Please assign a provider to a member',
	SENT_SUCCESSFULLY = 'Sent successfully',
}

export enum ServerErrorMessages {
	SERVER_DOWN = 'Sorry, it looks like the server is currently down. Please try again later.',
	NETWORK_ERROR = 'Network Error',
	NETWORK_ISSUE = 'ERR_NETWORK',
	INTERNAL_SERVER_ERROR = 'Internal Server Error',
	UNVERIFIED_EMAIL = 'Unverified Email',
	NO_PROVIDER_FOUND = 'Provider not found',
	USER_NOT_FOUND = 'User not found',
}

export enum API_ERROR_STATUS {
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	PAYMENT_REQUIRED = 402,
	NOT_FOUND = 404,
	METHOD_NOT_ALLOWED = 405,
	NOT_ACCEPTABLE = 406,
	PROXY_AUTHENTICATION_REQUIRED = 407,
	REQUEST_TIMEOUT = 408,
	CONFLICT = 409,
	GONE = 410,
}

export enum API_REQUEST_METHODS {
	POST = 'post',
	GET = 'get',
	UPDATE = 'update',
	DELETE = 'delete',
}

export enum API_ERROR_STATUS_MESSAGES {
	BAD_REQUEST = 'You need to log in to access this feature. Please log in and try again.',
	UNAUTHORIZED = 'There was an issue with the request. Please check and try again.',
	PAYMENT_REQUIRED = 'Payment is required to access this resource. Please complete the payment to proceed.',
	NOT_FOUND = 'The requested resource could not be found. Please check the link or try again.',
	METHOD_NOT_ALLOWED = 'This action is not allowed. Please check the request method and try again.',
	NOT_ACCEPTABLE = 'The requested content cannot be provided in the required format. Please try again with different settings.',
	PROXY_AUTHENTICATION_REQUIRED = 'Authentication with the proxy server is needed. Please check your connection and credentials.',
	REQUEST_TIMEOUT = 'The request took too long to complete. Please check your connection and try again.',
	CONFLICT = 'There’s a conflict with the current data. Please refresh the page and try again.',
	GONE = 'The resource you are looking for has been permanently removed. Please check the link or contact support for more information.',
}
