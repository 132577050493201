import { Column } from 'react-table'
import {
	ITemplateFilters,
	ITemplatesList,
	ITemplatesParams,
	IUpdateStatusToggle,
} from './Templates.interface'
import { useUrlQuery } from 'hooks/url'
import { useMyProfile, useSortHandler } from 'hooks/common'
import { ReactComponent as AddIcon } from 'assets/images/ic_add.svg'
import { useEffect, useMemo, useState } from 'react'
import { ReactComponent as EditIcon } from 'assets/images/ic_edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/ic-delete.svg'
import { ReactComponent as CloneIcon } from 'assets/images/template_clone.svg'
import { ENDPOINTS } from 'const/endpoints'
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query'
import { IAxiosResponseWithPagination } from 'types/entities'
import axios, { AxiosResponse, CancelTokenSource } from 'axios'
import { getDropdownTemplates, getTemplates } from './Templates.service'
import { commonMessages } from 'utils/messages'
import { Link } from 'react-router-dom'
import { APP_ROUTES } from 'const/router'
import { AssignedTemplateColumns, NotAssignedTemplateColumns } from './Templates.constants'
import { CategoryTypes, ConditionsTypes, templateTypes } from './Templates.Information'
import { ROLES } from 'const/auth'

export function useTemplatesTableData({
	onDelete,
}: {
	onDelete: (id: string) => void
}): [
	Column<{ [key: string]: React.ReactNode }>[],
	{ [key: string]: React.ReactNode }[],
	(param: string) => void,
	any,
] {
	/**
	 * Hooks Section
	 */
	const [columns, setColumns] = useState(AssignedTemplateColumns)

	/**
	 * Custom hooks Section
	 */
	const profile = useMyProfile()
	const isAdmin = profile.role == ROLES.ADMIN
	const [urlQuery, setUrlParams] = useUrlQuery<ITemplatesParams & ITemplateFilters>()
	const templatesData = useTemplates({
		...urlQuery,
		assigned: isAdmin ? '' : urlQuery?.assigned,
	})
	const onSort = useSortHandler(urlQuery.sort)
	/**
	 * useEffect Section
	 */

	useEffect(() => {
		if (urlQuery.assigned) {
			setColumns(AssignedTemplateColumns)
		} else {
			setColumns(NotAssignedTemplateColumns)
		}
	}, [urlQuery])

	// useEffect(() => {
	// 	ReactTooltip.rebuild()
	// }, [templatesData?.data?.data?.items])

	/**
	 * * Using the useMemo hook to create an array of partner data for the partners table.
	 */

	const assignedTempaltesList = useMemo(() => {
		const handleEditAction = (id: string | null = null) => {
			setUrlParams((old) => {
				return {
					...old,
					isTemplateEdit: id,
				}
			})
		}
		return (
			templatesData?.data?.data?.items?.map((item) => {
				return {}
			}, []) || []
		)
	}, [templatesData?.data?.data?.items])

	const notAssginedtempaltesList = useMemo(() => {
		const handleEditAction = (id: string | null = null) => {
			setUrlParams((old) => {
				return {
					...old,
					isTemplateEdit: id,
				}
			})
		}
		return (
			templatesData?.data?.data?.items?.map((item) => {
				return {}
			}, []) || []
		)
	}, [templatesData?.data?.data?.items])

	return [
		columns,
		urlQuery?.assigned ? assignedTempaltesList : notAssginedtempaltesList,
		onSort,
		templatesData,
	]
}

interface ITemapltesQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<ITemplatesList>>>,
		Partial<ITemplatesParams & ITemplateFilters> {}

/**
 * * The useTemplates function is a React hook that takes in a set of query parameters.
 */
export function useTemplates({
	page,
	limit,
	sort,
	search,
	conditionName,
	categoryName,
	client,
	assigned,
	...rest
}: ITemapltesQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<ITemplatesList>>,
		any,
		IAxiosResponseWithPagination<Partial<ITemplatesList>>
	>(
		[ENDPOINTS.TEMPLATE, page, limit, sort, search, client, conditionName, categoryName, assigned],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getTemplates(
				{
					client,
					condition: conditionName,
					category: categoryName,
					page,
					limit,
					sort,
					search,
					assigned,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}

interface ITemapltesDropdownQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<ITemplatesList>>>,
		Partial<ITemplatesParams> {}

/**
 * * The useDropdownTemplates function is a React hook that takes in a set of query parameters.
 */
export function useDropdownTemplates({
	page,
	limit,
	sort,
	search,
	condition,
	...rest
}: ITemapltesDropdownQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<ITemplatesList>>,
		any,
		IAxiosResponseWithPagination<Partial<ITemplatesList>>
	>(
		[ENDPOINTS.TEMPLATE_ALL, page, limit, sort, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getDropdownTemplates({ page, limit, sort, search, condition }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}

/**
 * useStatusUpdateToggle to active/inActive the status of template
 */
interface IUpdateStatusToggleProps
	extends Partial<UseMutationOptions<AxiosResponse<ITemplatesList>, any, IUpdateStatusToggle>>, //<AxiosResponse<received data from api>, any, payload data send to>
		IUpdateStatusToggle {}
export function useStatusUpdateToggle({ active, ...rest }: IUpdateStatusToggleProps = {}) {
	//@ts-ignore
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()
	return useMutation<AxiosResponse<ITemplatesList>, any, any>(
		async (id: string) => await statusToggle(id, { active }, source),
		{
			...rest,
		},
	)
}

/**
 * * PATCH request for partner's status change
 */
export function statusToggle(
	id: string | undefined,
	data: Partial<ITemplatesParams>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch(ENDPOINTS.TEMPLATE + '/' + id, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// //* Create template clone hook
// type ICreateTemplateProps = Partial<
// 	UseMutationOptions<
// 		AxiosResponse<{
// 			id: string
// 		}>,
// 		any,
// 		Partial<{
// 			id: string
// 		}>
// 	>
// >

// export function useCreateTemplate({ ...rest }: ICreateTemplateProps = {}) {
// 	return useMutation<
// 		AxiosResponse<{
// 			id: string
// 		}>,
// 		any,
// 		Partial<{
// 			id: string
// 		}>
// 	>(() => {}, {
// 		...rest,
// 	})
// }
