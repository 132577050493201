import React, { ReactNode } from 'react'

import Input, { InputProps } from '../Input'
import classnames from 'classnames'
import './styles.css'
import { Label } from 'components/Label'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import { IS_PHONE_OPTIONS } from 'hooks/common'
import { FormikAdvanceSelect } from 'components/FormikAdvancedSelect'

export interface IProps extends InputProps {
	name?: string
	label?: string | ReactNode
	error?: any
	require?: boolean | string
	required?: boolean
	children?: React.ReactElement
	classNames?: {
		label?: string
		input?: string
	}
	phone?: any
	weight?: boolean
	pattren?: any
	autoComplete?: string
	countryCodeName?: string
	isCountryCodeDisabled?: boolean
	isCountryCodeShow?: boolean
	onPaste?: any
	readOnly?: boolean
}

export function InputField({
	classNames,
	isCountryCodeShow = false,
	isCountryCodeDisabled = true,
	countryCodeName = 'countryCode',
	onPaste = () => {
		//
	},
	...rest
}: IProps) {
	const { label, className, error, children, require = '', required } = rest

	const containerClasses = classnames(
		'relative',
		className,
		error?.length && 'text__wrapper__error',
	)
	const labelClasses = classnames('', { disabled: rest.disabled }, classNames?.label)

	const componentClasses = classnames(
		error ? 'text__error_input' : 'text__input',
		// rest?.phone ? '!pl-[35px]' : '',
		{ disabled: rest.disabled },
		classNames?.input,
		rest.disabled ? 'bg-[#ccc] cursor-not-allowed' : '',
	)
	// Clear value attr because it resides in memory
	if (rest.hasOwnProperty('value')) rest.value = rest?.value || ''
	// if (rest.hasOwnProperty('defaultValue')) rest.defaultValue = rest?.defaultValue || ''
	//
	// hansle trimStart() to the value prop if the text is paste and it is a string
	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		event.preventDefault()
		const clipboardData = event.clipboardData || window.Clipboard
		const pastedText = clipboardData.getData('text')
		const trimmedText = pastedText.trimStart()
		document.execCommand('insertText', false, trimmedText)
		if (onPaste) {
			onPaste(event)
		}
	}
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target
		const trimmedValue = value.trimStart()
		if (value !== trimmedValue) {
			event.target.value = trimmedValue
		}
		// Call the onChange handler if provided
		if (rest.onChange) {
			rest.onChange(event)
		}
	}

	// handle entering space as the first character
	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === ' ' && (event.target as HTMLInputElement).value === '') {
			event.preventDefault() // Prevent entering space as the first character
		}
	}

	return (
		<div className={`${containerClasses} select-country-code pb-2`}>
			<div className={isCountryCodeShow ? 'relative' : ''}>
				{label && (
					<Label name={label + ''} className={labelClasses} required={require || required} />
				)}

				<div className="relative w-full">
					{isCountryCodeShow && (
						<div className="absolute top-0 left-0 z-20">
							<FormikAdvanceSelect
								className="!h-[36px]"
								name={countryCodeName}
								id="Country Code"
								isDisabled={isCountryCodeDisabled}
								options={[]}
								defaultValue={{
									label: '+1',
									value: '+1',
								}}
							/>
						</div>
					)}
					{!children && (
						<Input
							{...rest}
							className={componentClasses}
							onPaste={handlePaste}
							onKeyPress={handleKeyPress}
							onKeyDown={handleChange}
							onChange={handleChange}
						/>
					)}
				</div>

				{error && <ErrorMessage>{error}</ErrorMessage>}
			</div>
		</div>
	)
}
// absolute top-[12px] right-[13px] text-[14px] text-fountainBlue z-99999 font-bold
