import { ENDPOINTS } from 'const/endpoints'
import {
	ITemplateFilters,
	ITemplatesDropdownList,
	ITemplatesList,
	ITemplatesParams,
} from './Templates.interface'
import axios from 'services/axios'
import { CancelTokenSource } from 'axios'
import _ from 'lodash'
import { IPagination } from 'types/entities'
import { commonMessages } from 'utils/messages'
import { onCreateEndpointsWithClientid } from 'utils/common'

/**
 * * GET TEMPLATES (view templates list)
 */
export function getTemplates(
	params: Partial<ITemplatesParams & ITemplateFilters>,
	source?: CancelTokenSource,
) {
	const templateEndpointWithClientId = onCreateEndpointsWithClientid(ENDPOINTS?.TEMPLATE)

	const filteredParams = _.pickBy(params, _.identity)
	// const promise = axios.get<IPagination<Partial<ITemplatesList>>>(ENDPOINTS.TEMPLATE, {
	const promise = axios.get<IPagination<Partial<ITemplatesList>>>(templateEndpointWithClientId, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * GET DROPDOWN TEMPLATES (view dropdown templates list)
 */
export function getDropdownTemplates(
	params: Partial<ITemplatesParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	// const promise = axios.get<IPagination<Partial<ITemplatesDropdownList>>>(ENDPOINTS.TEMPLATE_ALL, {
	const promise = axios.get<IPagination<Partial<ITemplatesDropdownList>>>(
		onCreateEndpointsWithClientid(ENDPOINTS.TEMPLATE_ALL),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
