import classNames from 'classnames'
import './styles.css'
import { ACTIVITY_ACTION_NAME } from 'const/common'
import { useLogger } from 'components/PageLogger/Logger.Utility'

interface IProps {
	type?: string | 'primary'
	onClick?: any
	buttonType?: 'submit' | 'button'
	className?: string
	children: React.ReactNode
	disabled?: boolean | any
	ref?: any
}

export default function Button({
	className,
	buttonType,
	onClick,
	// disabled,
	type = 'default',
	...rest
}: IProps) {
	const { onCallLoggerFunction } = useLogger()

	const onButtonClick = (onClickfunc: any) => {
		const childName = rest?.children
		const buttonDisplayName = typeof childName === 'string' ? childName : ''
		onCallLoggerFunction({
			actionName: ACTIVITY_ACTION_NAME?.BUTTON_CLICK,
			buttonName: buttonDisplayName,
		})
		onClickfunc && onClickfunc?.()
	}
	return (
		<button
			type={buttonType}
			className={classNames(
				`button justify-center items-center hover:opacity-60 pb-4 capitalize button__${type}`,
				className,
				{
					'!bg-gray-300 !border-none !text-white': !!rest.disabled,
				},
			)}
			// disabled={disabled}
			// onClick={onClick}
			onClick={() => onButtonClick(onClick)}
			{...rest}
		/>
	)
}
