import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ReactComponent as EditIcon } from 'assets/images/ic_edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/ic-delete.svg'
import { ReactComponent as ViewIcon } from 'assets/images/Eye.svg'
import { useUrlQuery } from 'hooks/url'
import {
	getClientDetails,
	useCheckScreenPermission,
	useMyProfile,
	usePermission,
	useSectionPermission,
	useSortHandler,
} from 'hooks/common'
import { APP_ROUTES } from 'const/router'
import { ROLES } from 'const/auth'
import { ENDPOINTS } from 'const/endpoints'
import { IAxiosResponseWithPagination } from 'types/entities'
import { formatDate } from 'utils/time'
import { getAllAssigneeList, getAllConditions, getPrograms } from './Program.service'
import {
	IFullHealthConditions,
	IFullProgram,
	IHealthConditionsParams,
	IProgramParams,
} from './Program.interface'
import { programColumns } from './Programs.constants'
import { COLUMN_NAME, SCREEN_NAME } from 'const/screen'
import { MODULE_NAME } from 'const/module'
import { DROPDOWN_LIMIT } from 'const/common'

export function useProgramsTableData({
	onDelete,
}: {
	onDelete: (id: string) => void
}): [
	Column<{ [key: string]: React.ReactNode }>[],
	{ [key: string]: React.ReactNode }[],
	(param: string) => void,
	any,
] {
	/**
	 * *Variable and hooks Section
	 */
	const profile = useMyProfile()
	const isAccess =
		profile?.role === ROLES.SUPER_ADMIN ||
		profile?.role === ROLES.ADMIN ||
		profile?.role === ROLES.PROGRAM_MANAGER ||
		profile?.role === ROLES.CHCT

	const [params, setParams] = useUrlQuery<IProgramParams>()

	const programs = usePrograms({ ...params, id: getClientDetails()?.clientId })
	const onSort = useSortHandler(params.sort)

	const programProfileProgramMembersSection = useCheckScreenPermission(
		SCREEN_NAME?.PROGRAM_PROFILE_PROGRAM_MEMBERS,
	)
	const programsPermissions = usePermission(MODULE_NAME.PROGRAMS)
	// const programsSection = useSectionPermission(SCREEN_NAME.PROGRAMS)
	// console.log('programsPermissions', programsPermissions)
	// console.log('programsSection', programsSection)

	const filterProgramsColumns = programColumns?.filter((columnObj: any) => {
		return !(
			columnObj?.name === COLUMN_NAME?.ACTION &&
			!programsPermissions?.edit &&
			!programsPermissions?.delete &&
			!programProfileProgramMembersSection
		)
	})

	/**
	 * *useMemo Section
	 */
	const programsData = useMemo(() => {
		return (
			programs?.data?.data.items.map((item: any) => {
				const startdate = formatDate(+item?.programCriteria?.startDate)
				const endDate = formatDate(+item?.programCriteria?.endDate)
				let assigneeId = ''
				const isAssignee = item?.programCriteria?.assignee?.map((items: any, index: any) => {
					assigneeId = items?.partner?.id
					return (
						items?.partner?.companyName?.charAt(0).toUpperCase() +
						items?.partner?.companyName?.slice(1)
					)
				})

				const isConditions = item?.programApplicableFor?.conditions?.map(
					(items: any, index: any, arr: any) => {
						return index === arr.length - 1
							? items?.condition?.condition
							: `${items?.condition?.condition}, `
					},
				)

				return {
					col1: (
						<div className="">
							{programProfileProgramMembersSection ? (
								<Link
									to={`${APP_ROUTES.PROGRAM_PROFILE}/${item.id}/members`}
									className="text-fountainBlue"
								>
									{item?.programCriteria?.programTitle?.charAt(0).toUpperCase() +
										item?.programCriteria?.programTitle?.slice(1)}
								</Link>
							) : (
								'--'
							)}
						</div>
					),
					col2: startdate || '--',
					col3: endDate || '--',

					col4: <span className="capitalize">{item?.programCriteria?.assigneeType || '--'}</span>,
					col5: (
						<div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
							{isAssignee && isAssignee.length > 0 ? (
								<span>
									{isAssignee.map((assigneeName: any, index: any) => {
										const currentAssigneeId = item?.programCriteria?.assignee[index]?.partner?.id
										return (
											<span key={index}>
												{isAccess ? (
													<Link
														to={`${APP_ROUTES.PARTNER_PROFILE}/${currentAssigneeId}/locations`}
														className="text-fountainBlue"
														data-tip={assigneeName}
													>
														{assigneeName}
													</Link>
												) : (
													<span data-tip={assigneeName}>{assigneeName}</span>
												)}
												{index < isAssignee.length - 1 && ', '}
											</span>
										)
									})}
								</span>
							) : (
								'--'
							)}
						</div>
					),

					col6: item?.client?.title || '--',

					col7:
						item?.programApplicableFor?.gender?.charAt(0).toUpperCase() +
							item?.programApplicableFor?.gender?.slice(1) || '--',
					col8:
						(
							<div className="max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
								<span data-tip={isConditions}>{isConditions}</span>
							</div>
						) || '--',

					col9: (
						<div className="flex items-center gap-2">
							<Link
								to={`${APP_ROUTES.PROGRAM_PROFILE}/${item.id}/members`}
								className="text-blue-500"
								data-tip="Open to view"
							>
								{/* <span data-tip="Open to view"> */}
								<ViewIcon className="hover:stroke-fountainBlue  stroke-solidGray " />
								{/* </span> */}
							</Link>
							{isAccess && (
								<span
									data-tip="Open to edit"
									onClick={() => {
										setParams((old) => ({
											...old,
											isEdit: item.id,
										}))
									}}
								>
									<EditIcon
										className={` stroke-solidGray fill-solidGray  cursor-pointer ${'hover:stroke-fountainBlue hover:fill-fountainBlue'}`}
									/>
								</span>
							)}
							<span data-tip="Delete">
								{isAccess && (
									<DeleteIcon
										onClick={() => {
											item.id && onDelete(item.id)
										}}
										className={` stroke-solidGray fill-solidGray  cursor-pointer ${'hover:stroke-fountainBlue hover:fill-fountainBlue'}`}
									/>
								)}
							</span>
						</div>
					),
					status: programs.status,
				}
			}, []) || []
		)
		// eslint-disable-next-line
	}, [programs?.data?.data.items, onDelete])

	return [filterProgramsColumns, programsData, onSort, programs]
}
/**
 * * Using the React Hooks useQuery to  a query function are used to filter the results and ensure that only relevant programs are returned.
 */
interface IProgramsQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullProgram>>>,
		Partial<IProgramParams> {}
//
export function usePrograms({
	id,
	page,
	limit,
	sort,
	programName,
	startDate,
	endDate,
	type,
	assignee,
	gender,
	condition,
	search,
	status,
	title,
	client,
	...rest
}: IProgramsQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullProgram>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullProgram>>
	>(
		[
			ENDPOINTS.PROGRAMS,
			client,
			page,
			limit,
			sort,
			title,
			search,
			programName,
			startDate,
			endDate,
			assignee,
			gender,
			condition,
			status,
			id,
		],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getPrograms(
				id,
				{
					client,
					page,
					limit,
					sort,
					title,
					startDate,
					programName,
					endDate,
					type,
					assignee,
					gender,
					condition,
					status,
					search,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			...rest,
		},
	)
}
/**
 * * Using the React Hooks useQuery to  a query function are used to using a function to return the conditions from the source.
 */
interface IHealthConditionsQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullHealthConditions>>>,
		Partial<IHealthConditionsParams> {}

export function useHealthConditions({
	page = 1,
	limit = DROPDOWN_LIMIT.LIMIT,
	search,
	sort,
	clientId,
	...rest
}: IHealthConditionsQueryProps) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullHealthConditions>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullHealthConditions>>
	>(
		[ENDPOINTS.PROGRAM_CONDITIONS, sort, search, page, limit, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getAllConditions(
				clientId,
				{
					limit,
					page,
					search,
					sort,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			...rest,
		},
	)
}

// useAssignee List
/**
 * * Using the React Hooks useQuery to  a query function are  using these parameters to make an API call and retrieve a list of all assignees from the source.
 */
interface IProgramAssigneeListQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullProgram>>>,
		Partial<IProgramParams> {}
export function useAssigneeList({
	id,
	page,
	limit,
	search,
	status,
	client,
	type,
	...rest
}: IProgramAssigneeListQueryProps) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullProgram>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullProgram>>
	>(
		[ENDPOINTS.ASSIGNEE_LISTING, page, limit, status, search, client, type],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getAllAssigneeList(id, { page, limit, status, search, client, type }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			...rest,
		},
	)
}
