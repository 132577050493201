import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { IAxiosResponseWithPagination } from 'types/entities'
import {
	createAssignModule,
	getServiceSpecificModules,
	updateAssignModule,
} from './AssignModules.service'
import { IAssignModulesParams } from './AssignModules.interface'

/**
 * * The ServiceSpecificModules function is a React hook that takes in a set of query parameters.
 */
interface IAssignedModulesQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IAssignModulesParams>>>,
		Partial<IAssignModulesParams> {}
export function useServiceSpecificModules({ serviceId, ...rest }: IAssignedModulesQueryProps = {}) {
	return useQuery<any>(
		[ENDPOINTS.CLIENT_MODULES_ACCORDING_TO_SERVICE, serviceId],
		({ signal }) => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getServiceSpecificModules(
				{
					serviceId,
				},
				source,
				signal,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			enabled: !!serviceId,
		},
	)
}

/**
 * * Using the React Hooks useMutation to create a mutation function for creating a assign module.
 */
type ICreateAssignModuleProps = Partial<UseMutationOptions<AxiosResponse<any>, any, Partial<any>>>

export function useCreateAssignModule({ ...rest }: ICreateAssignModuleProps = {}) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(createAssignModule, {
		...rest,
	})
}

/**
 * * Using the React Hooks useMutation to update a mutation function for updating a assigning module.
 */
type IUpdateAssignModuleProps = Partial<UseMutationOptions<AxiosResponse<any>, any, Partial<any>>>

export function useUpdateAssignModule({ ...rest }: IUpdateAssignModuleProps = {}) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(updateAssignModule, {
		...rest,
	})
}
