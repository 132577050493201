import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'

interface IActivityLogPayLoad {
	isEmail: boolean
	device: string
	source: string
}

interface IUserActivityLogger {
	action: any
	error: any
	screen: string
	domainName: string
	clientName: string
	tokenUserId: string
}

class ActivityLogger {
	logs: Array<IActivityLogPayLoad>

	constructor() {
		this.logs = []
	}

	async log(details: IUserActivityLogger): Promise<void> {
		const logEntry: IActivityLogPayLoad = {
			...details,
			isEmail: true,
			device: 'webApp',
			source: 'web/frontend',
		}
		this.logs.push(logEntry)
		await this.sendToServer(logEntry)
	}

	async sendToServer(logEntry: IActivityLogPayLoad): Promise<void> {
		try {
			const response = await axios.post<any>(ENDPOINTS.ACTIVITY_LOG, logEntry)
			// console.log('Log entry successfully sent:', response.data)
		} catch (error) {
			console.error('Failed to send activity log entry:', error)
		}
	}
}

const activityLogger = new ActivityLogger()
export default activityLogger
