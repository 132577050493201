import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import axios from 'services/axios'
import { commonMessages } from 'utils/messages'
import { ModulesInitialState, ModulesPayload } from './Module.types'
import { IGlobalServices, IGlobalServicesParams } from './Module.interface'
import _ from 'lodash'
import { IPagination } from 'types/entities'

export function getModuleToEdit(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<ModulesInitialState>(ENDPOINTS.GET_MODULE_BY_ID + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

//* Create Speciality function
export function createModule(data: Partial<ModulesPayload>, source?: CancelTokenSource) {
	const promise = axios.post<ModulesPayload>(ENDPOINTS.CREATE_UPDATE_MODULES, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

//* Update Speciality function
export function updateModule(
	id: string | undefined,
	data: Partial<ModulesPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch<ModulesPayload>(ENDPOINTS.CREATE_UPDATE_MODULES + '/' + id, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
//Get All Services
export function getAllServices(params: Partial<IGlobalServicesParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IGlobalServices>>>(ENDPOINTS.SERVICES, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
