import { ReactNode } from 'react'
import classnames from 'classnames'
import { SCREEN_WRAPPER_CONTENT } from 'const/common'
import { ReactComponent as LeftArrowIcon } from 'assets/images/ic-left-arrow.svg'
import { useNavigate } from 'react-router-dom'

interface Props {
	children?: ReactNode
	title?: string
	className?: string
	color?: string
	classNames?: { content?: string; title?: string }
	addBackButton?: boolean
	route?: any
	isBorder?: boolean
}

export default function ScreenWrapper({
	children,
	title,
	className,
	classNames = {
		// content: SCREEN_WRAPPER_CONTENT.CONTENT_CLASSNAME,
		title: SCREEN_WRAPPER_CONTENT.CONTENT_TITLE,
	},
	addBackButton,
	route,
}: Props) {
	const navigate = useNavigate()
	return (
		<div
			className={classnames(
				'flex rounded-[5px] relative custom-shadow border border-lightGray w-full bg-white mb-4',
				className,
			)}
		>
			{/* <div className={classnames('hidden md:block', classNames?.content)} /> */}
			<div className="flex flex-col flex-1 p-0 w-full">
				{title && (
					<div
						className={classnames(
							'w-full flex items-center h-[55px] border-b border-solid ',
							classNames?.title,
						)}
					>
						{addBackButton && (
							<div
								className={classnames(
									'rounded-full flex items-center justify-center hover:bg-whiteSmoke cursor-pointer h-[30px] w-[30px]',
									classNames,
								)}
								onClick={() => navigate(route)}
							>
								<LeftArrowIcon className=" cursor-pointer opacity-50 h-[32px]" />
							</div>
						)}
						<span className="self-center opacity-60 pl-[15px] font-lato font-semibold text-[15px] text-nightRider">
							{title.toUpperCase()}
						</span>
					</div>
				)}

				<div className="w-full mt-2 lg:mt-4 px-4 sm:px-2 pb-2">{children}</div>
			</div>
		</div>
	)
}
