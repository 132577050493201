import classnames from 'classnames'

interface IProps extends React.HTMLAttributes<HTMLInputElement> {
	checked?: boolean
	name?: string
	className?: string
	disabled?: boolean
	readOnly?: boolean
	isAssigned?: boolean
}

export function CustomCheckbox({
	name,
	checked,
	onChange,
	className,
	onClick,
	disabled = false,
	isAssigned = false,
}: IProps) {
	return (
		<input
			checked={checked}
			onChange={onChange}
			readOnly
			style={{
				appearance: 'none',
				width: '16px',
				height: '16px',
				fontSize: '18px',
			}}
			name={name}
			className={classnames(
				'checked:before:block checked:border-fountainBlue before:absolute checked:before:content-checked  before:w-[12px] before:h-[12px] before:bottom-[65%] before:left-[15%] checked:bg-fountainBlue  rounded-sm relative',
				'border border-fountainBlue bg-white border-solid relative rounded-[4px]flex justify-center items-center flex-col box-content flex-shrink-0 ',
				disabled ? 'bg-gray-200 cursor-not-allowed' : 'cursor-pointer',
				isAssigned ? 'bg-gray-100 border-solid' : 'bg-white border-solid',
				className,
			)}
			onClick={onClick}
			type="checkbox"
			disabled={disabled}
		/>
	)
}
