import { ISelectOption } from 'types/forms'
import { IScreeningsList } from '../Screenings.interface'
import { IGlobalCodeSelect } from 'modules/Templates/GlobalCodes/GlobalCodes.interface'
import { SCORING_OPTIONS_VALUES } from '../Screenings.constant'

export class ScreeningInitialState {
	title: string | null = null
	description: string | null = null
	scoring: string | null = SCORING_OPTIONS_VALUES.SCORING
	answeredBy: ISelectOption | null | any = null
	order: number | null = null
	// isPartiallyFilled: boolean | null = false
	questionTemplate: string | null = null
	screeningCode: string | null = null
	screening: ISelectOption | null = null
	isExisting: string | null = 'new'
	isExistingDataFetching: boolean | null = false
	isDataFetching: boolean | null = false
	constructor({
		title,
		description,
		order,
		answeredBy,
		scoring,
		// isPartiallyFilled,
		screeningCode,
		isExistingDataFetching,
		isDataFetching,
		isExisting,
		screening,
	}: Partial<
		IScreeningsList & {
			isExistingDataFetching?: boolean
			isDataFetching?: boolean
		}
	> = {}) {
		this.addTitle(title)
		this.addDescription(description)
		this.addAnsweredBy(answeredBy)
		this.addOrder(order)
		// this.addIsPartiallyFilled(isPartiallyFilled)
		this.addScoring(scoring)
		this.addSessionCode(screeningCode)
		this.addIsExistingDataFetching(isExistingDataFetching)
		this.addIsDataFetching(isDataFetching)
		this.addIsExisting(isExisting)
		this.addScreening(screening)
	}

	addScreening(screening: ISelectOption | undefined) {
		if (screening) {
			this.screening = screening
		}
	}

	addIsExisting(isExisting: string | null = null) {
		if (isExisting) {
			this.isExisting = isExisting
		}
	}

	addIsExistingDataFetching(isExistingDataFetching: boolean | null = false) {
		this.isExistingDataFetching = isExistingDataFetching
	}
	addIsDataFetching(isDataFetching: boolean | null = false) {
		this.isDataFetching = isDataFetching
	}
	addDescription(description: string | null = null) {
		if (description) {
			this.description = description
		}
	}

	// addIsPartiallyFilled(isFilled: boolean | null = false) {
	// 	this.isPartiallyFilled = isFilled
	// }

	addOrder(order: number | null = null) {
		if (order) {
			this.order = order
		}
	}
	addAnsweredBy(data: IGlobalCodeSelect | null | any = null) {
		if (data) {
			this.answeredBy = {
				label: data.globalCodesName,
				value: data.slug,
				id: data?.id,
			}
		}
	}

	addScoring(scoring: boolean | null = false) {
		if (scoring) {
			this.scoring = SCORING_OPTIONS_VALUES.SCORING
		} else {
			this.scoring = SCORING_OPTIONS_VALUES.NOT_SCORING
		}
	}

	addTitle(data: string | null = null) {
		if (data) {
			this.title = data
		}
	}
	addSessionCode(code: string | null = null) {
		this.screeningCode = code
	}
}

export class ScreeningPayload {
	title: string | null = null
	description: string | null = null
	scoring: boolean | null = false
	answeredBy: string | null = null
	order: number | null = null
	// isPartiallyFilled: boolean | null = null
	screeningCode: string | null = null
	questionTemplate: string | null = null
	screening: string | null | undefined = null
	constructor({
		title,
		answeredBy,
		scoring,
		description,
		order,
		// isPartiallyFilled,
		questionTemplate,
		screeningCode,
		screening,
	}: Partial<ScreeningInitialState> = {}) {
		this.addTitle(title)
		this.addDescription(description)
		// this.addIsPartiallyFilled(isPartiallyFilled)
		this.addOrder(order)
		this.addAnsweredBy(answeredBy)
		this.addScoring(scoring)
		this.addTemplate(questionTemplate)
		this.addSessionCode(screeningCode)
		this.addScreening(screening)
	}

	addScreening(screening: ISelectOption | null = null) {
		if (screening) {
			this.screening = screening?.value
		} else {
			delete this.screening
		}
	}

	addTitle(title: string | null = null) {
		if (title) {
			this.title = title
		}
	}

	addDescription(description: string | null = null) {
		if (description) {
			this.description = description
		}
	}

	// addIsPartiallyFilled(isFilled: boolean | null = null) {
	// 	this.isPartiallyFilled = isFilled
	// }

	addOrder(data: number | null = null) {
		if (data) {
			this.order = data
		}
	}
	addAnsweredBy(data: ISelectOption | null | any = null) {
		if (data) {
			this.answeredBy = data.id
		}
	}
	addScoring(scoring: string | null = null) {
		if (scoring === SCORING_OPTIONS_VALUES.SCORING) {
			this.scoring = true
		} else {
			this.scoring = false
		}
	}
	addTemplate(template: string | null = null) {
		if (template) {
			this.questionTemplate = template
		}
	}
	addSessionCode(code: string | null = null) {
		this.screeningCode = code
	}
}
