import { useUrlQuery } from 'hooks/url'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { ModulesInitialState, ModulesPayload } from './Module.types'
import { ENDPOINTS } from 'const/endpoints'
import {
	UseMutationOptions,
	UseQueryOptions,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import axiosLibrary from 'axios'
import { IModulesParams } from '../Modules.interface'
import { createModule, getAllServices, getModuleToEdit, updateModule } from './Module.service'
import { useNavigate, useParams } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { errorNotify, successNotify } from 'utils/toster'
import { commonMessages } from 'utils/messages'
import { scrollToError } from 'utils/common'
import { IGetModuleByIdParams, IGlobalServices, IGlobalServicesParams } from './Module.interface'
import { IAxiosResponseWithPagination } from 'types/entities'

export function useInitial() {
	/**
	 * *Hooks and variable Section
	 */
	const alreadyMounted = useRef(false)
	const [urlParams] = useUrlQuery<IModulesParams>()
	const isEdit = urlParams?.isModuleEdit
	const { data, isFetched, isFetching } = useModuleToEdit({
		id: isEdit,
		suspense: !alreadyMounted.current, //TO USE SUSPENSE ONCE ON MOUNT
	})
	/**
	 * *useEffects Section
	 * @params useEffectName
	 */
	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetched, isFetching])
	/**
	 * *useMemo Section
	 * @params useEffectName
	 */
	return useMemo(() => {
		return new ModulesInitialState(isEdit ? data?.data : {})
	}, [data?.data, isEdit, isFetching])
}

export function useSubmitHandler() {
	const [urlParams, setUrlParams] = useUrlQuery<IModulesParams>()
	const id = urlParams?.isModuleEdit
	const { mutateAsync: create } = useCreateModule()
	const { mutateAsync: update } = useUpdateModule({
		id: id,
	})

	const navigate = useNavigate()
	const client = useQueryClient()
	return useCallback(
		async (form: ModulesInitialState, formikHelpers: FormikHelpers<ModulesInitialState>) => {
			formikHelpers.setSubmitting(true)
			try {
				if (!id) {
					await create(new ModulesPayload(form))
				} else {
					await update(new ModulesPayload(form))
				}
				successNotify(id ? commonMessages.updateMessage : commonMessages.createMessage)

				formikHelpers.resetForm()
				formikHelpers.setSubmitting(false)
				setUrlParams((old) => {
					return {
						...old,
						isModuleEdit: undefined,
						isModuleAdd: undefined,
					}
				})
				client.invalidateQueries([ENDPOINTS.MODULES])
			} catch (err: any) {
				const errorMsg = err?.response?.data?.message
				if (err?.response?.data && err?.response?.data?.message) {
					errorNotify(errorMsg)
				} else {
					formikHelpers.setErrors(err?.response?.data?.address) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
					errorNotify(commonMessages.errorHandlingMessage)
				}
				scrollToError()
			}
		},
		[client, create, navigate, update, id],
	)
}

//* Create Speciality hook
type ICreatePartnertProps = Partial<
	UseMutationOptions<AxiosResponse<ModulesPayload>, any, Partial<ModulesPayload>>
>

export function useCreateModule({ ...rest }: ICreatePartnertProps = {}) {
	return useMutation<AxiosResponse<ModulesPayload>, any, Partial<ModulesPayload>>(createModule, {
		...rest,
	})
}

//* Update Speciality hook
interface IUpdateProfileProps
	extends Partial<UseMutationOptions<AxiosResponse<ModulesPayload>, any, Partial<ModulesPayload>>> {
	id?: string
}

export function useUpdateModule({ id, ...rest }: IUpdateProfileProps) {
	return useMutation<AxiosResponse<ModulesPayload>, any, Partial<ModulesPayload>>(
		async (data) => await updateModule(id, data),
		{
			...rest,
		},
	)
}

interface IGetModuleByIdQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<ModulesInitialState>>>,
		Partial<IGetModuleByIdParams> {}
function useModuleToEdit({ id, ...rest }: IGetModuleByIdQueryProps) {
	return useQuery<AxiosResponse<ModulesInitialState>, any, AxiosResponse<ModulesInitialState>>(
		[ENDPOINTS.GET_MODULE_BY_ID],
		() => {
			const CancelToken = axiosLibrary.CancelToken
			const source = CancelToken.source()
			return getModuleToEdit(id, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}
interface IGlobalServicesQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IGlobalServices>>>,
		Partial<IGlobalServicesParams> {}

export function useGlobalServices({ search, ...rest }: IGlobalServicesQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IGlobalServices>>,
		any,
		IAxiosResponseWithPagination<Partial<IGlobalServices>>
	>(
		[ENDPOINTS.SERVICES, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getAllServices(
				{
					search,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			...rest,
		},
	)
}
