import { ENDPOINTS } from 'const/endpoints'
import { commonMessages } from 'utils/messages'
import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import { IPagination } from 'types/entities'
import _ from 'lodash'
import { IFullRoleBase, IRoleBaseParams } from './Role.interface'
import { RoleBasePayload } from './Role.types'

/**
 * * GET request for roles (Roles Listing)
 */
export function getRolesAll(
	client: string | undefined,
	params?: Partial<IRoleBaseParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullRoleBase>>>(
		ENDPOINTS.ROLES_ALL + '/' + client,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getParentRole(
	client: string | undefined,
	params?: Partial<IRoleBaseParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullRoleBase>>>(
		ENDPOINTS.PARENT_ROLE + '/' + client,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getRoleForId(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<IFullRoleBase>(ENDPOINTS.ROLES + '/' + clientId + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function createRole(
	id: string | any,
	clientId: string | undefined,
	data: Partial<RoleBasePayload>,
	source?: CancelTokenSource,
) {
	if (!data?.parentRole) {
		delete data?.parentRole
	}
	const promise = axios.post<RoleBasePayload>(ENDPOINTS.ROLES + '/' + clientId, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function updateRole(
	id: string | undefined,
	roleId: string | undefined,
	clientId: string | undefined,
	data: Partial<RoleBasePayload>,
	source?: CancelTokenSource,
) {
	if (!data?.parentRole) {
		delete data?.parentRole
	}
	const promise = axios.patch<RoleBasePayload>(
		ENDPOINTS.ROLES + '/' + clientId + '/' + roleId,
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * PATCH request for global code's status change
 */
export function statusToggle(
	id: string | undefined,
	data: Partial<any>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch(ENDPOINTS.ROLES + '/' + id, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

//Permissions API

// export function getClientSpecificDetails(params: Partial<any>, source?: CancelTokenSource) {
export function getProviderDetailsFromRole(
	clientId: string | undefined,
	params: any,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<any>(ENDPOINTS.GET_PROVIDER_DETAILS_FROM_ROLE + '/' + clientId, {
		params: filteredParams,
	})

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel('Query was cancelled by React Query')
	}
	promise.then(() => {
		//
	})
	return promise
}

/**
 * Create Assign Services
 */
export function createAssignServicesToProvider(
	clientId: string | undefined,
	data: Partial<any>,
	source?: CancelTokenSource,
) {
	const promise = axios.post<any>(ENDPOINTS.ASSIGN_SERVICE_TO_ROLE + '/' + clientId, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function assignALLModulesToProvider(
	clientId: string | undefined,
	data: any,
	source?: CancelTokenSource,
) {
	const promise = axios.post(
		ENDPOINTS.ALL_SELECT_UNSELECT_PERMISSION_TO_PROVIDER + '/' + clientId,
		{ ...data },
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// assignModules
export function modulesSpecificAttributes(
	clientId: string | undefined,
	data: any,
	source?: CancelTokenSource,
) {
	const promise = axios.post(ENDPOINTS.MODULES_ATTRIBUTES, { ...data })
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
export function getModuleForRole(
	clientId: string | undefined,
	params: any,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get(ENDPOINTS.GET_MODULE_FOR_ROLE + '/' + clientId, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
// addUpdateProviderAccess
export function updateProviderAccess(data: any, source?: CancelTokenSource) {
	const promise = axios.patch<RoleBasePayload>(ENDPOINTS.PROVIDER_ACCESS, { ...data })
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function updateALLProviderAccess(data: any, source?: CancelTokenSource) {
	const promise = axios.post(ENDPOINTS.ASSIGN_NODES_TO_PROVIDER, { ...data })
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
