import { ISelectOption } from 'types/forms'
import { SCORING_OPTIONS_VALUES } from '../Screenings/Screenings.constant'
import { IGlobalCodeSelect } from 'modules/Templates/GlobalCodes/GlobalCodes.interface'
import { IQuestionnaireList } from '../Questionnaires.interface'
import { ACTION_LABELS } from '../Screenings/QuestionOptions/QuestionOptions.constants'
import { SelectOption } from 'types/entities'
import { QUESTION_TYPES_VALUES } from '../Questionnaires.constant'

export class QuestionnaireInitialState {
	title: string | null = null
	type: ISelectOption | null | any = null
	isScore: string | null = SCORING_OPTIONS_VALUES.SCORING
	action: ISelectOption | null | any = null
	dataType: ISelectOption | null | any = null
	score: string | null | undefined = null
	isRequired: boolean | null = false
	order: number | null = null
	templateScreening: string | null = null
	optionType: ISelectOption | null | any = null
	reference: ISelectOption | null = null
	parentQuestion: string | undefined
	option: string | undefined
	isRoot: boolean | undefined
	constructor({
		question,
		isScore,
		score,
		order,
		parentQuestion,
		optionreference,
		isRoot,
		...data
	}: Partial<IQuestionnaireList> = {}) {
		this.addIsRoot(isRoot)
		this.addTitle(question?.title)
		this.addType(question?.type)
		this.addDataType(question?.dataType)
		this.addActionType(question?.action)
		this.addScore(score)
		this.addIsRequired(data?.isRequired)
		this.addOrder(order)
		this.addIsScore(isScore)
		this.addOptionType(question?.optionType)
		this.addParentQuestion(parentQuestion)
		this.addOption(optionreference)
		this.addReference(question?.reference, question?.action)
	}

	addReference(
		reference: Partial<IQuestionnaireList> | undefined,
		action: IGlobalCodeSelect | undefined,
	) {
		if (action?.globalCodesName === ACTION_LABELS.GO_TO_EXISTING_QUESTIONS) {
			this.reference = new SelectOption(reference?.id || '', reference?.question?.title)
		}
	}

	addIsRoot(isRoot: boolean | undefined) {
		if (isRoot) {
			this.isRoot = isRoot
		} else {
			delete this.isRoot
		}
	}

	addOption(optionreference: any) {
		if (optionreference?.length) {
			optionreference?.map((item: any) => {
				return (this.option = item?.id)
			})
		}
	}
	addParentQuestion(
		parentQuestion: {
			id: string
		} | null = null,
	) {
		if (parentQuestion) {
			this.parentQuestion = parentQuestion?.id
		}
	}

	addOptionType(optionType: IGlobalCodeSelect | null = null) {
		if (optionType) {
			this.optionType = {
				value: optionType?.slug,
				label: optionType?.globalCodesName,
				id: optionType?.id,
			}
		}
	}

	addTitle(title: string | null = null) {
		this.title = title
	}

	addActionType(action: IGlobalCodeSelect | null | any = null) {
		if (action) {
			this.action = {
				value: action?.slug,
				label: action?.globalCodesName,
				id: action?.id,
			}
		}
	}

	addDataType(dataType: IGlobalCodeSelect | null = null) {
		if (dataType) {
			this.dataType = {
				value: dataType.slug,
				label: dataType.globalCodesName,
				id: dataType?.id,
			}
		}
	}

	addType(type: IGlobalCodeSelect | null | any = null) {
		if (type) {
			this.type = {
				value: type?.slug,
				label: type?.globalCodesName,
				id: type?.id,
			}
		}
	}
	addScore(score: string | null = null) {
		if (score) {
			return (this.score = score)
		} else {
			delete this.score
		}
	}
	addIsRequired(isRequired: boolean | null = null) {
		if (isRequired) {
			this.isRequired = isRequired
		}
	}
	addIsScore(isScore: boolean | null = false) {
		if (isScore) {
			this.isScore = SCORING_OPTIONS_VALUES.SCORING
		} else {
			this.isScore = SCORING_OPTIONS_VALUES.NOT_SCORING
		}
	}
	addOrder(order: number | null = null) {
		this.order = order
	}
}
export class QuestionnairePayload {
	title: string | null = null
	type: string | null | any = null
	isScore: boolean | null = false
	action: string | null | undefined | any = null
	dataType: string | null | undefined = null
	score: string | null | undefined = null
	isRequired: boolean | null | undefined = false
	order: number | null = null
	templateScreening: string | null = null
	optionType: string | null | undefined = null
	reference: string | null | undefined = null
	parentQuestion: string | undefined
	option: string | undefined
	isRoot: boolean | undefined
	constructor({
		title,
		type,
		isScore,
		action,
		dataType,
		score,
		isRequired,
		order,
		templateScreening,
		optionType,
		reference,
		parentQuestion,
		option,
		isRoot,
		...rest
	}: Partial<QuestionnaireInitialState> = {}) {
		this.addTitle(title)
		this.addType(type)
		this.addDataType(dataType)
		this.addActionType(action)
		this.addScore(score)
		this.addIsRequired(isRequired, type)
		this.addOrder(order)
		this.addIsScore(isScore)
		this.addTemplateScreening(templateScreening)
		this.addOptionType(optionType)
		this.addReference(reference)
		this.parentQuestion = parentQuestion
		this.option = option
		this.addIsRoot(isRoot)
	}

	addIsRoot(isRoot: boolean | undefined) {
		if (isRoot) {
			this.isRoot = isRoot
		} else {
			delete this.isRoot
		}
	}

	addReference(reference: ISelectOption | null = null) {
		if (reference) {
			this.reference = reference.value
		} else {
			delete this.reference
		}
	}

	addOptionType(optionType: ISelectOption | null | any = null) {
		if (optionType) {
			this.optionType = optionType?.id
		} else {
			delete this.optionType
		}
	}

	addTemplateScreening(templateScreening: string | null = null) {
		this.templateScreening = templateScreening
	}

	addTitle(title: string | null = null) {
		this.title = title
	}

	addActionType(action: ISelectOption | null | any = null) {
		if (action) {
			this.action = action?.id
		} else {
			delete this.action
		}
	}

	addDataType(dataType: ISelectOption | null | any = null) {
		if (dataType) {
			this.dataType = dataType?.id
		} else {
			delete this.dataType
		}
	}

	addType(type: ISelectOption | null | any = null) {
		if (type) {
			this.type = type?.id
		}
	}
	addScore(score: string | null = null) {
		if (score) {
			return (this.score = score)
		} else {
			delete this.score
		}
	}
	addIsRequired(isRequired: boolean | null = false, type: ISelectOption | null = null) {
		if (type?.label === QUESTION_TYPES_VALUES.LABEL) {
			delete this.isRequired
		} else {
			this.isRequired = isRequired
		}
	}
	addIsScore(isScore: string | null = null) {
		if (isScore === SCORING_OPTIONS_VALUES.SCORING) {
			this.isScore = true
		} else {
			this.isScore = false
		}
	}
	addOrder(order: number | null = null) {
		this.order = order
	}
}
