import { useField } from 'formik'
import { InputField, IProps as TextFieldProps } from 'components/InputField'
import { useEffect } from 'react'

interface IInputField extends TextFieldProps {
	name: string
	innerRef?: any
}

export default function FormikInputField({ ...rest }: IInputField) {
	const [field, form, { setValue }] = useField(rest.name)
	const message = form.touched && form.error
	useEffect(() => {
		if (rest.defaultValue) {
			setValue(rest.defaultValue)
		}
	}, [rest?.defaultValue])
	return (
		<InputField
			{...field}
			{...rest}
			error={typeof message === 'object' ? JSON.stringify(message) : message}
		/>
	)
}
