import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import _ from 'lodash'
import axios from 'services/axios'
import { CommonMessages, commonMessages } from 'utils/messages'

/**
 * * GET request for All services
 */
export function getClientServcesAll(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<any>(ENDPOINTS.CLIENT_SEVICE_ALL_WITH_CLIENT + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Create Assign Services
 */
export function createAssignServices(data: Partial<any>, source?: CancelTokenSource) {
	const promise = axios.post<any>(ENDPOINTS.CLIENT_SERVICES_ASSIGN_TO_CLIENT, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * GET request for All services
 */
export function getSectionScreenPermission(
	clientId: string | undefined | any,
	params: Partial<{ screenId: any; clientId: any }>,
	source: CancelTokenSource,
	signal: any,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<any>(
		ENDPOINTS.GET_PROVIDER_ROLE_PERMISSION_BY_SCREEN_ID + '/' + clientId,
		{
			params: filteredParams,
			cancelToken: source?.token,
		},
	)
	//@ts-ignore
	// Cancel the request if TanStack Query signals to abort
	signal?.addEventListener('abort', () => {
		source.cancel(CommonMessages?.QUERY_CANCELLED_MESSAGE)
	})
	// promise.cancel = () => {
	// 	source?.cancel(commonMessages.queryCancelledMessage)
	// }
	return promise
}
