import moment from 'moment-timezone'

export const FORMAT = 'MM-DD-YYYY'
export const DATE_PICKER_COMP_FORMAT = 'ddd MMM DD YYYY'
export const DATEPICKER_FORMAT = 'MM/dd/yyyy'
export const MDE_DATE_FORMAT = 'MM/DD/yyyy'
export const LISTING_FORMAT_DATEPICKER = 'MM-DD-YYYY, hh:mm A'
export const DATEPICKER_FORMAT_WITH_TIME = 'MM/dd/yyyy h:mm aa'
export const FORMAT_WITH_TIME = 'MM-DD-YYYY hh:mm:ss A'
export const SLOT_TIME_FORMAT = 'hh:mm A'
export const SESSION_TIME_FORMAT = 'hh:mm:ss A'
export const HH_MM_SS_FORMAT = 'hh:mm:ss'
export const DATE_PICKER_FORMAT = 'MM-dd-yyyy'
export const CALENDAR_DATE_FORMAT = 'YYYY-MM-DD'
export const customTimeSlotGap = 15
export const getCurrentTimeZone = moment.tz.guess()
export const getCurrentDateNumber = 'D'
export const monthYearFormat = 'MMYYYY'
export const DATE_FORMAT_WITH_MMM = 'MMM,DD,YYYY [at] hh:mm a'
export const DATE_FORMAT_WITH_ddd = 'ddd, MMM D, YYYY'
export const DATE_FORMAT_WITH_MMMM = 'MMMM DD, YYYY'
export const YearFormat = 'YY'

export const convertUnix = (date: Date | null | string = toDate()) => {
	return moment.utc(date).unix() * 1000
}

export const convertUnixWithEndOf = (date: Date | null | string = toDate()) => {
	const endOf = moment(date).endOf('date')
	return moment.utc(endOf).unix() * 1000
}

export const convertUnixWithStartOf = (date: Date | null | string = toDate()) => {
	const endOf = moment(date).startOf('date')
	return moment.utc(endOf).unix() * 1000
}

export const unixToDate = (date: number, format = FORMAT) => {
	return moment(date).format(format)
}

export const formatDate = (
	date: Date | null | string | undefined | number = toDate(),
	format = FORMAT,
) => {
	return moment(date).format(format).toString().trim()
}

export const formatDateFromCurrent = (
	date: Date | null | string | undefined | number = toDate(),
	currentFormat = FORMAT,
	format = FORMAT,
) => {
	return moment(date, currentFormat).format(format).toString().trim()
}

export const formatDateUTC = (
	date: Date | null | string | undefined | number = new Date(),
	format = FORMAT,
) => {
	return moment.utc(date).format(format)
}

export function convertToMMDDYY(dateString: string) {
	const isDate = dateString.slice(0, 2)
	const isMonth = dateString.slice(3, 5)
	const isCentury = +dateString.slice(6, 5) - 1
	const isYear = dateString.slice(6, 8)
	return `${isMonth}${isDate}${isCentury}${isYear}`
}

export const toDate = (date?: Date | null | string | undefined | number) => {
	return moment(date).toDate()
}

export const toDateTz = (date?: Date | null | string | undefined | number) => {
	return moment.tz(date, moment.tz.guess()).toDate()
}

export const checkTimeDifference = (
	startDate: string | Date | null,
	endDate: string | Date | null,
	format: string = FORMAT,
	timeFormat?: any,
) => {
	const start = moment(startDate, format)
	const end = moment(endDate, format)
	return moment(end, format).diff(moment(start, format), timeFormat)
}

export const getAllCountryTimezones = () => {
	const currentyCode = 'US'
	return moment.tz.zonesForCountry(currentyCode)
}

export const checkSlotInRange = (
	startTime: string,
	endTime: string,
	currentTime: string,
	format: string = SLOT_TIME_FORMAT,
) => {
	const start = moment(startTime, format)
	const end = moment(endTime, format)
	const current = moment(currentTime, format)
	return moment(current, format).isBetween(moment(start, format), moment(end, format))
}

export const mergeCurrentTimeWithTs = (
	dateTime: Date | null | string | undefined | number = toDate(),
) => {
	const time = moment().format(SLOT_TIME_FORMAT)
	const date = formatDate(dateTime)
	const newDate = formatDate(date + ' ' + time, FORMAT_WITH_TIME)
	return convertUnix(newDate)
}

export const monthConvert = (
	month: null | string | number | any,
	date: Date | null | string = toDate(),
) => {
	return moment.utc(date, FORMAT_WITH_TIME).add(month, 'months').unix() * 1000
}

export const addDateConverter = (
	date: Date | null | string | undefined = toDate(),
	count: number | null = 0,
	format: string = FORMAT,
	type: any = 'days',
) => {
	return moment(date).add(count, type).format(format)
}

export const getEndOf = (
	date?: Date | null | string | undefined,
	format: string = FORMAT,
	name: any = 'day',
) => {
	return moment(date).endOf(name).format(format)
}

export const timeFormat = (time: string | null) => {
	return moment(time, 'hh:mm A').unix() * 1000
}

export const filterDateTime = (timeStampDate: any) => {
	const date = toDate(timeStampDate)

	// Extracting individual date and time components
	const hours = date.getHours()
	const minutes = date.getMinutes()
	const seconds = date.getSeconds()
	const formattedTime = `${hours}:${minutes}:${seconds}`

	return formattedTime
}

export const FilterTime = (time: string | null | number) => {
	return moment(time).format('h:mm:ss A')
}

export const convertUnixWithTime = (date: Date | null | string = toDate()) => {
	return moment.utc(date, FORMAT_WITH_TIME).unix() * 1000
}

export const calculateYear = (givenDate: string | null = null) => {
	const birthDate = moment(givenDate, 'YYYY-DD-MM')
	const currentDate = moment()
	return currentDate.diff(birthDate, 'years')
}

export function getAge(dateString: string) {
	return moment().diff(moment(dateString), 'years')
}
export const convertUTCUnix = (date: Date | null | string = toDate()) => {
	const localDate = moment(date, 'MM-DD-YYYY h:mma')

	// Convert the parsed date to UTC
	const utcDate = localDate.utc()

	// Get the UTC timestamp
	return utcDate.valueOf()
}

export const validateFutureDate = ({ selectedDate = null }: { selectedDate: Date | null }) => {
	// Get the start of today and end of today
	const startOfToday = moment().startOf('day').toDate()
	const endOfToday = moment().endOf('day').toDate()
	// Determine if the selected date is today
	const isToday = selectedDate && moment(selectedDate).isSame(moment(), 'day')

	// Define the minTime and maxTime conditionally based on whether the selected date is today
	const minTime = isToday ? startOfToday : moment('1920-01-01 00:00', 'YYYY-MM-DD HH:mm').toDate()
	const maxTime = isToday ? toDate() : endOfToday
	return {
		minTime,
		maxTime,
	}
}

export const formatNumber = ({
	value = 0,
	toFixed = null,
}: {
	value: number
	toFixed: number | null
}) => {
	// Convert the number to a fixed-point notation string with two decimal places
	let numStr = value.toString()
	const isDecimal = value % 1 != 0
	if (isDecimal && toFixed) {
		numStr = Number(value).toFixed(toFixed)
	}

	// Split the number into integer and decimal parts
	const [integerPart, decimalPart] = numStr.split('.')

	// Add commas to the integer part in the Indian numbering format
	let lastThree = integerPart.slice(-3)
	const otherNumbers = integerPart.slice(0, -3)

	if (otherNumbers !== '') {
		lastThree = ',' + lastThree
	}
	const formattedInteger = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree

	// Join the integer and decimal parts
	const formattedNumber = isDecimal ? `${formattedInteger}.${decimalPart}` : `${formattedInteger}`

	return formattedNumber
}
