import TableSortHeader from 'components/TableSortHeader'

export const membersColumns = [
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Name</TableSortHeader>,
		accessor: 'col1', // accessor is the "key" in the data
		name: 'name',
		sortable: true,
	},

	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Date of Birth</TableSortHeader>,
		accessor: 'col3',
		name: 'dateOfBirth',
		sortable: true,
	},
	{
		Header: 'Email',
		name: 'contacts',
		accessor: 'col4',
	},

	{
		Header: 'Status',
		accessor: 'col5',
		name: 'status',
		sortable: false,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>City</TableSortHeader>,
		accessor: 'col6',
		name: 'city',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>State</TableSortHeader>,
		accessor: 'col7',
		name: 'state',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Client</TableSortHeader>,
		accessor: 'col8',
		name: 'client',
		sortable: true,
	},
	{
		Header: 'Actions',
		accessor: 'col9',
		name: 'actions',
	},
]

export const membersAlertColumns = [
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Name</TableSortHeader>,
		accessor: 'col1', // accessor is the "key" in the data
		name: 'name',
		sortable: true,
	},
	{
		Header: 'Alert',
		name: 'alert',
		accessor: 'col2',
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Date of Birth</TableSortHeader>,
		accessor: 'col3',
		name: 'dateOfBirth',
		sortable: true,
	},
	{
		Header: 'Email',
		name: 'contacts',
		accessor: 'col4',
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Provider</TableSortHeader>,
		name: 'provider',
		accessor: 'col5',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Partner</TableSortHeader>,
		name: 'partner',
		accessor: 'col6',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Status</TableSortHeader>,
		accessor: 'col7',
		name: 'status',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>City</TableSortHeader>,
		accessor: 'col8',
		name: 'city',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>State</TableSortHeader>,
		accessor: 'col9',
		name: 'state',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Client</TableSortHeader>,
		accessor: 'col10',
		name: 'client',
		sortable: true,
	},
	{
		Header: 'Actions',
		accessor: 'col11',
		name: 'actions',
	},
]
export enum MemeberLabels {
	MEMBER_ID = 'Member Id',
	DATE_OF_BIRTH = 'Date of Birth',
	CLIENT_NAME = 'Client Name',
	PHONE_NUMBER = 'Phone Number',
	STATUS = 'Member Status',
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
	TIMEZONE = 'timezone',
}

export enum MEMBER_FILTER_FIELDS {
	SEARCH = 'Search',
	CLIENT = 'Client',
	PARTNER = 'Partner',
	STATUS = 'Status',
	STATE = 'State',
	CITY = 'City',
	PROGRAM = 'Program',
}
export const countryCodeKeyValue = {
	label: '+1',
	value: '+1',
}
