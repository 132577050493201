import TableSortHeader from 'components/TableSortHeader'

export const AssignedTemplateColumns = [
	{
		Header: 'Template Code',
		accessor: 'col1',
		name: 'templateCode',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Title</TableSortHeader>,
		accessor: 'col2',
		name: 'title',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}> Type</TableSortHeader>,
		accessor: 'col3',
		name: 'type',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}> Categories</TableSortHeader>,
		accessor: 'col4',
		name: 'questionTemplateCategory',
		sortable: true,
	},

	{
		Header: 'Client Name',
		accessor: 'col5',
		name: 'client',
	},
	{
		Header: 'Medical Conditions',
		accessor: 'col6',
		name: 'questionTemplateCondition',
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Created By</TableSortHeader>,
		accessor: 'col7',
		name: 'createdBy',
		sortable: true,
	},

	{
		Header: 'Screenings',
		accessor: 'col8',
		name: 'screenings',
	},
	{ Header: 'Actions', accessor: 'col9', name: 'actions' },
]

export const NotAssignedTemplateColumns = [
	{
		Header: 'Template Code',
		accessor: 'col1',
		name: 'templateCode',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Title</TableSortHeader>,
		accessor: 'col2',
		name: 'title',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}> Type</TableSortHeader>,
		accessor: 'col3',
		name: 'type',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}> Categories</TableSortHeader>,
		accessor: 'col4',
		name: 'questionTemplateCategory',
		sortable: true,
	},
	{
		Header: 'Medical Conditions',
		accessor: 'col5',
		name: 'questionTemplateCondition',
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Created By</TableSortHeader>,
		accessor: 'col6',
		name: 'createdBy',
		sortable: true,
	},

	{
		Header: 'Screenings',
		accessor: 'col7',
		name: 'screenings',
	},
	{ Header: 'Actions', accessor: 'col8', name: 'actions' },
]

export enum TEMAPLTES_TYPE_VALUES {
	HEALTHCARE = 'healthCare',
	NON_HEALTHCARE = 'nonHealthCare',
}

export enum FIELD_NAMES {
	TITLE = 'title',
	DESCRIPTION = 'description',
	TYPE = 'questionTemplateType',
	ACTIVE = 'isActive',
	CONDITION = 'questionTemplateCondition',
	CATEGORY = 'questionTemplateCategory',
	TEMPLATE_CODE = 'templateCode',
}

export enum FIELD_NAMES_ASSIGN_TEMPLATE {
	CLIENT = 'client',
	TEMPLATE = 'template',
}

export enum TEMPLATES_FILTER_FIELDS {
	SEARCH = 'Search',
	CLIENT = 'Client',
	CONDITION = 'Condition',
	CATEGORY = 'Category',
}
