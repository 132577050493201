import classnames from 'classnames'
import { memo } from 'react'

interface ILabelProps {
	name: string
	className?: string
	required?: boolean | string
	onClick?: () => void
}

function Label({ name, className, required, ...rest }: ILabelProps) {
	return (
		<label
			className={classnames(
				className,
				'text-[13px]  font-lato text-nightRider font-semibold relative',
				required ? '!p-0' : '',
			)}
			{...rest}
		>
			{name}
			{required && <span className="text-green-500 text-sm ml-1 absolute top-[-4px]">*</span>}
		</label>
	)
}

export default memo(Label)
