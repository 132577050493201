import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { matchPath, useNavigate, useParams } from 'react-router-dom'
import {
	UseMutationOptions,
	UseQueryOptions,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { FormikHelpers } from 'formik'
import axios, { AxiosResponse } from 'axios'
import { getClientDetails, useMyProfile } from 'hooks/common'
import { useUrlQuery } from 'hooks/url'
import { ENDPOINTS } from 'const/endpoints'
import { APP_ROUTES } from 'const/router'
import { errorNotify, successNotify } from 'utils/toster'
import { scrollToError } from 'utils/common'
import { commonMessages } from 'utils/messages'
import { MemberTaskInitialState, MemberTaskPayload } from './MemberTask.types'
import { IFullMemberTasks } from '../MemberTasks.interface'
import {
	createMemberJournalNoteTask,
	createMemberTask,
	getMemberProviderTask,
	getMemberTask,
	updateMemberJournalNoteTask,
	updateMemberTask,
} from '../MemberTasks.service'
import { IAxiosResponseWithPagination } from 'types/entities'
import { TimerContext } from 'App'
import { ITaskListingParams } from 'modules/TaskManagement/TaskListing/TaskListing.interface'

interface IConditionQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IFullMemberTasks>>>,
		Partial<IFullMemberTasks> {
	patient?: string
}

export function useInitial() {
	const alreadyMounted = useRef(false)
	const [timeTrackerId] = useUrlQuery<any>()
	const { timerContextValue, taskContextValue } = useContext(TimerContext)
	const { time, startTrackerTime } = timerContextValue
	const id = timeTrackerId.timeTrackerId
	const { taskType, task, setNoteId } = taskContextValue

	const { data, isFetched } = useMemberTask({
		id,
		clientId: getClientDetails()?.clientId,
		suspense: !alreadyMounted.current,
	})

	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetched])

	return useMemo(() => {
		setNoteId(data?.data?.note?.id)
		if (id) {
			return new MemberTaskInitialState(data?.data)
		} else {
			return new MemberTaskInitialState({
				time: time,
				currentTime: startTrackerTime,
				taskType: taskType?.value ? taskType : null,
				taskManagement: task?.id ? task : null,
			})
		}
	}, [data?.data, id])
}

interface IAddMemberProps
	extends Partial<
		UseMutationOptions<AxiosResponse<MemberTaskPayload>, any, Partial<MemberTaskPayload>>
	> {
	id?: string
	clientId?: string
}

// export function useAddMemberTask({ id, clientId, ...rest }: IAddMemberProps = {}) {
// 	return useMutation<AxiosResponse<MemberTaskPayload>, any, Partial<MemberTaskPayload>>(
// 		async (data) => await createMemberTask(id, clientId, data),
// 		{
// 			...rest,
// 		},
// 	)
// }

interface IUpdateMemberTaskProps
	extends Partial<
		UseMutationOptions<AxiosResponse<MemberTaskPayload>, any, Partial<MemberTaskPayload>>
	> {
	id?: string
	trackerId?: string
	clientId?: string
}

export function useUpdateMemberTask({ id, trackerId, clientId, ...rest }: IUpdateMemberTaskProps) {
	return useMutation<AxiosResponse<MemberTaskPayload>, any, Partial<MemberTaskPayload>>(
		async (data) => await updateMemberTask(id, trackerId, clientId, data),
		{
			...rest,
		},
	)
}

export function useSubmitHandler(props: any) {
	/**
	 * * Variable and hooks section
	 */
	const { timerContextValue, taskContextValue } = useContext(TimerContext)
	const { setTime } = timerContextValue
	const { noteId } = taskContextValue
	const params = useParams<{ id: string }>()
	let id = params.id
	const navigate = useNavigate()
	const profile = useMyProfile()
	if (matchPath(APP_ROUTES.ME, location.pathname)) {
		id = profile?.id
	}
	const client = useQueryClient()
	const [timeTrackerId] = useUrlQuery<any>()

	const trackerId = timeTrackerId.timeTrackerId
	const { mutateAsync: createJournalNote } = useAddMemberJournalNote()
	const { mutateAsync: updateJournalNote } = useUpdateMemberJournalNote({ id: noteId })
	const { mutateAsync: create } = useAddMemberTask({ id })

	if (matchPath(APP_ROUTES.ME, location.pathname)) {
		id = profile?.id
	}
	const { mutateAsync: update } = useUpdateMemberTask({
		id,
		trackerId,
		clientId: getClientDetails()?.clientId,
	})
	return useCallback(
		async (form: MemberTaskInitialState, formikHelpers: FormikHelpers<MemberTaskInitialState>) => {
			formikHelpers.setSubmitting(true)
			form.patient = id
			form.title = form.taskType?.label || form.task_type?.label
			try {
				const data = new MemberTaskPayload(form)
				data?.task_type && delete data?.status
				data?.status && delete data?.taskTypeId
				if (!trackerId) {
					const resp: any = await createJournalNote(new MemberTaskPayload(form))
					if (resp?.data?.id) {
						await create({
							...data,
							note: resp?.data?.id,
						})
					}
					successNotify(commonMessages.createMessage)
					props?.setShowTask(false)
					setTime(0)
					navigate(APP_ROUTES.MEMBER_PROFILE + '/' + id + '/journal-notes')
				}
				if (trackerId) {
					const resp: any = await updateJournalNote(new MemberTaskPayload(form))
					if (resp?.data?.id) {
						await update({ ...data, note: resp?.data?.id })
					}
					successNotify(commonMessages.updateMessage)
					navigate(APP_ROUTES.MEMBER_PROFILE + '/' + id + '/tasks')
				}
				client.invalidateQueries([ENDPOINTS.PATIENT_TIME_TRACKER], {
					type: 'all',
				})
				props?.setShowTask(false)
				setTime(0)
				client.invalidateQueries([ENDPOINTS.JOURNAL_NOTES])
			} catch (err: any) {
				formikHelpers.setErrors(err?.response?.data)
				scrollToError()
				errorNotify(err?.response?.data?.message || commonMessages.errorHandlingMessage)
			}
		},
		[create, id, navigate, update, trackerId],
	)
}

/**
 * * Using the React Hooks useMutation to create a mutation function for creating a createMemberTask.
 */
interface IAddMemberProps
	extends Partial<
		UseMutationOptions<AxiosResponse<MemberTaskPayload>, any, Partial<MemberTaskPayload>>
	> {
	id?: string
}

export function useAddMemberTask({ id, ...rest }: IAddMemberProps = {}) {
	return useMutation<AxiosResponse<MemberTaskPayload>, any, Partial<MemberTaskPayload>>(
		async (data) => await createMemberTask(id, data),
		{
			...rest,
		},
	)
}

export function useMemberTask({ id, clientId, ...rest }: IConditionQueryProps | any = {}) {
	return useQuery<AxiosResponse<IFullMemberTasks>, any, AxiosResponse<IFullMemberTasks>>(
		[ENDPOINTS.GET_TIME_TRACKER_BY_ID, id, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getMemberTask({ id: id }, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}

export function useAddMemberJournalNote({ ...rest }: IAddMemberProps = {}) {
	return useMutation<AxiosResponse<MemberTaskPayload>, any, Partial<MemberTaskPayload>>(
		async (data) => await createMemberJournalNoteTask(data),

		{
			...rest,
		},
	)
}

export function useUpdateMemberJournalNote({ id, ...rest }: IAddMemberProps = {}) {
	return useMutation<AxiosResponse<MemberTaskPayload>, any, Partial<MemberTaskPayload>>(
		async (data) => await updateMemberJournalNoteTask(id, data),

		{
			...rest,
		},
	)
}

interface ITaskLibraryListingQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<ITaskListingParams>>>,
		Partial<ITaskListingParams> {}

export function useMemberProviderTask({
	page,
	limit,
	sort,
	taskType,
	patient,
	hitQuery,
	...rest
}: ITaskLibraryListingQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<ITaskListingParams>>,
		any,
		IAxiosResponseWithPagination<Partial<ITaskListingParams>>
	>(
		[ENDPOINTS.TASK_MANAGEMENT_PROVIDER, taskType, patient, page, sort, limit],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getMemberProviderTask(
				{
					page,
					sort,
					limit,
					taskType,
					patient,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!hitQuery,
			...rest,
		},
	)
}
