import { SelectOption } from 'types/entities'
import { ISelectOption } from 'types/forms'

export class ModulesPayload {
	moduleName: string | null = null
	slug: string | null | undefined = null
	orderBy: number | null | undefined = null
	isActive: boolean | undefined = false
	isSubMenu: boolean | undefined = false
	service: string | null = null
	constructor(data: Partial<ModulesInitialState>) {
		this.addModuleName(data.moduleName)
		this.addSlug(data.slug)
		this.addIsActive(data.isActive)
		this.addOrderBy(data.orderBy)
		this.addIsSubMenu(data.isSubMenu)
		this.addIsService(data?.service)
	}
	addModuleName(moduleName: string | null | undefined) {
		if (moduleName) {
			this.moduleName = moduleName
		}
	}
	addIsService(service: ISelectOption | undefined | null) {
		if (service) {
			this.service = service.value
		}
	}
	addSlug(slug: string | null | undefined) {
		this.slug = slug
	}
	addIsActive(isActive: string | undefined) {
		if (isActive) {
			this.isActive = true
		} else {
			this.isActive = false
		}
	}
	addIsSubMenu(isSubMenu: string | undefined) {
		if (isSubMenu) {
			this.isSubMenu = true
		} else {
			this.isSubMenu = false
		}
	}
	addOrderBy(orderBy: number | null | undefined) {
		this.orderBy = orderBy
	}
}

export class ModulesInitialState {
	moduleName: string | null = null
	slug: string | null = null
	orderBy: number | null = null
	isActive: string | undefined
	isSubMenu: string | undefined
	service: ISelectOption | null = null

	constructor(data: any | undefined) {
		this.addModuleName(data.moduleName)
		this.addSlug(data.slug)
		this.addIsActive(data.isActive)
		this.addOrderBy(data.orderBy)
		this.addIsSubMenu(data.isSubMenu)
		this.addIsService(data?.service)
	}
	addModuleName(moduleName: any) {
		if (moduleName) {
			this.moduleName = moduleName
		}
	}
	addIsService(service: any) {
		if (service) {
			this.service = new SelectOption<string, string>(service.id, service.serviceName)
		}
	}
	addSlug(slug: string | null) {
		this.slug = slug
	}
	addIsActive(isActive: boolean | undefined = false) {
		this.isActive = isActive ? 'true' : ''
	}
	addIsSubMenu(isSubMenu: boolean | undefined = false) {
		this.isSubMenu = isSubMenu ? 'true' : ''
	}
	addOrderBy(orderBy: number | null) {
		this.orderBy = orderBy
	}
}
