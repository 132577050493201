import { useState, useEffect } from 'react'
import { components } from 'react-select'

export const useMenuScroll = (onMenuScrollDown: any) => {
	const [isBottom, setIsBottom] = useState(false)

	const handleScroll = (event: any) => {
		const { scrollTop, clientHeight, scrollHeight } = event.target

		if (scrollTop + clientHeight >= scrollHeight - 1) {
			setIsBottom(true)
			if (typeof onMenuScrollDown === 'function') {
				onMenuScrollDown()
			}
		} else {
			setIsBottom(false)
		}
	}

	useEffect(() => {
		const scrollElement = document.querySelector('.menuScrollBarList')

		if (scrollElement) {
			scrollElement.addEventListener('scroll', handleScroll)
		} else {
			console.error('Element menuScrollBarList not found')
		}

		return () => {
			if (scrollElement) {
				scrollElement.removeEventListener('scroll', handleScroll)
			}
		}
	}, [onMenuScrollDown])

	return { isBottom }
}

const MenuListWrapper = ({ onMenuScrollDown, ...props }: any) => {
	useMenuScroll(onMenuScrollDown)
	return (
		<components.MenuList className="menuScrollBarList" {...props}>
			<div>{props.children}</div>
		</components.MenuList>
	)
}

export default MenuListWrapper
