import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import {
	getAdminDashboardPrograms,
	getDashboardAppointmentCounts,
	getDashboardCounts,
	getProgramListing,
	getStarPartnersForAdmin,
} from './Dashboard.service'
import { IAxiosResponseWithPagination } from 'types/entities'
import { IProgramDashboardParams } from './Dashboard.interface'
import { onGetCustomSectionDetail, onGetFilterColumnDetail, onGetSectionName } from 'utils/common'
import { SECTION_NAME } from 'const/common'
import { tileIcons } from 'modules/ProgramManagerDashboard/Dashboard.const'

export function useDashboardCounts({ program, clientId }: any) {
	return useQuery<any>(
		[ENDPOINTS.ADMIN_DASHBOARD, program, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getDashboardCounts(source, clientId, program)
		},
		{
			enabled: !!program,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	)
}

export function useAdminDashboardPrograms({ partner, program }: any) {
	return useQuery<any>(
		[ENDPOINTS.DASHBOARD_PROGRAMS, partner, program],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getAdminDashboardPrograms(program, { partnerId: partner }, source)
		},
		{
			enabled: !!program,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	)
}

export function useDashboardAppointmentCounts() {
	return useQuery<any>(
		[ENDPOINTS.ADMIN_DASHBOARD_APPOINTMENT],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getDashboardAppointmentCounts(source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
		},
	)
}

export function useStarPartnersForAdmin({ program, clientId }: any) {
	return useQuery<any>(
		[ENDPOINTS.ADMIN_STAR_PARTNERS, program, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getStarPartnersForAdmin(source, clientId, program)
		},
		{
			enabled: !!program,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	)
}

// Program Filter
interface IProgramQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<any>>>,
		Partial<IProgramDashboardParams> {}
export function useGetProgramFilter({
	limit,
	page,
	companyName,
	clientId,
	...rest
}: IProgramQueryProps) {
	return useQuery<any>(
		[ENDPOINTS.CLIENT_PROGRAM, limit, page, companyName, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getProgramListing(source, clientId, { limit, page, companyName })
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	)
}

export const getAdminDashboardSectionName = (screenSectionDetails: any) => {
	const goalsSectioName = onGetSectionName(screenSectionDetails, SECTION_NAME?.GOALS)
	const alertsSectioName = onGetSectionName(screenSectionDetails, SECTION_NAME?.ALERTS)
	const claimsSectioName = onGetSectionName(screenSectionDetails, SECTION_NAME?.CLAIMS)
	const mdeAnalysisSectioName = onGetSectionName(screenSectionDetails, SECTION_NAME?.MDE_ANALYSIS)
	const starPartnerSectioName = onGetSectionName(screenSectionDetails, SECTION_NAME?.STAR_PARTNERS)
	return {
		goalsSectioName,
		alertsSectioName,
		claimsSectioName,
		mdeAnalysisSectioName,
		starPartnerSectioName,
	}
}

export const onCreateAdminTilesDetails = (programManagerScreebDetails: any, data: any) => {
	const appointmentData = data?.data?.totalAppointment
	const programData = data?.data?.programCount
	const referralData = data?.data?.referral

	const tileInfoIObj: any = {
		totalMembersCount: `${data?.data?.patientCount ?? 0}`,

		programEnrollmentsCount: `${programData?.programEnrollment ?? 0}`,

		appointmentsCount: `${appointmentData?.total ?? 0}`,
		appointmentsLeft: 'Scheduled',
		appointmentsLeftCount: `${appointmentData?.pendingAppointment ?? 0}`,
		appointmentsRight: 'Completed',
		appointmentsRightCount: `${appointmentData?.completedAppointment ?? 0}`,

		totalReferralsCount: `${referralData?.totalReferral ?? 0}`,
		totalReferralsLeft: 'Accepted',
		totalReferralsLeftCount: `${referralData?.accepted ?? 0}`,
		totalReferralsRight: 'Declined',
		totalReferralsRightCount: `${referralData?.declined ?? 0}`,
	}
	const getCustomSectionDetails = onGetCustomSectionDetail(
		programManagerScreebDetails,
		SECTION_NAME?.ADMIN_DASHBOARD_TILES,
	)
	const getTilesWithData = onCreateDynamicTilesData({ getCustomSectionDetails, data, tileInfoIObj })

	// const fieldLabelValue = getCustomSectionDetails?.providerColumns?.map((screenTempObj: any) => {
	// 	const { fieldLabel, fieldName } = onGetFilterColumnDetail(screenTempObj)
	// 	const fieldTotalCount = `${fieldName}Count`
	// 	const fieldLeft = `${fieldName}Left`
	// 	const fieldLeftCount = `${fieldName}LeftCount`
	// 	const fieldRight = `${fieldName}Right`
	// 	const fieldRightCount = `${fieldName}RightCount`

	// 	const apiCountValue = data?.data?.fieldName ?? 0
	// 	return {
	// 		totalTitle: fieldLabel,
	// 		totalCount: tileInfoIObj[fieldTotalCount] || apiCountValue,
	// 		icon: tileIcons(fieldName),
	// 		leftTitle: tileInfoIObj[fieldLeft] ?? '',
	// 		leftCount: tileInfoIObj[fieldLeftCount] ?? '',
	// 		rightTile: tileInfoIObj[fieldRight] ?? '',
	// 		rightCount: tileInfoIObj[fieldRightCount] ?? '',
	// 	}
	// })
	// return fieldLabelValue ?? []
	return getTilesWithData ?? []
}

export const onCreateDynamicTilesData = ({
	getCustomSectionDetails,
	data,
	tileInfoIObj,
}: {
	getCustomSectionDetails: any
	data: any
	tileInfoIObj: any
}) => {
	const fieldLabelValue = getCustomSectionDetails?.providerColumns?.map((screenTempObj: any) => {
		const { fieldLabel, fieldName } = onGetFilterColumnDetail(screenTempObj)
		//
		const fieldTotalCount = `${fieldName}Count`
		const fieldLeft = `${fieldName}Left`
		const fieldLeftCount = `${fieldName}LeftCount`
		const fieldRight = `${fieldName}Right`
		const fieldRightCount = `${fieldName}RightCount`
		const apiCountValue = data?.data?.fieldName ?? ''

		return {
			tileName: fieldLabel,
			totalCount: tileInfoIObj[fieldTotalCount] ?? apiCountValue,
			icon: tileIcons(fieldName),
			leftTitle: tileInfoIObj[fieldLeft] ?? '',
			leftCount: tileInfoIObj[fieldLeftCount] ?? '',
			rightTile: tileInfoIObj[fieldRight] ?? '',
			rightCount: tileInfoIObj[fieldRightCount] ?? '',
		}
	})
	return fieldLabelValue ?? []
}
