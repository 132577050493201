import TableSortHeader from 'components/TableSortHeader'
/**
 * * Table Column Name Section
 */
export const programColumns = [
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Program Title</TableSortHeader>,
		accessor: 'col1', // accessor is the "key" in the data
		name: 'programTitle',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Start Date</TableSortHeader>,
		name: 'startDate',
		accessor: 'col2',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>End Date</TableSortHeader>,
		name: 'endDate',
		accessor: 'col3',
		sortable: true,
	},
	{
		Header: 'Type',
		name: 'type',
		accessor: 'col4',
	},
	{
		Header: 'Assignee',
		accessor: 'col5',
		name: 'assignee',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Client</TableSortHeader>,
		accessor: 'col6',
		name: 'title',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Applicable For</TableSortHeader>,
		accessor: 'col7',
		name: 'gender',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Conditions</TableSortHeader>,
		accessor: 'col8',
		name: 'condition',
		sortable: true,
	},
	{
		Header: 'Actions',
		accessor: 'col9',
		name: 'actions',
	},
]
/**
 * * Field Name Section
 */
export enum FIELD_NAMES {
	PROGRAM_NAME = 'programTitle',
	STATE = 'state',
	START_DATE = 'startDate',
	END_DATE = 'endDate',
	ACTIVE = 'active',
	CONDITION = 'condition',
	ASSIGNEE = 'assignee',
	GENDER = 'gender',
	GENDERID = 'genderId',
	MIN_AGE = 'minAge',
	MAX_AGE = 'maxAge',
	PROGRAM_VITALS = 'programVitals',
	DESCRIPTION = 'description',
	HEATHCARE = 'healthcare',
	NON_HEALTHCARE = 'nonhealthcare',
	PARTNERS_LIST = 'PARTNERS_LIST',
	CLIENT = 'client',
	ASSIGNEE_TYPE_ID = 'assigneeTypeId',
	IS_MDE = 'isMde',
}

export enum FIELD_LABEL {
	PROGRAM_CRITERIA = 'Program Criteria',
	PROGRAM_TITLE = 'Program Title',
	START_DATE = 'Start Date',
	END_DATE = 'End Date',
	TYPE = 'Type',
	ASSIGNEE = 'Assignee',
	PROGRAM_DESCRIPTION = 'Program Description',
	CONDITIONS = 'Conditions',
	STATE = 'State',
	AGE_CRITERIA_GT = 'Age Criteria (Max)',
	AGE_CRITERIA_LT = 'Age Criteria (Min)',
	GENDER = 'Gender',
}
export enum PROGRAM_FIELD_SLUG {
	PROGRAM_TITLE = 'programTitle',
	START_DATE = 'startDate',
	END_DATE = 'endDate',
	CLIENT = 'client',
	ASSIGNEE = 'assignee',
	TYPE = 'type',
	PROGRAM_DESCRIPTION = 'programDescription',
	CONDITIONS = 'conditions',
	STATE = 'state',
	AGE_CRITERIA_LT = 'ageCriteriaLessThan',
	AGE_CRITERIA_GT = 'ageCriteriaGreaterThan',
	GENDER = 'gender',

	PROGRAM_CRITERIA = 'Program Criteria',
}
/**
 * * Const Section
 */
export const TYPE_OPTIONS = [
	{ value: 'healthCare', label: 'Healthcare ' },
	{ value: 'nonHealthCare', label: 'Non Healthcare' },
	{ value: 'both', label: 'Both' },
]

export const GENDER_OPTIONS = [
	{ value: 'male', label: 'Male' },
	{ value: 'female', label: 'Female' },
	{ value: 'both', label: 'Both' },
]

export const CONDITION_OPTIONS: {
	label: string
	value: string
}[] = []

export enum LABEL {
	PROGRAMS = 'Programs',
	PROGRAM = 'Program',
	EDIT_PROGRAM = 'Edit Program',
	ADD_PROGRAM = 'Add Program',
}

export enum PROGRAM_FILTER_FIELDS {
	SEARCH = 'Search',
	CONDITIONS = 'Condition',
	ASSIGNEE = 'Assignee',
}
