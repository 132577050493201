import { DEVICE_TYPE, VITAL_TYPE } from 'types/entities'
// import { PARTNER_ROLE, PARTNER_STATUS, TIME_RANGE } from './auth'
import { PARTNER_ROLE, PARTNER_STATUS } from './auth'
import { TIME_RANGE } from 'modules/Partners/Partner/Schedules/Schedules.constants'

export const STATES_OPTIONS = [
	{ label: 'Alabama', value: 'Alabama', abbreviation: 'AL' },
	{ label: 'Alaska', value: 'Alaska', abbreviation: 'AK' },
	{ label: 'Arizona', value: 'Arizona', abbreviation: 'AZ' },
	{ label: 'Arkansas', value: 'Arkansas', abbreviation: 'AR' },
	{ label: 'California', value: 'California', abbreviation: 'CA' },
	{ label: 'Colorado', value: 'Colorado', abbreviation: 'CO' },
	{ label: 'Connecticut', value: 'Connecticut', abbreviation: 'CT' },
	{ label: 'Delaware', value: 'Delaware', abbreviation: 'DE' },
	{
		label: 'District of Columbia',
		value: 'District of Columbia',
		abbreviation: 'DC',
	},
	{
		label: 'Federated States Of Micronesia',
		value: 'Federated States Of Micronesia',
		abbreviation: 'FM',
	},
	{ label: 'Florida', value: 'Florida', abbreviation: 'FL' },
	{ label: 'Georgia', value: 'Georgia', abbreviation: 'GA' },
	{ label: 'Guam', value: 'Guam', abbreviation: 'GU' },
	{ label: 'Hawaii', value: 'Hawaii', abbreviation: 'HI' },
	{ label: 'Idaho', value: 'Idaho', abbreviation: 'ID' },
	{ label: 'Illinois', value: 'Illinois', abbreviation: 'IL' },
	{ label: 'Indiana', value: 'Indiana', abbreviation: 'IN' },
	{ label: 'Iowa', value: 'Iowa', abbreviation: 'IA' },
	{ label: 'Kansas', value: 'Kansas', abbreviation: 'KS' },
	{ label: 'Kentucky', value: 'Kentucky', abbreviation: 'KY' },
	{ label: 'Louisiana', value: 'Louisiana', abbreviation: 'LA' },
	{ label: 'Maine', value: 'Maine', abbreviation: 'ME' },
	{ label: 'Marshall Islands', value: 'Marshall Islands', abbreviation: 'MH' },
	{ label: 'Maryland', value: 'Maryland', abbreviation: 'MD' },
	{ label: 'Massachusetts', value: 'Massachusetts', abbreviation: 'MA' },
	{ label: 'Michigan', value: 'Michigan', abbreviation: 'MI' },
	{ label: 'Minnesota', value: 'Minnesota', abbreviation: 'MN' },
	{ label: 'Mississippi', value: 'Mississippi', abbreviation: 'MS' },
	{ label: 'Missouri', value: 'Missouri', abbreviation: 'MO' },
	{ label: 'Montana', value: 'Montana', abbreviation: 'MT' },
	{ label: 'Nebraska', value: 'Nebraska', abbreviation: 'NE' },
	{ label: 'Nevada', value: 'Nevada', abbreviation: 'NV' },
	{ label: 'New Hampshire', value: 'New Hampshire', abbreviation: 'NH' },
	{ label: 'New Jersey', value: 'New Jersey', abbreviation: 'NJ' },
	{ label: 'New Mexico', value: 'New Mexico', abbreviation: 'NM' },
	{ label: 'New York', value: 'New York', abbreviation: 'NY' },
	{ label: 'North Carolina', value: 'North Carolina', abbreviation: 'NC' },
	{ label: 'North Dakota', value: 'North Dakota', abbreviation: 'ND' },
	{
		label: 'Northern Mariana Islands',
		value: 'Northern Mariana Islands',
		abbreviation: 'MP',
	},
	{ label: 'Ohio', value: 'Ohio', abbreviation: 'OH' },
	{ label: 'Oklahoma', value: 'Oklahoma', abbreviation: 'OK' },
	{ label: 'Oregon', value: 'Oregon', abbreviation: 'OR' },
	{ label: 'Palau', value: 'Palau', abbreviation: 'PW' },
	{ label: 'Pennsylvania', value: 'Pennsylvania', abbreviation: 'PA' },
	{ label: 'Puerto Rico', value: 'Puerto Rico', abbreviation: 'PR' },
	{ label: 'Rhode Island', value: 'Rhode Island', abbreviation: 'PI' },
	{ label: 'South Carolina', value: 'South Carolina', abbreviation: 'SC' },
	{ label: 'South Dakota', value: 'South Dakota', abbreviation: 'SD' },
	{ label: 'Tennessee', value: 'Tennessee', abbreviation: 'TN' },
	{ label: 'Texas', value: 'Texas', abbreviation: 'TX' },
	{ label: 'Utah', value: 'Utah', abbreviation: 'UT' },
	{ label: 'Vermont', value: 'Vermont', abbreviation: 'VT' },
	{ label: 'Virgin Islands', value: 'Virgin Islands', abbreviation: 'VI' },
	{ label: 'Virginia', value: 'Virginia', abbreviation: 'VA' },
	{ label: 'Washington', value: 'Washington', abbreviation: 'WA' },
	{ label: 'West Virginia', value: 'West Virginia', abbreviation: 'WV' },
	{ label: 'Wisconsin', value: 'Wisconsin', abbreviation: 'WI' },
	{ label: 'Wyoming', value: 'Wyoming', abbreviation: 'WY' },
]

export const DEVICE_OPTIONS: {
	label: string
	value: DEVICE_TYPE
}[] = [
	{ label: 'Blood pressure', value: DEVICE_TYPE.DEVICE_BP },
	{ label: 'Scales', value: DEVICE_TYPE.DEVICE_SC },
	{ label: 'Glucose', value: DEVICE_TYPE.DEVICE_GL },
	{ label: 'Pulse Oximeter', value: DEVICE_TYPE.DEVICE_PO },
	{ label: 'Temperature', value: DEVICE_TYPE.DEVICE_T },
	{
		label: 'Cellular blood pressure',
		value: DEVICE_TYPE.DEVICE_BP_CELLULAR,
	},
	{ label: 'Cellular Scales', value: DEVICE_TYPE.DEVICE_SC_CELLULAR },
	{ label: 'Cellular Glucose', value: DEVICE_TYPE.DEVICE_GL_CELLULAR },
	{
		label: 'Cellular Pulse Oximeter',
		value: DEVICE_TYPE.DEVICE_PO_CELLULAR,
	},
	{ label: 'Temperature cellular', value: DEVICE_TYPE.DEVICE_T_CELLULAR },
]

export const VITAL_TYPE_OPTIONS: {
	label: string
	value: VITAL_TYPE
}[] = [
	{ label: 'Blood pressure', value: VITAL_TYPE.BLOOD_PRESSURE },
	{ label: 'Glucose', value: VITAL_TYPE.GLUCOSE },
	{ label: 'Weight', value: VITAL_TYPE.WEIGHT },
	{ label: 'Temperature', value: VITAL_TYPE.TEMPERATURE },
	{ label: 'Oxygen Saturation', value: VITAL_TYPE.OXYGEN_SATURATION },
]

export const SPECIALITY_OPTIONS = [
	{ label: 'ambulance', value: '1d65da85-a237-4cc8-aa06-b358fdbdf9e7' },
	{ label: 'x-ray', value: 'bff4ce12-8772-46c5-9c8c-6a53183a02cf' },
]

export const PARTNER_ROLE_OPTION: {
	label: string
	value: PARTNER_ROLE
}[] = [
	{ label: 'Medical Assistant', value: PARTNER_ROLE.MEDICAL_ASSISTANT },
	{ label: 'Doctor', value: PARTNER_ROLE.DOCTOR },
	// { label: 'Provider', value: PARTNER_ROLE.PROVIDER },
	// { label: 'Care Coordinator', value: PARTNER_ROLE.CARE_COORDINATOR },
	// { label: 'Program Coordinator', value: PARTNER_ROLE.PROGRAM_COORDINATOR },
]

export const PARTNER_AVAILABILITY_OPTIONS: {
	label: string
	value: TIME_RANGE
}[] = [
	{ label: 'Day', value: TIME_RANGE.DAY },
	{ label: 'Week', value: TIME_RANGE.WEEK },
	{ label: 'Date Range', value: TIME_RANGE.DATE_RANGE },
]

export const PARTNER_STATUS_OPTION: {
	label: string
	value: PARTNER_STATUS
}[] = [
	{ label: 'Active', value: PARTNER_STATUS.ACTIVE },
	{ label: 'Inactive', value: PARTNER_STATUS.IN_ACTIVE },
]
