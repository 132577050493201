import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import axios from 'services/axios'
import { commonMessages } from 'utils/messages'
import { convertUnix, toDate } from 'utils/time'
import { IProgramDashboardParams } from './Dashboard.interface'
import _ from 'lodash'
import { onCreateEndpointsWithClientid } from 'utils/common'

export function getDashboardCounts(
	source?: CancelTokenSource,
	clientId?: string | undefined,
	program?: string | undefined,
	// params?: Partial<any>,
) {
	const date = toDate()
	// const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get(
		ENDPOINTS.ADMIN_DASHBOARD +
			'/' +
			clientId +
			'/' +
			program +
			`?currentDate=${convertUnix(date)}`,
	)

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

export function getDashboardAppointmentCounts(source?: CancelTokenSource) {
	const promise = axios.get(ENDPOINTS.ADMIN_DASHBOARD_APPOINTMENT)

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

export function getAdminDashboardPrograms(
	program: string | undefined,
	params: any,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get(
		onCreateEndpointsWithClientid(ENDPOINTS.DASHBOARD_PROGRAMS) + '/' + program,
		{
			params: filteredParams,
		},
	)

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

export function getProgramPartnerList(totalLimit?: number, source?: CancelTokenSource) {
	const promise = axios.get(onCreateEndpointsWithClientid(ENDPOINTS.PROGRAM_PARTNER))

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

export function getStarPartnersForAdmin(
	source?: CancelTokenSource,
	clientId?: string | undefined,
	program?: string | undefined,
) {
	const promise = axios.get(ENDPOINTS.ADMIN_STAR_PARTNERS + '/' + clientId + '/' + program)

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

// Dashboard Program List
export function getProgramListing(
	source?: CancelTokenSource,
	clientId?: string | undefined,
	params?: Partial<IProgramDashboardParams>,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get(onCreateEndpointsWithClientid(ENDPOINTS.CLIENT_PROGRAM), {
		params: filteredParams,
	})
	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}
