import _ from 'lodash'
import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'
import { IPagination } from 'types/entities'
import { commonMessages } from 'utils/messages'
/**
 * getMemebersListing
 */
export function getMemebersListing(
	clientId: string | undefined,
	id: string | undefined,
	source?: CancelTokenSource,
	params?: Partial<any>,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<any>>>(
		ENDPOINTS.PROGRAM_MEMBER + '/' + clientId + '/' + id,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
