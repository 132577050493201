import {
	getAllGlobalCodes,
	getAllVitals,
	getItems,
	getMemberChats,
	getNonHealthcarePartners,
	getPartnerScheduleSlots,
	getPartnerScheduleWeek,
	getSchedule,
	getTasks,
	IItemParams,
	INonHeathCarePartners,
	TaskParams,
	updateCustom,
} from './../services/requests'
import axios, { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
	createAudit,
	getMe,
	getAudits,
	IAuditsParams,
	getPostalCodes,
	getStates,
	getPatients,
	sendResetPasswordRequest,
	resetPassword,
	getPatient,
	IPatientsParams,
	getVital,
	getVitals,
	IVitalParams,
	getVitalsReport,
	IVitalReportParams,
	getJournalNote,
	IJournalNotesParams,
	getJournalNotes,
	getNotifications,
	INotificationParams,
} from 'services/requests'
import {
	IAudit,
	IAxiosResponseWithPagination,
	IPostalCode,
	IFullPatient,
	IFullVital,
	IVitalReport,
	IJournalNote,
	IFullJournalNote,
	IFullNotification,
	IItems,
	IFullTasks,
	IGlobalCodes,
	IGlobalCodesParams,
} from 'types/entities'
import { IResetPasswordData, IResetPasswordEmail } from 'types/auth'
import { ENDPOINTS } from 'const/endpoints'
import { IPartner } from 'modules/Partners/Partner.interfaces'
import { IFullProvider } from 'modules/Providers/Provider.interfaces'
import { getMemebersListing } from 'modules/Programs/Program/Members/Members.service'
import { IFullAddSchedule } from 'modules/Partners/Partner/Schedules/Schedules.interface'
import { convertUnix } from 'utils/time'

type IMeByIdQueryProps = Partial<UseQueryOptions<AxiosResponse<IFullProvider>>>

export function useMe({ ...rest }: IMeByIdQueryProps = {}) {
	return useQuery<AxiosResponse<IFullProvider>, any, AxiosResponse<IFullProvider>>(
		[ENDPOINTS.ME],
		async () => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return await getMe(source)
		},
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			staleTime: 5000,
			retry: 0,
			...rest,
			suspense: false,
		},
	)
}
interface IAllGlobalCodeProps
	extends UseQueryOptions<AxiosResponse<IGlobalCodes[]>>,
		Partial<IGlobalCodesParams> {}

export function useGetAllGlobalCodes({
	clientId,
	isEnabled = false,
	...rest
}: IAllGlobalCodeProps = {}) {
	return useQuery<AxiosResponse<IGlobalCodes[]>, any, AxiosResponse<IGlobalCodes[]>>(
		[ENDPOINTS.GET_GLOBAL_CATEGORY_ALL, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getAllGlobalCodes(clientId, source)
		},
		{
			keepPreviousData: true,
			enabled: isEnabled,
			refetchOnWindowFocus: false,
			suspense: false,
			...rest,
		},
	)
}

interface IAuditsQueryProps
	extends Partial<UseQueryOptions<IAxiosResponseWithPagination<IAudit>>>,
		Partial<IAuditsParams> {}

export function useAudits({
	id,
	page,
	limit,
	auditGroup,
	sort,
	startDate,
	endDate,
	credentials,
	...rest
}: IAuditsQueryProps = {}) {
	let queryKey = [ENDPOINTS.AUDITS, page, limit, auditGroup, sort, credentials, id]
	let queryParams: Partial<IAuditsParams> = {
		page,
		limit,
		auditGroup,
		sort,
		credentials,
	}
	if (startDate && endDate) {
		queryKey = [...queryKey, startDate, endDate]
		queryParams = {
			...queryParams,
			startDate,
			endDate,
		}
	}
	return useQuery<IAxiosResponseWithPagination<IAudit>, any, IAxiosResponseWithPagination<IAudit>>(
		[...queryKey],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getAudits(
				{
					...queryParams,
				},
				id,
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			...rest,
		},
	)
}

export function useVitalItemList(
	id: string | undefined,
	clientId: string | undefined,
	openGoalsDrawer: any,
) {
	return useQuery(
		[ENDPOINTS.UNDER_PROGRAM_VITAL, openGoalsDrawer, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getAllVitals(source, clientId, id)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			// enabled: !!openGoalsDrawer,
		},
	)
}

type ICreateAuditProps = Partial<UseMutationOptions<AxiosResponse<IAudit>, any, Partial<IAudit>>>

export function useCreateAudit({ ...rest }: ICreateAuditProps = {}) {
	return useMutation<AxiosResponse<IAudit>, any, Partial<IAudit>>(createAudit, {
		...rest,
	})
}

interface IPostalCodeQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IPostalCode[]>>>,
		Partial<IPostalCode> {}

export function usePostalCodes({ zipCode, ...rest }: IPostalCodeQueryProps = {}) {
	return useQuery<AxiosResponse<IPostalCode[]>, any, AxiosResponse<IPostalCode[]>>(
		[ENDPOINTS.ZIP_CODES, zipCode],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			let code = zipCode
			if (zipCode?.includes('-')) {
				code = zipCode.replace('-', '')
			}
			return getPostalCodes({ zipCode: code || '' }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}

interface IProviderQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IFullProvider>>>,
		Partial<IFullProvider> {
	id?: string
}

interface IPatientQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IFullPatient>>>,
		Partial<IFullPatient> {
	id?: string
	clientId?: string
}

export function usePatient({ id, clientId, ...rest }: IPatientQueryProps) {
	return useQuery<AxiosResponse<IFullPatient>, any, AxiosResponse<IFullPatient>>(
		[ENDPOINTS.PATIENTS, id, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getPatient(id, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}

type IStatesQueryProps = Partial<UseQueryOptions<AxiosResponse<string[]>>>

export function useStates({ ...rest }: IStatesQueryProps = {}) {
	return useQuery<AxiosResponse<string[]>, any, AxiosResponse<string[]>>(
		[ENDPOINTS.STATES],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getStates(source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}

interface IPatientsQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullPatient>>>,
		Partial<IPatientsParams> {}

export function usePatients({
	clientId,
	page,
	limit,
	sort,
	name,
	client,
	status,
	programName,
	city,
	state,
	isAlert,
	untrained,
	lastMeasurementLt,
	healthCondition,
	search,
	partner,
	...rest
}: IPatientsQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullPatient>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullPatient>>
	>(
		[
			ENDPOINTS.PATIENTS,
			page,
			limit,
			sort,
			name,
			client,
			status,
			programName,
			city,
			state,
			isAlert,
			untrained,
			lastMeasurementLt,
			healthCondition,
			search,
			partner,
			// clientId,
		],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getPatients(
				// clientId,
				{
					page,
					limit,
					sort,
					name,
					client,
					status,
					programName,
					city,
					state,
					isAlert,
					untrained,
					lastMeasurementLt,
					healthCondition,
					search,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,

			...rest,
		},
	)
}

interface IPatientsQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullPatient>>>,
		Partial<IPatientsParams> {}

export function useMemberChats({
	clientId,
	page,
	limit,
	sort,
	name,
	client,
	status,
	programName,
	city,
	state,
	isAlert,
	untrained,
	lastMeasurementLt,
	healthCondition,
	search,
	partner,
	...rest
}: IPatientsQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullPatient>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullPatient>>
	>(
		[
			ENDPOINTS.GET_PATIENT_FOR_CHAT,
			page,
			limit,
			sort,
			name,
			client,
			status,
			programName,
			city,
			state,
			isAlert,
			untrained,
			lastMeasurementLt,
			healthCondition,
			search,
			partner,
			// clientId,
		],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getMemberChats(
				// clientId,
				{
					page,
					limit,
					sort,
					name,
					client,
					status,
					programName,
					city,
					state,
					isAlert,
					untrained,
					lastMeasurementLt,
					healthCondition,
					search,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,

			...rest,
		},
	)
}

export function useEpidemiologistPatients({
	clientId,
	page,
	limit,
	sort,
	name,
	client,
	status,
	programName,
	city,
	state,
	isAlert,
	untrained,
	lastMeasurementLt,
	healthCondition,
	...rest
}: IPatientsQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullPatient>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullPatient>>
	>(
		[
			ENDPOINTS.EPIDEMIOLOGIST_MEMBER_LIST,
			page,
			limit,
			sort,
			name,
			client,
			status,
			programName,
			city,
			state,
			isAlert,
			untrained,
			lastMeasurementLt,
			healthCondition,
			clientId,
		],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getPatients(
				// clientId,
				{
					page,
					limit,
					sort,
					name,
					client,
					status,
					programName,
					city,
					state,
					isAlert,
					untrained,
					lastMeasurementLt,
					healthCondition,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,

			...rest,
		},
	)
}

type IResetPasswordEmailProps = Partial<
	UseMutationOptions<AxiosResponse<IResetPasswordEmail>, any, any>
>

export function useSendResetPasswordRequest({ ...rest }: IResetPasswordEmailProps = {}) {
	return useMutation<AxiosResponse<IResetPasswordEmail>, any, any>(
		(email) => sendResetPasswordRequest({ email }),
		{
			...rest,
		},
	)
}

type IResetPasswordDataProps = Partial<
	UseMutationOptions<AxiosResponse<IResetPasswordData>, any, any>
>

export function useResetPassword({ ...rest }: IResetPasswordDataProps) {
	return useMutation<AxiosResponse<IResetPasswordData>, any, any>(resetPassword, {
		...rest,
	})
}

interface IVitalsQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IFullVital>>>,
		Partial<IVitalParams> {
	id?: string
}

export function useVital({ id, ...rest }: IVitalsQueryProps) {
	return useQuery<AxiosResponse<IFullVital>, any, AxiosResponse<IFullVital>>(
		[ENDPOINTS.VITALS, id],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getVital(id, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}

interface IVitalQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullVital>>>,
		Partial<IVitalParams> {}

export function useVitals({
	page,
	limit,
	sort,
	name,
	deviceType,
	takenAt,
	onlyDeleted,
	type,
	isAlert,
	isCleared,
	patient,
	...rest
}: IVitalQueryProps = {}) {
	const params = {
		page,
		limit,
		sort,
		name,
		deviceType,
		takenAt,
		onlyDeleted,
		type,
		isAlert,
		isCleared,
		patient,
	}

	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullVital>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullVital>>
	>(
		[ENDPOINTS.VITALS, ...Object.values(params)],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getVitals({ ...params }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			...rest,
		},
	)
}

interface IVitalReportQueryProps
	extends UseQueryOptions<AxiosResponse<IVitalReport[]>>,
		Partial<IVitalReportParams> {}

export function useVitalsReport({
	page,
	limit,
	sort,
	name,
	deviceType,
	takenAt,
	onlyDeleted,
	client,
	patient,
	startDate,
	type,
	endDate,
	...rest
}: IVitalReportQueryProps = {}) {
	const params = {
		page,
		limit,
		sort,
		name,
		deviceType,
		takenAt,
		onlyDeleted,
		client,
		patient,
		startDate,
		endDate,
		type,
	}

	return useQuery<AxiosResponse<IVitalReport[]>>(
		[ENDPOINTS.VITALS, ...Object.values(params)],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getVitalsReport({ ...params }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,

			...rest,
		},
	)
}

interface IJournalNoteQueryProps extends Partial<UseQueryOptions<AxiosResponse<IJournalNote>>> {
	id?: string
}

export function useJournalNote({ id, ...rest }: IJournalNoteQueryProps) {
	return useQuery<AxiosResponse<IJournalNote>, any, AxiosResponse<IJournalNote>>(
		[ENDPOINTS.JOURNAL_NOTES, id],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getJournalNote(id, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}

interface IJournalNotesQueryProps
	extends Partial<UseQueryOptions<IAxiosResponseWithPagination<IFullJournalNote>>>, //resposnse params
		Partial<IJournalNotesParams> {} //request params

export function useJournalNotes({
	title,
	page,
	limit,
	patient,
	sort,
	provider,
	...rest
}: IJournalNotesQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<IFullJournalNote>, //query function
		any,
		IAxiosResponseWithPagination<IFullJournalNote> // api reciev data
	>(
		[ENDPOINTS.JOURNAL_NOTES, page, limit, patient, sort, provider, title],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getJournalNotes({ page, limit, sort, patient, provider, title }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			...rest,
		},
	)
}

interface INotificationQueryProps
	extends Partial<UseQueryOptions<IAxiosResponseWithPagination<IFullNotification>>>,
		Partial<INotificationParams> {}

export function useNotifications({
	page,
	limit,
	patient,
	sort,
	...rest
}: INotificationQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<IFullNotification>,
		any,
		IAxiosResponseWithPagination<IFullNotification>
	>(
		[ENDPOINTS.NOTIFICATIONS, page, limit, patient, sort],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getNotifications({ page, limit, sort, patient }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			...rest,
		},
	)
}

// Get Scheduler
interface IScheduleEditQueryProp
	extends Partial<UseQueryOptions<AxiosResponse<IFullAddSchedule>>>,
		Partial<IFullAddSchedule> {
	id?: string
	clientId?: string
}

export function useGetSchedule({ id, clientId, ...rest }: IScheduleEditQueryProp) {
	return useQuery<AxiosResponse<IFullAddSchedule>, any, AxiosResponse<IFullAddSchedule>>(
		[ENDPOINTS.PARTNER_SCHEDULER, id, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getSchedule(id, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}

/*
Program
/*/

// interface IProgramQueryProps
// 	extends Partial<UseQueryOptions<AxiosResponse<IFullProgram>>>,
// 		Partial<IFullProgram> {
// 	id?: string
// }

// export function useProgram({ id, ...rest }: IProgramQueryProps) {
// 	return useQuery<AxiosResponse<IFullProgram>, any, AxiosResponse<IFullProgram>>(
// 		[ENDPOINTS.PROGRAMS, id],
// 		() => {
// 			const CancelToken = axios.CancelToken
// 			const source = CancelToken.source()
// 			return getProgram(id, source)
// 		},
// 		{
// 			keepPreviousData: true,
// 			refetchOnWindowFocus: false,
// 			suspense: true,
// 			cacheTime: 0,
// 			enabled: !!id,
// 			...rest,
// 		},
// 	)
// }

type IPartnerScheduleWeekQueryProps = Partial<any>

export function usePartnerScheduleWeek({ id, ...rest }: IPartnerScheduleWeekQueryProps = {}) {
	return useQuery<AxiosResponse<string[]>, any, AxiosResponse<string[]>>(
		[ENDPOINTS.PARTNER_SCHEDULER_WEEK, id],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getPartnerScheduleWeek(id, source)
		},
		{
			keepPreviousData: true,
			suspense: false,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}

// getPartnerScheduleSlots

type IPartnerScheduleSlotQueryProps = Partial<any>

export function usePartnerScheduleSlots({ id, ...rest }: IPartnerScheduleSlotQueryProps = {}) {
	return useQuery<AxiosResponse<string[]>, any, AxiosResponse<string[]>>(
		[ENDPOINTS.PARTNER_SCHEDULER_SLOTS, id],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getPartnerScheduleSlots(id, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}

interface INonHealthcarePartnersProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullPatient>>>, //response time
		Partial<INonHeathCarePartners> {} //request time

export function useNonHealthcarePartners({
	page,
	limit,
	sort,
	name,
	search,
	status,
	...rest
}: INonHealthcarePartnersProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IPartner>>,
		any,
		IAxiosResponseWithPagination<Partial<IPartner>>
	>(
		[ENDPOINTS.NONHEATHCARE_PARTNERS, page, limit, sort, name, search, status],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getNonHealthcarePartners({ page, limit, sort, name, status, search }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			...rest,
		},
	)
}

//Session

interface IItemsQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IItems<string[]>>>>,
		Partial<IItemParams> {}

export function useItems({
	sort,
	limit,
	title,
	search,
	programId,
	clientId,
	...rest
}: IItemsQueryProps = {}) {
	return useQuery<AxiosResponse<IItems<string[]>>, any, AxiosResponse<IItems<string[]>>>(
		[ENDPOINTS.PROGRAMS_VITALS_ITEM, sort, limit, title, search, programId, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getItems(
				// clientId,
				{
					sort,
					limit,
					title,
					search,
					programId,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}

/**
 * tasks
 */

interface ITaskProps
	extends Partial<UseQueryOptions<IAxiosResponseWithPagination<IFullTasks>>>,
		Partial<TaskParams> {}
export function useTasks({ page, limit, patient, sort, ...rest }: ITaskProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<IFullTasks>,
		any,
		IAxiosResponseWithPagination<IFullTasks>
	>(
		[ENDPOINTS.TASKS, page, limit, patient, sort],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getTasks({ page, limit, sort, patient }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			...rest,
		},
	)
}

export function useCustom({ id, clientId, ...rest }: any) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(
		async (data) =>
			await updateCustom(id, clientId, data, {
				...rest,
			}),
		{
			...rest,
		},
	)
}
interface IMemberListingProps extends Partial<UseQueryOptions<AxiosResponse<any>>>, Partial<any> {
	id?: string
}
export function useGetMemeberListing({
	clientId,
	limit,
	page,
	sort,
	checkInInterval,
	week,
	trackingDevices,
	search,
	id,
	...rest
}: any = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<any>>,
		any,
		IAxiosResponseWithPagination<Partial<any>>
	>(
		[
			ENDPOINTS.PROGRAM_MEMBER,
			page,
			limit,
			sort,
			checkInInterval,
			week,
			trackingDevices,
			search,
			clientId,
			id,
		],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getMemebersListing(
				clientId,
				id,
				source,

				{
					page,
					limit,
					sort,
					checkInInterval,
					week,
					trackingDevices,
					search,
				},
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...rest,
		},
	)
}
// getListing
// Assignne Listing
// export function useAssigneeList({
// 	page,
// 	limit,
// 	sort,
// 	search,
// 	status,
// 	client,
// 	type,
// 	...rest
// }: IProgramsParams) {
// 	return useQuery<
// 		IAxiosResponseWithPagination<Partial<IFullProgram>>,
// 		any,
// 		IAxiosResponseWithPagination<Partial<IFullProgram>>
// 	>(
// 		[ENDPOINTS.ASSIGNEE_LISTING, page, limit, sort, status, search, client, type],
// 		() => {
// 			const CancelToken = axios.CancelToken
// 			const source = CancelToken.source()
// 			return getAllAssigneeList({ page, limit, sort, status, search, client, type }, source)
// 		},
// 		{
// 			keepPreviousData: true,
// 			refetchOnWindowFocus: false,
// 			enabled: !!client,
// 			...rest,
// 		},
// 	)
// }
