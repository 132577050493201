import { AxiosResponse } from 'axios'
import { ISelectOption } from './forms'
import { IFullProvider, IProvider } from 'modules/Providers/Provider.interfaces'
import { IClient } from 'modules/Clients/Clients.interface'

export class SelectOption<TValue extends string = string, TLabel extends string = string>
	implements ISelectOption
{
	value: TValue | string = ''
	label: TLabel | string = ''

	constructor(value: TValue | string = '', label: TLabel | string = '') {
		if (value) {
			this.value = value
		}
		if (label) {
			this.label = label
		}
	}
}

export interface IBase {
	createdAt?: string
	updatedAt?: string
}

export interface IBaseWithId extends IBase {
	id: string
}
export type EthnicityTypes = 'Hispanic' | 'Non Hispanic'
export enum ACTIONS {
	PATIENT_CREATION = 'patientCreation',
	EDIT_PATIENT = 'editPatient',
	DEVICE_ASSIGN = 'deviceAssign',
	DEVICE_UNASSIGN = 'deviceUnassign',
	REPORT_GENERATION = 'reportGeneration',
	LOGIN = 'login',
	GET_VR_REPORT = 'getVrReport',
	GET_SR_REPORT = 'getSrReport',
}

enum ENTITY {
	PATIENT = 'patient',
	PROVIDER = 'provider',
}

export enum AUDIT_STATUS {
	SUCCESS = 'success',
	ERROR = 'error',
}

export enum AUDIT_GROUPS {
	LOGIN = 'login',
	API = 'api',
	REPORTS = 'reports',
	PROGRAM = 'program',
	PARTNER = 'partner',
	VITALS = 'vitals',
	PATIENT = 'patient',
	PROVIDER = 'provider',
	MEMBER = 'Member',
}

export interface IAudit extends IBaseWithId {
	action: ACTIONS
	credentials: string //email, phone
	status: AUDIT_STATUS //success, error
	params: string // request with params (for reports)
	entityType: ENTITY //type of entity that was edited, created
	entity: string // id edited entity
	entityUserType: ENTITY // who made action type
	entityUser: IProvider // who made action id
	newValues: string //new values - delta of changed data
	prevValues: string
	auditGroup: AUDIT_GROUPS
}

export interface IPaginationMeta {
	currentPage: number
	itemCount: number
	itemsPerPage: number
	totalItems: number
	totalPages: number
}
export interface IPagination<T> {
	items: T[]
	meta: IPaginationMeta
}

export type IAxiosResponseWithPagination<T> = AxiosResponse<IPagination<T>>

export interface IHumanName extends IBaseWithId {
	text: string
	given: string
	family: string
	middle: string
}

export interface IPartnerHumanName extends IBaseWithId {
	given: string
	family: string
}

export interface IContactPoint extends IBaseWithId {
	text: string
	type: CONTACT_POINT
	typeId: string
	id: string
}
export interface IProvidersContactPoint extends IBaseWithId {
	contactPoint: {
		text: string
		type: CONTACT_POINT
	}
}

export interface IAddress extends IBaseWithId {
	text: string
	line: string
	line1: string
	city: string
	state: string
	postalCode: string
}

export enum CONTACT_POINT {
	PHONE = 'phone',
	EMAIL = 'email',
	FAX = 'fax',
}

export enum CONDITION_TYPE {
	DIABETES_TYPE = 'diabetes',
	BLOOD_PRESSURE = 'blood_pressure',
	THERMOMETER = 'thermometer',
}
export enum ZIP_INPUT {
	BACK_TO_AUTOFILL = 'Back to Autofill',
	PUT_MANUALLY = 'Add Manually',
}

export interface IPostalCode {
	id: string
	zipCode: string
	primaryCity: string
	state: string
	timezone: string
}

export interface ISlots {
	startTime: string
	endTime: string
}

export interface ISchedule<TSlots = ISlots> {
	date?: string
	slot: TSlots[]
}

// Object Interface for Items

export interface IItems<T> extends IBaseWithId {
	vital: string
	items: T[]
}

export enum DEVICE_TYPE {
	DEVICE_T = 'deviceT',
	DEVICE_BP = 'deviceBP',
	DEVICE_SC = 'deviceSC',
	DEVICE_GL = 'deviceGL',
	DEVICE_PO = 'devicePO', //cellular
	DEVICE_T_CELLULAR = 'deviceTCellular',
	DEVICE_BP_CELLULAR = 'deviceBPCellular',
	DEVICE_SC_CELLULAR = 'deviceSCellular',
	DEVICE_GL_CELLULAR = 'deviceGLCellular',
	DEVICE_PO_CELLULAR = 'devicePOCellular',
}

export interface IContactPoint extends IBaseWithId {
	text: string
	type: CONTACT_POINT
	device: DEVICE_TYPE
}

export interface IAssignTask extends IBaseWithId {
	partner: any
	assignTo: any
	conditions: any
	members: any
}

export interface IProviderContactPoint<TContactPoint = string, TProvider = string>
	extends IBaseWithId {
	contactPoint: TContactPoint
	provider: TProvider
}

//goals

export type ServicesType = 'ccm' | 'prm' | 'cde' | 'mht' | 'mh'
export type GadgetDeviceType = 'android' | 'ios'
export type GenderType = 'female' | 'male' | 'unknown'
export type RaceType =
	| 'White'
	| 'Black or African American'
	| 'Asian'
	| 'Native Hawaiian or Other Pacific Islander'
	| 'American Indian or Alaska Native'
export type VitalType = 'bloodPressure' | 'glucose' | 'weight' | 'temperature' | 'oxygenSaturation'
export type stateType =
	| 'chf'
	| 'diabetes'
	| 'stroke'
	| 'copd'
	| 'cardiovascular'
	| 'hypertension'
	| 'depression'
	| 'alzheimer/dementia'
	| 'ckd'
	| 'text'

export type MeasurementDeviceType = 'personal' | 'carehalo' | 'none'
export type HealthConditionsType =
	| 'chf'
	| 'diabetes'
	| 'stroke'
	| 'copd'
	| 'cardiovascular'
	| 'hypertension'
	| 'depression'
	| 'alzheimer/dementia'
	| 'ckd'
	| 'text'

export interface IPatient<
	TAssets = string,
	TAdress = string,
	THumanName = string,
	TPatientContactPoint = string,
	TClient = string,
> extends IBaseWithId {
	assets: TAssets[]
	address: TAdress
	humanName: THumanName
	patientContactPoints: TPatientContactPoint[]
	dateOfBirth: string
	healthCondition: string
	services: string
	programName?: string
	device: GadgetDeviceType
	deviceId: string | any
	deceased: boolean
	height?: number
	active: boolean
	gender: GenderType
	insuranceT: InsuranceType
	phone: string
	client: any
	patientDevices: IPatientDevice[]
	vitalAlert?: IVital
	measurement: string
	isChecked?: boolean
	phoneNumber: string
}

export interface IAsset extends IBaseWithId {
	type: 'image' | 'document'
	path: string
	location: string
	eTag: string
}

export interface IPatientAsset<TPatient = string, TAsset = string> extends IBaseWithId {
	patient: TPatient
	asset: TAsset
}

export interface IPatientContactPoint<TPatient = string, TContactPoint = string> {
	patient: TPatient
	contactPoint: TContactPoint
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFullPatient
	extends IPatient<
		IPatientAsset<string, IAsset>,
		IAddress,
		IHumanName,
		IPatientContactPoint<IPatient, IContactPoint>,
		IClient
	> {}

export type InsuranceType = 'medicare' | 'advantage' | 'medicaid' | 'private' | 'self-pay'

export interface IPatientDevice<TPatient = string> extends IBaseWithId {
	patient: TPatient
	type: DEVICE_TYPE
	providedBy: DeviceProvidedByType
	identifier: string
	simNumber: string
}

export type DeviceProvidedByType = 'personal' | 'carehalo' | 'none'

export enum CIRCUMSTANCES {
	FASTING = 'fasting',
	BEFORE_MEAL = 'beforeMeal',
	BEFORE_EXERCISES = 'beforeExercises',
	BEDTIME = 'bedtime',
}

export interface IPatientDevice<TPatient = string> extends IBaseWithId {
	patient: TPatient
	type: DEVICE_TYPE
	providedBy: DeviceProvidedByType
	identifier: string
	simNumber: string
}

export interface IVital<TPatient = string, TMeasurement = string, TVitalNote = string>
	extends IBaseWithId {
	deviceType: DEVICE_TYPE
	patient: TPatient
	isAlert: boolean
	isCleared: boolean
	isDeleted: boolean
	isManually: boolean
	takenAt: string
	measurements: TMeasurement[]
	type: VITAL_TYPE
	circumstances: CIRCUMSTANCES
	vitalNote: TVitalNote
}

export interface IFullVital
	extends IVital<IFullPatient, IMeasurement, IVitalNote<string, IFullJournalNote>> {
	patient: IFullPatient
	deletedAt: string
}

export interface IMeasurement<TVital = string> extends IBaseWithId {
	vital: TVital
	type: MEASUREMENT_TYPE
	value: number | null
	unitOfMeasurement: string // kg for example
}

export enum MEASUREMENT_TYPE {
	BODY_SCALE = 'bodyScale',
	BLOOD_PRESSURE_SYS = 'bloodPressureSYS', //
	BLOOD_PRESSURE_HB = 'bloodPressureHB', //
	BLOOD_PRESSURE_DIA = 'bloodPressureDIA', //
	BLOOD_GLUCOSE_BG = 'bloodGlucoseBG', //
	THERMOMETER_C = 'thermometerC', //
	PULSE_OXIMETER_O = 'pulseOximeterO', //
}

export interface IVitalReport {
	id: string
	deviceType: DEVICE_TYPE | null
	takenAt: string
	measurements: Partial<IMeasurement>[]
	type: VITAL_TYPE
}

export enum VITAL_TYPE {
	BLOOD_PRESSURE = 'blood Pressure',
	GLUCOSE = 'glucose',
	WEIGHT = 'weight',
	TEMPERATURE = 'temperature',
	OXYGEN_SATURATION = 'oxygenSaturation',
	empty = '',
}

export interface IHealthGoal {
	borderLineMin: number
	normalMinThreshold: number
	normalHight: number
	borderlineHight: number
	patient: string
	measurementType: MEASUREMENT_TYPE
	circumstances: CIRCUMSTANCES
}

export interface IVitalNote<TVital = string, TJournalNote = string> {
	vital: TVital
	journalNote: TJournalNote
}

export interface IJournalNote<TProvider = string, TPatient = string, TVitalNote = string>
	extends IBaseWithId {
	provider: TProvider
	patient: TPatient
	title: string
	details: string
	vitalNote: TVitalNote
}

export type IFullJournalNote = IJournalNote<IFullProvider, IFullPatient>

export interface IVitalNote<TVital = string, TJournalNote = string> {
	vital: TVital
	journalNote: TJournalNote
}

export interface INotification<TPatient = string> extends IBaseWithId {
	type: NOTIFICATION_TYPE
	body: string
	title: string
	patient: TPatient
}

export enum NOTIFICATION_TYPE {
	EXCEPT_MEASUREMENTS = 'exceptMeasurements',
	ABNORMAL_VITALS = 'abnormalVitals',
}

export type IFullNotification = INotification<Partial<IFullPatient>>
export interface ILocation<TAdress = string> extends IBaseWithId {
	address: TAdress
	phone: string
	isPrimary: boolean | null
	partner: string
}

// Program

export enum CHECK_IN_INTERVAL {
	DAY = 'daily',
	WEEK = 'weekly',
	MONTH = 'monthly',
}

export enum TRACKING_DEVICE {
	GLUCOMETER = 'glucometer',
	BLOOD_PRESSURE_CUFF = 'bloodPressure',
	WEIGHT_MACHINE = 'weightMachine',
}
export enum ALERT {
	YES = 'yes',
	NO = 'no',
}

export const ALERTS = {
	YES: true,
	NO: false,
}

export interface IVitals extends IBaseWithId {
	item: VITAL_TYPE
	checkInInterval: CHECK_IN_INTERVAL
	checkInFrequency: string
	minLimit: number
	maxLimit: number
	trackingDevice: TRACKING_DEVICE
	alert: ALERT
}

export interface IState {
	state: string
}

export interface TState<TState = IState> {
	state: TState[]
}

export interface IProviderPoc extends IBaseWithId {
	type: string | undefined
	text: string
}

export type IFullProviderPoc = IProviderPoc

// sessionCpt
export interface ISessionCpt extends IBaseWithId {
	cpt: any
	session: string
}

export type IFullSessionCpt = ISessionCpt

//program goals

export interface ICustomGoals extends IBaseWithId {
	category: string
	subCategory: string
	limitLessThan: string
	limitGreaterThan: string
	unit: string
	duration: string
}

export interface IDuration {
	hours: number
	minutes: number
	seconds: number
}

export interface ITask<IJournalNote = string, IProvider = string, IPatient = string>
	extends IBaseWithId {
	patient: IPatient
	note: IJournalNote
	title: string
	id: string
	task_type: string
	duration: IDuration
	provider: IProvider
}

export interface IFullTasks extends ITask<IFullJournalNote> {
	data: any
}

export interface IGlobalCodeSelect {
	globalCodesName: string
	slug: string
	id: string
}

export interface IGlobalCodesParams {
	clientId: string
	isEnabled: boolean
}

export interface IGlobalCodes {
	categoryCode: string
	globalCodes: IGlobalCodeSelect[] | []
}
