import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { matchPath, useNavigate, useParams } from 'react-router-dom'
import axiosLibrary, { AxiosResponse } from 'axios'
import axios from 'axios'
import { FormikHelpers } from 'formik'
import { getClientDetails, useMyProfile } from 'hooks/common'
import { APP_ROUTES } from 'const/router'
import { errorNotify, successNotify } from 'utils/toster'
import { commonMessages } from 'utils/messages'
import { ENDPOINTS } from 'const/endpoints'
import { scrollToError } from 'utils/common'
import { useUrlQuery } from 'hooks/url'
import { IFullRoleBase, IRoleBase } from './Role.interface'
import {
	assignALLModulesToProvider,
	createAssignServicesToProvider,
	createRole,
	getModuleForRole,
	getProviderDetailsFromRole,
	getRoleForId,
	modulesSpecificAttributes,
	statusToggle,
	updateALLProviderAccess,
	updateRole,
} from './Role.service'
import { RoleBaseInitialState, RoleBasePayload } from './Role.types'

interface IRoleQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IFullRoleBase>>>,
		Partial<IFullRoleBase> {
	patient?: string
	clientId?: string
}

export function useRole({ id, clientId, ...rest }: IRoleQueryProps = {}) {
	return useQuery<AxiosResponse<IFullRoleBase>, any, AxiosResponse<IFullRoleBase>>(
		[ENDPOINTS.ROLES, id, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getRoleForId(id, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}

export function useInitial() {
	const alreadyMounted = useRef(false)
	const [role] = useUrlQuery<any>()
	const params = useParams()
	const id = role?.id || (role?.isRoleBaseEdit && params?.id)

	const { data, isFetched } = useRole({
		id,
		clientId: getClientDetails()?.clientId,
		suspense: !alreadyMounted.current, //TO USE SUSPENSE ONCE ON MOUNT
	})

	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetched])

	return useMemo(() => {
		return new RoleBaseInitialState(id ? data?.data : {})
	}, [data?.data, id])
}

export function useSubmitHandler() {
	const params = useParams<{ id: string }>()
	let id = params.id

	const [role] = useUrlQuery<any>()
	const roleId = role?.id || id
	const { mutateAsync: create } = useAddRole({ id, clientId: getClientDetails()?.clientId })
	const navigate = useNavigate()
	const profile = useMyProfile()
	if (matchPath(APP_ROUTES.ME, location.pathname)) {
		id = profile.id
	}
	const { mutateAsync: update } = useUpdateRole({
		id,
		roleId,
		clientId: getClientDetails()?.clientId,
	})

	return useCallback(
		async (form: RoleBaseInitialState, formikHelpers: FormikHelpers<RoleBaseInitialState>) => {
			form.client = getClientDetails()?.clientId
			formikHelpers.setSubmitting(true)
			try {
				if (!roleId) {
					const createRoleResponse: any = await create(new RoleBasePayload(form))
					const createRoleId = createRoleResponse?.data?.id
					const clientId = createRoleResponse?.data?.client
					successNotify(commonMessages.createMessage)

					navigate(
						`${APP_ROUTES.ROLE_PROFILE}/${createRoleId}?client=${clientId}&providerService=${true}`,
					)
				}
				if (roleId) {
					await update(new RoleBasePayload(form))
					successNotify(commonMessages.updateMessage)
					// navigate(`${APP_ROUTES.ROLE_PROFILE}/${roleId}	`)
					navigate(
						`${APP_ROUTES.ROLE_PROFILE}/${roleId}?client=${
							getClientDetails()?.clientId
						}&providerService=${true}`,
					)
				}
			} catch (err: any) {
				formikHelpers.setErrors(err.response.data) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
				scrollToError()
				if (err?.response?.data?.message) {
					errorNotify(err.response.data.message)
				} else {
					errorNotify(commonMessages.errorHandlingMessage)
				}
			}
		},
		[create, id, navigate, update, roleId, getClientDetails()?.clientId],
	)
}

//* Global code status toggle hook
interface IUpdateStatusToggle {
	isActive?: true | false
}
interface IUpdateStatusToggleProps
	extends Partial<UseMutationOptions<AxiosResponse<IRoleBase>, any, IUpdateStatusToggle>>,
		IUpdateStatusToggle {}

export function useStatusUpdateToggle({ isActive, ...rest }: IUpdateStatusToggleProps = {}) {
	//@ts-ignore
	const CancelToken = axiosLibrary.CancelToken
	const source = CancelToken.source()
	return useMutation<AxiosResponse<IRoleBase>, any, any>(
		async (id: string) => await statusToggle(id, { isActive }, source),
		{
			...rest,
		},
	)
}

// Add Condition
interface IAddRoleProps
	extends Partial<
		UseMutationOptions<AxiosResponse<RoleBasePayload>, any, Partial<RoleBasePayload>>
	> {
	id?: string
	clientId?: string
}

export function useAddRole({ id, clientId, ...rest }: IAddRoleProps = {}) {
	return useMutation<AxiosResponse<RoleBasePayload>, any, Partial<RoleBasePayload>>(
		async (data) => await createRole(id, clientId, data),
		{
			...rest,
		},
	)
}

interface IUpdateRoleProps
	extends Partial<
		UseMutationOptions<AxiosResponse<RoleBasePayload>, any, Partial<RoleBasePayload>>
	> {
	id?: string
	roleId: string
	clientId: string
}

export function useUpdateRole({ id, roleId, clientId, ...rest }: IUpdateRoleProps) {
	return useMutation<AxiosResponse<RoleBasePayload>, any, Partial<RoleBasePayload>>(
		async (data) => await updateRole(id, roleId, clientId, data),
		{
			...rest,
		},
	)
}

export function useProviderDetailsFromRole({
	clientId,
	roleId,
	providerRoleId,
	isEnabled,
	...rest
}: any) {
	return useQuery<any, any, any>(
		[ENDPOINTS.GET_PROVIDER_DETAILS_FROM_ROLE, clientId, roleId, providerRoleId, isEnabled],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getProviderDetailsFromRole(clientId, { roleId, providerRoleId }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: isEnabled,
			suspense: false,
			...rest,
		},
	)
}

export function useCreateAssignServicesToProvider({ clientId, ...rest }: any = {}) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(
		async (data) => await createAssignServicesToProvider(clientId, data),

		{
			...rest,
		},
	)
}

export function useGetModuleForRole({
	providerService,
	clientId,
	role,
	providerRoleId,
	...rest
}: any) {
	return useQuery<any, any, any>(
		[ENDPOINTS.GET_MODULE_FOR_ROLE, providerService, clientId, role, providerRoleId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getModuleForRole(clientId, { providerService, role, providerRoleId }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!providerService,
			suspense: false,
			...rest,
		},
	)
}

export function useAssignALLModulesToProvider({ clientId, ...rest }: any = {}) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(
		async (data) => await assignALLModulesToProvider(clientId, data),
		{
			...rest,
		},
	)
}

// Modules Attribute
// nodes/assignPermissionsToRole

export function useSpecificModulesAttribute({ clientId, ...rest }: any = {}) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(
		async (data) => await modulesSpecificAttributes(clientId, data),
		{
			...rest,
		},
	)
}

export function useupdateALLProviderAccess({ ...rest }: any) {
	return useMutation<any, any, any>(async (data) => await updateALLProviderAccess(data), {
		...rest,
	})
}
