export enum MODULE_NAME {
	PARTNERS = 'Partners',
	FEE_SCHEDULES = 'Fee Schedule',
	REFERRALS = 'Referrals',
	MEMBER = 'Members',
	MEMBERS = 'Members',
	PROGRAMS = 'Programs',
	PROVIDERS = 'Providers',
	CALENDAR = 'Calendar',
	CHATS = 'Chats',
	CLIENTS = 'Clients',
	REPORT = 'Report',
	SUMMARY_REPORT = 'Summary Report',
	AUDITS = 'Audits',
	DASHBOARD = 'Dashboard',
	ADMIN_DASHBOARD = 'Admin Dashboard',
	PM_DASHBOARD = 'PM Dashboard',
	EPI_DASHBOARD = 'EPI Dashboard',
	EPI_MEMBERS = 'EPI Members',
	EPI_PARTNERS = 'EPI Partners',
	ROLES = 'Roles',
	SETTINGS = 'Settings',
	NOTIFICATIONS = 'Notifications',
	MY_PROFILE = 'My Profile',
	GROUPS = 'Groups',
	SCREENING_TEMPLATE = 'Screening Template',
	SCREENING = 'Screening',
	// MESSAGES = 'Messages',
	// CHATS = 'sms-message',
	MESSAGES = 'Chats',
	SESSIONS = 'Session Library',
	SCREENING_LIBRARY = 'Screening Library',
	TASK_MANAGEMENT = 'Task Management',
	TASK_MANAGEMENT_DASHBOARD = 'Task Management Dashboard',
	TASK_REPORT = 'Task Report',
	COMMUNITY_BASED_PARTNER = 'Community Based Members',
	SMBP_REPORTS = 'SMBP Reports',
	SMBP_MEMBERS = 'SMBP Members',
}

//  Module Slug Name
export enum MODULE_SLUG_NAME {
	MESSAGES = 'chats',
	NOTIFICATIONS = 'notifications',
}

//  Module Routes
export enum MODULE_PATH {
	SETTINGS = 'settings',
	CALENDAR = 'calendar',
}

//  currently not used
export const moduelRoutes: any = {
	providers: '/providers',
	members: '/members',
	partners: '/partners',
	fee_schedule: '/fee-schedules',
	referrals: '/referrals',
	programs: '/programs',
	calendar: '/calendar',
	chats: '/chats',
	clients: '/clients',
	report: '/report',
	summary_report: '/summary-report',
	audits: '/audits',
	dashboard: '/dashboard',
	roles: '/roles',
	settings: '/settings',
}
