export enum APP_ROUTES {
	SIGN_IN = '/sign-in',
	EMAIL_VERIFICATION = '/email-verification',
	FORGOT_PASSWORD = '/forgot-password',
	AUDITS = '/audits',
	FEESCHEDULES = '/fee-schedules',
	CLIENTS = '/clients',
	CLIENT = '/client',
	PROVIDERS = '/providers',
	PROVIDER = '/provider',
	PATIENTS = '/patients',
	PATIENT = '/patient',
	MEMBERS = '/members',
	MEMBER = '/member',
	PM_MEMBERS = 'pm-members',
	NONHEALTHCAREMEMBERS = 'smbp-members',
	NONHEALTHCARE_MEMBER_REPORTS = 'smbp_reports',
	//EPIDEMIOLOGIST ROLES
	EPIDEMIOLOGISTMEMBERS = '/epidemiologist-members',
	EPIDEMIOLOGIST_REPORT_ALL_MEMBERS = '/report-view-member',
	EPIDEMIOLOGISTPARTNERS = '/epidemiologist-partners',
	MEMBER_PROFILE = '/member-profile',
	ROLE_PROFILE = '/role-profile',
	NONHEALTHCARE_MEMBER_PROFILE = '/smbp_memberProfile',
	PROGRAMS = '/programs',
	PROGRAM = '/program',
	PROGRAM_PROFILE = '/program-profile',
	SESSIONS = '/sessions',
	ACTION = '/action',
	CHANGE_PASSWORD = '/change-password',
	ME = '/me',
	VITAL = '/vital',
	VITALS = '/vitals',
	PROFILE = '/profile',
	JOURNAL_NOTE = '/journal-note',
	NOTIFICATIONS = '/notifications',
	SUMMARY_REPORT = '/summary-report',
	ADMIN_PORTAL = '/chats',
	PARTNER = '/partner',
	SCHEDULE = '/schedule',
	//Template Information
	TEMPLATE_VIEW = '/template-view',
	TEMPLATE_CLIENT_VIEW = '/template-client-view',
	//Questionnaire
	QUESTIONNAIRE = '/question',
	QUESTIONNAIRE_LIBRARY = '/screening-library',
	CALENDAR = '/calendar',
	MEMBER_ASSESSMENT = 'assessment',
	GROUPS = '/groups',
	MESSAGES = '/messages',
	MESSAGES_MEMBER = '/messages-members',
	PROVIDER_PROFILE = '/provider-profile',
	FEESCHEDULE = '/fee-schedule',
	PROGRAMMANAGERDASHBOARD = '/dashboard',
	SMBPDASHBORAD = 'smbp_dashboard',
	EPIDEMIOLOGISTDASHBOARD = '/dashboard-epidemiologist',
	PROVIDERDASHBOARD = '/dashboard-provider',
	EPIDEMIOLOGISTREPORT = '/report',
	ADMINDASHBOARD = '/dashboard-admin',
	REFERRALS = '/referrals',
	CLAIM_NOW = '/claims',
	NOTIFICATION = '/notifications',
	TEMPLATES = '/templates',
	/**
	 * * Partner's module APP_ROUTES
	 */
	PARTNERS = '/partners',
	PARTNER_PROFILE = '/partner-profile',
	/**
		 Settings
	 */
	SETTINGS = '/settings',
	GLOBAL_SPECIALITIES = '/settings/global-specialities',
	GLOBAL_CODES_CATEGORY = '/settings/global-codes-category',
	GLOBAL_CODES = '/settings/global-codes',
	GLOBAL_CONDITIONS = '/settings/global-conditions',
	MODULES = '/settings/modules',
	GLOBAL_PROGRAMS = '/settings/global-programs',
	GLOBAL_SESSIONS = '/settings/globalSessions',
	ROLES = '/roles',
	CLIENT_SERVICE = '/client-service',
	SERVICES = '/',
	MY_PROFILE = 'myProfile',
	GLOBAL_PROGRAM_PROFILE = '/global-program-profile',
	// GROUP_MESSAGES = 'GroupMessages',
	TASK_MANAGEMENT = '/task-management',
	TASK_REPORT = '/task-report',
	TASK_LISTING = '/task-management/task-listing',
	TASK_LIBRARY = '/task-management/task-library',
	TASK_FOLLOWUPS_LISTING = '/task-management/task-follow-ups-listing',
	TASK_DETAIL = '/task-management/task-detail',
	TASK_MANAGEMENT_DASHBOARD = '/dashboard-task-management',
	TASK_MANAGEMENT_CALL_DETAILS = '/dashboard-task-management/call-details',
	TASK_MANAGEMENT_CALL_LISTING = '/dashboard-task-management/call-listing',
	TASK_MANAGEMENT_FOLLOWUPS_LISTING = '/dashboard-task-management/task-follow-ups-listing',
	TASK_MANAGEMENT_LISTING = '/dashboard-task-management/task-listing',
	TASK_MANAGEMENT_DETAIL = '/dashboard-task-management/task-detail',
	TASK_TRACK_RECORDING_LISTING = '/task-tracking',
	CALENDAR_SCHEDULE = 'calendar/calendar-schedule',
	// FollowUpsListing
	GROUP_MESSAGES = 'GroupMessages',
	MEMBER_FILE_UPLOAD = 'file-upload',
}
//http://localhost:3000/change-password?oobCode=IjNljfi0wqmGi1wqXVpnA1YDZVBIh25k5_cbFlr_tqgAAAGCxbdzIQ
// global-program-profile/:id
