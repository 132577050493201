import TableSortHeader from 'components/TableSortHeader'
import { ALERT, CHECK_IN_INTERVAL, TRACKING_DEVICE_VITALS } from 'const/auth'

export const VitalColumns = [
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Items</TableSortHeader>,
		accessor: 'col1', // accessor is the "key" in the data
		name: 'item',
		sortable: true,
	},

	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Check-In Interval</TableSortHeader>,
		accessor: 'col2',
		name: 'checkInInterval',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Tracking Device</TableSortHeader>,
		accessor: 'col3',
		name: 'trackingDevices',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Alert</TableSortHeader>,
		accessor: 'col4',
		name: 'alerts',
		sortable: true,
	},

	{
		Header: 'Actions',
		accessor: 'col5',
		name: 'actions',
	},
]

export enum FIELD_NAMES {
	ITEMS = 'item',
	CHECK_IN_INTERVAL = 'checkInInterval',
	CHECK_IN_INTERVAL_ID = 'checkInIntervalId',

	TRACKING_DEVICE = 'trackingDevices',
	CHECK_IN_FREQUENCY = 'checkInFrequency',
	MAX_LIMIT = 'maxLimit',
	MIN_LIMIT = 'minLimit',
	ALERTS = 'alerts',
	DEVICES = 'devices',
	PULSERATE = 'otherParam',
	READING_TYPE = 'readingType',
}

export const checkIntervalOptions: {
	label: string
	value: CHECK_IN_INTERVAL
}[] = [
	{ label: 'Daily', value: CHECK_IN_INTERVAL.DAY },
	{ label: 'Weekly', value: CHECK_IN_INTERVAL.WEEK },
	{ label: 'Monthly', value: CHECK_IN_INTERVAL.MONTH },
]

export const TrackingDevicesOptions: {
	label: string
	value: TRACKING_DEVICE_VITALS
}[] = [
	{
		label: 'Glucometer',
		value: TRACKING_DEVICE_VITALS.GLUCOMETER_250,
	},
	{ label: 'Blood Pressure', value: TRACKING_DEVICE_VITALS.BLOOD_PRESSURE_CUFF },
	{ label: 'Weight Machine', value: TRACKING_DEVICE_VITALS.WEIGHTING_MACHINE },
]

export const AlertOptions: {
	label: string
	value: ALERT
}[] = [
	{
		label: 'Yes',
		value: ALERT.YES,
	},
	{ label: 'No', value: ALERT.NO },
]

export enum VITAL_LABEL {
	BLOODPRESSURE = 'Blood Pressure',
	BLOODPRESSURECAPITAL = 'BLOOD PRESSURE',
}

export enum PROGRAM_VITALS_FILTER_FIELDS {
	SEARCH = 'Search',
}
