import { useEffect } from 'react'
import { useGetActivityLogDetails } from 'hooks/customHooks'
import activityLogger from './ActivityLogger'

export const useActivityLogger = (error: any) => {
	const { screenName, clientName, tokenUserId } = useGetActivityLogDetails()

	const domainName = window.location.hostname
	const filteredError = error?.response as any
	if (filteredError) {
		delete filteredError.headers
		delete filteredError.config?.headers
	}

	const serializedError =
		JSON.stringify(error?.stack as Error) || JSON.stringify(filteredError as Error)

	const objectDetails = {
		action: error?.name,
		error: serializedError,
		screen: screenName,
		domainName,
		clientName,
		tokenUserId,
	}

	useEffect(() => {
		if (error) {
			activityLogger.log(objectDetails)
		}
	}, [error]) // Dependency array ensures this effect runs when 'error' changes

	return {
		onCallActivityLoggerFunction: () => activityLogger.log(objectDetails),
	}
}
