import TableSortHeader from 'components/TableSortHeader'
/**
 * *Table column name
 */
export const clientsColumns = [
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Company Name</TableSortHeader>,
		accessor: 'col1', // accessor is the "key" in the data
		name: 'title',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>State</TableSortHeader>,
		accessor: 'col2',
		name: 'state',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>City</TableSortHeader>,
		accessor: 'col3',
		name: 'city',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Name</TableSortHeader>,
		accessor: 'col4',
		name: 'name',
		sortable: true,
	},

	{
		Header: 'Assign Modules',
		accessor: 'col5',
		name: 'assignModules',
	},
	{
		Header: 'Actions',
		accessor: 'col6',
		name: 'actions',
	},
]

/**
 * * Field Name Section
 */

export const clientFormFieldDetails = [
	{
		sectionName: 'General information',
		formFieldData: [
			{
				name: 'title',
				label: 'Company Name',
				placeholder: 'Your text',
				type: 'text',
				className: 'w-1/3 p-2',
				require: true,
				autoComplete: 'off',
				fieldType: 'inputField',
				validation: [
					{
						type: 'required',
						message: 'Company is required new',
					},
				],
			},
			{
				name: 'line',
				label: 'Address',
				placeholder: 'Your text',
				type: 'text',
				// className: 'w-1/2 p-2',
				className: 'w-1/3 p-2',
				require: true,
				autoComplete: 'off',
				fieldType: 'inputField',
				validation: [
					{
						type: 'required',
						message: 'Address fee is required new',
					},
				],
			},
			{
				name: 'postalCode',
				label: 'Zip',
				placeholder: 'Your text',
				className: 'w-1/3 p-2',
				// className: 'w-full p-2',
				require: true,
				autoComplete: 'off',
				fieldType: 'inputField',
				validation: [
					{
						type: 'required',
						message: 'Zip is required new',
					},
				],
			},
			// {
			// 	name: 'city',
			// 	label: 'City',
			// 	placeholder: 'City',
			// 	// className: 'w-1/2 p-2',
			// 	className: 'w-1/3 p-2',
			// 	require: false,
			// 	autoComplete: 'off',
			// 	fieldType: 'inputField',
			// 	validation: [
			// 		{
			// 			type: 'required',
			// 			message: 'City is required new',
			// 		},
			// 	],
			// },
			// {
			// 	name: 'state',
			// 	label: 'State',
			// 	placeholder: 'State',
			// 	// className: 'w-1/2 p-2',
			// 	className: 'w-1/3 p-2',
			// 	require: false,
			// 	autoComplete: 'off',
			// 	fieldType: 'selectDropdown',
			// 	validation: [
			// 		{
			// 			type: 'required',
			// 			message: 'State is required new',
			// 		},
			// 	],
			// },
			// {
			// 	name: 'Add Manually',
			// 	label: '',
			// 	placeholder: '',
			// 	className: 'w-1/3 p-2',
			// 	require: false,
			// 	autoComplete: 'off',
			// 	fieldType: 'button',
			// 	buttonType: 'button',
			// 	type: 'primary',
			// 	validation: [],
			// 	subFields: [
			// 		{
			// 			name: 'city',
			// 			label: 'City',
			// 			placeholder: 'City',
			// 			className: 'w-1/3 p-2',
			// 			require: false,
			// 			autoComplete: 'off',
			// 			fieldType: 'inputField',
			// 			validation: [
			// 				{
			// 					type: 'required',
			// 					message: 'City is required new',
			// 				},
			// 			],
			// 		},
			// 		{
			// 			name: 'state',
			// 			label: 'State',
			// 			placeholder: 'State',
			// 			className: 'w-1/3 p-2',
			// 			require: false,
			// 			autoComplete: 'off',
			// 			fieldType: 'selectDropdown',
			// 			validation: [
			// 				{
			// 					type: 'required',
			// 					message: 'State is required new',
			// 				},
			// 			],
			// 		},
			// 	],
			// },
			{
				name: 'Add Manually',
				label: '',
				placeholder: '',
				className: 'w-1/3 p-2',
				require: false,
				autoComplete: 'off',
				fieldType: 'button',
				buttonType: 'button',
				type: 'primary',
				slug: 'addManually',
				isToShowInStart: false,
				validation: [],
				subFields: [
					{
						name: 'city',
						slug: 'city',
						label: 'City',
						placeholder: 'City',
						className: 'w-1/3 p-2',
						require: false,
						autoComplete: 'off',
						fieldType: 'inputField',
						validation: [
							{
								type: 'required',
								message: 'City is required new',
							},
						],
					},
					{
						name: 'state',
						slug: 'state',
						label: 'State',
						placeholder: 'State',
						className: 'w-1/3 p-2',
						require: false,
						autoComplete: 'off',
						fieldType: 'selectDropdown',
						validation: [
							{
								type: 'required',
								message: 'State is required new',
							},
						],
					},
				],
			},
			{
				name: 'timezone',
				label: 'timezone',
				placeholder: 'Select',
				className: 'w-1/3 p-2',
				require: false,
				autoComplete: 'off',
				fieldType: 'timezoneSelectDropdown',
				validation: [
					{
						type: 'required',
						message: 'timezone is required new',
					},
				],
			},
		],
		buttonDetails: [],
	},
	{
		sectionName: 'CONTACT INFORMATION',
		formFieldData: [
			{
				name: 'Add Contact',
				slug: 'addContact',
				label: 'Company Name',
				placeholder: 'Your text',
				type: 'text',
				className: 'w-1/4 p-2',
				require: true,
				autoComplete: 'off',
				fieldType: 'button',
				validation: [],
				subFields: [
					{
						name: 'first',
						label: 'First Name',
						slug: 'firstName',
						placeholder: 'Your text',
						type: 'text',
						className: 'w-1/4 p-2',
						require: true,
						autoComplete: 'off',
						fieldType: 'inputField',
						validation: [
							{
								type: 'required',
								message: 'contacts fee is required new',
							},
						],
						subFields: [],
					},
					{
						name: 'Last',
						label: 'Last Name',
						slug: 'lastName',
						placeholder: 'Your text',
						type: 'text',
						className: 'w-1/4 p-2',
						require: true,
						autoComplete: 'off',
						fieldType: 'inputField',
						validation: [
							{
								type: 'required',
								message: 'Last fee is required new',
							},
						],
						subFields: [],
					},
					{
						name: 'title',
						label: 'Title',
						slug: 'title',
						placeholder: 'Title',
						type: 'text',
						className: 'w-1/4 p-2',
						require: true,
						autoComplete: 'off',
						fieldType: 'inputField',
						validation: [
							{
								type: 'required',
								message: 'Title fee is required new',
							},
						],
						subFields: [],
					},
					{
						name: 'Remove',
						label: '',
						slug: 'removeButton',
						placeholder: '',
						type: 'button',
						className: 'w-1/4 p-2',
						require: true,
						autoComplete: 'off',
						fieldType: 'button',
						validation: [
							{
								type: 'required',
								message: 'Title fee is required new',
							},
						],
						subFields: [],
					},
					{
						name: 'Contact Type',
						label: '',
						placeholder: '',
						slug: 'contactType',
						type: '',
						className: 'w-full p-2',
						require: true,
						autoComplete: 'off',
						fieldType: 'Contact Type',
						validation: [],
						subFields: [
							{
								name: 'Contact point type ',
								label: 'Contact type ',
								slug: 'selectContactDropdown',
								placeholder: 'Select',
								className: 'w-1/3 p-2',
								require: false,
								autoComplete: 'off',
								fieldType: 'selectDropdown',
								validation: [
									{
										type: 'required',
										message: 'Contact point type is required new',
									},
								],
							},
							{
								name: 'Contact Type ',
								label: ' ',
								slug: 'inputContactField',
								placeholder: 'Contact Type',
								className: 'w-1/3 p-2',
								require: false,
								autoComplete: 'off',
								fieldType: 'inputField',
								validation: [
									{
										type: 'required',
										message: 'Contact point type is required new',
									},
								],
							},
							{
								name: 'DeleteIcon',
								label: ' ',
								placeholder: '',
								slug: 'deleteIcon',
								className: 'w-1/4 p-2',
								require: false,
								autoComplete: 'off',
								fieldType: 'icon',
								validation: [],
							},
							{
								name: 'AddIcon',
								label: ' ',
								slug: 'addIcon',
								placeholder: '',
								className: 'w-1/4 p-2',
								require: false,
								autoComplete: 'off',
								fieldType: 'icon',
								validation: [],
							},
							// {
							// 	name: 'name',
							// 	label: 'Name',
							// 	placeholder: 'Name',
							// 	type: 'text',
							// 	require: true,
							// 	className: 'w-1/2 p-2',
							// 	// className: 'w-1/5 p-2',
							// 	autoComplete: 'off',
							// 	validation: [
							// 		{
							// 			type: 'required',
							// 			message: 'Name is required new',
							// 		},
							// 	],
							// 	fieldType: 'inputField',
							// },
						],
					},
				],
			},
			{
				name: 'active',
				label: 'Status',
				slug: 'status',
				placeholder: '',
				options: [],
				className: 'w-full p-2',
				require: false,
				autoComplete: 'off',
				fieldType: 'radioButton',
				validation: [],
			},
			{
				name: 'isWisewoman',
				label: 'isWisewoman',
				slug: 'isWisewoman',
				placeholder: '',
				options: [],
				// className: 'w-1/2 p-2',
				className: 'w-full p-2',
				require: false,
				autoComplete: 'off',
				fieldType: 'radioButton',
				validation: [],
			},
		],
		buttonDetails: [
			{
				type: 'default',
				buttonType: 'button',
				className: 'mt-2',
				name: 'Cancel',
			},
			{
				type: 'primary',
				buttonType: 'submit',
				className: 'mt-2',
				name: 'Create',
			},
		],
	},
]

export enum FIELD_NAMES {
	TITLE = 'title',
	LINE = 'line',
	POSTAL_CODE = 'postalCode',
	CITY = 'city',
	STATE = 'state',
	CONTACTS = 'contacts',
	ACTIVE = 'active',
	COUNTRY_CODE = 'countryCode',
	TIMEZONE = 'timezone',
	LINE1 = 'line1',
}

//  Form fields details
export enum CLIENT_FORM_FIELDS {
	COMPANY_NAME = 'companyName',
	ADDRESS = 'address',
	ZIP_CODE = 'zipCode',
	ADD_MANUALLY = 'addManually',
	CITY = 'city',
	STATE = 'state',
	CONTACTS = 'contacts',
	ACTIVE = 'active',
	COUNTRY_CODE = 'countryCode',
	IS_WISE_WOMAN = 'isWisewoman',
	TIMEZONE = 'timezone',
}
export enum CLIENT_FILTER_FIELDS {
	SEARCH = 'Search',
}

export const tableFormSection = {
	deletedAt: null,
	id: '274fb70c-e4d2-4a01-b558-e5efed8f76c0',
	isActive: true,
	providerSection: {
		deletedAt: null,
		id: '20897adc-e053-40fe-8beb-c961e027fb62',
		isPrimary: false,
		isActive: true,
		isAssigned: true,
		clientSection: {
			deletedAt: null,
			id: '6583c05c-9a17-4d29-beff-f4912f2fe3ec',
			isActive: true,
			slug: 'grid',
			isAssigned: true,
			clientSectionName: 'Grid View',
			order: 3,
			sections: {
				deletedAt: null,
				id: 'fc710365-8de8-4a4a-a03d-293d3cc82569',
				sectionName: 'Grid View',
				order: 3,
				slug: 'grid',
				isActive: true,
			},
		},
		providerSectionChildren: [],
		providerColumns: [
			{
				deletedAt: null,
				id: '7673d760-11d7-4de3-b8e2-a08ecba5b20e',
				isActive: true,
				isAssigned: true,
				clientColumn: {
					deletedAt: null,
					id: '24e1030e-81fa-4f1c-8bef-8379b5644eb7',
					isActive: true,
					isAssigned: true,
					isRequired: false,
					clientColumnName: null,
					clientColumnDisplayName: 'Company Name',
					clientColumnLabel: 'Company Name',
					order: 1,
					clientColumnPlaceholder: 'Company Name',
					column: {
						deletedAt: null,
						id: '9882b2ac-e99f-4e7e-bd36-887ace658be1',
						name: 'title',
						displayName: 'Company Name',
						label: 'Company Name',
						placeholder: 'Company Name',
						className: '',
						autoComplete: 'false',
						isRequired: 'false',
						endpoint: '',
						isActive: true,
						isSortable: true,
						order: 1,
					},
				},
			},
			{
				deletedAt: null,
				id: '34dd5fbd-f49c-4afc-8e5f-7ac56e43b92a',
				isActive: true,
				isAssigned: true,
				clientColumn: {
					deletedAt: null,
					id: '61992117-454a-4841-8abd-279b1c8e6c20',
					isActive: true,
					isAssigned: true,
					isRequired: false,
					clientColumnName: null,
					clientColumnDisplayName: 'State',
					clientColumnLabel: 'State',
					order: 2,
					clientColumnPlaceholder: 'State',
					column: {
						deletedAt: null,
						id: 'c71455e6-e1bf-4b28-ae8d-5cc568d989b3',
						name: 'state',
						displayName: 'State',
						label: 'State',
						placeholder: 'State',
						className: '',
						autoComplete: 'false',
						isRequired: 'false',
						endpoint: '',
						isActive: true,
						isSortable: true,
						order: 2,
					},
				},
			},
			{
				deletedAt: null,
				id: '37a4b6aa-a3ad-4dc9-b120-b8a1e37d847a',
				isActive: true,
				isAssigned: true,
				clientColumn: {
					deletedAt: null,
					id: 'a765e059-51e5-4ce4-89ea-52dab73112df',
					isActive: true,
					isAssigned: true,
					isRequired: false,
					clientColumnName: null,
					clientColumnDisplayName: 'City',
					clientColumnLabel: 'City',
					order: 3,
					clientColumnPlaceholder: 'City',
					column: {
						deletedAt: null,
						id: 'f6e63c91-022f-4c22-8513-5c3f63692720',
						name: 'city',
						displayName: 'City',
						label: 'City',
						placeholder: 'City',
						className: '',
						autoComplete: 'false',
						isRequired: 'false',
						endpoint: '',
						isActive: true,
						isSortable: true,
						order: 3,
					},
				},
			},
			{
				deletedAt: null,
				id: '8b1d5287-256d-43e8-abc2-1b71d7f2c228',
				isActive: true,
				isAssigned: true,
				clientColumn: {
					deletedAt: null,
					id: '53f35ede-2a12-40a3-a4a8-5b349e2a1c59',
					isActive: true,
					isAssigned: true,
					isRequired: false,
					clientColumnName: null,
					clientColumnDisplayName: 'Name',
					clientColumnLabel: 'Name',
					order: 4,
					clientColumnPlaceholder: 'Name',
					column: {
						deletedAt: null,
						id: '47f4d2e4-4cff-4fc4-93e4-1356d2c5e819',
						name: 'name',
						displayName: 'Name',
						label: 'Name',
						placeholder: 'Name',
						className: '',
						autoComplete: 'false',
						isRequired: 'false',
						endpoint: '',
						isActive: true,
						isSortable: true,
						order: 4,
					},
				},
			},
			{
				deletedAt: null,
				id: '3890e29b-1ec9-4298-b418-fd7a016495f9',
				isActive: true,
				isAssigned: true,
				clientColumn: {
					deletedAt: null,
					id: '01836a7e-7a86-4de3-8e8f-208e86951500',
					isActive: true,
					isAssigned: true,
					isRequired: false,
					clientColumnName: null,
					clientColumnDisplayName: 'Actions',
					clientColumnLabel: 'Actions',
					order: 5,
					clientColumnPlaceholder: 'Actions',
					column: {
						deletedAt: null,
						id: 'a9731b71-4a39-45cb-a9c4-91d2353e54c9',
						name: 'Actions',
						displayName: 'Actions',
						label: 'Actions',
						placeholder: 'Actions',
						className: '',
						autoComplete: 'false',
						isRequired: 'false',
						endpoint: '',
						isActive: true,
						isSortable: false,
						order: 5,
					},
				},
			},
		],
		providerForms: [],
	},
}
