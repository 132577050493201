import icAwv from 'assets/images/ic-awv.svg'
import icCcm from 'assets/images/ic-ccm.svg'
import icDashboard from 'assets/images/ic-dashboard.svg'
import icMtm from 'assets/images/ic-mtm.svg'
import icRpm from 'assets/images/ic-rpm.svg'
import icWellness from 'assets/images/ic-wellness.svg'
import { IService } from './Services.types'

export const services: IService[] = [
	{
		serviceName: 'DASHBOARD',
		isActive: false,
		image: icDashboard,
	},
	{
		serviceName: 'AWV',
		isActive: false,
		image: icAwv,
	},
	{
		serviceName: 'CCM',
		isActive: false,
		image: icCcm,
	},
	{
		serviceName: 'MTM',
		isActive: false,
		image: icMtm,
	},
	{
		serviceName: 'WELLNESS',
		isActive: false,
		image: icWellness,
	},
	{
		serviceName: 'PUBLIC HEALTH',
		// serviceName: 'Public Health',
		isActive: true,
		image: icRpm,
	},
]

export const divServiceClassName =
	'md:w-[200px] w-[100%] md:h-[200px] h-[60px] rounded-lg shadow-[0_1px_10px_0_rgba(0,0,0,0.11)] bg-white flex flex-row md:flex-col items-center inactive-item'
