import classnames from 'classnames'

interface IInputWrapperProps {
	isError: boolean
	children: React.ReactNode
	className?: string
	tabIndex?: number
	noAnimation?: boolean
	shadow?: boolean
}

export function ColorBorderWrapper({
	isError,
	children,
	noAnimation = false,
	className,
	shadow = false,
	...rest
}: IInputWrapperProps) {
	return (
		<div
			className={classnames(
				'rounded-md select-country',
				{
					'shadow-input': shadow,
					'bg-alert': isError,
					'': !isError,
					'input-bg': !noAnimation,
				},
				className,
			)}
			{...rest}
		>
			{children}
		</div>
	)
}
