import TableSortHeader from 'components/TableSortHeader'

export const membersColumns = [
	{
		Header: 'Participant ID',
		accessor: 'col1',
		name: 'participantId',
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Name</TableSortHeader>,
		accessor: 'col2',
		name: 'patient',
		sortable: true,
	},
	{
		Header: 'Address',
		name: 'address',
		accessor: 'col3',
	},
	{
		Header: 'Phone',
		name: 'phone',
		accessor: 'col4',
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Date of Birth</TableSortHeader>,
		accessor: 'col5',
		name: 'dateOfBirth',
		sortable: true,
	},

	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>City</TableSortHeader>,
		accessor: 'col6',
		name: 'city',
		sortable: true,
	},
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>State</TableSortHeader>,
		accessor: 'col7',
		name: 'state',
		sortable: true,
	},
]
import { ALERT, CHECK_IN_INTERVAL, TRACKING_DEVICE_VITALS } from 'const/auth'

export enum FIELD_NAMES {
	ITEMS = 'item',
	CHECK_IN_INTERVAL = 'checkInInterval',
	TRACKING_DEVICE = 'trackingDevices',
	CHECK_IN_FREQUENCY = 'checkInFrequency',
	MAX_LIMIT = 'maxLimit',
	MIN_LIMIT = 'minLimit',
	ALERTS = 'alerts',
	DEVICES = 'devices',
}

export const checkIntervalOptions: {
	label: string
	value: CHECK_IN_INTERVAL
}[] = [
	{ label: 'Daily', value: CHECK_IN_INTERVAL.DAY },
	{ label: 'Weekly', value: CHECK_IN_INTERVAL.WEEK },
	{ label: 'Monthly', value: CHECK_IN_INTERVAL.MONTH },
]

export const TrackingDevicesOptions: {
	label: string
	value: TRACKING_DEVICE_VITALS
}[] = [
	{
		label: 'Glucometer',
		value: TRACKING_DEVICE_VITALS.GLUCOMETER_250,
	},
	{ label: 'Blood Pressure', value: TRACKING_DEVICE_VITALS.BLOOD_PRESSURE_CUFF },
	{ label: 'Weight Machine', value: TRACKING_DEVICE_VITALS.WEIGHTING_MACHINE },
]

export const AlertOptions: {
	label: string
	value: ALERT
}[] = [
	{
		label: 'Yes',
		value: ALERT.YES,
	},
	{ label: 'No', value: ALERT.NO },
]
