export enum FIELD_NAMES {
	SELECT_TASK = 'selectedTask',
	TASK_MODE = 'taskMode',
	TASK_CATEGORY = 'taskCategory',
	ADD_PROGRAM = 'program',
	TASK_TYPE = 'taskType',
	TASK_NAME = 'name',
	START_DATE = 'startDate',
	END_DATE = 'endDate',
	TASK_PRIORITY = 'priority',
	IS_BILLABLE = 'isBillable',
	TASK_CPT = 'taskCpt',
	FREQUENCY = 'frequency',
	PERIOD = 'period',
	PERIOD_ID = 'periodId',

	DESCRIPTION = 'description',
	PARTNER = 'partner',
	CONDITIONS = 'conditions',
	ASSIGN_TYPE = 'assignableFor',
	ASSIGNEE = 'assignee',
	ASSIGN_TO = 'assignTo',
	MEMBERS_LIST = 'membersList;',
}

export enum TASK_CATEGORIES {
	OPERATIONAL = 'Operation',
	CLINICAL = 'Clinical',
	NON_CLINICAL = 'Non Clinical',
}

export enum TASK_PAID {
	BILLABLE = 'billable',
	NON_BILLABLE = 'nonBillable',
}

export enum TASK_MODE {
	SINGLE = 'Single Mode',
	MULTIPLE = 'Multiple Mode',
}

export enum TASK_ASSIGNABLE_FOR {
	PROVIDER = 'provider',
	CARETEAM = 'careTeam',
}

export enum STEPPER_TITLE {
	TASK_MODE = 'Choose Task Mode',
	CREATE_TASK = 'Create Task',
	IS_BILLABLE = 'Billable Category',
	ASSIGN_TASK = 'Assign Task',
}

export const TASK_IS_BILLABLE = [
	{ value: 'nonBillable', label: 'Non-Billable' },
	{ value: 'billable', label: 'Billable' },
]

export const TASK_IS_BILLABLE_FILTER = [
	{ value: 'false', label: 'Non-Billable' },
	{ value: 'true', label: 'Billable' },
]

export const TASK_ASSIGN = [
	{ value: 'provider', label: 'Provider' },
	{ value: 'careTeam', label: 'Care Team' },
]

export const TASK_PRIORITIES = [
	{ value: 'high', label: 'High' },
	{ value: 'medium', label: 'Medium' },
	{ value: 'low', label: 'Low' },
]

export enum COMMON_FIELD_NAMES {
	CARETEAM = 'careTeam',
}
