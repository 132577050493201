import { toast } from 'react-toastify'

export const successNotify = (text: string) => {
	toast(text, {
		className: 'toaster-wrapper toaster-wrapper_success',
		bodyClassName: 'toaster-wrapper__content',
	})
}

export const errorNotify = (text: string) => {
	toast(text, {
		className: 'toaster-wrapper toaster-wrapper_error',
		bodyClassName: 'toaster-wrapper__content',
	})
}

export const warningNotify = (text: string) => {
	toast.warn(text, {
		className: 'toaster-wrapper toaster-wrapper_warning bg-amber-400',
		bodyClassName: 'toaster-wrapper__content text-black',
		theme: 'colored',
	})
}
