import { IFullGlobalCodeCategory } from './GlobalCodeCategory.interface'

export class GlobalCodeCategoryPayload {
	categoryName: string | null = null
	categoryCode: string | null = null
	description?: string | undefined | null = null

	constructor({ ...data }: Partial<GlobalCodeCategoryInitialState> = {}) {
		this.addcategoryName(data?.categoryName)
		this.addDescription(data?.description)
		this.addCategoryCode(data?.categoryCode)
	}

	addcategoryName(category: string | null = null) {
		if (category) {
			this.categoryName = category
		}
	}

	addCategoryCode(categoryCode: string | null = null) {
		if (categoryCode) {
			this.categoryCode = categoryCode
		}
	}
	addDescription(description: string | undefined | null) {
		this.description = description
	}
}

export class GlobalCodeCategoryInitialState {
	categoryName: string | null = null
	categoryCode: string | null = null
	description?: string | undefined | null = null

	constructor({ ...data }: Partial<IFullGlobalCodeCategory> = {}) {
		this.addCategoryName(data?.categoryName)
		this.addCategoryCode(data?.categoryCode)
		this.addDescription(data?.description)
	}

	addCategoryName(category: string | null = null) {
		if (category) {
			this.categoryName = category
		}
	}
	addCategoryCode(categoryCode: string | null = null) {
		if (categoryCode) {
			this.categoryCode = categoryCode
		}
	}
	addDescription(description: string | undefined | null) {
		this.description = description
	}
}
