import _ from 'lodash'
import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'
import { commonMessages } from 'utils/messages'
import { IMemberTemplatesParams } from './MemberScreening.interface'
import { onCreateEndpointsWithClientid } from 'utils/common'

/**
 * * Get All Template
 */

export function getMemberTemplateDropdownList(
	params: Partial<IMemberTemplatesParams>,
	source?: CancelTokenSource,
) {
	const conditionsConvert = params?.conditions && JSON.parse(params?.conditions)
	const conditionFilterParams = conditionsConvert?.some((ds: any) => ds)
	if (!conditionFilterParams) {
		params.conditions = '[]'
	}
	const filteredParams = _.pickBy(params, _.identity)
	// const promise = axios.get<any>(ENDPOINTS.MEMBER_TEMPLATE, {
	const promise = axios.get<any>(onCreateEndpointsWithClientid(ENDPOINTS.MEMBER_TEMPLATE), {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * Assign Template
 */
export function assignTemplateToMember(data: Partial<any>, source?: CancelTokenSource) {
	const promise = axios.post<any>(
		onCreateEndpointsWithClientid(ENDPOINTS.ASSIGN_MEMBERS_TEMPLATE),
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
