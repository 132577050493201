import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import _ from 'lodash'
import { ENDPOINTS } from 'const/endpoints'
import { commonMessages } from 'utils/messages'
import { errorNotify } from 'utils/toster'
import { onCreateEndpointsWithClientid } from 'utils/common'
import { IPagination } from 'types/entities'
import { ProgramDropdownList } from './NonHealthCareReport.interfaces'

export function getNonHealthCareMemberReports(
	params: Partial<any>,
	client: string,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get(ENDPOINTS.SMBP_REPORTS + `/${client}`, {
		params: filteredParams,
	})

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

export function downloadReport(
	params: any,
	client: string,
	type?: string | null,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const { reportBy, programName = '' } = filteredParams
	const checkPath = `${ENDPOINTS.DOWNLOAD_REPORT}/${client}?type=${type}`
	const promise = axios
		.get(checkPath, {
			params: filteredParams,
			responseType: 'blob',
		})
		.then((response) => {
			// Create a temporary URL object to generate a download link
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			const header = programName
				? `smbp_report_of_${programName}_for_${reportBy}.${
						type == REPORT_TYPES.EXCEL ? 'xlsx' : type
				  }`
				: `smbp_report_for_${reportBy}.${type == REPORT_TYPES.EXCEL ? 'xlsx' : type}`
			link.setAttribute('download', header)

			// Simulate a click event to trigger the download
			document.body.appendChild(link)
			link.click()

			// Clean up the temporary URL object
			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		})
		.catch(() => {
			errorNotify(`Error downloading ${type}`)
		})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export enum REPORT_TYPES {
	PDF = 'pdf',
	EXCEL = 'excel',
}

export function getSmbpPrograms(
	params: { limit: number | undefined; page?: number | string; search?: string; sort?: string },
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<ProgramDropdownList>>(
		onCreateEndpointsWithClientid(ENDPOINTS.PROGRAM_FILTER_LIST),
		{
			params: filteredParams,
		},
	)

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}
