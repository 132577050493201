import classNames from 'classnames'

interface IErrorMessage {
	children?: React.ReactNode
	className?: string
}

export default function ErrorMessage({ children, className }: IErrorMessage) {
	return (
		<div
			className={classNames(
				' text-red-400 text-xs pt-2',
				{ 'pb-4': !children },
				{ 'error-message': children },
				className,
			)}
		>
			{children}
		</div>
	)
}
