import { default as axiosService } from 'axios'
import { AUTH_ERRORS } from 'const/common'
import { APP_ROUTES } from 'const/router'
import { globalRouter } from 'hooks/common'
import { createActivityLog } from 'utils/appCommon'
import { resendRefreshToken, signOut } from 'utils/common'
import { API_ERROR_STATUS, API_REQUEST_METHODS, ServerErrorMessages } from 'utils/messages'
import { errorNotify } from 'utils/toster'

export const axios = axiosService.create({
	baseURL: process.env.REACT_APP_API_URL,
})

axios.interceptors.response.use(
	(response) => {
		return response
	},
	async (error) => {
		//
		await createActivityLog(error as Error)
		if (error?.config?.url === APP_ROUTES.ME) {
			if (error?.response?.status === 404) {
				if (error?.response?.data?.message === ServerErrorMessages.NO_PROVIDER_FOUND) {
					errorNotify(ServerErrorMessages.USER_NOT_FOUND)
					await signOut()
					// if (globalRouter?.navigate) {
					// 	return globalRouter?.navigate(APP_ROUTES.SIGN_IN)
					// }
				}
			} else if (error?.response?.status === 403) {
				if (ServerErrorMessages.UNVERIFIED_EMAIL === error.response?.data?.message) {
					if (globalRouter?.navigate) {
						return globalRouter?.navigate(APP_ROUTES.EMAIL_VERIFICATION)
					}
				}
			} else if (error?.response?.status >= 500 && error?.response?.status <= 600) {
				errorNotify(ServerErrorMessages.INTERNAL_SERVER_ERROR)
				await signOut()
				if (globalRouter?.navigate) {
					return globalRouter?.navigate(APP_ROUTES.SIGN_IN)
				}
			} else if (error?.code === ServerErrorMessages.NETWORK_ISSUE) {
				errorNotify(ServerErrorMessages.NETWORK_ERROR)
			}
		}
		if (error?.response?.status === 401) {
			const token = localStorage.getItem('token')
			if (AUTH_ERRORS.UNAUTHORIZED === error?.response?.data?.message && token) {
				const newAccessToken = await resendRefreshToken({
					navigate: globalRouter?.navigate,
				})
				if (newAccessToken) {
					error.config.headers = {
						...error?.config?.headers,
						Authorization: 'Bearer ' + newAccessToken,
					}
					try {
						const axiosRequest = await axiosService(error?.config)
						return axiosRequest
					} catch (err: any) {
						if (err?.response?.status === 401) {
							errorNotify(ServerErrorMessages.SERVER_DOWN)
							await signOut()
						} else {
							errorNotify(error?.response?.data?.message)
						}
					}
				}
			} else if (AUTH_ERRORS.PERMISSION_DENIED === error?.response?.data?.message) {
				errorNotify(error?.response?.data?.message)
			} else {
				await signOut()
				if (globalRouter?.navigate) {
					return globalRouter?.navigate(APP_ROUTES.SIGN_IN)
				}
			}
		}
		if (error?.code === ServerErrorMessages.NETWORK_ISSUE) {
			errorNotify(ServerErrorMessages.NETWORK_ERROR)
		}
		if (
			error?.response?.status == API_ERROR_STATUS.BAD_REQUEST &&
			error?.response?.config?.method == API_REQUEST_METHODS.GET
		) {
			if (error?.response?.data?.message) {
				errorNotify(error?.response?.data?.message)
			}
		}

		//add some logic if needed
		return Promise.reject(error)
	},
)

export default axios
