import { Checkbox } from 'components/Checkbox/Checkbox'
import ScreenWrapper from 'components/ScreenWrapper'
import { useRolePermission } from 'hooks/common'
import Loader from 'components/Loader'
import LoadingComponent from 'components/LoadingComponent'
import { useGetScreenSlugDetail } from 'hooks/customHooks'
import { SCREEN_NAME } from 'const/screen'
import Button from 'components/Button'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { RoleContext } from './RoleLayout'

export function Permissions() {
	/**
	 * Context Section
	 */
	const roleInformationData = useContext(RoleContext)
	const { providerServicesDeails } = roleInformationData
	/**
	 * Variable Section
	 */

	const providerServiceId = providerServicesDeails?.filter((item: any) => item?.isAssigned)

	/** use useRolePermission hook for
	 1. select all Module  checkbox onchange function -handleAllPermissions 
	 2. select Module  checbox on chanage function - handeleModuleChange
	 3. select attributes checbox on change funtion - handeleAttributeChange */

	const {
		handeleModuleChange,
		handeleAttributeChange,
		permissionData,
		isModuleDataFetching,
		isModuleLoading,
	} = useRolePermission(providerServiceId?.[0]?.id)
	/**
	 *  Custom hooks Section
	 */
	const roleScreenPath = useGetScreenSlugDetail(SCREEN_NAME?.ROLES)
	const navigate = useNavigate()

	/**
	 * Function Section
	 */

	function getMoudleName(clientModule?: { clientModuleName: string }) {
		const moduleName = clientModule?.clientModuleName
		return moduleName
	}

	/**
	 * *JSX Section
	 */

	return (
		<ScreenWrapper title="Permissions">
			<div className="mr-[15px]">
				{isModuleLoading && <LoadingComponent />}
				{isModuleDataFetching ? (
					<Loader />
				) : (
					<>
						<div className="bg-lightGreen">
							{/* Not in use for now but needed don't remove this */}
							{/* <RoleFilters /> */}
							<div>
								{permissionData?.length > 0 ? (
									permissionData?.map((item: any, moduleIndex: any) => {
										const { clientModules, providerModulesAttribute } = item
										return (
											<>
												<div
													className={`h-max relative ml-[80px] before:absolute before:-left-[54px] before:border before:border-dashed before:border-[#62bdb5] before:h-full before:w-[1px] before:top-[18px] `}
												>
													<div className="flex">
														<div
															className={`h-[18px] w-[18px] bg-fountainBlue border-fountainBlue rounded-full absolute left-[-62px] top-3 z-[9]`}
														/>
													</div>
													<div className="border rounded-[4px] ml-[60px] mb-2 relative last-of-type:after:bg-transparent  after:absolute after:w-[1px] after:bg-fountainBlue after:h-[101%] after:left-[-35px] after:top-[20px]">
														<h2
															className={`relative px-3 font-semibold text-textColor border-b py-2 bg-slate-50`}
														>
															{getMoudleName(clientModules)}
														</h2>
														<div className="p-2 px-3 mb-2 mt-[10px]">
															<Checkbox
																checked={item?.isAssigned}
																onChange={(e: any) => handeleModuleChange(e, moduleIndex)}
																className="!mb-0 font-semibold checkboxField"
															>
																{clientModules?.clientModuleName}
															</Checkbox>
															<div className="flex items-center flex-wrap">
																{providerModulesAttribute?.map(
																	(clientModulesAttributeObj: any, attributeIndex: number) => {
																		const modulesAttribute =
																			clientModulesAttributeObj?.clientModulesAttribute
																				?.modulesAttribute
																		return (
																			<>
																				<div className="min-w-[200px] py-1 px-4 last-of-type:border-0 mt-3 border-r border-[#dbdbdb]">
																					<Checkbox
																						className="!mb-0"
																						checked={clientModulesAttributeObj?.isAssigned}
																						onChange={() =>
																							handeleAttributeChange(moduleIndex, attributeIndex)
																						}
																					>
																						{modulesAttribute?.attributes?.attributeName}
																					</Checkbox>
																				</div>
																			</>
																		)
																	},
																)}
															</div>
														</div>
													</div>
												</div>
											</>
										)
									})
								) : (
									<Loader />
								)}
							</div>
						</div>
					</>
				)}
			</div>
			<div className="py-2 flex justify-end">
				<Button
					className="py-4 !min-w-[100px] font-bold button__primary "
					onClick={() => navigate(`${roleScreenPath}`)}
				>
					Back to Roles
				</Button>
			</div>
		</ScreenWrapper>
	)
}
