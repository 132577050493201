const NoPermissionBox = ({ width = 190, height = 190, color = 'currentColor' }) => {
	return (
		<div className="flex items-center justify-center h-screen">
			<div
				className="bg-stone-100 w-full max-w-[450px] border border-fountainBlue text-fountainBlue px-4 py-3 rounded flex items-center relative"
				role="alert"
			>
				<span className="absolute bg-white rounded-full shadow -top-[15px] -right-[15px] cursor-pointer">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						className="h-8 w-8 text-fountainBlue"
					>
						<circle
							cx="12"
							cy="12"
							r="9"
							fill="none"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path d="M7.5 7.5 L16.5 16.5 M7.5 16.5 L16.5 7.5" stroke="currentColor" />
					</svg>
				</span>
				<div className="flex justify-center items-center w-full flex-col">
					<strong className="font-bold text-2xl mb-2">No Permission!</strong>
					<span className="block sm:inline">You do not have any permission.</span>
				</div>
			</div>
		</div>
	)
}

export default NoPermissionBox
