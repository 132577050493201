import _ from 'lodash'
import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'
import { IAddress, IHumanName, IPagination } from 'types/entities'
import { commonMessages } from 'utils/messages'
import { ClientPayload } from './Client/Client.types'
import {
	IClient,
	IClientRepresentative,
	IClientsParams,
	IFullClientType,
} from './Clients.interface'
import { onCreateEndpointsWithClientid } from 'utils/common'

/**
 * * Create Client
 */
export function createClient(
	clientId?: string | undefined,
	data?: Partial<ClientPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.post<ClientPayload>(ENDPOINTS.CLIENT_ENTITIES + '/' + clientId, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * * Update Client
 */

export function updateClient(
	id: string | undefined,
	clientId: string | undefined,
	data: Partial<ClientPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch<ClientPayload>(
		ENDPOINTS.CLIENT_ENTITIES + '/' + clientId + '/' + id,
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * * Get Client By Id
 */

export function getClient(
	id: string | undefined,
	clientId?: string | undefined,
	source?: CancelTokenSource,
) {
	// const promise = axios.get<IFullClientType>(ENDPOINTS.CLIENTS + '/' + clientId + '/' + id)
	const promise = axios.get<IFullClientType>(
		onCreateEndpointsWithClientid(ENDPOINTS.CLIENTS) + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * * Get Clients
 */

export function getClients(params: Partial<IClientsParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<IClient<IClientRepresentative<IHumanName>, IAddress>>, any>(
		ENDPOINTS.CLIENTS,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	promise.then(() => {
		//
	})
	return promise
}

/**
 * Get All Client
 */
export function getAllClients(params: Partial<IClientsParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<IClient<IClientRepresentative<IHumanName>, IAddress>>, any>(
		ENDPOINTS.CLIENTS_ALL,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	promise.then(() => {
		//
	})
	return promise
}

/**
 * * GetClientList
 */

export function getClientList(
	clientId?: string | undefined,
	params?: Partial<IClientsParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<IClient<IClientRepresentative<IHumanName>, IAddress>>, any>(
		ENDPOINTS.CLIENTS_TO_ALL_CLIENTS + '/' + clientId,
		{
			params: filteredParams,
		},
	)
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel('Query was cancelled by React Query')
	}
	promise.then(() => {
		//
	})
	return promise
}
