import { ReactNode } from 'react'
import { InputProps } from '../Input'
import classnames from 'classnames'
import { Label } from 'components/Label'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import { CustomCheckbox } from 'components/CustomCheckbox'

export interface IProps extends InputProps {
	label?: string | ReactNode
	error?: any
	children?: React.ReactNode
	className?: string
	name?: string
	classNames?: {
		label?: string
		input?: string
		content?: string
		children?: string
	}
	customClass?: string
	isLoader?: any
	isAssigned?: boolean
	onClick?: (data: any) => void
	isError?: boolean
	// onClick?: any
}

export function Checkbox({
	classNames,
	className,
	label = '',
	error,
	children,
	isLoader,
	customClass,
	name,
	onClick,
	...rest
}: IProps) {
	const containerClasses = classnames('relative', customClass, className, {
		'mb-4': !error,
	})
	const labelClasses = classnames('', { disabled: rest.disabled }, classNames?.label)

	return (
		<div className={containerClasses}>
			<Label name={label + ''} className={labelClasses} />
			<div className={`flex relative gap-2 items-center ${classNames?.content}`}>
				<CustomCheckbox
					{...rest}
					className={(classNames?.input || '') + ' shrink-0 focus:ring-transparent'}
					onClick={onClick}
				/>
				<div className={classNames?.children}>{children}</div>
			</div>
			<ErrorMessage>{error}</ErrorMessage>
		</div>
	)
}
