import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Toaster = () => {
	return (
		<div>
			<ToastContainer position="bottom-right" hideProgressBar={true} />
		</div>
	)
}

export default Toaster
