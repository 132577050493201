import { useContext } from 'react'
import Button from 'components/Button'
import { useUrlQuery } from 'hooks/url'
import Loader from 'components/Loader'
import { getClientDetails } from 'hooks/common'
import LoadingComponent from 'components/LoadingComponent'
import { useCreateAssignServicesToProvider } from 'modules/Settings/Roles/Role/Role.hooks'
import { calculateServiceColumnLength } from 'modules/Dashboard copy/Services.hooks'
import { RoleContext } from './RoleLayout'

function ServicesAssigned() {
	/**
	 * Context Section
	 */
	const roleInformationData = useContext(RoleContext)
	const [_urlParams, setUrlParams] = useUrlQuery<any>()
	/**
	 * Destructuring Section
	 */
	const {
		dataValue: {
			data: {
				data: { id },
			},
		},
		providerServicesDeails,
		setProviderServicesDeails,
		isProviderDetailFetching,
	} = roleInformationData
	/**
	 * *Variable and hooks Section
	 */
	const permissionDetailData = providerServicesDeails || []
	const serviceColumnLength = calculateServiceColumnLength(
		permissionDetailData && permissionDetailData?.length,
	)
	const isAssignClientFilter = permissionDetailData
	const isSaveButtonDisabled = permissionDetailData?.some((item: any) => item?.isAssigned)
	const serviceClassName = `grid grid-cols-${serviceColumnLength} gap-[23px] justify-center"`
	/**
	 * Custom hooks section
	 */
	const { mutateAsync: create, isLoading: isLoadingOfAssignService } =
		useCreateAssignServicesToProvider({
			clientId: getClientDetails()?.clientId,
		})
	/**
	 * Function Section
	 */

	const handleChange = (item: any) => {
		if (item?.isActive) {
			const itemSelected = { ...item, isAssigned: !item?.isAssigned }
			setProviderServicesDeails((old: any) => {
				return old?.map((itemValue: any) => {
					if (itemValue?.id === itemSelected?.id) {
						return itemSelected
					} else {
						return itemValue
					}
				})
			})
		}
	}

	const asssignServiceToModules = async () => {
		const paylod = {
			role: id,
			clientService: isAssignClientFilter?.map((item: any) => {
				if (item?.isServiceAssigned) {
					return {
						clientService: item?.clientService?.id,
						isAssigned: item?.clientService?.isAssigned,
						isActive: item?.clientService?.isActive,
					}
				} else {
					return {
						clientService: item?.clientService?.id,
						isAssigned: item?.clientService?.isAssigned,
						isActive: item?.clientService?.isActive,
					}
				}
			}),
		}

		const response = await create(paylod)
		const providerServiceId = response?.data?.find((item: any) => item?.isAssigned)

		if (response?.data) {
			setUrlParams((old) => {
				return {
					...old,
					providerService: undefined,
					isAssignedService: true,
					isProviderServiceId: providerServiceId?.id,
				}
			})
		}
	}

	/**
	 * *JSX Section
	 */
	return (
		<div className="bg-snow h-[100%] p-4">
			<div className="flex font-bold text-xl">Assign Service</div>
			{/* Not in use for now but needed don't remove this */}
			{/* <RoleFilters /> */}
			<div className="  flex flex-col items-center pb-4 content-height scrollbar-thin">
				<div className="md:mt-[40px] flex  flex-col-2 mt-[40px] justify-center items-center w-full px-4 md:px-0 gap-2.5">
					<div className={`${serviceClassName}`}>
						{isLoadingOfAssignService && <LoadingComponent />}
						{isProviderDetailFetching ? (
							<Loader />
						) : (
							permissionDetailData?.map((servicesDeailsObj: any) => {
								return (
									<>
										<div
											className={` cursor-not-allowed md:w-[200px] w-[100%] md:h-[200px] h-[60px] rounded-lg shadow-[0_1px_10px_0_rgba(0,0,0,0.11)] bg-white flex flex-row md:flex-col items-center inactive-item ${
												servicesDeailsObj?.isActive &&
												'cursor-pointer !bg-white shadow-[0_1px_10px_0_rgba(0,0,0,0.11)]'
											} ${
												servicesDeailsObj?.isAssigned && 'border-2 !border-fountainBlue !bg-white '
											} `}
											onClick={() => handleChange(servicesDeailsObj)}
										>
											<img
												src={servicesDeailsObj?.clientService?.clientServiceImage}
												alt={servicesDeailsObj?.clientService?.clientServiceName}
												className="md:h-[85px] md:w-[85px] md:mt-[36px] md:mb-[36px] h-[40px] w-[40px] mt-0 mb-0 mr-[20px] ml-[20px]"
											/>
											<p className="text-base text-nightRider opacity-60  md:mb-[24px] mt-0">
												{servicesDeailsObj?.clientService?.clientServiceName}
											</p>
										</div>
									</>
								)
							})
						)}
					</div>
					<div className="flex md:flex-row flex-col gap-2.5 justify-center"></div>
				</div>
			</div>
			<div className="flex gap-4 m-4 justify-end flex-col md:flex-row">
				<Button
					className="mt-2"
					buttonType="submit"
					type="primary"
					disabled={!isSaveButtonDisabled || isLoadingOfAssignService}
					onClick={() => {
						asssignServiceToModules()
					}}
				>
					Save
				</Button>
			</div>{' '}
		</div>
	)
}
export default ServicesAssigned
