export enum FIELD_NAMES {
	TITLE = 'title',
	DESCRIPTION = 'description',
	ORDER = 'order',
	ANSWERED_BY = 'answeredBy',
	// IS_PARTIALLY_FILLED = 'isPartiallyFilled',
	SCORING = 'scoring',
	SCREENING_CODE = 'screeningCode',
	SCREENING = 'screening',
	EXISTING_SCREENING = 'isExisting',
}

export enum COMMON_MESSAGES {
	DELETE_SCREENING = 'Please confirm, would you like to delete this Screening ?',
}

export enum SCORING_OPTIONS_VALUES {
	SCORING = 'scoring',
	NOT_SCORING = 'notScoring',
}

export const IS_SCORING_OPTIONS = [
	{ value: SCORING_OPTIONS_VALUES?.SCORING, label: 'Scoring' },
	{ value: SCORING_OPTIONS_VALUES?.NOT_SCORING, label: 'Non Scoring' },
]

export enum SCREENING {
	EXISTING = 'existing',
	NEW = 'new',
}

export const IS_EXISTING_OPTIONS = [
	{ value: SCREENING?.NEW, label: 'Create New Screening' },
	{ value: SCREENING?.EXISTING, label: 'Modify Existing Screening' },
]
