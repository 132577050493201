import _ from 'lodash'
import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'
import { commonMessages } from 'utils/messages'
import { IPagination } from 'types/entities'
import { IFullPartner, IPartnerParams } from './Partner.interfaces'
import { PartnerPayload } from './Partner/Partner.types'
import { onCreateEndpointsWithClientid } from 'utils/common'
/**
 * * Create Partner
 */
export function createPartner(
	clientId: string | undefined,
	data: Partial<PartnerPayload>,
	source?: CancelTokenSource,
) {
	if (!data.npi) {
		delete data.npi
	}
	if (!data.image) {
		delete data?.image
	}

	const promise = axios.post<PartnerPayload>(
		ENDPOINTS.PARTNER_ENTITY + '/' + clientId,
		// data
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * * Update Partner
 */
export function updatePartner(
	id: string | undefined,
	clientId: string | undefined,
	data: Partial<PartnerPayload>,
	source?: CancelTokenSource,
) {
	if (!data.npi) {
		delete data.npi
	}
	if (!data.image) {
		delete data?.image
	}
	const promise = axios.patch<PartnerPayload>(
		ENDPOINTS.PARTNER_ENTITY + '/' + clientId + '/' + id,
		// data
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * * GET PARTNER (view particular partner's details with the help of partner ID)
 */
export function getPartner(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<IFullPartner>(ENDPOINTS.PARTNER_ENTITY + '/' + clientId + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * * GET request for partners client
 * Gives the client of the particular partner
 */
export function getPartnerClients(
	params: {
		limit: number | undefined
		page?: number | string
		search?: string
		sort?: string
		templateCode?: string | undefined
		client?: string | undefined
	},
	clientId?: string | undefined | any,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	// const promise = axios.get(ENDPOINTS.CLIENTS_ALL + '/' + clientId, {
	const promise = axios.get(ENDPOINTS.CLIENTS_ALL, {
		params: filteredParams,
	})

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

/**
 * * GET request for partners (Partners Listing)
 */
export function getPartners(
	id: string | undefined,
	params: Partial<IPartnerParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullPartner>>>(ENDPOINTS.PARTNER + '/' + id, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * GET request for healthcare partners (healthcare partners Listing)
 */
export function getHealthCarePartners(params: Partial<IPartnerParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullPartner>>>(
		onCreateEndpointsWithClientid(ENDPOINTS.GET_ALL_HEALTHCARE_PARTNERS),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * PATCH request for partner's status change
 */
export function statusToggle(
	id: string | undefined,
	clientId: string | undefined,
	data: Partial<PartnerPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch(ENDPOINTS.PARTNER_ENTITIES_STATUS + '/' + clientId + '/' + id, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
