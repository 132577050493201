import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import classnames from 'classnames'
import ScreenWrapper from 'components/ScreenWrapper'
import { Permissions } from './Permissions'
import { useUrlQuery } from 'hooks/url'
import { useProviderDetailsFromRole, useRole } from 'modules/Settings/Roles/Role/Role.hooks'
import { AssignedModulesContainer } from './ServiceRole.container'
import { getClientDetails } from 'hooks/common'
import RoleInformation from 'modules/Settings/Roles/Role/RoleInformation'

export const RoleContext = createContext<any>('')

/* Provider Service Tabs  */
function Tab({ id, children, Icon }: { id: string; children: React.ReactNode; Icon?: any }) {
	return (
		<div
			className={`${
				id ? 'border-t-fountainBlue border-t-[3px] text-fountainBlue ' : 'border-[1px] bg-[#f6f6f6]'
			} 'flex flex-col px-4 md:px-0 items-center py-2 justify-center text-center  uppercase text-xs font-semibold box-border h-full'`}
		>
			<div
				className={`${
					id ? 'text-fountainBlue fill-fountainBlue 	' : 'text-dimGray text-opacity-30'
				}`}
			>
				<img src={Icon} className={classnames('w-[40px] h-[40px] mb-2 mx-auto')} />
			</div>

			{children}
		</div>
	)
}

function ProfileTabs() {
	/**
	 * Context Section
	 */
	const roleInformationData = useContext(RoleContext)
	const { providerServicesDeails } = roleInformationData
	/**
	 * *Variable and hooks Section
	 */

	const providerDetailData = providerServicesDeails
	const isAssignProviderFilter = providerDetailData?.filter((item: any) => item?.isAssigned)
	const serviceName = isAssignProviderFilter?.[0]
	/**
	 * *State Section
	 */

	const [tabData, setTabData] = useState<any>(serviceName)
	/**
	 * Function Section
	 */
	const tabInfo = (providerServicesDeailsObj: any) => {
		setTabData(providerServicesDeailsObj)
		setAllPermissions(false)
	}
	/**
	 * useEffects Section
	 */

	useEffect(() => {
		setTabData(serviceName)
	}, [serviceName])

	const { setAllPermissions } = roleInformationData

	/**
	 * JSX Section
	 */

	return (
		<>
			<div className="flex flex-col px-4  bg-aliceBlue h-full">
				<div className="py-2 ">
					<ScreenWrapper
						title=""
						classNames={{
							title: 'border-t-fountainBlue border-t-[4px]',
						}}
					>
						<div
							className={`grid 'xl:grid-cols-${isAssignProviderFilter?.length}' grid-cols-${isAssignProviderFilter?.length} mb-[20px] pt-[8px] leading-[22px]`}
						>
							{isAssignProviderFilter?.map((providerServicesDeailsObj: any) => {
								const matchedId =
									tabData?.id === providerServicesDeailsObj?.id ? tabData?.clientService.id : ''
								return (
									<div onClick={() => tabInfo(providerServicesDeailsObj)}>
										<Tab
											id={matchedId}
											Icon={providerServicesDeailsObj?.clientService?.clientServiceImage}
										>
											<div>{providerServicesDeailsObj?.clientService?.clientServiceName}</div>
										</Tab>
									</div>
								)
							})}
						</div>

						<Permissions />
					</ScreenWrapper>
				</div>
			</div>
		</>
	)
}

function RoleLayout() {
	/**
	 * *Variable and hooks Section
	 */
	const param = useParams<{ id: string }>()
	const [urlParams] = useUrlQuery<any>()
	const id = param.id
	const isAssignedService = !!urlParams?.isAssignedService
	const isProviderService = !!urlParams?.providerService
	const isClient: any = urlParams?.client
	/**
	 * * State Section
	 */
	const [allPermissions, setAllPermissions] = useState<boolean>(false)
	const [providerServicesDeails, setProviderServicesDeails] = useState<any>()
	const alreadyMounted = useRef(false)

	/**
	 * Custom hook section
	 */
	const roleData = useRole({
		id: id,
		clientId: getClientDetails()?.clientId,
		suspense: !alreadyMounted.current, //TO USE SUSPENSE ONCE ON MOUNT
	})

	const providerDetail: any = useProviderDetailsFromRole({
		clientId: isClient,
		roleId: id,
		providerRoleId: urlParams?.providerRoleId,
		isEnabled: !!isProviderService || !!isAssignedService,
	})
	const providerDetailData = providerDetail?.data?.data
	const isProviderDetailFetching = providerDetail?.isFetching

	/**
	 * useEffects Section
	 */

	useEffect(() => {
		if (providerDetailData) {
			setProviderServicesDeails(providerDetailData)
		} else {
			setProviderServicesDeails([])
		}
	}, [isProviderDetailFetching])

	/**
	 * *JSX Section
	 */
	return (
		<>
			<RoleContext.Provider
				value={{
					dataValue: roleData,
					allPermissions,
					setAllPermissions,
					providerServicesDeails,
					setProviderServicesDeails,
					isProviderDetailFetching,
				}}
			>
				<section className="h-full relative flex flex-col">
					<RoleInformation roleInformationData={roleData} />
					{!isAssignedService && <AssignedModulesContainer />}
					{isAssignedService && <ProfileTabs />}
				</section>
			</RoleContext.Provider>
		</>
	)
}

export default RoleLayout
