import { Suspense } from 'react'
import SideDrawer from 'components/SideDrawer'
import { Loader } from 'components/Loader'
import Avatar from 'assets/images/ic_avatar_default.svg'
import { useUrlQuery } from 'hooks/url'
import RoleContainer from '../Role.container'
import { IRoleBaseParams } from '../Role.interface'
import { RoleLabels } from '../../Roles.constants'
import Button from 'components/Button'
import { useGetScreenSlugDetail } from 'hooks/customHooks'
import { SCREEN_NAME } from 'const/screen'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { ENDPOINTS } from 'const/endpoints'

export default function RoleInformation({ roleInformationData }: any) {
	/**
	 * *Context Section
	 */
	const [urlParams] = useUrlQuery<IRoleBaseParams>()
	const params = useParams<{ id: string }>()
	const navigate = useNavigate()
	const id = params?.id
	const roleQuery = useQueryClient()

	/**
	 * *Variable Section
	 */
	const isEdit = urlParams?.isRoleBaseEdit
	const isAdd = urlParams?.isRoleBaseAdd
	const isClient = urlParams?.client
	const isProviderService = urlParams?.providerService
	const roleInfo = roleInformationData?.data?.data || []
	/**
	 * *Custom hooks Section
	 */
	const roleScreenPath = useGetScreenSlugDetail(SCREEN_NAME?.ROLES)
	const roleProfile = useGetScreenSlugDetail(SCREEN_NAME.ROLE_PROFILE)
	/**
	 * *useEffects Section
	 * @param useEffectName
	 */
	const redirectToAssignService = async () => {
		navigate(`${roleProfile}/${id}?client=${isClient}&providerService=${true}`)
		await roleQuery.invalidateQueries([ENDPOINTS.GET_PROVIDER_DETAILS_FROM_ROLE])
	}

	/**
	 * *JSX Section
	 */
	return (
		<>
			<div className="w-full mb-3 rounded-md flex flex-col justify-between min-h-[140px] gap-2">
				<div className="flex gap-8 pr-5 bg-white p-4 items-center">
					<img src={roleInfo?.image || Avatar} className="h-[170px] w-[170px] mr-4 mx-10" />
					<div className="w-full">
						<div className="flex justify-between">
							<h2 className="first-letter:uppercase text-3xl text-gray-600 mt-3" id="memberName">
								{roleInfo?.roleName || '--'}
							</h2>
							<div className="flex justify-end gap-4">
								{!isProviderService && (
									<div className="py-2">
										<Button
											className="py-4 !min-w-[100px] font-bold button__primary "
											onClick={() => {
												redirectToAssignService()
											}}
										>
											Back to Assign Service
										</Button>
									</div>
								)}

								<div className="py-2">
									<Button
										className="py-4 !min-w-[100px] font-bold button__primary "
										onClick={() => navigate(`${roleScreenPath}`)}
									>
										Back to Roles
									</Button>
								</div>
							</div>
						</div>

						<div className="flex justify-between w-full border-t mt-6 flex-wrap">
							<div className="h-max py-2">
								<h2 className="font-semibold text-[17px] min-w-[160px]">
									{RoleLabels.CLIENT_NAME}
								</h2>
								<p className="text-[#7e7e7e]">{roleInfo?.client?.title || '--'}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<SideDrawer header={isAdd ? 'Add Role ' : 'Edit Role '} isOpen={!!isAdd || !!isEdit}>
				<Suspense fallback={<Loader />}>{(!!isAdd || !!isEdit) && <RoleContainer />}</Suspense>
			</SideDrawer>
		</>
	)
}
