import { useMemo } from 'react'
import { getClientDetails, usePermission, useSortHandler } from 'hooks/common'
import { useUrlQuery } from 'hooks/url'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { IAxiosResponseWithPagination } from 'types/entities'
import { ENDPOINTS } from 'const/endpoints'
import axios from 'axios'
import { APP_ROUTES } from 'const/router'
import { Link } from 'react-router-dom'
import { ReactComponent as EditIcon } from 'assets/images/ic_edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/ic-delete.svg'
import { ReactComponent as DefaultIcon } from 'assets/images/default_active.svg'
import { ReactComponent as DisabledIcon } from 'assets/images/default_disabled.svg'
import { Column } from 'react-table'
import { unixToDate } from 'utils/time'
import { ROLES } from 'const/auth'
import { IFullRoleBase, IRoleBaseParams } from './Role/Role.interface'
import { getParentRole, getRolesAll } from './Role/Role.service'
import { MODULE_NAME } from 'const/module'
import { useGetScreenSlugDetail } from 'hooks/customHooks'
import { onGetTableColumns, onGetTablebodyColumn } from 'utils/common'
import { COLUMN_NAME, SCREEN_NAME } from 'const/screen'
import { IAssignProviderRoleParams } from 'modules/Clients/AssignedServicesAndModules/AssignedProviders/AssignedProvidersRole/AssignedProviderRole.interface'
import { getRolesForClient } from 'modules/Clients/AssignedServicesAndModules/AssignedProviders/AssignedProviders.service'

export function useRolesTableData({
	onDelete,
	onEdit,
	tableSectionDetail,
}: {
	onDelete: (id: string) => void
	onEdit: any
	tableSectionDetail: any
}): [
	Column<{ [key: string]: React.ReactNode }>[],
	{ [key: string]: React.ReactNode }[],
	(params: string) => void,
	any,
] {
	/**
	 * * Hooks Section
	 */
	const [urlParams, setUrlParams] = useUrlQuery<IRoleBaseParams>()

	const role: any = useGetRolesForClientForListing({
		...urlParams,
		clientId: getClientDetails()?.clientId,
	})

	const onSort = useSortHandler(urlParams.sort)

	/**
	 * * Module wise permission custom hook to get permission details module wise
	 */
	const rolePermission = usePermission(MODULE_NAME?.ROLES)
	/**
	 * * To filter action particular column
	 */

	const dynamicColumn = tableSectionDetail?.providerColumns
	const partnersTableColumn = onGetTableColumns(dynamicColumn)
	/**
	 * * To filter action particular column if nothing to display in it
	 */
	const filterRoleBaseColumns = Array.isArray(partnersTableColumn)
		? partnersTableColumn?.filter((columnObj: any) => {
				return !(
					columnObj?.name?.toLowerCase() === COLUMN_NAME?.ACTION &&
					!rolePermission?.edit &&
					!!rolePermission?.delete
				)
		  })
		: []

	const roleProfile = useGetScreenSlugDetail(SCREEN_NAME.ROLE_PROFILE)

	/**
	 * * Using the useMemo hook to create an array roles data for the role table.
	 */
	const rolesData = useMemo(() => {
		const mapBodyObj = (item: any) => {
			const isAdminRole = item?.slug === ROLES.ADMIN
			return {
				roleName: (
					<div className="flex gap-2">
						{item.providerModules?.length && !isAdminRole ? (
							<Link
								to={`${roleProfile}/${item?.id}?client=${
									getClientDetails()?.clientId
								}&isAssignedService=${true}`}
								className="text-fountainBlue"
							>
								{item?.roleName ?? '--'}
							</Link>
						) : !isAdminRole && !item.providerModules?.length ? (
							<Link
								to={`${roleProfile}/${item?.id}?client=${
									getClientDetails()?.clientId
								}&providerService=${true}`}
								className="text-fountainBlue"
							>
								{item?.roleName ?? '--'}
							</Link>
						) : (
							<span>{item?.roleName ?? '--'}</span>
						)}
					</div>
				),
				client: item?.client?.title ?? '--',

				createdOn: item?.createdAt ? unixToDate(item?.createdAt) : '--',

				defaultRoutes: (
					<span>
						{item?.providerModules?.length && !isAdminRole ? (
							<DefaultIcon
								className="h-10 w-10 cursor-pointer"
								onClick={() => {
									if (item?.providerModules?.length) {
										setUrlParams((old) => {
											return {
												...old,
												role: item?.id,
												client: getClientDetails()?.clientId,
												isDefaultRoute: item?.defaultRoute?.length ? undefined : 'true',
												isDefaultRouteEdit: item?.defaultRoute?.length ? 'true' : undefined,
											}
										})
									}
								}}
							/>
						) : (
							<DisabledIcon className="h-10 w-10 cursor-not-allowed" />
						)}
					</span>
				),

				action: (
					<div className="flex items-center gap-2">
						{rolePermission?.edit && (
							<Link
								to={!isAdminRole ? APP_ROUTES.ROLES + `?id=${item?.id}` : ''}
								className="text-blue-500"
							>
								<span data-tip="Open to edit" onClick={() => !isAdminRole && onEdit('edit')}>
									<EditIcon
										className={` stroke-solidGray fill-solidGray   ${
											!isAdminRole
												? 'hover:stroke-fountainBlue hover:fill-fountainBlue cursor-pointer'
												: 'bg-slate-200 cursor-not-allowed'
										}`}
									/>
								</span>
							</Link>
						)}
						{rolePermission?.delete && (
							<span data-tip="Delete">
								<DeleteIcon
									onClick={() => !isAdminRole && onDelete(item?.id)}
									className={` stroke-solidGray fill-solidGray   ${
										!isAdminRole
											? 'hover:stroke-fountainBlue hover:fill-fountainBlue cursor-pointer'
											: 'bg-slate-200 cursor-not-allowed'
									}`}
								/>
							</span>
						)}
					</div>
				),
			}
		}

		return (
			role?.data?.data?.items?.map((item: any) => {
				const bodyobj = mapBodyObj(item)
				return onGetTablebodyColumn(item, bodyobj, filterRoleBaseColumns)
			}, []) || []
		)
	}, [role?.data?.data?.items, filterRoleBaseColumns, onEdit])

	return [filterRoleBaseColumns, rolesData, onSort, role]
}

/**
 * * Defining an interface called IRolesQueryProps which extends the UseQueryOptions type from the axios library.
 * * Used to define the options for a query used to get data from an API, such as a partner list.
 * * Contains a partial IRoleBaseParams type which will be used to specify any additional parameters that the query might require, such as a filter or sorting criteria.
 */
interface IRolesQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullRoleBase>>>,
		Partial<IRoleBaseParams> {}

/**
 * * The useRoles function is a React hook that takes in a set of query parameters.
 */
export function useRoleAll({
	page,
	limit,
	sort,
	name,
	label,
	active,
	search,
	client,
	...rest
}: IRolesQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullRoleBase>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullRoleBase>>
	>(
		[ENDPOINTS.ROLES_ALL, page, limit, sort, name, label, active, search, client],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getRolesAll(
				client,
				{
					page,
					limit,
					sort,
					name,
					label,
					active,
					search,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			enabled: !!client,
			...rest,
		},
	)
}

export function useGetRole({
	page,
	limit,
	sort,
	name,
	label,
	active,
	search,
	client,
	parentRole,
	id,
	...rest
}: IRolesQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullRoleBase>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullRoleBase>>
	>(
		[ENDPOINTS.PARENT_ROLE, page, limit, sort, name, label, active, search, client, parentRole, id],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getParentRole(
				client,
				{
					page,
					limit,
					sort,
					name,
					label,
					active,
					search,
					parentRole,
					role: id,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			...rest,
		},
	)
}

interface IAssignedProviderForCientRoleQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IAssignProviderRoleParams>>>,
		Partial<IAssignProviderRoleParams> {}
export function useGetRolesForClientForListing({
	clientId,
	role,
	page,
	limit,
	client,
	search,
	sort,
	...rest
}: IAssignedProviderForCientRoleQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IAssignProviderRoleParams>>,
		any,
		IAxiosResponseWithPagination<Partial<IAssignProviderRoleParams>>
	>(
		[ENDPOINTS.GET_ROLES_FOR_ALL_CLIENT, role, search, client, clientId, page, limit, sort],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getRolesForClient(
				clientId,
				{
					client,
					role,
					search,
					page,
					limit,
					sort,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			enabled: !!role || !!client || !!clientId,
		},
	)
}
