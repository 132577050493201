import { StylesConfig } from 'react-select'

//@ts-ignore
import { ISelectOption } from 'types/forms'

export function getStylesForReactSelectInputs(config: StylesConfig<ISelectOption> = {}) {
	const customStyles: StylesConfig<ISelectOption> = {
		control: (provided, state) => {
			const styles = {
				...provided,
				menuPosition: 'fixed',
				borderColor: '#CCCCCC',
			}
			if (state.isFocused) {
				styles.borderColor = '#78ade4'
				styles.boxShadow = '0 0 5px 0 rgb(120 173 228 / 76%), inset 0 2px 2px 0 rgb(0 0 0 / 7%);'
			}
			if (state.isDisabled) {
				styles.color = '#999999'
				styles.border = '1px solid #dedede'
				styles.opacity = '0.7'
				styles.borderColor = '#e3e3e3'
				styles.boxShadow = 'none'
				styles.cursor = 'not-allowed'
			}

			return styles
		},
		placeholder: (provided) => {
			return { ...provided, fontSize: '14px', color: '#9da4b1' }
		},
		singleValue: (provided) => {
			return {
				...provided,
				// color: '#bbbbbb',
				lineHeight: '17px',
				fontSize: '14px',
				fontWeight: '400',
			}
		},
		input: (provided) => {
			return {
				...provided,
				color: '#333333',
				lineHeight: '17px',
				fontSize: '14px',
				fontWeight: '400',
			}
		},
		menu: (provided) => {
			return { ...provided, borderRadius: '3px' }
		},
		indicatorsContainer: (provided) => {
			return { ...provided, transform: 'scale(0.7)' }
		},
		indicatorSeparator: () => ({}),
		menuList: (provided) => {
			return {
				...provided,
				paddingTop: '8px',
				paddingBottom: '8px',
				maxHeight: '200px',
				background: '#fff',
			}
		},
		option: (provided, state) => {
			if (state.isSelected) {
				provided.backgroundColor = '#dfe3eb' //look at calmIridescence color in tailwind config
				provided.color = 'inherit'
			}

			return {
				...provided,
				color: '#333333',
				padding: '2px 12px',
				fontSize: '14px',
				fontWeight: '400',
			}
		},
		...config,
	}
	return customStyles
}
