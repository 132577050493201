import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactTooltip from 'react-tooltip'
import { BrowserRouter } from 'react-router-dom'
import 'assets'
import { initializeApp } from 'firebase/app'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { windowAddEventListener, windowRemoveEventListener } from 'utils/appCommon'
import { EVENT_NAME } from 'const/common'

export const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
}

initializeApp(firebaseConfig)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 0,
			suspense: false,
			cacheTime: 0,
		},
	},
})

initializeApp(firebaseConfig)

function Render() {
	const [isTablet, setIsTablet] = useState(false)

	useEffect(() => {
		const handleResize = () => {
			const screenWidth = window.innerWidth
			setIsTablet(screenWidth <= 991) // Adjust the breakpoint as per your definition of a tablet screen size
		}

		handleResize() // Call the function once to set the initial state
		// window.addEventListener('resize', handleResize)
		windowAddEventListener(EVENT_NAME?.RESIZE, handleResize)
		return () => {
			windowRemoveEventListener(EVENT_NAME?.RESIZE, handleResize)
		}
	})

	return (
		<>
			<React.StrictMode>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<App />
						{!isTablet && (
							<ReactTooltip
								clickable={false}
								backgroundColor="black"
								className="opacity-70 capitalize rounded-md !px-2 !pt-2"
							/>
						)}
					</BrowserRouter>
				</QueryClientProvider>
			</React.StrictMode>
		</>
	)
}

root.render(<Render />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
