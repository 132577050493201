import Loader from 'components/Loader'

const LoadingComponent = () => {
	return (
		<div className="fixed h-screen z-50 flex items-center justify-center w-full top-0 left-0 bg-gray-100 bg-opacity-50">
			<Loader />
		</div>
	)
}

export default LoadingComponent
