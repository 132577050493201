import { useEffect } from 'react'
import { ACTIVITY_ACTION_NAME } from 'const/common'
import { useLogger } from './Logger.Utility'

const usePageLogger = (): void => {
	const { onCallLoggerFunction, location } = useLogger()

	useEffect(() => {
		onCallLoggerFunction({ actionName: ACTIVITY_ACTION_NAME?.PAGE_VISIT })
	}, [location?.pathname])

	useEffect(() => {
		if (location?.search) {
			onCallLoggerFunction({ actionName: ACTIVITY_ACTION_NAME?.FILTER_SECTION_ACTIVITY })
		}
	}, [location?.search])
}
export default usePageLogger
