import { useUrlQuery } from 'hooks/url'
import { Column } from 'react-table'
import { membersAlertColumns } from './NonHealthCareReports.contants'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { IAxiosResponseWithPagination } from 'types/entities'
import { useMemo } from 'react'
import { getClientDetails, useSortHandler } from 'hooks/common'
import { ENDPOINTS } from 'const/endpoints'
import axios from 'axios'
import { getNonHealthCareMemberReports, getSmbpPrograms } from './NonHealthCareReport.service'
import { formatDate, getAge } from 'utils/time'
import { DROPDOWN_LIMIT } from 'const/common'
import { onGetTableColumns, onGetTablebodyColumn } from 'utils/common'
import {
	INonHealthCareFullMember,
	INonHealthCareMemberParams,
} from '../NonHealthCareMemberComponents/NonHealthCaremember.interface'
import { INonHealthCareReport, ProgramDropdownList } from './NonHealthCareReport.interfaces'

export function useNonHealthCareReportsTableData({
	nonHealthCareMemberGrid,
	nonHealthCarePartnerGrid,
}: {
	nonHealthCareMemberGrid: any
	nonHealthCarePartnerGrid: any
}): [Column<{ [key: string]: React.ReactNode }>[], { [key: string]: React.ReactNode }[], any, any] {
	const [params] = useUrlQuery<any>()
	const isMember = params?.reportBy === 'member'
	const clientId: string = getClientDetails()?.clientId
	const patients = useReports(
		{ ...params, startDate: params?.startDate, endDate: params?.endDate },
		clientId,
	)
	const onSort = useSortHandler(params.sort)

	const dynamicMembersColumn = nonHealthCareMemberGrid?.providerColumns
	const dynamicPartnersColumn = nonHealthCarePartnerGrid?.providerColumns

	const nonHealthCareMembersTableColumn = onGetTableColumns(dynamicMembersColumn)
	const nonHealthCarePartnersTableColumn = onGetTableColumns(dynamicPartnersColumn)

	const mapBodyObj = (item: any) => {
		const age = item?.dateOfBirth ? getAge(item?.dateOfBirth) : 0
		let participantId = ''
		item?.program?.map((data: any, i: any) => {
			if (!i) {
				return (participantId = data?.patientProgramId)
			}
		})
		let enrolledDate = '--'
		item?.program?.forEach((data: any, i: any) => {
			if (!i) {
				enrolledDate = formatDate(+data?.enrollmentDate)
			}
		})
		return {
			participant_id: isMember ? participantId : item?.companyName || '--',
			healthcarePartner: isMember ? item?.partner?.companyName : item?.partner_id || '--',
			nonHealthcarePartner: item?.enrolledPartner?.companyName ?? '--',
			enrolledDate,
			gender: isMember ? item?.genderId?.globalCodesName : item?.numberOfHypertensive || '--',
			age: age || '--',
			race: item?.raceId?.globalCodesName || '--',
			ethnicity: item?.ethnicityId?.globalCodesName || '--',
			education: item?.educationId?.globalCodesName || '--',
			household: item?.householdIncomeId?.globalCodesName || '--',
			firstVitalReading: item?.firstVitalReading || '--',
			hypertensive: isMember
				? item?.isHypertensive
					? 'Yes'
					: 'No'
				: item?.numberOfHypertensive || '0',
			providerContacted: 'N/A',
			noOfScreening: isMember
				? item?.noOfScreening || '0'
				: item?.totalScreeningOfNonHealthCare || '0',
			enrolledParticipants: item?.NumberOfEnrolledParticipent || '0',
			noOfParticipants: 'N/A',
			noOfReferrals: 'N/A',
		}
	}

	const nonHealthCareMembersData = useMemo(() => {
		return (
			patients?.data?.data?.items?.map((item, key) => {
				const bodyobj = mapBodyObj(item)
				const nonHealthCareMembersTableColumnDetails = onGetTablebodyColumn(
					item,
					bodyobj,
					nonHealthCareMembersTableColumn,
				)
				return nonHealthCareMembersTableColumnDetails
			}, []) || []
		)
	}, [patients?.data?.data?.items, nonHealthCareMembersTableColumn])

	const nonHealthCarePartnersData = useMemo(() => {
		return (
			patients?.data?.data?.items?.map((item, key) => {
				const bodyobj = mapBodyObj(item)
				const nonHealthCarePartnersTableColumnDetails = onGetTablebodyColumn(
					item,
					bodyobj,
					nonHealthCarePartnersTableColumn,
				)
				return nonHealthCarePartnersTableColumnDetails
			}, []) || []
		)
	}, [patients?.data?.data?.items, nonHealthCarePartnersTableColumn])

	return [
		isMember ? nonHealthCareMembersTableColumn : nonHealthCarePartnersTableColumn,
		isMember ? nonHealthCareMembersData : nonHealthCarePartnersData,
		onSort,
		patients,
	]
}
interface IPatientsQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<INonHealthCareFullMember>>>,
		Partial<INonHealthCareMemberParams> {}
export function useReports(
	{
		page,
		limit,
		sort,
		name,
		client,
		status,
		programName,
		program,
		city,
		state,
		isAlert,
		untrained,
		welcomeCall,
		patientReadings,
		currentDate,
		abnormalVitals,
		lastMeasurementLt,
		healthCondition,
		search,
		startDate,
		endDate,
		reportBy,
		...rest
	}: IPatientsQueryProps = {},
	clientId?: any,
) {
	let queryKey = [
		ENDPOINTS.SMBP_REPORTS,
		page,
		limit,
		sort,
		name,
		client,
		status,
		programName,
		welcomeCall,
		patientReadings,
		currentDate,
		abnormalVitals,
		city,
		state,
		isAlert,
		untrained,
		lastMeasurementLt,
		healthCondition,
		search,
		reportBy,
		program,
	]
	let queryParams: Partial<IPatientsQueryProps> = {
		page,
		limit,
		sort,
		name,
		client,
		status,
		welcomeCall,
		patientReadings,
		abnormalVitals,
		currentDate,
		programName,
		city,
		state,
		isAlert,
		untrained,
		lastMeasurementLt,
		healthCondition,
		search,
		reportBy,
		program,
	}
	if (startDate && endDate) {
		queryKey = [...queryKey, startDate, endDate]
		queryParams = {
			...queryParams,
			startDate,
			endDate,
		}
	}
	return useQuery<
		IAxiosResponseWithPagination<Partial<INonHealthCareFullMember>>,
		any,
		IAxiosResponseWithPagination<Partial<INonHealthCareFullMember>>
	>(
		queryKey,
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getNonHealthCareMemberReports(queryParams, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			enabled: !!program,
			...rest,
		},
	)
}

interface ISmbpProgramQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<ProgramDropdownList>>>,
		Partial<INonHealthCareReport> {}

export function useSmbpPrograms({
	limit = DROPDOWN_LIMIT.LIMIT,
	page = 1,
	search = '',
	sort = '',
	isEnabled = true,
	...rest
}: ISmbpProgramQueryProps) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<ProgramDropdownList>>,
		any,
		IAxiosResponseWithPagination<Partial<ProgramDropdownList>>
	>(
		[ENDPOINTS.PROGRAM_FILTER_LIST, limit, page, search, sort],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getSmbpPrograms(
				{
					limit,
					page,
					search,
					sort,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: isEnabled,
			...rest,
		},
	)
}
