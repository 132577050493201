import { ALERT, CHECK_IN_INTERVAL } from 'const/auth'
import { IItems, SelectOption } from 'types/entities'
import { ISelectOption } from 'types/forms'
import { IFullVitalsProgram, ITrackingDevices } from '../Vital.interfaces'
import { AlertOptions, checkIntervalOptions } from '../Vitals.constants'

export class AddVitalPayload {
	program?: string | null = null
	item: string | null = null
	checkInInterval: ISelectOption | null = null
	checkInIntervalId: ISelectOption | null | any = null
	checkInFrequency: string | number | undefined | null = null
	maxLimit: string | number | undefined | null = null
	minLimit: string | number | undefined | null = null
	trackingDevices: string | null = null
	alerts?: ALERT | null | boolean = null
	otherParam?: string | number | undefined | null = null
	readingType?: any
	constructor({ ...data }: Partial<AddVitalInitialState> = {}) {
		this?.addProgram(data?.program)
		this.addItem(data?.item)
		this.addCheckInterval(data?.checkInIntervalId)
		this.addcheckInFrequency(data?.checkInFrequency)
		this.addlimitGreaterThan(data?.maxLimit)
		this.addlimitLessThan(data?.minLimit)
		this.addTrackingDevice(data?.trackingDevices)
		this.addAlert(data?.alerts)
		this.otherParamAdd(data?.otherParam)
		this.addReadingType(data?.readingType)
	}
	addReadingType(item: any) {
		if (item) {
			this.readingType = item
		}
	}
	otherParamAdd(otherParam: string | undefined | null | number) {
		if (otherParam) {
			this.otherParam = otherParam
		}
	}
	addProgram(program: string | undefined | null) {
		if (program) {
			this.program = program
		}
	}
	addItem(item: ISelectOption | null | undefined) {
		if (item) {
			this.item = item.value
		}
	}

	addCheckInterval(checkInterval: ISelectOption | null | undefined | any) {
		if (checkInterval) {
			this.checkInIntervalId = checkInterval?.id
			this.checkInInterval = checkInterval?.value
		}
	}

	addcheckInFrequency(item: string | undefined | null | number) {
		if (item) {
			this.checkInFrequency = item
		}
	}

	addlimitGreaterThan(item: string | undefined | null | number) {
		if (item) {
			this.maxLimit = item
		}
	}
	addlimitLessThan(item: string | undefined | null | number) {
		if (item) {
			this.minLimit = item
		}
	}
	addTrackingDevice(item: ISelectOption | null | undefined) {
		if (item) {
			this.trackingDevices = item?.value
		}
	}

	addAlert(alert: any) {
		if (alert) {
			if (alert?.value == 'yes') {
				this.alerts = true
			}
			if (alert.value == 'no') {
				this.alerts = false
			}
		}
	}
}

export class AddVitalInitialState {
	program?: string | null = null
	item: ISelectOption | null = null
	checkInIntervalId: ISelectOption | null | any = null
	checkInFrequency: string | number | null | undefined = null
	maxLimit: string | number | null = null
	minLimit: string | number | null = null
	trackingDevices: ISelectOption | null = null
	alerts: ISelectOption<ALERT> | null = null
	otherParam?: string | number | undefined | null = null
	readingType?: any

	constructor({ ...data }: Partial<IFullVitalsProgram> = {}) {
		this.addProgram(data?.program)
		this.addItem(data?.item)
		this.addCheckInterval(data?.checkInIntervalId)
		this.addcheckInFrequency(data?.checkInFrequency)
		this.addLimitGreaterThan(data?.maxLimit)
		this.addLimitLessThan(data?.minLimit)
		this.addTrackingDevice(data?.trackingDevices)
		this.addAlert(data?.alerts)
		this.otherParamAdd(data?.otherParam)
		this.addReadingType(data?.readingType)
	}

	addReadingType(item?: any) {
		if (item) {
			this.readingType = item?.id
		}
	}

	otherParamAdd(item?: string | undefined | number) {
		if (item) {
			this.otherParam = item
		}
	}
	addProgram(program: string | undefined) {
		if (program) {
			this.program = program
		}
	}
	addItem(type?: IItems<string[]>) {
		if (type) {
			this.item = new SelectOption(type.id, type.vital)
		}
	}

	addCheckInterval(checkInterval: ISelectOption | any) {
		if (checkInterval) {
			this.checkInIntervalId = {
				label: checkInterval?.globalCodesName,
				value: checkInterval?.slug,
				id: checkInterval?.id,
			}
		}
	}

	addcheckInFrequency(frequency?: string | undefined | number) {
		if (frequency) {
			this.checkInFrequency = frequency
		}
	}
	addLimitGreaterThan(item?: string | undefined | number) {
		if (item) {
			this.maxLimit = item
		}
	}

	addLimitLessThan(item?: string | undefined | number) {
		if (item) {
			this.minLimit = item
		}
	}

	addTrackingDevice(trackingDevice?: ITrackingDevices<string[]>) {
		if (trackingDevice) {
			this.trackingDevices = new SelectOption(trackingDevice?.id, trackingDevice?.deviceName)
		}
	}

	addAlert(alert?: ALERT | undefined | null | boolean) {
		const alertValues = alert == true ? 'yes' : 'no'
		const foundValue = AlertOptions.find((i) => i.value === alertValues)
		if (foundValue) {
			this.alerts = foundValue
		}

		if (alert == undefined) {
			this.alerts = null
		}
	}
}
