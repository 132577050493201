export enum ROLES {
	ADMIN = 'clientAdmin',
	MA = 'ma',
	DOCTOR = 'doctor',
	SUPER_ADMIN = 'superAdmin',
	PROGRAM_MANAGER = 'programManager',
	PARTNER = 'partner',
	CARE_COORDINATOR = 'careCoordinator',
	PROGRAM_COORDINATOR = 'programCoordinator',
	EPIDEMIOLOGIST = 'epidemiologist',
	PROVIDER = 'provider',
	CHCT = 'chct',
	MEDICAL_ASSISTANT = 'medicalAssistant',
	COMMUNITY_BASED_PARTNER = 'communityBasedPartner',
}

export enum ROLES_NAMES {
	ADMIN = 'Admin',
	MA = 'Medical Assistance',
	DOCTOR = 'Doctor',
	SUPER_ADMIN = 'Super Admin',
	PROGRAM_MANAGER = 'Program Manager',
	PARTNER = 'Partner',
	CARE_COORDINATOR = 'Care Coordinator',
	PROGRAM_COORDINATOR = 'Program Coordinator',
	EPIDEMIOLOGIST = 'Epidemiologist',
	PROVIDER = 'Provider',
	CHCT = 'CHCT',
}

export enum NOT_FOUND_MESSAGE {
	NOT_RESULT_FOUND = 'No Result Found!',
}

export const USER_ROLES_BY_ACCESS_LEVEL = {
	// values are access level where 0 is simple user and 3 super admin
	[ROLES.MA]: 1,
	[ROLES.DOCTOR]: 2,
	[ROLES.ADMIN]: 3,
	[ROLES.SUPER_ADMIN]: 4,
	[ROLES.CHCT]: 3,

	[ROLES.PARTNER]: 5,
	[ROLES.CARE_COORDINATOR]: 2,
	[ROLES.PROGRAM_MANAGER]: 3,
	[ROLES.PROGRAM_COORDINATOR]: -1,
	[ROLES.EPIDEMIOLOGIST]: 1,
	[ROLES.COMMUNITY_BASED_PARTNER]: 1,
}

export enum PARTNER_STATUS {
	ACTIVE = 'active',
	IN_ACTIVE = 'inactive',
}

export enum PARTNER_ROLE {
	MEDICAL_ASSISTANT = 'ma',
	DOCTOR = 'doctor',
	PROVIDER = 'provider',
	CARE_COORDINATOR = 'care_coordinator',
	PROGRAM_COORDINATOR = 'program_coordinator',
}

export enum VITAL_TYPE_PROGRAM {
	BLOOD_PRESSURE = 'bloodPressure',
	GLUCOSE = 'glucose',
	WEIGHT = 'weight',
	TEMPERATURE = 'temperature',
	OXYGEN_SATURATION = 'oxygenSaturation',
	HEART_RATE = 'heartRate',
}

export enum CONTACT_POINT_TYPE {
	PHONE = 'phone',
	EMAIL = 'email',
	FAX = 'fax',
}

export enum VITAL_TYPE {
	BLOOD_PRESSURE = 'bloodPressure',
	GLUCOSE = 'glucose',
	WEIGHT = 'weight',
	TEMPERATURE = 'temperature',
	OXYGEN_SATURATION = 'oxygenSaturation',
	empty = '',
}

export enum CHECK_IN_INTERVAL {
	DAY = 'daily',
	WEEK = 'weekly',
	MONTH = 'monthly',
}

export enum TRACKING_DEVICE_VITALS {
	GLUCOMETER_250 = 'Glucometer 250',
	BLOOD_PRESSURE_CUFF = 'Blood Pressure cuff',
	WEIGHTING_MACHINE = 'Weighting Machine',
}

export enum ALERT {
	YES = 'yes',
	NO = 'no',
}

export enum DURATION {
	DAY = 'daily',
	WEEK = 'weekly',
	MONTH = 'monthly',
}
export enum VITALSOPTION {
	DAY = 'daily',
	WEEK = 'weekly',
	MONTH = 'monthly',
}
export enum CLAIM_STATUS {
	REVIEW = 'Review',
	GENERATE_INVOICE = 'GenerateInvoice',
	INVOICE = 'Invoice',
}

// export enum PATIENT_STAGE {
// 	INITIAL_PATIENT = 'initialPatient',
// 	ESTABLISHED_PATIENT = 'establishedPatient',
// 	URGENT_CARE_VISIT = 'urgentCareVisit',
// }
