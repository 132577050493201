import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import axios from 'services/axios'
import { commonMessages } from 'utils/messages'
import { QuestionnairePayload } from './Questionnaire.types'
import {
	IQuestionnaireList,
	IQuestionnaireServiceParams,
	ISuggestionBoxList,
	ISuggestionBoxParams,
} from '../Questionnaires.interface'
import { IPagination } from 'types/entities'
import _ from 'lodash'
import { onCreateEndpointsWithClientid } from 'utils/common'

/**
 * * GET TEMPLATE (view particular partner's details with the help of partner ID)
 */
export function getScreeningQuestion(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<IQuestionnaireList>(
		onCreateEndpointsWithClientid(ENDPOINTS.QUESTION) + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * Create Screening Question
 */
export function createScreeningQuestion(
	data: Partial<QuestionnairePayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.post<QuestionnairePayload>(
		onCreateEndpointsWithClientid(ENDPOINTS.QUESTION),
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * * Update Screening Question
 */
export function updateScreeningQuestion(
	id: string | undefined,
	data: Partial<QuestionnairePayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch<QuestionnairePayload>(
		onCreateEndpointsWithClientid(ENDPOINTS.QUESTION) + '/' + id,
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * * Get Existing Question
 */

export function getExistingQuestions(
	params: Partial<IQuestionnaireServiceParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IQuestionnaireList>>>(
		onCreateEndpointsWithClientid(ENDPOINTS.EXISTING_QUESTIONS),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// Suggestion box api

/**
 * Get FeeSchedules Listing
 */
export function getSuggestionBoxList(
	params: Partial<ISuggestionBoxParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<Partial<ISuggestionBoxList>>>(
		onCreateEndpointsWithClientid(ENDPOINTS.QUESTION_LIBRARY_SUGGESTION_BOX),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
