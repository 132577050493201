export enum QUESTION_TYPES {
	CHECKBOX = 'Checkbox',
	RADIO = 'Radio',
	TEXT = 'Text',
	DATEPICKER = 'Date picker',
	DROPDOWN = 'Drop-down',
}
export enum SIDE_DRAWER_LABEL_QUESTION {
	ADD_QUESTION = 'Add Question',
	EDIT_QUESTION = 'Edit Question',
}

export enum SIDE_DRAWER_LABEL {
	ADD_SCREENING = 'Create Screening',
	EDIT_SCREENING = 'Edit Screening',
}

export enum QUESTION_TYPES_VALUES {
	CHECKBOX = 'Checkbox',
	RADIO = 'Radio',
	INPUT = 'Input',
	DATEPICKER = 'datepicker',
	DECISION = 'Decision',
	LABEL = 'Label',
	// DROPDOWN = 'dropdown',
}

export enum FIELD_NAMES {
	TYPE = 'type',
	TITLE = 'title',
	ORDER = 'order',
	// DESCRIPTION = 'description',
	SCORE = 'score',
	REQUIRED = 'isRequired',
	ACTION_TYPE = 'action',
	DATA_TYPE = 'dataType',
	SCORING = 'isScore',
	OPTION_TYPE = 'optionType',
	EXISTING_QUESTIONS = 'reference',
}

export enum QUESTIONNAIRE_COMMON_MESSAGES {
	NO_QUESSTION_ADDED = 'No Question Added',
	NO_SCREEN_ADDED = 'No Screening Added',
	SELECT_TEMPLATE = 'Please Select Template',
}

export enum QUESTIONNAIRE_TYPE_SELECTED {
	QUESTION = 'question',
	OPTION = 'option',
}

export enum COMMON_MESSAGES {
	DELETE_QUESTION = 'Please confirm, would you like to delete this Question ?',
	DELETE_QUESTION_OPTION = 'Please confirm, would you like to delete this Option ?',
}
