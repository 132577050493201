import Calendar from '../../assets/images/ic_calendar_edit.svg'
import User from '../../assets/images/ic_user.svg'
import Persons from '../../assets/images/personsIcon.png'
import Copy from '../../assets/images/copyIcon.png'
import AppointmentCalendar from 'assets/images/ic-appointmentCalender.svg'
import TotalMde from 'assets/images/total_MDE.svg'
import CompletedMde from 'assets/images/complete_MDE.svg'
import InCompletedMdes from 'assets/images/incomplet_MDE.svg'
import Enrolled from 'assets/images/ic-profile.c9719a31.svg'
import NoVitalsTracked from 'assets/images/ic-mdi-alert-octagon.2f7e6f0f.svg'
import AbnormalVitals from 'assets/images/ic-attention.c8a8f118.svg'
import Compliant from 'assets/images/ic_calendar.01b445eb.svg'
import MDEIcon from 'assets/images/MDE_icon.svg'
import ReferralIcon from 'assets/images/referral_icon.svg'
//  task management icons
import TotalTask from 'assets/images/totalTask.svg'
import CompleteTask from 'assets/images/completeTask.svg'
import OngoingTask from 'assets/images/ongoingTask.svg'
import PendingTask from 'assets/images/pendingTask.svg'
import NotCompletedTask from 'assets/images/notCompletedTask.svg'

export const PartnersList = [
	{ label: 'careHalo', value: 'CareHalo' },
	{ label: 'careHalo1', value: 'CareHalo1' },
	{ label: 'careHalo2', value: 'CareHalo2' },
]

export const clientsColumns = [
	{
		Header: 'Company Name',
		name: 'companyName',
		accessor: 'col1',
	},

	{
		Header: 'City',
		name: 'city',
		accessor: 'col2',
	},
	{
		Header: 'Members enrolled',
		name: 'speciality',
		accessor: 'col3',
	},
	{
		Header: 'Goals',
		name: 'goals',
		accessor: 'col4',
	},

	{
		Header: 'Claims',
		name: 'revenue',
		accessor: 'col5',
	},
]

export const Options1 = {
	chart: {
		height: 350,
		type: 'radialBar',
	},
	fill: {
		colors: '#98C895',
	},
	stroke: {
		lineCap: 'round',
	},
	plotOptions: {
		radialBar: {
			startAngle: -90,
			endAngle: 90,
			hollow: {
				margin: 4,
				size: '75%',
				background: '#fff',
				image: undefined,

				position: 'front',
			},
			track: {
				background: '#e1e1e1',
				strokeWidth: '92%',
				margin: '2px', // margin is in pixels
			},
			dataLabels: {
				show: true,
				name: {
					offsetY: -30,
					show: true,
					color: '#888',
					fontSize: '13px',
				},
				value: {
					offsetY: -20,
					color: '#111',
					fontSize: '18px',
					fontWeight: 'bold',
					show: true,
				},
			},
		},
	},
	annotations: {
		points: [
			{
				x: '70%',
				y: '70',
				marker: {
					size: 30,
					style: {
						fill: '#22ef7e',
					},
				},
			},
		],
	},
	labels: ['Actual Goal'],
}
export const Options2 = {
	chart: {
		height: 450,
		type: 'radialBar',
	},
	fill: {
		colors: ['#C5D773', '#00BAC7'],
	},
	stroke: {
		lineCap: 'round',
	},
	plotOptions: {
		radialBar: {
			hollow: {
				margin: 6,
				size: '65%',
				background: '#fff',
				image: undefined,
				position: 'front',
			},
			track: {
				background: '#e1e1e1',
				strokeWidth: '88%',
				margin: '2px', // margin is in pixels
			},
			x: {
				show: true,
				name: {
					offsetY: -30,
					show: true,
					color: '#888',
					fontSize: '13px',
				},
				value: {
					offsetY: -20,
					color: '#111',
					fontSize: '18px',
					fontWeight: 'bold',
					show: true,
				},
				total: {
					show: true,
					label: 'Total',
				},
			},
		},
	},

	// labels: ['Actual Goal'],
	labels: ['Completed', 'Incomplete '],
}
export const Options3 = {
	chart: {
		height: 60,
		type: 'radialBar',
	},
	fill: {
		colors: ['#C5D773', '#00BAC7'],
	},
	stroke: {
		lineCap: 'round',
	},
	plotOptions: {
		radialBar: {
			hollow: {
				margin: 3,
				size: '70%',
				background: '#fff',
				image: undefined,
				position: 'front',
			},
			track: {
				background: '#fff',
				strokeWidth: '77%',
				margin: '2px', // margin is in pixels
			},
			dataLabels: {
				show: true,
				name: {
					show: false,
				},
				value: {
					offsetY: 5,
					color: '#111',
					fontSize: '14px',
					fontWeight: 'bold',
					show: true,
				},
			},
		},
	},
}
export const Options4 = {
	chart: {
		height: 60,
		type: 'radialBar',
	},
	fill: {
		colors: ['#C5D773'],
	},
	stroke: {
		lineCap: 'round',
	},
	plotOptions: {
		radialBar: {
			hollow: {
				margin: 3,
				size: '70%',
				background: '#fff',
				image: undefined,
				position: 'front',
			},
			track: {
				background: '#ffffff',
				strokeWidth: '77%',
				margin: '2px', // margin is in pixels
			},
			dataLabels: {
				show: true,
				name: {
					show: false,
				},
				value: {
					offsetY: 5,
					color: '#111',
					fontSize: '14px',
					fontWeight: 'bold',
					show: true,
				},
			},
		},
	},
}

const valueFormatter = {
	formatter: function (val: any) {
		return `${val}%`
	},
}

export enum RACE {
	WHITE = 'White',
	BLACK_OR_AFRICAN_AMERICAN = 'Black or African American',
	ASIAN = 'Asian',
	NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'Native Hawaiian or Other Pacific Islander',
	AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'American Indian or Alaska Native',
	UNKNOWN = 'Unknown',
}

export const genderOptions: any = (demoGraphicGenderData: any) => {
	return {
		chart: {
			type: 'pie',
		},
		colors: ['#A2D49F', '#00BAC7', '#C5D773'],
		fill: {
			opacity: 1,
			colors: ['#A2D49F', '#00BAC7', '#C5D773'],
		},
		labels: demoGraphicGenderData,
		legend: {
			position: 'left',
		},
		dataLabels: {
			enabled: false,
		},
		yaxis: {
			labels: valueFormatter,
		},
		responsive: [
			{
				breakpoint: 767,
				options: {
					chart: {
						width: '100%',
						height: '250',
					},
					plotOptions: {
						bar: {
							horizontal: false,
						},
					},
					legend: {
						height: '30',
						position: 'bottom',
						width: 'full',
						scroll: false,
					},
				},
			},
		],
	}
}

export const educationOptions: any = (demoGraphicEducationData: any) => {
	return {
		labels: demoGraphicEducationData,
		colors: ['#C5D773', '#A2D49F', '#00BAC7', '#EAA07A'],
		size: '90%',
		dataLabels: {
			enabled: false,
		},
		fill: {
			opacity: 1,
			colors: ['#C5D773', '#A2D49F', '#00BAC7', '#EAA07A'],
		},
		yaxis: {
			labels: valueFormatter,
		},
		stroke: {
			width: 1,
		},
		legend: {
			position: 'left',
		},
		hollow: {
			margin: 9,
			size: '75%',
			background: '#fff',
			image: undefined,
			position: 'front',
		},
		responsive: [
			{
				breakpoint: 767,
				options: {
					chart: {
						width: '100%',
						height: '330',
					},
					plotOptions: {
						bar: {
							horizontal: false,
						},
					},
					legend: {
						height: '80',
						position: 'bottom',
						width: 'full',
						scroll: false,
					},
				},
			},
		],
	}
}

export const householdOptions: any = (demoGraphicHouseHoldIncomeData: any) => {
	return {
		chart: {
			width: 380,
			type: 'polarArea',
		},
		colors: [
			'#A2D49F',
			'#00BAC7',
			'#F7BA1E',
			'#008BC7',
			'#69a88b',
			'#177178',
			'#A2D49F',
			'#EAA07A',
			'#C5D773',
			'#855b2f',
		],
		labels: demoGraphicHouseHoldIncomeData,
		fill: {
			opacity: 1,
			colors: [
				'#A2D49F',
				'#00BAC7',
				'#F7BA1E',
				'#008BC7',
				'#69a88b',
				'#177178',
				'#A2D49F',
				'#EAA07A',
				'#C5D773',
				'#855b2f',
			],
		},
		stroke: {
			width: 2,
		},
		yaxis: {
			show: false,
			labels: valueFormatter,
		},

		legend: {
			position: 'left',
		},
		plotOptions: {
			polarArea: {
				rings: {
					strokeWidth: 0,
				},
			},
		},
		responsive: [
			{
				breakpoint: 767,
				options: {
					chart: {
						width: '100%',
						height: '550',
					},
					plotOptions: {
						bar: {
							horizontal: false,
						},
					},
					legend: {
						height: '160',
						position: 'bottom',
						width: 'full',
						scroll: false,
					},
				},
			},
		],
	}
}

export const raceOptions: any = () => {
	return {
		labels: [
			RACE.WHITE,
			RACE.BLACK_OR_AFRICAN_AMERICAN,
			RACE.ASIAN,
			RACE.NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER,
			RACE.AMERICAN_INDIAN_OR_ALASKA_NATIVE,
			RACE.UNKNOWN,
		],
		yaxis: {
			show: false,
			labels: valueFormatter,
		},
		colors: ['#18DC95', '#008BC7', '#00BAC7', '#A2D49F', '#EAA07A', '#C5D773'],
		dataLabels: {
			enabled: false,
		},
		fill: {
			opacity: 1,
			colors: ['#18DC95', '#008BC7', '#00BAC7', '#A2D49F', '#EAA07A', '#C5D773'],
		},
		stroke: {
			width: 2,
		},
		legend: {
			position: 'left',
			width: 170,
			scroll: false,
		},
		responsive: [
			{
				breakpoint: 767,
				options: {
					chart: {
						width: '100%',
						height: '550',
					},
					plotOptions: {
						bar: {
							horizontal: false,
						},
					},
					legend: {
						height: '160',
						position: 'bottom',
						width: 'full',
						scroll: false,
					},
				},
			},
		],
	}
}

// totalEnrollments fieldName1
//  referrals fieldName1
// abnormalVitalAlerts fieldName1
// noReadingFor48Hours fieldName1
//  membersWithIncompleteMDEs fieldName1
// appointments fieldName1

export const tileIcons = (tileName: any) => {
	switch (tileName) {
		//  task management icons
		case 'totalTasks':
			return TotalTask
		case 'completedTasks':
			return CompleteTask
		case 'ongoingTasks':
			return OngoingTask
		case 'pendingTasks':
			return PendingTask
		case 'notCompleted':
			return NotCompletedTask
		//

		case 'totalEnrollments':
			return Enrolled
		case 'referrals':
			return ReferralIcon
		case 'abnormalVitalAlerts':
			return AbnormalVitals
		case 'noReadingFor48Hours':
			return NoVitalsTracked
		case 'membersWithIncompleteMDEs':
			return MDEIcon
		case 'appointments':
			return Compliant

		case 'totalMembers':
			return User
		case 'allPrograms':
		case 'programEnrollments':
			return Calendar
		case 'appointments':
			return AppointmentCalendar
		case 'totalSessions':
			return Copy
		case 'totalReferrals':
			return Persons
		case 'totalMdes':
			return TotalMde
		case 'completedMdes':
			return CompletedMde
		case 'inCompletedMdes':
			return InCompletedMdes
		default:
			return Calendar
	}
}
