import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import axios from 'services/axios'
import { commonMessages } from 'utils/messages'
import { IAssignModulesParams } from './AssignModules.interface'

/**
 * * GET request for all Specific modules.
 */
export function getServiceSpecificModules(
	params?: Partial<IAssignModulesParams>,
	source?: CancelTokenSource,
	signal?: any,
) {
	const promise = axios.get<Partial<any>>(
		ENDPOINTS.CLIENT_MODULES_ACCORDING_TO_SERVICE + '/' + params?.serviceId,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Create Module Assign
 */
export function createAssignModule(data: Partial<any>, source?: CancelTokenSource) {
	const promise = axios.post<any>(ENDPOINTS.CLIENT_MODULES_ASSIGN_TO_CLIENT, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
/**
 * Update Module Assign
 */
export function updateAssignModule(data: Partial<any>, source?: CancelTokenSource) {
	const promise = axios.patch<any>(ENDPOINTS.CLIENT_MODULES_UPDATE_INHERITED_TO_CLIENT, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
