import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { getPermission, getServicesClients } from './Services.service'
import { useUrlQuery } from 'hooks/url'

export function usePermissionDetails({ client, providerRole, isEnabled, ...rest }: any) {
	return useQuery<any, any, any>(
		[ENDPOINTS.PERMISSION, client, providerRole, isEnabled],
		async () => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getPermission({ client, providerRole }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!client && isEnabled,
			suspense: false,
			...rest,
		},
	)
}

export const getServiceDetail = (moduleObj: any) => {
	const {
		clientService: { clientServiceName, clientServiceImage },
		isAssigned,
	} = moduleObj
	return {
		serviceName: clientServiceName,
		isActive: isAssigned,
		image: clientServiceImage,
		serviceId: moduleObj?.id,
	}
}
//  This function is used to set dynamic grid column to display service boxes
export const calculateServiceColumnLength = (serviceLength: number) => {
	const columnLength = Math.min(Math.max(serviceLength, 1), 3)
	return columnLength
}

export function useServicesClients({
	limit,
	page,
	search,
	isEnabled,
	sort,
	isAssign,
	templateCode,
}: {
	limit?: number
	page?: number
	search?: string
	isEnabled?: boolean
	sort?: string
	clientId?: string | number | any
	isAssign?: string
	templateCode?: string
}) {
	const [urlParams] = useUrlQuery<{ clientSearch: string }>()
	const clientSearch = urlParams.clientSearch
	return useQuery<any>(
		[ENDPOINTS.SERVICES_CLIENTS, clientSearch, limit, page, search, sort, isAssign, templateCode],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getServicesClients(
				{
					limit,
					page,
					search: search || clientSearch,
					client: isAssign,
					templateCode,
				},
				source,
			)
		},
		{
			suspense: false,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: isEnabled,
		},
	)
}
