import axios from 'services/axios'
import { IFullMember, IMemberParams } from './Member.interfaces'
import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { commonMessages } from 'utils/messages'
import { convertUnix, toDate } from 'utils/time'
import _ from 'lodash'
import { IPagination } from 'types/entities'
import { errorNotify } from 'utils/toster'
import { onCreateEndpointsWithClientid } from 'utils/common'

export function getMember(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	// const promise = axios.get<IFullMember>(ENDPOINTS.PATIENTS + '/' + clientId + '/' + id)
	const promise = axios.get<IFullMember>(
		onCreateEndpointsWithClientid(ENDPOINTS.PATIENTS) + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
export function getMemberCalendarEvents(params: Partial<any>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)
	delete filteredParams.clientId
	delete filteredParams.patientId
	const promise = axios.get(
		onCreateEndpointsWithClientid(ENDPOINTS.MEMBER_CALENDAR_SLOT) + '/' + params?.patientId,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getMembers(
	id: string | undefined,
	client: string | undefined,
	params: Partial<IMemberParams>,
	source?: CancelTokenSource,
) {
	/**
	 * Variable Section
	 */
	const partnerConvert = params?.partner && JSON.parse(params?.partner)
	const partnerFilterParams = partnerConvert?.some((ds: any) => ds)
	if (!partnerFilterParams) {
		delete params?.partner
	}
	const updatedParams = { ...params, currentDate: convertUnix(toDate()) }
	const filteredParams = _.pickBy(updatedParams, _.identity)
	const promise = axios.get<IPagination<Partial<IFullMember>>>(ENDPOINTS.PATIENTS + '/' + client, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function downloadMemberExcel(
	patient?: string,
	clientId?: string,
	memberName?: string,
	params?: any,
	source?: CancelTokenSource,
) {
	delete params?.limit
	const payload: string | number | Object | unknown | any = {
		startDate: Number(convertUnix(params?.startDate)),
		endDate: Number(convertUnix(params?.endDate)),
		month: params?.month,
	}
	if (params.month) {
		delete payload.startDate, delete payload.endDate
	} else {
		delete payload.month
	}

	const checkPath = `${ENDPOINTS.DOWNLOAD_MEMBERVITAL_EXCEL}/${clientId}/${patient}`
	const promise = axios
		.get(checkPath, {
			params: payload,
			responseType: 'blob',
		})
		.then((response) => {
			// Create a temporary URL object to generate a download link
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `report_${memberName}.xlsx`)

			// Simulate a click event to trigger the download
			document.body.appendChild(link)
			link.click()

			// Clean up the temporary URL object
			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		})
		.catch(() => {
			errorNotify('Downloading error')
		})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel('Query was cancelled by React Query')
	}
	return promise
}
