import classnames from 'classnames'
import React from 'react'

interface Meta {
	active?: boolean
	error?: string
	value?: string
}

export interface InputProps {
	id?: string
	type?: string
	disabled?: boolean | any
	checked?: boolean
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
	onMouseLeave?: any
	min?: number
	rightElement?: React.ReactNode
	defaultValue?: string
	maxLength?: number
	value?: string | undefined | null | number
	placeholder?: string
	meta?: Meta
	error?: any
	className?: string
	autoComplete?: string
	onKeyPress?: any
	onKeyUp?: any
	onKeyDown?: any
	phone?: any
	unit?: string
	clientPhone?: any
	onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void
	readOnly?: boolean
}

function Input({
	type = 'text',
	placeholder,
	meta: { active = true } = {},
	error,
	rightElement,
	className,
	autoComplete = 'off',
	...rest
}: InputProps) {
	return (
		<div className="relative w-full">
			<input
				type={type}
				placeholder={active ? placeholder : undefined}
				className={classnames(className, rest.phone ? '!pl-[80px] w-full' : 'w-full')}
				{...rest}
				value={rest.value === null ? '' : rest.value}
				style={
					{
						// paddingLeft: rest.phone && '80px',
					}
				}
				autoComplete={autoComplete}
				// readOnly={readOnly}
			/>
			{rest.unit && (
				<div className="absolute top-0 right-[10px] flex items-center justify-center font-semibold text-sm h-full text-[#ccc]">
					{rest?.unit}
				</div>
			)}
			{/* <div className="absolute top-0 left-[10px] flex items-center justify-center h-full ">
				{rest.phone && '+1'}
			</div> */}
			{!!rightElement && (
				<div className="absolute top-0 right-[10px] flex items-center justify-center h-full ">
					{rightElement}
				</div>
			)}
		</div>
	)
}

export default Input
