import axios from 'services/axios'
import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { commonMessages } from 'utils/messages'

/**
 * * createVitalDevices section
 */

export function createVitalDevices(
	clientId: string | undefined,
	data: Partial<any>,
	source?: CancelTokenSource,
) {
	const promise = axios.post<any>(ENDPOINTS.PROGRAM_VITAL_DEVICES + '/' + clientId, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
